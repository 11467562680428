import { useNavigate } from 'react-router-dom';

import styles from '../index.module.css';

export function Root({ children, to, skeleton, onClick }) {
  const navigate = useNavigate();

  if (skeleton) {
    return (
      <div
        className={[
          'flex flex-col gap-4',
          'text-transparent cursor-default',
        ].join(' ')}
      >
        {children}
      </div>
    );
  }

  const handleClick = (e) => {
    if (e.target.closest('.edit-button')) {
      e.preventDefault();
      e.stopPropagation();
      return;
    }

    navigate(to);
    if (onClick) onClick(e);
  };

  return (
    <div className={styles.rootWrapper} onClick={handleClick}>
      {children}
    </div>
  );
}
