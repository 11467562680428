import * as Tabs from '@radix-ui/react-tabs';

import { Heading } from '@/components/Heading';
import { cn } from '@/helpers/utils';

export function TabHeading({
  label,
  value,
  size = 'xl',
  className,
  variant = 'default',
}) {
  if (variant === 'snapshots') {
    return (
      <Tabs.Trigger
        value={value}
        className={cn(
          'data-[state=active]:text-primary text-secondary border-b border-secondary data-[state=active]:border-primary hover:border-secondary transition',
          className,
        )}
      >
        <Heading
          size={size}
          className={'px-2 text-sm font-bold leading-4 text-inherit'}
        >
          {label}
        </Heading>
      </Tabs.Trigger>
    );
  } else if (variant === 'studioExplore') {
    return (
      <Tabs.Trigger
        value={value}
        className={cn(
          'data-[state=active]:text-primary text-secondary  data-[state=active]:border-primary hover:border-secondary transition',
          className,
        )}
      >
        <Heading
          size={size}
          className={`pr-3 text-base font-bold leading-4 text-inherit`}
        >
          {label}
        </Heading>
      </Tabs.Trigger>
    );
  } else if (variant === 'mobile') {
    return (
      <Tabs.Trigger
        value={value}
        className={cn(
          'data-[state=active]:text-primary text-secondary border-b-2 border-transparent data-[state=active]:border-primary hover:border-secondary transition',
          className,
        )}
      >
        <Heading
          size={size}
          className={`px-1 text-[1rem] font-semibold leading-4 text-inherit xs:text-[1.125rem]`}
        >
          {label}
        </Heading>
      </Tabs.Trigger>
    );
  }

  return (
    <Tabs.Trigger
      value={value}
      className={cn(
        'data-[state=active]:text-primary text-secondary border-b-2 border-transparent data-[state=active]:border-primary hover:border-secondary transition',
      )}
    >
      <Heading size={size} className={'px-2 text-inherit'}>
        {label}
      </Heading>
    </Tabs.Trigger>
  );
}
