import { gql } from '@apollo/client';

export const COLLECTIONS = gql`
  query Collections($cursor: String, $includeOrganizationViewable: Boolean) {
    collections(
      cursor: $cursor
      includeOrganizationViewable: $includeOrganizationViewable
    ) {
      data {
        id
        name
        itemsCount
        featuredImages {
          url
        }
        owner {
          fullName
          id
        }
        updatedAt
        items {
          type
          trendItemId
          postId
          tone
          id
        }
      }
      cursor
    }
  }
`;

export const COLLECTION = gql`
  query Collection($id: ID!) {
    collection(id: $id) {
      id
      name
      access
      owner {
        id
      }
    }
  }
`;

export const GET_COLLECTION_ITEMS_MERGED = gql`
  query GetCollectionItems($collection: ID!, $cursor: String) {
    collectionItemsMerged(collection: $collection, cursor: $cursor) {
      cursor
      data {
        id
        item {
          ... on ExploreFashionWeekItem {
            entityId
            id
            image {
              url
              width
              height
              blurhash
            }
            season
            fashionWeekSlug
            city
            category
            apparel
            itemType
          }
          ... on Tone {
            name
            code
            hex
            main
          }
          ... on StudioItem {
            entityId
            id
            image {
              url
              width
              height
              blurhash
            }
            createdBy {
              fullName
            }
          }
          ... on Post {
            entityId
            id
            image {
              url
              width
              height
              credit
              designer
              blurhash
            }
          }
          ... on TrendItem {
            entityId
            id
            image {
              url
            }
            market {
              id
            }
            tiType
            tone {
              hex
            }
            title
            optimalLaunch
            reach
            trendType
            yoyGrowth {
              percent
              data {
                full
                green
              }
            }
          }
          ... on SnapshotItem {
            isLiked
            collections
            weeklyData {
              growth
              trendType
              value
              visibility
              qualityEngagement
              totalEngagement
              totalPostCount
              postCount
              date
              apparelCount
            }
            twoWeeklyData {
              growth
              trendType
              value
              visibility
              qualityEngagement
              totalEngagement
              totalPostCount
              postCount
              date
              apparelCount
            }
            fourWeeklyData {
              growth
              trendType
              value
              visibility
              qualityEngagement
              totalEngagement
              totalPostCount
              postCount
              date
              apparelCount
            }
            lastFourWeeksMagnitude
            lastFourWeeksQualityEngagement
            lastTwoWeeksMagnitude
            lastTwoWeeksQualityEngagement
            lastWeekMagnitude
            lastWeekQualityEngagement
            ssiColor
            ssiFabric
            ssiPattern
            ssiType
            subcolor
            attributeKeyA
            attributeValueA
            attributeKeyB
            attributeValueB
            color
            colorTone
            audience {
              ageMin
              ageMax
              gender
              groups {
                audienceId
                platform
              }
            }
            itemId
            title
            tone {
              code
              hex
              main
              name
            }
            featuredImages {
              credit
              blurhash
              designer
              exploreItemId
              height
              source
              studioItemId
              systemPick
              url
              urlKey
              width
            }
          }
        }
        addedBy {
          createdAt
          fullName
          id
          organization {
            name
          }
          organizationRole
        }
        metadata {
          audienceName
          addedDate
        }
      }
    }
  }
`;

export const GET_COLLECTION_ITEMS_GROUPED = gql`
  query GetCollectionItems($collection: ID!, $cursor: String) {
    collectionItemsGrouped(collection: $collection) {
      tones(cursor: $cursor) {
        data {
          item {
            id
            name
            code
            hex
            main
          }
          addedBy {
            fullName
          }
        }
        cursor
      }
      images(cursor: $cursor) {
        data {
          item {
            ... on Post {
              image {
                url
                width
                height
                credit
                designer
                blurhash
              }
              id
              entityId
            }
            ... on ExploreFashionWeekItem {
              image {
                url
                width
                height
                blurhash
              }
              id
              entityId
            }
            ... on StudioItem {
              image {
                url
                width
                height
                blurhash
              }
              id
              entityId
            }
          }
          addedBy {
            fullName
          }
        }
        cursor
      }
      trendItems(cursor: $cursor) {
        data {
          item {
            id
            tiType
            tone {
              hex
            }
            image {
              url
            }
            market {
              id
            }
            title
            optimalLaunch
            reach
            trendType
            yoyGrowth {
              percent
              data {
                full
                green
              }
            }
          }
          addedBy {
            fullName
          }
        }
        cursor
      }
    }
  }
`;

export const REMOVED_EXPLORE_ITEM_FRAGMENT = gql`
  fragment RemovedExploreItem on ExploreItem {
    removed
  }
`;
