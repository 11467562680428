import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { Pencil } from 'lucide-react';

import { ME } from '@/api/authentication/queries';
import { useCollectionsQuery } from '@/api/collections/hooks';
import { CHANGE_COLLECTION_NAME } from '@/api/collections/mutations';
import { Container, Text } from '@/components';
import {
  PageHeader,
  PageHeaderDescription,
  PageHeaderTitle,
} from '@/components/PageHeader';
import { cn } from '@/helpers/utils';

import CollectionCard from './components';

import styles from './index.module.css';

const MyCollectionsLoadingScreen = () => {
  return (
    <div className="flex flex-col gap-8 p-[3.25rem]">
      <MyCollectionsPageHeader />
      <div className="mt-10 grid grid-cols-3 gap-7">
        {Array.from({ length: 6 }).map((_, index) => (
          <CollectionCard.Root key={index} skeleton>
            <CollectionCard.Images skeleton />
            <CollectionCard.Footer skeleton />
          </CollectionCard.Root>
        ))}
      </div>
    </div>
  );
};

const MyCollectionsPageHeader = () => {
  return (
    <PageHeader>
      <div className="flex items-center justify-between">
        <PageHeaderTitle>
          <div className={'flex gap-x-2 capitalize'}>Collections</div>
        </PageHeaderTitle>
        {/* <PageHeaderLink to="/create-trend-report/select-a-plan"> */}
        {/*   Create a New Report + */}
        {/* </PageHeaderLink> */}
      </div>
      <PageHeaderDescription>
        Here are the yours and your organization&#39;s collections. <br />
        You can create a new collection by clicking the &#34;+&#34; button next
        to any item you like.
      </PageHeaderDescription>
    </PageHeader>
  );
};

export const MyCollections = () => {
  const { data, loading, error, refetch } = useCollectionsQuery('collections', {
    variables: {
      cursor: null,
      includeOrganizationViewable: true,
    },
  });

  const { data: meData } = useQuery(ME);

  const isOwnerForCollection = (collection) => {
    return (
      meData?.me?.id === collection?.owner?.id ||
      meData?.me?.organizationRole === 'ADMIN'
    );
  };

  const [changeCollectionName] = useMutation(CHANGE_COLLECTION_NAME);

  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    if (location.pathname === '/collections') {
      refetch();
    }
  }, [location.pathname]);

  const [editingId, setEditingId] = useState(null);
  const [editedNames, setEditedNames] = useState({});

  if (loading) {
    return <MyCollectionsLoadingScreen />;
  }

  if (error) {
    return (
      <div className="flex h-full items-center justify-center">
        <span>Unexpected error {error.message}</span>
      </div>
    );
  }

  if (!data?.collections?.data.length) {
    return (
      <div className="flex h-full flex-col items-center justify-center gap-2 bg-gray-50">
        <span className={'text-2xl font-semibold'}>
          Oops! No Collections Yet 😊
        </span>
        <span className={'text-md'}>
          To start your own collection, simply click the &#34;Save&#34; or
          &#34;+&#34; button next to any item you like.
        </span>
      </div>
    );
  }

  return (
    <Container mainPage>
      <MyCollectionsPageHeader />

      <div className={cn(styles.cardsWrapper, 'mt-10')}>
        {data?.collections?.data.map((collection, index) => (
          <CollectionCard.Root
            key={collection.id}
            to={`/collections/${collection.id}/${encodeURIComponent(collection.name)}`}
            onClick={(e) => {
              if (
                e.target.closest('.edit-button') ||
                editingId === collection.id
              ) {
                e.preventDefault();
                e.stopPropagation();
              }
            }}
          >
            <CollectionCard.Images
              images={collection.featuredImages}
              totalItemCount={collection.itemsCount}
            />
            <CollectionCard.Footer>
              <div
                className="edit-button group flex w-full cursor-pointer items-start gap-2"
                onClick={(e) => {
                  e.stopPropagation();
                  navigate(
                    `/collections/${collection.id}/${encodeURIComponent(
                      collection.name,
                    )}`,
                  );
                }}
              >
                <div className="flex flex-col items-start justify-start gap-2">
                  {editingId === collection.id ? (
                    <input
                      className="max-w-[10rem] border-b border-gray-300 bg-transparent !text-[1.4rem] font-semibold outline-none"
                      defaultValue={
                        editedNames[collection.id] || collection.name
                      }
                      autoFocus
                      onBlur={() => setEditingId(null)}
                      onChange={(e) => {
                        setEditedNames((prev) => ({
                          ...prev,
                          [collection.id]: e.target.value,
                        }));
                      }}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          changeCollectionName({
                            variables: {
                              collection: editingId,
                              name: e.target.value,
                            },
                            onCompleted: () => {
                              setEditingId(null);
                            },
                          });
                        }
                      }}
                    />
                  ) : (
                    <div className="flex items-center gap-2">
                      <Text className="!text-[1.5rem]" weight="semibold">
                        {editedNames[collection.id] || collection.name}
                      </Text>
                      {index !== 0 && isOwnerForCollection(collection) && (
                        <div
                          onClick={(e) => {
                            if (
                              index !== 0 &&
                              isOwnerForCollection(collection)
                            ) {
                              e.stopPropagation();
                              setEditingId(collection.id);
                            }
                          }}
                        >
                          <Pencil
                            size={20}
                            className="hidden group-hover:block"
                          />
                        </div>
                      )}
                    </div>
                  )}
                  <Text className="text-gray-500">
                    {collection.owner.fullName}
                  </Text>
                </div>
              </div>
            </CollectionCard.Footer>
          </CollectionCard.Root>
        ))}
      </div>
    </Container>
  );
};
