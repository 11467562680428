// import React, { useEffect } from 'react'
import { useEffect } from 'react';
import ReactGA from 'react-ga4';
import {
  createBrowserRouter,
  matchRoutes,
  Navigate,
  Outlet,
  useLocation,
} from 'react-router-dom';
import { datadogRum } from '@datadog/browser-rum';
import * as Sentry from '@sentry/react';

import { ApiCacheProvider } from '@/api/context';
import { DialogProvider } from '@/components/ui/dialog';
import { AddToCollectionProvider } from '@/contexts/AddToCollectionContext';
import { AppContextProvider } from '@/contexts/AppContext';
import { AppFiltersProvider } from '@/contexts/AppFiltersContext';
import { CreateReportProvider } from '@/contexts/CreateReportContext';
import { ExploreProvider } from '@/contexts/ExploreContext';
import { FashionWeeksProvider } from '@/contexts/FashionWeeksContext';
import { FiltersProvider } from '@/contexts/FiltersContext';
import { InsightProvider } from '@/contexts/InsightContext';
import { StudioProvider } from '@/contexts/StudioContext';
import { ToastProvider } from '@/contexts/ToastContext';
import { TrendsProvider } from '@/contexts/TrendsContext';
import { AuthMiddleware } from '@/helpers/AuthMiddleware';
import { Layout } from '@/layouts/Layout';
import * as Pages from '@/pages/general';
import { RootErrorBoundary } from '@/pages/general/errorBoundary/RootErrorBoundary';

import * as routes from './';

ReactGA.initialize('G-W7CHGF2LYQ', {
  debug: true,
});

function computeViewName(routeMatches) {
  let viewName = '';
  for (let index = 0; index < routeMatches.length; index++) {
    const routeMatch = routeMatches[index];
    const path = routeMatch.route.path;
    if (!path) {
      continue;
    }

    if (path.startsWith('/')) {
      viewName = path;
    } else {
      viewName += viewName.endsWith('/') ? path : `/${path}`;
    }
  }

  return viewName || '/';
}

function DatadogWrapper({ children }) {
  const location = useLocation();

  useEffect(() => {
    const routeMatches = matchRoutes(router.routes, location.pathname);
    const viewName = routeMatches && computeViewName(routeMatches);
    if (viewName) {
      datadogRum.startView({ name: viewName });
    }
  }, [location.pathname]);

  return children;
}

const Providers = ({ children }) => {
  const location = useLocation();

  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: location.pathname + location.search,
      title: document.title,
    });
  }, [location]);

  // const [currentParams, setSearchParams] = useSearchParams()

  // useEffect(() => {
  //   if (!location.search) {
  //     console.log('currentParams', currentParams);
  //     const savedSearchParams = localStorage.getItem('filtersContext');
  //     if (savedSearchParams) {
  //       setSearchParams({ payload: savedSearchParams, ...currentParams });
  //     }
  //   } else {
  //     console.log('location.search', location.search);
  //   }
  // }, [location]);

  return (
    <ToastProvider>
      <ApiCacheProvider>
        <AuthMiddleware>
          <FiltersProvider>
            <AppFiltersProvider>
              <AddToCollectionProvider>
                <AppContextProvider>
                  <TrendsProvider>
                    <InsightProvider>
                      <ExploreProvider>
                        <FashionWeeksProvider>
                          <CreateReportProvider>
                            <StudioProvider>
                              <DialogProvider>
                                <Outlet />
                              </DialogProvider>
                            </StudioProvider>
                          </CreateReportProvider>
                        </FashionWeeksProvider>
                      </ExploreProvider>
                    </InsightProvider>
                  </TrendsProvider>
                </AppContextProvider>
              </AddToCollectionProvider>
            </AppFiltersProvider>
          </FiltersProvider>
        </AuthMiddleware>
      </ApiCacheProvider>
    </ToastProvider>
  );
};

export const router = createBrowserRouter([
  ...routes.authentication,
  {
    path: '',
    element: <Providers />,
    children: [
      {
        path: '/',
        element: <Layout />,
        // errorElement: <RootErrorBoundary/>,
        errorElement: (
          <Sentry.ErrorBoundary fallback={RootErrorBoundary}>
            <RootErrorBoundary />
          </Sentry.ErrorBoundary>
        ),
        children: [
          ...routes.account,
          ...routes.explore,
          ...routes.collections,
          ...routes.invite,
          ...routes.report,
          ...routes.trends,
          ...routes.studio,
          ...routes.moderation,
          ...routes.studioLicence,
          // ...routes.imagePreview,
          {
            path: '/',
            element:
              window.innerWidth < 640 ? (
                <Navigate to="/explore" replace />
              ) : (
                <Navigate to="/overview" replace />
              ),
          },
          {
            path: '*',
            element: <Pages.NotFound />,
          },
          {
            path: '/',
            element: <DatadogWrapper />,
          },
        ],
      },
    ],
  },
]);
