import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import { useOrganizationQuery } from '@/api/organization/hook';

dayjs.extend(utc);

export function Plans() {
  const { data } = useOrganizationQuery('organization');

  return (
    <div className="mb-12 flex w-full flex-col">
      <h5 className="mb-[21px] flex text-[36px] font-semibold   leading-[42px]">
        Plans
      </h5>
      <p className="font-regular w-[80%] text-[16px]   leading-[18px]">
        Explore our tailored subscription plans on the T-Fashion platform&rsquo;
        designed to elevate your experience with cutting-edge fashion analysis
        tools. Choose from a variety of plans crafted to meet your unique
        needs&rsquo; whether you&apos;re a trend enthusiast&rsquo; a fashion
        professional, or a data-driven decision-maker. Dive into a world of
        comprehensive insights and exclusive features&rsquo; all conveniently
        packaged to suit your preferences. Select, upgrade, or modify your plan
        effortlessly to unlock the full potential of T-Fashion, empowering your
        journey in the dynamic realm of fashion analytics. Join us on the
        forefront of industry innovation and discover the perfect plan to propel
        your fashion intelligence to new heights.
      </p>
      <div className="mt-[47px] flex flex-col gap-y-[32px]">
        <div className="flex flex-col gap-4">
          <h5 className="mb-[4px] flex max-w-[404px] justify-between text-[16px] font-semibold leading-[28px]">
            Your Market Plan
          </h5>

          <div className="flex items-center gap-8 overflow-y-hidden border-2 px-8 py-4 transition-colors hover:border-primary">
            <div
              className="min-h-full w-[12px] py-36"
              style={{
                backgroundColor:
                  data?.organization?.marketLicence?.marketPlan?.color ||
                  '#FFF',
              }}
            ></div>

            <div className="flex w-full flex-col gap-4">
              <div className="flex flex-col">
                <h5 className="font-semibold">
                  {data?.organization?.marketLicence?.marketPlan?.label}
                </h5>
                <p>
                  {data?.organization?.marketLicence?.marketPlan?.description}
                </p>
              </div>

              <div className="flex flex-col">
                <h5 className="font-semibold">Limitations</h5>

                <div className="mt-2 grid grid-cols-2">
                  <span>Explore Pages</span>
                  <span>
                    {
                      data?.organization?.marketLicence?.marketPlan?.limitations
                        .explore
                    }{' '}
                    Page
                  </span>
                  <span>Trend Item Pages</span>
                  <span>
                    {
                      data?.organization?.marketLicence?.marketPlan?.limitations
                        .trendItem
                    }{' '}
                    Page (4 item per page)
                  </span>
                  <span>Trend Color Pages</span>
                  <span>
                    {
                      data?.organization?.marketLicence?.marketPlan?.limitations
                        .trendColor
                    }{' '}
                    Page (6 color per page)
                  </span>
                  <span>Invitation</span>
                  <span>
                    {
                      data?.organization?.marketLicence?.marketPlan?.limitations
                        .invitation
                    }{' '}
                    Invitation
                  </span>
                </div>
              </div>

              <Link
                to="/create-trend-report/select-a-plan"
                className="max-w-fit cursor-pointer self-end border-2 border-primary px-4 py-2 font-bold text-primary transition-colors hover:bg-primary hover:text-white"
              >
                Upgrade Plan
              </Link>
            </div>
          </div>
        </div>

        <div className="flex flex-col gap-4">
          <div className="flex items-center justify-between">
            <h5 className="mb-[4px] flex max-w-[404px] justify-between text-[16px] font-semibold leading-[28px]">
              Your Studio Plans
            </h5>

            <Link to="/studio-licence/select-plan">
              <span className="cursor-pointer text-sm font-medium underline">
                Purchase New Licence
              </span>
            </Link>
          </div>

          <div className="flex flex-col gap-2">
            {data?.organization?.studioLicences.map((licence, i) => (
              <div
                className="flex items-center gap-8 border-2 px-8 py-4 transition-colors hover:border-primary"
                key={i}
              >
                <div
                  className="w-[12px] py-44"
                  style={{
                    backgroundColor: licence.studioPlan?.color || '#FFF',
                  }}
                />

                <div className="flex w-full flex-col gap-4">
                  <div className="flex flex-col">
                    <h5 className="font-semibold">
                      {licence.studioPlan?.label}
                    </h5>
                    <p>{licence.studioPlan?.description}</p>
                  </div>

                  <div className="flex w-full flex-col gap-2">
                    <h5 className="font-semibold">Features</h5>
                    <div className="grid w-full grid-cols-2">
                      <span>Studio Enabled</span>
                      <span>
                        {licence.studioPlan?.featureFlags.isStudioEnabled
                          ? 'Enabled'
                          : 'Disabled'}
                      </span>
                      <span>Export Mode</span>
                      <span>
                        {licence.studioPlan?.featureFlags
                          .isStudioExpertModeEnabled
                          ? 'Enabled'
                          : 'Disabled'}
                      </span>
                      <span>Private Image</span>
                      <span>
                        {licence.studioPlan?.featureFlags.isPrivateImageEnabled
                          ? 'Enabled'
                          : 'Disabled'}
                      </span>
                    </div>
                  </div>

                  <div className="flex w-full flex-col gap-2">
                    <h5 className="font-semibold">
                      Total Usage {licence.members.length}/{licence.quantity}
                    </h5>

                    <h5 className="font-semibold">Members</h5>
                    <div className="flex flex-col">
                      {licence.members.map((member) => (
                        <span key={member.email}>
                          {member.fullName} ({member.email})
                        </span>
                      ))}
                    </div>
                  </div>

                  <div className="grid w-full grid-cols-2">
                    <h5 className="font-semibold">Credits Renews At</h5>
                    <h3 className="text-lg font-semibold">
                      {/* {dayjs(licence.creditsRenewsAt)
                        .utc()
                        .format('DD MMMM YYYY')} */}
                    </h3>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
