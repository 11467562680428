import { datadogRum } from '@datadog/browser-rum';
import { usePostHog as usePostHogOriginal } from 'posthog-js/react';

import { useUserQuery } from '@/api/authentication/hook';

export function useEventCapture() {
  const { data } = useUserQuery('me');
  const posthogOriginal = usePostHogOriginal();

  function captureEvent(event, payload) {
    posthogOriginal.capture(
      event,
      payload ?? {
        message: 'EMPTY PAYLOAD',
      },
    );
  }

  function setIdentity(identity) {
    const user = data?.me;

    const organization = user?.organization?.id;

    const identityPayload = identity ?? {
      id: user?.id,
      email: user.email,
      name: user?.fullName || user.email,
      organization,
    };

    posthogOriginal.identify(identityPayload.id, identityPayload);

    if (organization) {
      posthogOriginal.group('organization', organization);
    }

    datadogRum.setUser(identityPayload);
  }

  function resetIdentity() {
    posthogOriginal.reset();
    datadogRum.resetUser();
  }

  return { captureEvent, setIdentity, resetIdentity };
}
