import React from 'react';

import { cn } from '@/helpers/utils';

export function FormInput({ children, className, ...props }) {
  return (
    <div
      className={cn('flex w-full flex-col gap-[0.25rem]', className)}
      {...props}
    >
      {children}
    </div>
  );
}

export function FormLabel({
  children,
  className,
  indent = false,
  required = false,
  isMobile = false,
  ...props
}) {
  return (
    <label
      className={cn(
        'text-sm leading-5 font-semibold pb-2',
        indent && 'pl-[1rem]',
        isMobile && 'text-base leading-7 font-medium',
        className,
      )}
      {...props}
    >
      {children}
      {required && <span className={'ml-0.5 text-red-700'}>*</span>}
    </label>
  );
}
