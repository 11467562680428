import React, { useEffect, useState } from 'react';
import { Blurhash } from 'react-blurhash';
import { NavLink } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import c from 'classnames';
import { useElementSize } from 'usehooks-ts';

import { ME } from '@/api/authentication';
import {
  DOWNVOTE_APPAREL,
  EXCLUDE_ACCOUNT,
  EXCLUDE_APPAREL,
  UPVOTE_APPAREL,
} from '@/api/explore/mutations';
import { AddToCollection } from '@/components';
import { cn } from '@/helpers/utils';

import { More, Tones } from '.';

const Backdrop = () => (
  <div
    className="absolute bottom-0 left-0 z-50 h-[45%] w-full opacity-0 transition-all ease-in-out group-hover:opacity-100"
    style={{
      background:
        'linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.52) 60.88%)',
    }}
  />
);

const Image = ({ url, className }) => (
  <img className={cn('w-full object-contain', className)} src={url} alt="" />
);

const Link = ({ title }) => (
  <div className="absolute bottom-[1.25rem] flex w-full items-center px-[0.875rem] py-[0px]">
    <NavLink
      className="flex w-full items-center justify-between text-[1rem] font-semibold leading-[1.5rem] text-white no-underline"
      to="/"
    >
      <span className="pt-[0.125rem] capitalize">
        {title.replace('-', ' ')}
      </span>
      <span>
        <svg
          width="15"
          height="17"
          viewBox="0 0 15 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6.42632 15.8844L11.7823 9.77017L12.4547 9.58423L12.4547 9.36449L-3.39295e-07 9.36449L-4.15158e-07 7.65724L12.4547 7.65724L12.4547 7.4375L11.8228 7.25156L6.40384 1.13736L7.4629 -3.20891e-07L15 8.50966L7.46515 17L6.42632 15.8844Z"
            fill="white"
          />
        </svg>
      </span>
    </NavLink>
  </div>
);

export const BlurhashImage = ({ image, width = false, height = false }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // start loading original image
    const imageToLoad = new window.Image();
    imageToLoad.src = image.url;
    imageToLoad.onload = () => {
      // When image is loaded replace the src and set loading to false
      setLoading(false);
    };
  }, [image]);

  const calculatedWidth = height
    ? (height / image.height) * image.width
    : width;
  const calculatedHeight = height || (width / image.width) * image.height;

  return (
    <>
      <div
        style={{
          width: `${calculatedWidth}px`,
          maxWidth: '100%',
          height: `${calculatedHeight}px`,
          position: 'relative',
        }}
        className={'max-w-full'}
      >
        <div className={'absolute z-10 h-full w-full'}>
          {!loading && (
            <div
              className={'h-full w-full'}
              style={{
                animation: 'fadein .3s ease-in-out',
              }}
            >
              <Image url={image.url} />
            </div>
          )}
        </div>

        <Blurhash
          hash={image.blurhash}
          width={calculatedWidth}
          height={calculatedHeight}
          resolutionX={32}
          resolutionY={32}
          punch={1}
        />
      </div>
    </>
  );
};

function ExploreApparelModeration({ item: { id, influencer } }) {
  const [isModerator, setIsModerator] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const { data } = useQuery(ME, {
    onCompleted: (data) => {
      const eligibleRoles = ['admin', 'moderator', 'annotator'];

      if (data.me && eligibleRoles.includes(data.me.role)) {
        setIsModerator(true);
      }
    },
  });

  const [upvoteApparel, { loading: upvoteLoading, data: upvoteData }] =
    useMutation(UPVOTE_APPAREL, {});
  const [downvoteApparel, { loading: downvoteLoading, data: downvoteData }] =
    useMutation(DOWNVOTE_APPAREL, {});

  const [banApparel, { loading: banApparelLoading, data: banApparelData }] =
    useMutation(EXCLUDE_APPAREL, {});
  const [banAccount, { loading: banAccountLoading, data: banAccountData }] =
    useMutation(EXCLUDE_ACCOUNT, {});

  if (!isModerator) return null;

  const ModerationButton = ({ color, children, ...props }) => {
    return (
      <>
        <div
          className={
            'text-sm font-semibold text-center w-full hover:bg-gray-300 px-2 py-1 rounded-sm cursor-pointer text-' +
            (color === 'red' ? 'red-700' : 'green-900')
          }
          {...props}
        >
          {children}
        </div>
      </>
    );
  };

  return (
    <>
      <div className="relative w-full bg-gray-200 p-2">
        <div className="flex flex-col items-center justify-between">
          <div className="flex w-full items-center justify-between gap-x-2">
            <ModerationButton
              color={'green'}
              onClick={() => {
                const areYouSure = window.confirm(
                  'Are you sure you want to upvote this apparel?',
                );
                if (areYouSure) {
                  upvoteApparel({
                    variables: {
                      apparelId: id,
                    },
                  });
                }
              }}
            >
              {upvoteLoading ? (
                'Loading...'
              ) : upvoteData?.upvoteApparel?.status ? (
                <b>
                  <u>Upvoted</u>
                </b>
              ) : (
                'Upvote'
              )}
            </ModerationButton>
            <ModerationButton
              color={'red'}
              onClick={() => {
                const areYouSure = window.confirm(
                  'Are you sure you want to downvote this apparel?',
                );
                if (areYouSure) {
                  downvoteApparel({
                    variables: {
                      apparelId: id,
                    },
                  });
                }
              }}
            >
              {downvoteLoading ? (
                'Loading...'
              ) : downvoteData?.downvoteApparel?.status ? (
                <b>
                  <u>Downvoted</u>
                </b>
              ) : (
                'Downvote'
              )}
            </ModerationButton>
          </div>
          <div className="my-2 w-full border-b border-gray-300"></div>
          <div className="flex w-full items-center justify-between gap-x-2">
            <ModerationButton
              color={'green'}
              onClick={() => {
                const areYouSure = window.confirm(
                  'Are you sure you want to ban this apparel?',
                );
                if (areYouSure) {
                  banApparel({
                    variables: {
                      apparelId: id,
                    },
                  });
                }
              }}
            >
              {banApparelLoading ? (
                'Loading...'
              ) : banApparelData?.excludePhoto?.status ? (
                <b>
                  <u>Banned Apparel</u>
                </b>
              ) : (
                'Ban Apparel'
              )}
            </ModerationButton>
            <ModerationButton
              color={'red'}
              onClick={() => {
                const areYouSure = window.confirm(
                  'Are you sure you want to ban this influencer?',
                );
                if (areYouSure) {
                  banAccount({
                    variables: {
                      apparelId: id,
                    },
                  });
                }
              }}
            >
              {banAccountLoading ? (
                'Loading...'
              ) : banAccountData?.excludeAccount?.status ? (
                <b>
                  <u>Banned Account</u>
                </b>
              ) : (
                'Ban Account'
              )}
            </ModerationButton>
          </div>
        </div>
      </div>
    </>
  );
}

export const Card = ({ exploreItem, index }) => {
  const [ref, { width }] = useElementSize();
  const [closeMore, setCloseMore] = useState(false);
  const { image, title, id } = exploreItem;

  return (
    <div>
      <div
        className={c(
          'group relative w-full cursor-pointer bg-gray-100',
          exploreItem.disabled ? 'opacity-50' : 'opacity-100',
        )}
        onMouseLeave={() => setCloseMore(true)}
        onMouseEnter={() => setCloseMore(false)}
        ref={ref}
      >
        <BlurhashImage image={image} width={width} />

        <Backdrop />

        <div className="absolute inset-0 z-50 flex cursor-pointer opacity-0 transition-all ease-in-out group-hover:opacity-100">
          <div className="absolute top-[0.875rem] flex w-full items-center justify-between px-[0.875rem] py-0">
            <Tones exploreItem={exploreItem} setCloseMore={setCloseMore} />
            <div className="flex w-full items-center justify-end gap-x-[0.5rem]">
              <AddToCollection itemId={id} />
              {/* <SaveToCollection
                closeSave={closeSave}
                selectedTone={selectedTone}
                setSelectedTone={setSelectedTone}
                id={id}
              >
                <button className="color-[#28ff64] h-[1.48875rem] w-[3.625rem] cursor-pointer border-none bg-white pt-[0.0625rem] text-[0.875rem] font-semibold leading-[1.3125rem] outline-none transition-all ease-in-out hover:bg-charcoal hover:text-white">
                  Save
                </button>
              </SaveToCollection> */}
              <More closeMore={closeMore} setCloseMore={setCloseMore} />
            </div>
          </div>
          <Link title={title} />
        </div>
      </div>

      <ExploreApparelModeration item={exploreItem} />
    </div>
  );
};
