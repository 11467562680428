import { useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';

import { ME } from '@/api/authentication';
import { TFashion } from '@/assets/svg';
import { useEventCapture } from '@/hooks/useEventCapture';

export function AuthMiddleware({ children }) {
  const [me, { loading, data }] = useLazyQuery(ME);

  const { setIdentity, resetIdentity } = useEventCapture();

  useEffect(() => {
    const accessToken = localStorage.getItem('accessToken');
    if (accessToken) {
      me();
    } else {
      resetIdentity();
    }
  }, [me]);

  useEffect(() => {
    if (data?.me) {
      setIdentity();
    }
  }, [data]);

  if (loading) {
    return (
      <div className="flex h-full w-full items-center justify-center">
        <TFashion />
      </div>
    );
  }

  return <>{children}</>;
}
