import { gql } from '@apollo/client';

export const CREATE_COLLECTION = gql`
  mutation CreateCollection($name: String!) {
    createCollection(name: $name) {
      name
      id
    }
  }
`;

export const ADD_APPAREL_TO_COLLECTION = gql`
  mutation AddApparelToCollection(
    $collection: ID!
    $apparel: ID!
    $metadata: MetadataInput
  ) {
    addApparelToCollection(
      collection: $collection
      apparel: $apparel
      metadata: $metadata
    ) {
      status
    }
  }
`;

export const ADD_TONE_TO_COLLECTION = gql`
  mutation AddToneToCollection(
    $collection: ID!
    $tone: String!
    $metadata: MetadataInput
  ) {
    addToneToCollection(
      collection: $collection
      tone: $tone
      metadata: $metadata
    ) {
      status
    }
  }
`;

export const ADD_TREND_ITEM_TO_COLLECTION = gql`
  mutation AddTrendItemToCollection(
    $collection: ID!
    $trendItem: ID!
    $metadata: MetadataInput
    $season: String
  ) {
    addTrendItemToCollection(
      collection: $collection
      trendItem: $trendItem
      metadata: $metadata
      season: $season
    ) {
      status
    }
  }
`;

export const REMOVE_ITEM_FROM_COLLECTION = gql`
  mutation RemoveItemFromCollection(
    $collection: ID!
    $key: String!
    $value: String!
  ) {
    removeItemFromCollection(
      collection: $collection
      key: $key
      value: $value
    ) {
      status
    }
  }
`;

export const REMOVE_ITEM_FROM_COLLECTION_BY_ID = gql`
  mutation RemoveItemFromCollectionById($collection: ID!, $itemId: ID!) {
    removeItemFromCollectionById(collection: $collection, itemId: $itemId) {
      status
    }
  }
`;

export const DELETE_COLLECTION = gql`
  mutation DeleteCollection($collection: ID!) {
    deleteCollection(collection: $collection) {
      status
    }
  }
`;

export const CHANGE_COLLECTION_ACCESS = gql`
  mutation ChangeCollectionAccess($collection: ID!, $access: String!) {
    changeCollectionAccess(collection: $collection, access: $access) {
      status
    }
  }
`;

export const ADD_ITEM_TO_DEFAULT = gql`
  mutation AddItemToDefault(
    $metadata: MetadataInput
    $season: String
    $item: String
    $type: String
  ) {
    addItemToDefault(
      metadata: $metadata
      season: $season
      item: $item
      type: $type
    ) {
      status
    }
  }
`;

export const ADD_ITEM_TO_COLLECTION = gql`
  mutation AddItemToCollection(
    $collection: ID
    $metadata: MetadataInput
    $season: String
    $type: String
    $value: String
  ) {
    addItemToCollection(
      collection: $collection
      metadata: $metadata
      season: $season
      type: $type
      value: $value
    ) {
      status
    }
  }
`;

export const REMOVE_ITEM_FROM_DEFAULT = gql`
  mutation RemoveItemFromDefault($item: String) {
    removeItemFromDefault(item: $item) {
      status
    }
  }
`;

export const CHANGE_COLLECTION_NAME = gql`
  mutation ChangeCollectionName($collection: ID!, $name: String!) {
    changeCollectionName(collection: $collection, name: $name) {
      status
      collectionName
    }
  }
`;
