import { FlexColumn, FlexRow } from '@/components';
import { Icon } from '@/icons';

export function Footer({ children, skeleton }) {
  if (skeleton) {
    return (
      <div className="ghost py-4 text-transparent">
        <span>Collection Name</span>
      </div>
    );
  }

  return (
    <FlexRow
      items="center"
      justify="between"
      className="w-full border-2 border-charcoal px-8 py-5 transition hover:bg-gray-50"
    >
      <FlexColumn className="w-full" gap="sm">
        {children}
      </FlexColumn>

      <Icon name="chevron-right" />
    </FlexRow>
  );
}
