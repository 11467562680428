import React from 'react';

export const CreateReportCaption = () => (
  <>
    <h5 className=" mb-[16px] mt-[52px] text-[48px]  font-semibold leading-[55px]">
      Create Trend Report
    </h5>
    <p className="font-regular max-w-[546px] text-center text-[14px] leading-[173%] text-[#626775] ">
      Craft compelling trend reports utilizing our sophisticated analytics,
      enabling you to anticipate and lead in the fluctuating fashion domain.
    </p>
  </>
);
