import { InView } from 'react-intersection-observer';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';

export function LazyLoadTrigger({ fetchMore, cursorNext, loading }) {
  return (
    <InView
      onChange={async (inView) => {
        if (inView && !loading) {
          fetchMore({
            variables: {
              cursor: cursorNext,
            },
          });
        }
      }}
    />
  );
}

export function LazyLoadButton({
  fetchMore,
  cursorNext,
  loading,
  hidden,
  disabled,
}) {
  return (
    <button
      className={`${hidden ? 'hidden' : ''} ${disabled ? 'border-secondary text-secondary' : ''} mt-4 w-40 border-2 border-black bg-white p-4 text-lg font-semibold text-black`}
      onClick={async () => {
        if (!loading) {
          fetchMore({
            variables: {
              cursor: cursorNext,
            },
          });
        }
      }}
    >
      {loading ? 'Loading...' : 'Load More'}
    </button>
  );
}

export function ExploreLoadingScreen() {
  return (
    <div className="w-full">
      <ResponsiveMasonry
        columnsCountBreakPoints={{
          350: 2,
          900: 4,
          1200: 6,
        }}
      >
        <Masonry gutter={'1.625rem'}>
          {Array(32)
            .fill()
            .map((_, index) => (
              <div
                key={'lazyload_' + index}
                className="w-full bg-gray-200"
                style={{
                  height:
                    Math.floor(Math.random() * (40 - 10 + 1) + 10) + 'rem',
                }}
              />
            ))}
        </Masonry>
      </ResponsiveMasonry>
    </div>
  );
}

export function NoSuchResultsWereFound({ isTextSearch }) {
  return (
    <div className="flex h-full w-full flex-col items-center justify-start">
      <h4 className="py-8 text-center text-4xl font-semibold">
        {isTextSearch
          ? 'No such results were found for your text search'
          : 'No such results were found for your filters'}
      </h4>
      {isTextSearch && (
        <p className="text-center text-xl font-medium">
          (Please try a different search or remove the text search)
        </p>
      )}
    </div>
  );
}
