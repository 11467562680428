const exportSubColors = [
  {
    _id: 'Almond',
    colors: [
      {
        name: 'Moonbeam',
        main: 'beige',
        hex: 'CDC6BD',
        code: '13-0000',
      },
      {
        name: 'French Oak',
        main: 'beige',
        hex: 'CBC1B3',
        code: '13-0400',
      },
      {
        name: 'Fog',
        main: 'beige',
        hex: 'D0C5B1',
        code: '13-0607',
      },
      {
        name: 'Ancient Scroll',
        main: 'beige',
        hex: 'CBC3B7',
        code: '13-0404',
      },
      {
        name: 'Almond Peach',
        main: 'brown',
        hex: 'D8C8BD',
        code: '12-1406',
      },
      {
        name: 'Cement',
        main: 'brown',
        hex: 'C4B6A6',
        code: '14-0708',
      },
      {
        name: 'Castle Wall',
        main: 'beige',
        hex: 'C9C0AB',
        code: '14-0108',
      },
      {
        name: 'Crème Brûlée',
        main: 'brown',
        hex: 'DBCCB5',
        code: '13-1006',
      },
      {
        name: 'Frosted Almond',
        main: 'brown',
        hex: 'D2C2AC',
        code: '13-1012',
      },
      {
        name: 'Pink Tint',
        main: 'brown',
        hex: 'DBCBBE',
        code: '12-1404',
      },
      {
        name: 'Wheat',
        main: 'brown',
        hex: 'DEC5A5',
        code: '13-1016',
      },
      {
        name: 'Sheer Bliss',
        main: 'brown',
        hex: 'DBCABA',
        code: '13-1104',
      },
      {
        name: 'Wood Ash',
        main: 'brown',
        hex: 'D7CAB0',
        code: '14-1108',
      },
      {
        name: 'Shifting Sand',
        main: 'brown',
        hex: 'D8C0AD',
        code: '14-1210',
      },
      {
        name: 'Oat Milk',
        main: 'beige',
        hex: 'D3C9BE',
        code: '13-0003',
      },
      {
        name: 'Whitecap Gray',
        main: 'brown',
        hex: 'E0D5C6',
        code: '12-0304',
      },
      {
        name: 'Biscotti',
        main: 'brown',
        hex: 'DAC7AB',
        code: '13-1009',
      },
      {
        name: 'Peyote',
        main: 'gray',
        hex: 'C5BBAE',
        code: '14-1106',
      },
      {
        name: 'Putty',
        main: 'green',
        hex: 'D4CAB0',
        code: '13-0711',
      },
    ],
  },
  {
    _id: 'Apricot ',
    colors: [
      {
        name: 'Amber Yellow',
        main: 'orange',
        hex: 'FAB75A',
        code: '13-0942',
      },
      {
        name: 'Golden Apricot',
        main: 'orange',
        hex: 'DDA758',
        code: '14-1041',
      },
      {
        name: 'Marigold',
        main: 'orange',
        hex: 'FDAC53',
        code: '14-1050',
      },
      {
        name: 'Flame Orange',
        main: 'orange',
        hex: 'FB8B23',
        code: '15-1157',
      },
      {
        name: 'Autumn Glory',
        main: 'orange',
        hex: 'FF8813',
        code: '15-1263',
      },
      {
        name: 'Turmeric',
        main: 'orange',
        hex: 'FF850F',
        code: '15-1264',
      },
      {
        name: 'Amber',
        main: 'orange',
        hex: 'EFAD55',
        code: '14-1045',
      },
      {
        name: 'Chamois',
        main: 'orange',
        hex: 'F7B26A',
        code: '15-1145',
      },
      {
        name: 'Apricot',
        main: 'orange',
        hex: 'F19035',
        code: '15-1153',
      },
      {
        name: 'Apricot Tan',
        main: 'orange',
        hex: 'DD9760',
        code: '15-1237',
      },
      {
        name: 'Carrot Curl',
        main: 'orange',
        hex: 'FF8D19',
        code: '15-1262',
      },
      {
        name: 'Russet Orange',
        main: 'orange',
        hex: 'E47127',
        code: '16-1255',
      },
      {
        name: 'Warm Apricot',
        main: 'orange',
        hex: 'FFB765',
        code: '14-1051',
      },
      {
        name: 'Blazing Orange',
        main: 'orange',
        hex: 'FFA74F',
        code: '15-1160',
      },
      {
        name: 'Bright Marigold',
        main: 'orange',
        hex: 'FF8D00',
        code: '15-1164',
      },
      {
        name: 'Tangelo',
        main: 'orange',
        hex: 'FF7E04',
        code: '15-1335',
      },
      {
        name: 'Sun Orange',
        main: 'orange',
        hex: 'F48037',
        code: '16-1257',
      },
      {
        name: 'Autumn Sunset',
        main: 'orange',
        hex: 'F38554',
        code: '16-1343',
      },
      {
        name: 'Dusty Orange',
        main: 'orange',
        hex: 'E27A53',
        code: '16-1344',
      },
      {
        name: 'Coral Rose',
        main: 'orange',
        hex: 'F3774D',
        code: '16-1349',
      },
      {
        name: 'Kumquat',
        main: 'orange',
        hex: 'FCAB4D',
        code: '14-1052',
      },
      {
        name: 'Iceland Poppy',
        main: 'orange',
        hex: 'F4973B',
        code: '15-1151',
      },
      {
        name: 'Bird of Paradise',
        main: 'orange',
        hex: 'FF8C55',
        code: '16-1357',
      },
      {
        name: 'Orange Tiger',
        main: 'orange',
        hex: 'F96815',
        code: '16-1358',
      },
      {
        name: "Puffin's Bill",
        main: 'orange',
        hex: 'E95C20',
        code: '16-1363',
      },
      {
        name: 'Vibrant Orange',
        main: 'orange',
        hex: 'FF7420',
        code: '16-1364',
      },
      {
        name: 'Exuberance',
        main: 'orange',
        hex: 'E86800',
        code: '17-1349',
      },
      {
        name: 'Celosia Orange',
        main: 'orange',
        hex: 'E8703A',
        code: '17-1360',
      },
      {
        name: 'Flame',
        main: 'orange',
        hex: 'F2552C',
        code: '17-1462',
      },
      {
        name: 'Orange Peel',
        main: 'orange',
        hex: 'FA7A35',
        code: '16-1359',
      },
      {
        name: 'Exotic Orange',
        main: 'orange',
        hex: 'FA6632',
        code: '16-1453',
      },
      {
        name: 'Mandarin Orange',
        main: 'orange',
        hex: 'EC6A37',
        code: '16-1459',
      },
      {
        name: 'Golden Poppy',
        main: 'orange',
        hex: 'F56733',
        code: '16-1462',
      },
      {
        name: 'Persimmon Orange',
        main: 'orange',
        hex: 'F47327',
        code: '16-1356',
      },
      {
        name: 'Nectarine',
        main: 'orange',
        hex: 'FF8656',
        code: '16-1360',
      },
      {
        name: 'Carrot',
        main: 'orange',
        hex: 'FD6F3B',
        code: '16-1361',
      },
      {
        name: 'Vermillion Orange',
        main: 'orange',
        hex: 'F9633B',
        code: '16-1362',
      },
      {
        name: 'Dragon Fire',
        main: 'orange',
        hex: 'FD652D',
        code: '16-1460',
      },
      {
        name: 'Apricot Brandy',
        main: 'red',
        hex: 'C26A5A',
        code: '17-1540',
      },
    ],
  },
  {
    _id: 'Baby Blue ',
    colors: [
      {
        name: 'Tinted Aqua',
        main: 'blue',
        hex: 'C1D8DD',
        code: '12-5211',
      },
      {
        name: 'Ancient Water',
        main: 'blue',
        hex: 'CCD6E6',
        code: '12-4108',
      },
      {
        name: 'Blue Glass',
        main: 'blue',
        hex: 'C7E3E1',
        code: '12-5206',
      },
      {
        name: 'Ice Melt',
        main: 'blue',
        hex: 'D3E4F1',
        code: '13-4306',
      },
      {
        name: 'Quiet Tide',
        main: 'blue',
        hex: 'C0E3F0',
        code: '13-4401',
      },
      {
        name: 'Skyway',
        main: 'blue',
        hex: 'ADBED3',
        code: '14-4112',
      },
      {
        name: 'Bit of Blue',
        main: 'blue',
        hex: 'E2EAEB',
        code: '11-4601',
      },
      {
        name: 'Country Air',
        main: 'blue',
        hex: 'CBD9E7',
        code: '12-4303',
      },
      {
        name: 'Halogen Blue',
        main: 'blue',
        hex: 'BDC6DC',
        code: '13-3920',
      },
      {
        name: 'Blue Finch',
        main: 'blue',
        hex: 'BECEE2',
        code: '13-4016',
      },
      {
        name: 'Baby Blue',
        main: 'blue',
        hex: 'B5C7D3',
        code: '13-4308',
      },
      {
        name: 'Nantucket Breeze',
        main: 'blue',
        hex: 'B7D1EA',
        code: '14-4005',
      },
      {
        name: 'Blue Frost',
        main: 'blue',
        hex: 'A7C3E2',
        code: '14-4130',
      },
      {
        name: 'Corydalis Blue',
        main: 'blue',
        hex: 'A9CADA',
        code: '14-4311',
      },
      {
        name: 'Porcelain Blue',
        main: 'blue',
        hex: '95C0CB',
        code: '14-4512',
      },
      {
        name: 'Blue Elixir',
        main: 'blue',
        hex: '8DCCDD',
        code: '14-4515',
      },
      {
        name: 'Pure Pond',
        main: 'blue',
        hex: '9ECFD8',
        code: '14-4716',
      },
      {
        name: 'Delicate Blue',
        main: 'blue',
        hex: 'C2DAE8',
        code: '12-4202',
      },
      {
        name: 'Pastel Blue',
        main: 'blue',
        hex: 'BCD3D5',
        code: '12-4607',
      },
      {
        name: 'Ballad Blue',
        main: 'blue',
        hex: 'C0CEDA',
        code: '13-4304',
      },
      {
        name: 'Misty Blue',
        main: 'blue',
        hex: 'BFCDCC',
        code: '13-4405',
      },
      {
        name: 'Angel Falls',
        main: 'blue',
        hex: 'A3BDD3',
        code: '15-4105',
      },
      {
        name: 'Sterling Blue',
        main: 'blue',
        hex: 'A2B9C2',
        code: '15-4309',
      },
      {
        name: 'Blue Haze',
        main: 'blue',
        hex: 'A5BCBB',
        code: '15-4707',
      },
      {
        name: 'Moonlight Jade',
        main: 'blue',
        hex: 'C7E5DF',
        code: '12-5408',
      },
      {
        name: 'Omphalodes',
        main: 'blue',
        hex: 'B5CEDF',
        code: '13-4200',
      },
      {
        name: 'Skywriting',
        main: 'blue',
        hex: 'C5D1DD',
        code: '13-4302',
      },
      {
        name: 'Cooling Spray',
        main: 'blue',
        hex: 'C3E1EE',
        code: '13-4410',
      },
      {
        name: 'Skyride',
        main: 'blue',
        hex: 'AFC3D3',
        code: '14-4215',
      },
      {
        name: 'Summer Song',
        main: 'blue',
        hex: 'A1C3DA',
        code: '14-4316',
      },
      {
        name: 'Cool Blue',
        main: 'blue',
        hex: 'A5C5D9',
        code: '14-4317',
      },
      {
        name: 'Cooling Vapors',
        main: 'blue',
        hex: '97C5DA',
        code: '14-4509',
      },
      {
        name: 'Spa Blue',
        main: 'gray',
        hex: 'D3DEDF',
        code: '12-4305',
      },
      {
        name: 'Barely Blue',
        main: 'gray',
        hex: 'DDE0DF',
        code: '12-4306',
      },
      {
        name: 'Blue Flower',
        main: 'gray',
        hex: 'D0D9D4',
        code: '12-5403',
      },
      {
        name: 'Blue Blush',
        main: 'gray',
        hex: 'D6DBD9',
        code: '12-4705',
      },
      {
        name: 'Blue Glow',
        main: 'aqua',
        hex: 'B2D4DD',
        code: '13-4409',
      },
      {
        name: 'Iced Aqua',
        main: 'aqua',
        hex: 'ACD3DC',
        code: '13-5410',
      },
      {
        name: 'Aqua-esque',
        main: 'aqua',
        hex: 'A3CCD6',
        code: '13-5411',
      },
      {
        name: 'Aquamarine',
        main: 'aqua',
        hex: '9DC3D4',
        code: '14-4313',
      },
      {
        name: 'Spun Sugar',
        main: 'aqua',
        hex: 'B4DCEA',
        code: '12-4401',
      },
      {
        name: 'Saltwater Slide',
        main: 'aqua',
        hex: 'CBE9F2',
        code: '12-4611',
      },
      {
        name: 'Atomizer',
        main: 'aqua',
        hex: '9AD4EA',
        code: '14-4514',
      },
      {
        name: 'Waterspout',
        main: 'aqua',
        hex: '96D8DE',
        code: '14-4618',
      },
      {
        name: 'Water Ballet',
        main: 'aqua',
        hex: '9FDFE4',
        code: '14-4715',
      },
      {
        name: 'Clearwater',
        main: 'aqua',
        hex: 'AAD5DB',
        code: '12-4608',
      },
      {
        name: 'Cooling Oasis',
        main: 'aqua',
        hex: 'BAD7D9',
        code: '12-5302',
      },
      {
        name: 'Spa Retreat',
        main: 'aqua',
        hex: 'B4E8EC',
        code: '13-4908',
      },
    ],
  },
  {
    _id: 'Black',
    colors: [
      {
        name: 'Deep Caverns',
        main: 'brown',
        hex: '2D2C2A',
        code: '19-4001',
      },
      {
        name: 'Scarab',
        main: 'green',
        hex: '24312D',
        code: '19-5350',
      },
      {
        name: 'Elegance',
        main: 'black',
        hex: '48473F',
        code: '19-0505',
      },
      {
        name: 'Night Sky',
        main: 'black',
        hex: '2A2A35',
        code: '19-3924',
      },
      {
        name: 'Caviar',
        main: 'black',
        hex: '2A2A2D',
        code: '19-4006',
      },
      {
        name: 'Total Eclipse',
        main: 'black',
        hex: '2D313D',
        code: '19-4010',
      },
      {
        name: 'Carbon',
        main: 'black',
        hex: '272F38',
        code: '19-4012',
      },
      {
        name: 'Forest River',
        main: 'black',
        hex: '33393B',
        code: '19-4405',
      },
      {
        name: 'Woodland Gray',
        main: 'black',
        hex: '383D3F',
        code: '19-5002',
      },
      {
        name: 'Bitumen',
        main: 'black',
        hex: '3D3A39',
        code: '19-0503',
      },
      {
        name: 'Licorice',
        main: 'black',
        hex: '3B3536',
        code: '19-1102',
      },
      {
        name: 'Deep Well',
        main: 'black',
        hex: '2D2A33',
        code: '19-3713',
      },
      {
        name: 'Black Cat',
        main: 'black',
        hex: '30313A',
        code: '19-3820',
      },
      {
        name: 'Obsidian',
        main: 'black',
        hex: '3B363C',
        code: '19-3902',
      },
      {
        name: 'Black Bean',
        main: 'black',
        hex: '2E272A',
        code: '19-3909',
      },
      {
        name: 'Black Beauty',
        main: 'black',
        hex: '27272A',
        code: '19-3911',
      },
      {
        name: 'Black Onyx',
        main: 'black',
        hex: '2C282C',
        code: '19-4003',
      },
      {
        name: 'Tap Shoe',
        main: 'black',
        hex: '2A2B2D',
        code: '19-4004',
      },
      {
        name: 'Stretch Limo',
        main: 'black',
        hex: '2B2C30',
        code: '19-4005',
      },
      {
        name: 'Meteorite',
        main: 'black',
        hex: '2C2929',
        code: '19-4008',
      },
      {
        name: 'Dark Sapphire',
        main: 'black',
        hex: '262B37',
        code: '19-4020',
      },
      {
        name: 'Blueberry',
        main: 'black',
        hex: '2C333E',
        code: '19-4021',
      },
      {
        name: 'Black Robe',
        main: 'black',
        hex: '2D2E30',
        code: '19-4206',
      },
      {
        name: 'Pirate Black',
        main: 'black',
        hex: '373838',
        code: '19-4305',
      },
      {
        name: 'Unexplored',
        main: 'black',
        hex: '3B3D3C',
        code: '19-5005',
      },
      {
        name: 'Clever Crows',
        main: 'black',
        hex: '3E3F40',
        code: '19-5303',
      },
      {
        name: 'Charcoal Art',
        main: 'black',
        hex: '38373D',
        code: '19-3916',
      },
      {
        name: 'Salute',
        main: 'black',
        hex: '282B34',
        code: '19-4011',
      },
      {
        name: 'Parisian Night',
        main: 'black',
        hex: '323441',
        code: '19-4022',
      },
      {
        name: 'After Midnight',
        main: 'black',
        hex: '30343F',
        code: '19-4109',
      },
      {
        name: 'Moonless Night',
        main: 'black',
        hex: '2F2D30',
        code: '19-4203',
      },
      {
        name: 'Bottomless Pit',
        main: 'black',
        hex: '282C30',
        code: '19-4204',
      },
      {
        name: 'Jet Black',
        main: 'black',
        hex: '2D2C2F',
        code: '19-0303',
      },
      {
        name: 'Forest Night',
        main: 'black',
        hex: '434237',
        code: '19-0414',
      },
      {
        name: 'Ganache',
        main: 'black',
        hex: '34292A',
        code: '19-1018',
      },
      {
        name: 'Bristol Black',
        main: 'black',
        hex: '3B3739',
        code: '19-1100',
      },
      {
        name: 'Black Hole',
        main: 'black',
        hex: '423F43',
        code: '19-3913',
      },
      {
        name: 'Sky Captain',
        main: 'black',
        hex: '262934',
        code: '19-3922',
      },
      {
        name: 'Affenpinscher',
        main: 'black',
        hex: '272B2D',
        code: '19-4000',
      },
      {
        name: 'Anthracite',
        main: 'black',
        hex: '28282D',
        code: '19-4007',
      },
      {
        name: 'Blue Graphite',
        main: 'black',
        hex: '323137',
        code: '19-4015',
      },
      {
        name: 'Blue Nights',
        main: 'black',
        hex: '363B48',
        code: '19-4023',
      },
      {
        name: "Printer's Ink",
        main: 'black',
        hex: '353536',
        code: '19-4100',
      },
      {
        name: 'Silhouette',
        main: 'black',
        hex: '252B36',
        code: '19-4103',
      },
      {
        name: 'Phantom',
        main: 'black',
        hex: '39373B',
        code: '19-4205',
      },
      {
        name: 'Vulcan',
        main: 'black',
        hex: '2E3036',
        code: '19-4218',
      },
      {
        name: 'Jet Set',
        main: 'black',
        hex: '272C2A',
        code: '19-5708',
      },
    ],
  },
  {
    _id: 'Blush',
    colors: [
      {
        name: 'Hushed Violet',
        main: 'beige',
        hex: 'D1C0BF',
        code: '14-3803',
      },
      {
        name: 'Slightly Pink',
        main: 'pink',
        hex: 'EDE4DF',
        code: '11-1006',
      },
      {
        name: 'Cream Pink',
        main: 'pink',
        hex: 'F6E4D9',
        code: '11-1306',
      },
      {
        name: 'Powder Puff',
        main: 'pink',
        hex: 'F3E0D6',
        code: '11-1404',
      },
      {
        name: 'Mother of Pearl',
        main: 'pink',
        hex: 'E9D4C3',
        code: '12-1006',
      },
      {
        name: 'Vanilla Cream',
        main: 'pink',
        hex: 'F5D8C6',
        code: '12-1009',
      },
      {
        name: 'Delicacy',
        main: 'pink',
        hex: 'F5E3E2',
        code: '11-2409',
      },
      {
        name: 'Salsa Rosa',
        main: 'pink',
        hex: 'F3DBD3',
        code: '12-1105',
      },
      {
        name: 'Peach Dust',
        main: 'pink',
        hex: 'F0D8CC',
        code: '12-1107',
      },
      {
        name: 'Silver Peony',
        main: 'pink',
        hex: 'E7CFC7',
        code: '12-1206',
      },
      {
        name: 'Crème de Pêche',
        main: 'pink',
        hex: 'F6D7C7',
        code: '12-1110',
      },
      {
        name: 'Cream Tan',
        main: 'pink',
        hex: 'E4C7B7',
        code: '13-1108',
      },
      {
        name: 'Peachy Keen',
        main: 'pink',
        hex: 'E3BEB4',
        code: '13-1208',
      },
      {
        name: 'Cloud Pink',
        main: 'pink',
        hex: 'F5D1C8',
        code: '13-1406',
      },
      {
        name: 'Sepia Rose',
        main: 'pink',
        hex: 'D5BBB6',
        code: '14-1803',
      },
      {
        name: 'Peachskin',
        main: 'pink',
        hex: 'DFB8B6',
        code: '14-1907',
      },
      {
        name: 'Pearl Blush',
        main: 'pink',
        hex: 'F4CEC5',
        code: '12-1207',
      },
      {
        name: 'Rose Bisque',
        main: 'pink',
        hex: 'EDD6D1',
        code: '12-1505',
      },
      {
        name: 'Potpourri',
        main: 'pink',
        hex: 'E7C9CA',
        code: '13-2004',
      },
      {
        name: 'Veiled Pink',
        main: 'pink',
        hex: 'E6C4C5',
        code: '13-2007',
      },
      {
        name: 'Lotus',
        main: 'pink',
        hex: 'E2C1C0',
        code: '14-1905',
      },
      {
        name: 'Soft Pink',
        main: 'pink',
        hex: 'F2D8CD',
        code: '12-1209',
      },
      {
        name: 'Creole Pink',
        main: 'pink',
        hex: 'F7D5CC',
        code: '13-1407',
      },
      {
        name: 'Rose Dust',
        main: 'pink',
        hex: 'CDB2A5',
        code: '14-1307',
      },
      {
        name: 'Rose Smoke',
        main: 'pink',
        hex: 'D3B4AD',
        code: '14-1506',
      },
      {
        name: 'Misty Rose',
        main: 'pink',
        hex: 'CAA39A',
        code: '15-1512',
      },
      {
        name: 'Almost Mauve',
        main: 'pink',
        hex: 'E7DCD9',
        code: '12-2103',
      },
      {
        name: 'Pinkish Petals',
        main: 'pink',
        hex: 'E7D5D2',
        code: '12-2400',
      },
      {
        name: 'Pale Dogwood',
        main: 'pink',
        hex: 'EDCDC2',
        code: '13-1404',
      },
      {
        name: 'Chintz Rose',
        main: 'pink',
        hex: 'EEC4BE',
        code: '13-1408',
      },
      {
        name: 'Chalk Pink',
        main: 'pink',
        hex: 'E6C5CA',
        code: '13-1904',
      },
      {
        name: 'Peach Whip',
        main: 'pink',
        hex: 'DBBEB7',
        code: '14-1309',
      },
      {
        name: 'Cameo Rose',
        main: 'pink',
        hex: 'D7B8AB',
        code: '14-1310',
      },
      {
        name: 'Evening Sand',
        main: 'pink',
        hex: 'DDB6AB',
        code: '14-1311',
      },
      {
        name: 'Peach Powder',
        main: 'pink',
        hex: 'E4BFB3',
        code: '14-1312',
      },
      {
        name: 'Pale Lilac',
        main: 'blue',
        hex: 'E1C6CC',
        code: '13-2803',
      },
      {
        name: 'Peach Blush',
        main: 'blue',
        hex: 'E4CCC6',
        code: '13-1504',
      },
    ],
  },
  {
    _id: 'Bronze ',
    colors: [
      {
        name: 'Antelope',
        main: 'brown',
        hex: 'B19664',
        code: '16-1126',
      },
      {
        name: 'Lark',
        main: 'brown',
        hex: 'B89B72',
        code: '16-1324',
      },
      {
        name: 'Bistre',
        main: 'brown',
        hex: '98754A',
        code: '17-1036',
      },
      {
        name: 'Honey Mustard',
        main: 'brown',
        hex: 'B68F52',
        code: '17-1047',
      },
      {
        name: 'Dijon',
        main: 'brown',
        hex: '97754C',
        code: '17-1125',
      },
      {
        name: 'Medal Bronze',
        main: 'brown',
        hex: '977547',
        code: '17-0942',
      },
      {
        name: 'Antique Bronze',
        main: 'brown',
        hex: '907954',
        code: '17-1028',
      },
      {
        name: 'Wood Thrush',
        main: 'brown',
        hex: 'A47D43',
        code: '17-1129',
      },
      {
        name: 'Curry',
        main: 'brown',
        hex: 'BE9E6F',
        code: '16-0928',
      },
      {
        name: 'Mustard Gold',
        main: 'brown',
        hex: 'B08E51',
        code: '16-1133',
      },
      {
        name: 'Prairie Sand',
        main: 'brown',
        hex: 'B59A6A',
        code: '16-1326',
      },
      {
        name: 'Golden Brown',
        main: 'brown',
        hex: '91672F',
        code: '18-0940',
      },
      {
        name: 'Bronze Brown',
        main: 'brown',
        hex: '825E2F',
        code: '18-0937',
      },
      {
        name: 'Dull Gold',
        main: 'green',
        hex: '8A6F48',
        code: '17-0935',
      },
      {
        name: 'Gold Flake',
        main: 'yellow',
        hex: 'BC9312',
        code: '16-0748',
      },
      {
        name: 'Olive Oil',
        main: 'yellow',
        hex: 'A98B2D',
        code: '16-0847',
      },
      {
        name: 'Moonstone',
        main: 'gold',
        hex: 'CCB584',
        code: '14-1026',
      },
      {
        name: 'Pale Gold',
        main: 'gold',
        hex: 'BD9865',
        code: '15-0927',
      },
      {
        name: 'Tinsel',
        main: 'gold',
        hex: 'C3964D',
        code: '16-0945',
      },
      {
        name: 'Bright Gold',
        main: 'gold',
        hex: 'CF9F52',
        code: '16-0947',
      },
      {
        name: 'Antique Moss',
        main: 'gold',
        hex: 'B9A023',
        code: '16-0840',
      },
      {
        name: 'Harvest Gold',
        main: 'gold',
        hex: 'B68A3A',
        code: '16-0948',
      },
      {
        name: 'Arrowwood',
        main: 'gold',
        hex: 'BC8D1F',
        code: '16-0954',
      },
      {
        name: 'Amber Gold',
        main: 'gold',
        hex: 'C19552',
        code: '16-1139',
      },
      {
        name: 'Sahara Sun',
        main: 'gold',
        hex: 'DFC08A',
        code: '14-0936',
      },
      {
        name: 'Mango Mint',
        main: 'gold',
        hex: 'CDB127',
        code: '16-0838',
      },
      {
        name: 'Narcissus',
        main: 'gold',
        hex: 'C39449',
        code: '16-0950',
      },
      {
        name: 'Nugget Gold',
        main: 'gold',
        hex: 'C89721',
        code: '16-0952',
      },
      {
        name: 'New Wheat',
        main: 'gold',
        hex: 'D7B57F',
        code: '14-1038',
      },
      {
        name: 'Green Sulphur',
        main: 'gold',
        hex: 'AE8E2C',
        code: '16-0742',
      },
    ],
  },
  {
    _id: 'Bubblegum ',
    colors: [
      {
        name: "Peaches N' Cream",
        main: 'pink',
        hex: 'F4A6A3',
        code: '14-1521',
      },
      {
        name: 'Quartz Pink',
        main: 'pink',
        hex: 'EFA6AA',
        code: '14-1714',
      },
      {
        name: 'Candy Pink',
        main: 'pink',
        hex: 'F5B0BD',
        code: '14-1911',
      },
      {
        name: 'Salmon Rose',
        main: 'pink',
        hex: 'FF8D94',
        code: '15-1626',
      },
      {
        name: 'Peony',
        main: 'pink',
        hex: 'ED9CA8',
        code: '15-1816',
      },
      {
        name: 'Flamingo Pink',
        main: 'pink',
        hex: 'F7969E',
        code: '15-1821',
      },
      {
        name: 'Rosy Future',
        main: 'pink',
        hex: 'F37E9B',
        code: '15-1910',
      },
      {
        name: 'Geranium Pink',
        main: 'pink',
        hex: 'F6909D',
        code: '15-1922',
      },
      {
        name: 'Mauveglow',
        main: 'pink',
        hex: 'D18489',
        code: '16-1617',
      },
      {
        name: 'Lantana',
        main: 'pink',
        hex: 'DA7E7A',
        code: '16-1624',
      },
      {
        name: 'Shell Pink',
        main: 'pink',
        hex: 'F88180',
        code: '16-1632',
      },
      {
        name: 'Strawberry Ice',
        main: 'pink',
        hex: 'E78B90',
        code: '16-1720',
      },
      {
        name: 'Confetti',
        main: 'pink',
        hex: 'E6798E',
        code: '16-1723',
      },
      {
        name: 'Ash Rose',
        main: 'pink',
        hex: 'B5817D',
        code: '17-1514',
      },
      {
        name: 'Desert Rose',
        main: 'pink',
        hex: 'CF6977',
        code: '17-1927',
      },
      {
        name: 'Powder Pink',
        main: 'pink',
        hex: 'ECB2B3',
        code: '14-1511',
      },
      {
        name: 'Poignant Pink',
        main: 'pink',
        hex: 'E8A3AC',
        code: '14-1715',
      },
      {
        name: 'Pink Taffy',
        main: 'pink',
        hex: 'EA9DAC',
        code: '14-1816',
      },
      {
        name: 'Pink Dolphin',
        main: 'pink',
        hex: 'E8AFB6',
        code: '14-1904',
      },
      {
        name: 'Coral Blush',
        main: 'pink',
        hex: 'E6B2B8',
        code: '14-1909',
      },
      {
        name: 'Pink Nectar',
        main: 'pink',
        hex: 'D8AAB7',
        code: '14-2305',
      },
      {
        name: 'Conch Shell',
        main: 'pink',
        hex: 'FC8F9B',
        code: '15-1624',
      },
      {
        name: 'Plumeria',
        main: 'pink',
        hex: 'FB90A2',
        code: '15-1822',
      },
      {
        name: 'Pink Lemonade',
        main: 'pink',
        hex: 'EE6D8A',
        code: '16-1735',
      },
      {
        name: 'Dusty Rose',
        main: 'pink',
        hex: 'BA797D',
        code: '17-1718',
      },
      {
        name: 'Sun Kissed Coral',
        main: 'pink',
        hex: 'EA6676',
        code: '17-1736',
      },
      {
        name: 'Calypso Coral',
        main: 'pink',
        hex: 'EE5C6C',
        code: '17-1744',
      },
      {
        name: 'Camellia Rose',
        main: 'pink',
        hex: 'EB6081',
        code: '17-1930',
      },
      {
        name: 'Light Mahogany',
        main: 'pink',
        hex: 'AD6D68',
        code: '18-1436',
      },
      {
        name: 'Faded Rose',
        main: 'pink',
        hex: 'BF6464',
        code: '18-1629',
      },
      {
        name: 'Silver Pink',
        main: 'pink',
        hex: 'DCB1AF',
        code: '14-1508',
      },
      {
        name: 'Bridal Rose',
        main: 'pink',
        hex: 'D69FA2',
        code: '15-1611',
      },
      {
        name: 'Dianthus',
        main: 'pink',
        hex: 'FC97A5',
        code: '15-1919',
      },
      {
        name: 'Brandied Apricot',
        main: 'pink',
        hex: 'CA848A',
        code: '16-1610',
      },
      {
        name: 'Flamingo Plume',
        main: 'pink',
        hex: 'DE838D',
        code: '16-1615',
      },
      {
        name: 'Tea Rose',
        main: 'pink',
        hex: 'DC7178',
        code: '16-1620',
      },
      {
        name: 'Georgia Peach',
        main: 'pink',
        hex: 'F97272',
        code: '16-1641',
      },
      {
        name: 'Strawberry Pink',
        main: 'pink',
        hex: 'F57F8E',
        code: '16-1731',
      },
      {
        name: 'Canyon Rose',
        main: 'pink',
        hex: 'AF6C67',
        code: '17-1520',
      },
      {
        name: 'Bubblegum',
        main: 'pink',
        hex: 'E9738D',
        code: '17-1928',
      },
      {
        name: 'Rapture Rose',
        main: 'pink',
        hex: 'D16277',
        code: '17-1929',
      },
      {
        name: 'Hot Pink',
        main: 'pink',
        hex: 'E55982',
        code: '17-1937',
      },
      {
        name: 'Pink Cherub',
        main: 'pink',
        hex: 'FDB5C2',
        code: '13-2016',
      },
      {
        name: 'Rose Elegance',
        main: 'pink',
        hex: 'D1969A',
        code: '15-1614',
      },
      {
        name: 'Pink Icing',
        main: 'pink',
        hex: 'EEA0A6',
        code: '15-1717',
      },
      {
        name: 'Flowering Ginger',
        main: 'pink',
        hex: 'EB7C96',
        code: '15-1915',
      },
      {
        name: 'Peach Blossom',
        main: 'pink',
        hex: 'DE8286',
        code: '16-1626',
      },
      {
        name: 'Old Rose',
        main: 'pink',
        hex: 'B47B77',
        code: '17-1518',
      },
      {
        name: 'Porcelain Rose',
        main: 'pink',
        hex: 'EA6B6A',
        code: '17-1643',
      },
      {
        name: 'Coral Paradise',
        main: 'pink',
        hex: 'F05E73',
        code: '17-1746',
      },
      {
        name: 'Fruit Dove',
        main: 'pink',
        hex: 'CF5C78',
        code: '17-1926',
      },
      {
        name: 'Garnet Rose',
        main: 'pink',
        hex: 'AC4B55',
        code: '18-1633',
      },
      {
        name: 'Baroque Rose',
        main: 'pink',
        hex: 'B35A66',
        code: '18-1634',
      },
      {
        name: 'Slate Rose',
        main: 'pink',
        hex: 'B45865',
        code: '18-1635',
      },
    ],
  },
  {
    _id: 'Burgundy',
    colors: [
      {
        name: 'Chili Oil',
        main: 'orange',
        hex: '8F3D37',
        code: '18-1440',
      },
      {
        name: 'Sun-Dried Tomato',
        main: 'red',
        hex: '752329',
        code: '19-1531',
      },
      {
        name: 'Rosewood',
        main: 'red',
        hex: '813639',
        code: '19-1532',
      },
      {
        name: 'Merlot',
        main: 'red',
        hex: '73272D',
        code: '19-1534',
      },
      {
        name: 'Burnt Henna',
        main: 'red',
        hex: '7E392F',
        code: '19-1540',
      },
      {
        name: 'Ruby Wine',
        main: 'red',
        hex: '77333B',
        code: '19-1629',
      },
      {
        name: 'Biking Red',
        main: 'red',
        hex: '77202E',
        code: '19-1650',
      },
      {
        name: 'Barn Red',
        main: 'red',
        hex: '8F423B',
        code: '18-1531',
      },
      {
        name: 'Apple Butter',
        main: 'red',
        hex: '844B4D',
        code: '18-1426',
      },
      {
        name: 'Tandoori Spice',
        main: 'red',
        hex: '9F4440',
        code: '18-1444',
      },
      {
        name: 'Earth Red',
        main: 'red',
        hex: '95424E',
        code: '18-1631',
      },
      {
        name: 'Burnt Russet',
        main: 'red',
        hex: '7E3940',
        code: '19-1530',
      },
      {
        name: 'Syrah',
        main: 'red',
        hex: '6A282C',
        code: '19-1535',
      },
      {
        name: 'Roasted Russet',
        main: 'red',
        hex: '7A3B31',
        code: '19-1541',
      },
      {
        name: 'Tibetan Red',
        main: 'red',
        hex: '782A39',
        code: '19-1934',
      },
      {
        name: 'Beet Red',
        main: 'red',
        hex: '7A1F3D',
        code: '19-2030',
      },
      {
        name: 'Spiced Apple',
        main: 'red',
        hex: '793A38',
        code: '18-1325',
      },
      {
        name: 'Red Pear',
        main: 'red',
        hex: '7C3539',
        code: '19-1536',
      },
      {
        name: 'Rhubarb',
        main: 'red',
        hex: '77202F',
        code: '19-1652',
      },
      {
        name: 'Deep Claret',
        main: 'red',
        hex: '973443',
        code: '19-1840',
      },
      {
        name: 'Red Ochre',
        main: 'red',
        hex: '913832',
        code: '18-1442',
      },
      {
        name: 'Brandy Snifter',
        main: 'red',
        hex: '7E3F3A',
        code: '18-1323',
      },
      {
        name: 'Fired Brick',
        main: 'red',
        hex: '6A2E2A',
        code: '19-1337',
      },
      {
        name: 'Smoked Paprika',
        main: 'red',
        hex: '6F372C',
        code: '19-1429',
      },
      {
        name: 'Rhododendron',
        main: 'red',
        hex: '722B3F',
        code: '19-2024',
      },
      {
        name: 'Bossa Nova',
        main: 'red',
        hex: '973B36',
        code: '18-1547',
      },
      {
        name: 'Brick Red',
        main: 'red',
        hex: '8C373E',
        code: '19-1543',
      },
      {
        name: 'Rhythmic Red',
        main: 'red',
        hex: '8C2230',
        code: '19-1653',
      },
      {
        name: 'Winery',
        main: 'red',
        hex: '7E212A',
        code: '19-1537',
      },
      {
        name: 'Rumba Red',
        main: 'red',
        hex: '7C2439',
        code: '19-1940',
      },
      {
        name: 'Rio Red',
        main: 'red',
        hex: '8A2232',
        code: '19-1656',
      },
      {
        name: 'Scooter',
        main: 'red',
        hex: '941E32',
        code: '19-1863',
      },
      {
        name: 'Red Dahlia',
        main: 'red',
        hex: '7D2027',
        code: '19-1555',
      },
      {
        name: 'Karanda Red',
        main: 'red',
        hex: '8C2A37',
        code: '19-1657',
      },
      {
        name: 'Red Bud',
        main: 'red',
        hex: '962D49',
        code: '19-1850',
      },
    ],
  },
  {
    _id: 'Camel ',
    colors: [
      {
        name: 'Marzipan',
        main: 'brown',
        hex: 'D8C09D',
        code: '14-1113',
      },
      {
        name: "Lamb's Wool",
        main: 'brown',
        hex: 'E5D0B1',
        code: '12-0910',
      },
      {
        name: 'Tapioca',
        main: 'brown',
        hex: 'DCCDBC',
        code: '12-1403',
      },
      {
        name: 'Frozen Dew',
        main: 'brown',
        hex: 'D8CFB2',
        code: '13-0513',
      },
      {
        name: 'Oyster White',
        main: 'brown',
        hex: 'D2CAAF',
        code: '13-1007',
      },
      {
        name: 'Boulder',
        main: 'brown',
        hex: 'D1BE9B',
        code: '14-1110',
      },
      {
        name: 'Moth',
        main: 'brown',
        hex: 'D2CBAF',
        code: '13-0611',
      },
      {
        name: 'Parsnip',
        main: 'brown',
        hex: 'D6C69A',
        code: '14-0925',
      },
      {
        name: 'Winter Wheat',
        main: 'brown',
        hex: 'DFC09F',
        code: '14-1119',
      },
      {
        name: 'Sheepskin',
        main: 'brown',
        hex: 'DAB58F',
        code: '14-1122',
      },
      {
        name: 'Chino Green',
        main: 'brown',
        hex: 'D9CAA5',
        code: '13-0613',
      },
      {
        name: 'Sea Mist',
        main: 'brown',
        hex: 'D8C9A3',
        code: '13-0715',
      },
      {
        name: 'Soybean',
        main: 'brown',
        hex: 'D2C29D',
        code: '13-0919',
      },
      {
        name: 'Mellow Buff',
        main: 'brown',
        hex: 'D8B998',
        code: '13-1014',
      },
      {
        name: 'Pebble',
        main: 'brown',
        hex: 'CAB698',
        code: '14-1112',
      },
      {
        name: 'Candied Ginger',
        main: 'brown',
        hex: 'BFA387',
        code: '15-1213',
      },
      {
        name: 'Sand',
        main: 'brown',
        hex: 'CCA67F',
        code: '15-1225',
      },
      {
        name: 'Curds & Whey',
        main: 'brown',
        hex: 'BCA483',
        code: '16-0920',
      },
      {
        name: 'Sponge',
        main: 'brown',
        hex: 'A49775',
        code: '16-1118',
      },
      {
        name: 'Tan',
        main: 'brown',
        hex: 'B69574',
        code: '16-1334',
      },
      {
        name: 'Camel',
        main: 'brown',
        hex: 'B0846A',
        code: '17-1224',
      },
      {
        name: 'Travertine',
        main: 'brown',
        hex: 'AE997D',
        code: '15-1114',
      },
      {
        name: 'Mojave Desert',
        main: 'brown',
        hex: 'C7B595',
        code: '15-1217',
      },
      {
        name: 'Cuban Sand',
        main: 'brown',
        hex: 'C1A68D',
        code: '15-1314',
      },
      {
        name: 'Macaroon',
        main: 'brown',
        hex: 'B48B71',
        code: '16-1323',
      },
      {
        name: 'Kelp',
        main: 'brown',
        hex: '998467',
        code: '17-1022',
      },
      {
        name: 'Praline',
        main: 'brown',
        hex: 'AD8B75',
        code: '17-1223',
      },
      {
        name: 'Lattè',
        main: 'brown',
        hex: 'C5A582',
        code: '15-1220',
      },
      {
        name: 'Croissant',
        main: 'brown',
        hex: 'C4AB86',
        code: '16-0924',
      },
      {
        name: 'Warm Sand',
        main: 'brown',
        hex: 'C5AE91',
        code: '15-1214',
      },
      {
        name: 'Starfish',
        main: 'brown',
        hex: 'B09A77',
        code: '16-1120',
      },
      {
        name: 'Honey Peach',
        main: 'orange',
        hex: 'DCBD9E',
        code: '13-1015',
      },
      {
        name: 'Appleblossom',
        main: 'orange',
        hex: 'DDBCA0',
        code: '13-1013',
      },
      {
        name: 'Silver Fern',
        main: 'green',
        hex: 'BBAA7E',
        code: '15-0719',
      },
      {
        name: 'Green Haze',
        main: 'green',
        hex: 'CAC4A4',
        code: '14-0615',
      },
    ],
  },
  {
    _id: 'Canary ',
    colors: [
      {
        name: 'Glass Green',
        main: 'green',
        hex: 'ECEAD0',
        code: '11-0205',
      },
      {
        name: 'Happy Hopes',
        main: 'green',
        hex: 'E1D9A4',
        code: '12-0618',
      },
      {
        name: 'Sylvan Green',
        main: 'green',
        hex: 'E7EACB',
        code: '11-0410',
      },
      {
        name: 'Garden Glade',
        main: 'green',
        hex: 'DCD8A8',
        code: '13-0614',
      },
      {
        name: 'Charlock',
        main: 'yellow',
        hex: 'E5E790',
        code: '12-0530',
      },
      {
        name: 'Green Essence',
        main: 'yellow',
        hex: 'E9EAC8',
        code: '12-0607',
      },
      {
        name: 'Wax Yellow',
        main: 'yellow',
        hex: 'EDE9AD',
        code: '11-0618',
      },
      {
        name: 'Limelight',
        main: 'yellow',
        hex: 'F0E87D',
        code: '12-0740',
      },
      {
        name: 'Citron',
        main: 'yellow',
        hex: 'DFDE9B',
        code: '12-0524',
      },
      {
        name: 'Lemonade',
        main: 'yellow',
        hex: 'F0E79D',
        code: '12-0721',
      },
      {
        name: 'Tender Yellow',
        main: 'yellow',
        hex: 'EDEEB7',
        code: '11-0710',
      },
      {
        name: 'Canary Yellow',
        main: 'yellow',
        hex: 'DFD87E',
        code: '12-0633',
      },
      {
        name: 'Chardonnay',
        main: 'yellow',
        hex: 'E7DF99',
        code: '13-0633',
      },
      {
        name: 'Elfin Yellow',
        main: 'yellow',
        hex: 'EEEA97',
        code: '11-0620',
      },
      {
        name: 'Yellow Pear',
        main: 'yellow',
        hex: 'EDEA9B',
        code: '11-0623',
      },
      {
        name: 'Custard',
        main: 'yellow',
        hex: 'E5D68E',
        code: '13-0720',
      },
    ],
  },
  {
    _id: 'Caramel',
    colors: [
      {
        name: 'Sandstone',
        main: 'brown',
        hex: 'C48A69',
        code: '16-1328',
      },
      {
        name: 'Chipmunk',
        main: 'brown',
        hex: '976F4D',
        code: '17-1044',
      },
      {
        name: 'Apple Cinnamon',
        main: 'brown',
        hex: 'B0885B',
        code: '17-1045',
      },
      {
        name: 'Cashew',
        main: 'brown',
        hex: 'A57149',
        code: '17-1137',
      },
      {
        name: 'Meerkat',
        main: 'brown',
        hex: 'A47045',
        code: '16-1438',
      },
      {
        name: 'Roasted Pecan',
        main: 'brown',
        hex: '945A2C',
        code: '17-1052',
      },
      {
        name: 'Bone Brown',
        main: 'brown',
        hex: '9D7446',
        code: '17-1128',
      },
      {
        name: 'Toasted Nut',
        main: 'brown',
        hex: 'C08768',
        code: '16-1327',
      },
      {
        name: 'Sunburn',
        main: 'brown',
        hex: 'B37256',
        code: '16-1429',
      },
      {
        name: 'Almond',
        main: 'brown',
        hex: 'A7754D',
        code: '16-1432',
      },
      {
        name: 'Amber Brown',
        main: 'brown',
        hex: 'A76646',
        code: '17-1147',
      },
      {
        name: 'Lion',
        main: 'brown',
        hex: 'A0714F',
        code: '17-1330',
      },
      {
        name: 'Adobe',
        main: 'brown',
        hex: 'A3623B',
        code: '17-1340',
      },
      {
        name: 'Pecan Brown',
        main: 'brown',
        hex: 'A36E51',
        code: '17-1430',
      },
      {
        name: 'Tobacco Brown',
        main: 'brown',
        hex: '9A7352',
        code: '17-1327',
      },
      {
        name: 'Buckthorn Brown',
        main: 'brown',
        hex: 'A76F1F',
        code: '18-0935',
      },
      {
        name: 'Autumnal',
        main: 'brown',
        hex: 'A15325',
        code: '17-1342',
      },
      {
        name: 'Cathay Spice',
        main: 'brown',
        hex: '99642C',
        code: '18-0950',
      },
      {
        name: 'Tanzine',
        main: 'brown',
        hex: 'AD8567',
        code: '17-1328',
      },
      {
        name: 'Raw Sienna',
        main: 'brown',
        hex: 'B9714F',
        code: '17-1436',
      },
      {
        name: 'Ginger Bread',
        main: 'brown',
        hex: '8C4A2F',
        code: '18-1244',
      },
      {
        name: 'Sierra',
        main: 'brown',
        hex: '995C41',
        code: '18-1239',
      },
      {
        name: 'Glazed Ginger',
        main: 'brown',
        hex: '91552B',
        code: '18-1154',
      },
      {
        name: 'Caramel Café',
        main: 'brown',
        hex: '864C24',
        code: '18-1148',
      },
    ],
  },
  {
    _id: 'Champagne',
    colors: [
      {
        name: 'Cloud Dancer',
        main: 'white',
        hex: 'F0EEE9',
        code: '11-4201',
      },
      {
        name: 'Blanc de Blanc',
        main: 'white',
        hex: 'E7E9E7',
        code: '11-4800',
      },
      {
        name: 'White Alyssum',
        main: 'beige',
        hex: 'EFEBE7',
        code: '11-1001',
      },
      {
        name: 'Lily White',
        main: 'beige',
        hex: 'E2E2DA',
        code: '11-4301',
      },
      {
        name: 'Stalactite',
        main: 'white',
        hex: 'F7F6F3',
        code: '11-4101',
      },
      {
        name: 'Star White',
        main: 'beige',
        hex: 'EFEFE8',
        code: '11-4202',
      },
      {
        name: 'Marshmallow',
        main: 'beige',
        hex: 'F0EEE4',
        code: '11-4300',
      },
      {
        name: 'Bone White',
        main: 'beige',
        hex: 'D6D1C0',
        code: '12-0105',
      },
      {
        name: 'White Sand',
        main: 'white',
        hex: 'DBD5D1',
        code: '13-0002',
      },
      {
        name: 'Cannoli Cream',
        main: 'beige',
        hex: 'F1F0E2',
        code: '11-4302',
      },
      {
        name: 'Tofu',
        main: 'beige',
        hex: 'E8E4DA',
        code: '11-4801',
      },
      {
        name: 'Mystic Blue',
        main: 'gray',
        hex: 'E1E3DE',
        code: '11-4303',
      },
      {
        name: 'Wispy Clouds',
        main: 'gray',
        hex: 'F0EFEE',
        code: '11-3900',
      },
      {
        name: 'Early Frost',
        main: 'silver',
        hex: 'DBD7D1',
        code: '12-4001',
      },
    ],
  },
  {
    _id: 'Charcoal',
    colors: [
      {
        name: 'Smoked Pearl',
        main: 'gray',
        hex: '656466',
        code: '18-0000',
      },
      {
        name: 'Pyrite',
        main: 'gray',
        hex: '766E6E',
        code: '18-3801',
      },
      {
        name: 'Quiet Shade',
        main: 'gray',
        hex: '66676D',
        code: '18-4006',
      },
      {
        name: 'Impenetrable',
        main: 'gray',
        hex: '514F51',
        code: '18-4007',
      },
      {
        name: 'Rocky Rims',
        main: 'gray',
        hex: '616871',
        code: '18-4319',
      },
      {
        name: 'Iron Oxide',
        main: 'gray',
        hex: '5D5C5A',
        code: '18-5103',
      },
      {
        name: 'Dark Gull Gray',
        main: 'gray',
        hex: '625D5D',
        code: '18-0403',
      },
      {
        name: 'Gargoyle',
        main: 'gray',
        hex: '686767',
        code: '18-0503',
      },
      {
        name: 'Mulled Basil',
        main: 'gray',
        hex: '696B62',
        code: '18-0514',
      },
      {
        name: 'Charcoal Gray',
        main: 'gray',
        hex: '6C6868',
        code: '18-0601',
      },
      {
        name: 'Boulevard',
        main: 'gray',
        hex: '575358',
        code: '18-3906',
      },
      {
        name: 'Tornado',
        main: 'gray',
        hex: '5E5B60',
        code: '18-3907',
      },
      {
        name: 'Sedona Sage',
        main: 'gray',
        hex: '686D6C',
        code: '18-5105',
      },
      {
        name: 'Asphalt',
        main: 'gray',
        hex: '444447',
        code: '19-0201',
      },
      {
        name: 'Excalibur',
        main: 'gray',
        hex: '676168',
        code: '18-3905',
      },
      {
        name: 'Volcanic Glass',
        main: 'gray',
        hex: '615C61',
        code: '18-3908',
      },
      {
        name: 'Grisaille',
        main: 'gray',
        hex: '585E6F',
        code: '18-3912',
      },
      {
        name: 'Dark Core',
        main: 'gray',
        hex: '565050',
        code: '18-3914',
      },
      {
        name: 'Poppy Seed',
        main: 'gray',
        hex: '626367',
        code: '18-4004',
      },
      {
        name: 'Burnt Ash',
        main: 'gray',
        hex: '484B53',
        code: '18-4021',
      },
      {
        name: 'Stormy Weather',
        main: 'gray',
        hex: '58646D',
        code: '18-4214',
      },
      {
        name: 'Pewter',
        main: 'gray',
        hex: '666564',
        code: '18-5203',
      },
      {
        name: 'Granite Gray',
        main: 'gray',
        hex: '615F5F',
        code: '18-5204',
      },
      {
        name: 'Eiffel Tower',
        main: 'gray',
        hex: '5C5658',
        code: '18-5210',
      },
      {
        name: 'Gray Pinstripe',
        main: 'gray',
        hex: '4A494D',
        code: '19-0203',
      },
      {
        name: 'Castlerock',
        main: 'gray',
        hex: '5F5E62',
        code: '18-0201',
      },
      {
        name: 'Lava Smoke',
        main: 'gray',
        hex: '5E6064',
        code: '18-0202',
      },
      {
        name: 'Shark',
        main: 'gray',
        hex: '6D636B',
        code: '18-1703',
      },
      {
        name: 'Steel Gray',
        main: 'gray',
        hex: '726F70',
        code: '18-4005',
      },
      {
        name: 'Stone Mountain',
        main: 'gray',
        hex: '686A6C',
        code: '18-4705',
      },
      {
        name: 'Thunderstorm',
        main: 'gray',
        hex: '5B585A',
        code: '18-5206',
      },
      {
        name: 'Raven',
        main: 'gray',
        hex: '413E3D',
        code: '19-0000',
      },
      {
        name: 'Beluga',
        main: 'gray',
        hex: '4A4843',
        code: '19-0405',
      },
      {
        name: 'Peat',
        main: 'gray',
        hex: '3B3A36',
        code: '19-0508',
      },
      {
        name: 'Rabbit',
        main: 'gray',
        hex: '5F575D',
        code: '19-3905',
      },
      {
        name: 'Dark Shadow',
        main: 'gray',
        hex: '4A4B4D',
        code: '19-3906',
      },
      {
        name: 'Volcanic Ash',
        main: 'gray',
        hex: '484849',
        code: '19-3912',
      },
      {
        name: 'Graystone',
        main: 'gray',
        hex: '4D495B',
        code: '19-3915',
      },
      {
        name: 'Odyssey Gray',
        main: 'gray',
        hex: '444552',
        code: '19-3930',
      },
      {
        name: 'Camo Gray',
        main: 'gray',
        hex: '3F3B3E',
        code: '19-4002',
      },
      {
        name: 'Plum Kitten',
        main: 'gray',
        hex: '625B5C',
        code: '19-3803',
      },
      {
        name: 'Magnet',
        main: 'gray',
        hex: '4D4B4F',
        code: '19-3901',
      },
      {
        name: 'Carbon Copy',
        main: 'gray',
        hex: '464143',
        code: '19-3904',
      },
      {
        name: 'India Ink',
        main: 'gray',
        hex: '3C3F4A',
        code: '19-4019',
      },
      {
        name: 'Black Sand',
        main: 'gray',
        hex: '3C3E40',
        code: '19-4406',
      },
      {
        name: 'Urban Chic',
        main: 'gray',
        hex: '474E4D',
        code: '19-5004',
      },
      {
        name: 'Forged Iron',
        main: 'gray',
        hex: '48464A',
        code: '19-3907',
      },
      {
        name: 'Iron Gate',
        main: 'gray',
        hex: '4E5055',
        code: '19-3910',
      },
      {
        name: 'Coal Smoke',
        main: 'gray',
        hex: '545052',
        code: '19-3914',
      },
      {
        name: 'Blackened Pearl',
        main: 'gray',
        hex: '4E4B51',
        code: '19-3917',
      },
      {
        name: 'Graphite',
        main: 'gray',
        hex: '3B3B48',
        code: '19-3927',
      },
      {
        name: 'Black Oyster',
        main: 'gray',
        hex: '414143',
        code: '19-0205',
      },
      {
        name: 'Chimera',
        main: 'gray',
        hex: '4D4C47',
        code: '19-0406',
      },
      {
        name: 'Black Ink',
        main: 'gray',
        hex: '45413C',
        code: '19-0506',
      },
      {
        name: 'Pavement',
        main: 'gray',
        hex: '534D50',
        code: '19-3900',
      },
      {
        name: 'Nine Iron',
        main: 'gray',
        hex: '46434A',
        code: '19-3908',
      },
      {
        name: 'Periscope',
        main: 'gray',
        hex: '46444C',
        code: '19-3918',
      },
      {
        name: 'Ombre Blue',
        main: 'gray',
        hex: '434854',
        code: '19-4014',
      },
      {
        name: 'Ebony',
        main: 'gray',
        hex: '41424A',
        code: '19-4104',
      },
      {
        name: 'Enigma',
        main: 'gray',
        hex: '575B61',
        code: '19-4209',
      },
      {
        name: 'Turbulence',
        main: 'gray',
        hex: '4E545B',
        code: '19-4215',
      },
      {
        name: 'Dark Slate',
        main: 'gray',
        hex: '46515A',
        code: '19-4220',
      },
      {
        name: 'Gray Whale',
        main: 'gray',
        hex: '4D5055',
        code: '19-4310',
      },
      {
        name: 'Gray Velvet',
        main: 'green',
        hex: '605C55',
        code: '18-0205',
      },
      {
        name: 'Climbing Ivy',
        main: 'green',
        hex: '444940',
        code: '19-0307',
      },
      {
        name: 'Andesite',
        main: 'green',
        hex: '534D47',
        code: '18-1408',
      },
    ],
  },
  {
    _id: 'Cherry ',
    colors: [
      {
        name: 'Viva Magenta',
        main: 'pink',
        hex: 'BB2649',
        code: '18-1750',
      },
      {
        name: 'Barberry',
        main: 'pink',
        hex: 'BF1945',
        code: '18-1760',
      },
      {
        name: 'Chrysanthemum',
        main: 'red',
        hex: 'BE454F',
        code: '17-1641',
      },
      {
        name: 'Bittersweet',
        main: 'red',
        hex: 'D93744',
        code: '17-1663',
      },
      {
        name: 'Geranium',
        main: 'red',
        hex: 'DA3D58',
        code: '17-1753',
      },
      {
        name: 'Cranberry',
        main: 'red',
        hex: 'BB4A4D',
        code: '17-1545',
      },
      {
        name: 'Poinsettia',
        main: 'red',
        hex: 'CB3441',
        code: '17-1654',
      },
      {
        name: 'Cerise',
        main: 'pink',
        hex: 'A41247',
        code: '19-1955',
      },
      {
        name: 'Poppy Red',
        main: 'red',
        hex: 'DC343B',
        code: '17-1664',
      },
      {
        name: 'Lychee',
        main: 'red',
        hex: 'BA0E32',
        code: '18-1654',
      },
      {
        name: 'Lollipop',
        main: 'red',
        hex: 'CC1C3B',
        code: '18-1764',
      },
      {
        name: 'American Beauty',
        main: 'red',
        hex: 'A73340',
        code: '19-1759',
      },
      {
        name: 'Racing Red',
        main: 'red',
        hex: 'BD162C',
        code: '19-1763',
      },
      {
        name: 'Lipstick Red',
        main: 'red',
        hex: 'B31B38',
        code: '19-1764',
      },
      {
        name: 'Rococco Red',
        main: 'red',
        hex: 'BB363F',
        code: '18-1652',
      },
      {
        name: 'Flame Scarlet',
        main: 'red',
        hex: 'CD212A',
        code: '18-1662',
      },
      {
        name: 'Jalapeño Red',
        main: 'red',
        hex: 'B2123C',
        code: '18-1759',
      },
      {
        name: 'High Risk Red',
        main: 'red',
        hex: 'C71F2D',
        code: '18-1763',
      },
      {
        name: 'True Red',
        main: 'red',
        hex: 'BF1932',
        code: '19-1664',
      },
      {
        name: 'Crimson',
        main: 'red',
        hex: 'AE0F36',
        code: '19-1762',
      },
      {
        name: 'Fiery Red',
        main: 'red',
        hex: 'D01C1F',
        code: '18-1664',
      },
      {
        name: 'Watermelon',
        main: 'red',
        hex: 'C63A4F',
        code: '18-1665',
      },
      {
        name: 'Teaberry',
        main: 'red',
        hex: 'DC3855',
        code: '18-1756',
      },
      {
        name: 'Barbados Cherry',
        main: 'red',
        hex: 'AA0A27',
        code: '19-1757',
      },
    ],
  },
  {
    _id: 'Chocolate',
    colors: [
      {
        name: 'Dark Night',
        main: 'brown',
        hex: '645E58',
        code: '18-0307',
      },
      {
        name: 'Bungee Cord',
        main: 'brown',
        hex: '696156',
        code: '18-0513',
      },
      {
        name: 'Stone Gray',
        main: 'brown',
        hex: '685E4F',
        code: '18-0615',
      },
      {
        name: 'Capers',
        main: 'brown',
        hex: '6A5E4B',
        code: '18-0820',
      },
      {
        name: 'Cinder',
        main: 'brown',
        hex: '8A7E78',
        code: '17-1506',
      },
      {
        name: 'Hematite',
        main: 'brown',
        hex: '756F6A',
        code: '17-5800',
      },
      {
        name: 'Fallen Rock',
        main: 'brown',
        hex: '817669',
        code: '18-1108',
      },
      {
        name: 'Brindle',
        main: 'brown',
        hex: '82776B',
        code: '18-1110',
      },
      {
        name: 'Caribou',
        main: 'brown',
        hex: '816D5E',
        code: '18-1017',
      },
      {
        name: 'Walnut',
        main: 'brown',
        hex: '786A5F',
        code: '18-1112',
      },
      {
        name: 'Sea Turtle',
        main: 'brown',
        hex: '5E574A',
        code: '19-0403',
      },
      {
        name: 'Demitasse',
        main: 'brown',
        hex: '40332B',
        code: '19-0712',
      },
      {
        name: 'Desert Palm',
        main: 'brown',
        hex: '5A4632',
        code: '19-0815',
      },
      {
        name: 'Delicioso',
        main: 'brown',
        hex: '3F352F',
        code: '19-0840',
      },
      {
        name: 'Mulch',
        main: 'brown',
        hex: '433937',
        code: '19-0910',
      },
      {
        name: 'Solemnity',
        main: 'brown',
        hex: '372D2D',
        code: '19-0914',
      },
      {
        name: 'Java',
        main: 'brown',
        hex: '433331',
        code: '19-1016',
      },
      {
        name: 'Falcon',
        main: 'brown',
        hex: '6D625B',
        code: '18-1304',
      },
      {
        name: 'Sparrow',
        main: 'brown',
        hex: '69595C',
        code: '18-1404',
      },
      {
        name: 'Plum Truffle',
        main: 'brown',
        hex: '675758',
        code: '18-1506',
      },
      {
        name: 'Subterranean',
        main: 'brown',
        hex: '5B5252',
        code: '18-3709',
      },
      {
        name: 'Beech',
        main: 'brown',
        hex: '5B4F3B',
        code: '19-0618',
      },
      {
        name: 'Turkish Coffee',
        main: 'brown',
        hex: '483F39',
        code: '19-0812',
      },
      {
        name: 'Canteen',
        main: 'brown',
        hex: '5E5347',
        code: '19-0820',
      },
      {
        name: 'Hot Fudge',
        main: 'brown',
        hex: '493A35',
        code: '19-0913',
      },
      {
        name: 'Coffee Bean',
        main: 'brown',
        hex: '40312F',
        code: '19-0915',
      },
      {
        name: 'French Roast',
        main: 'brown',
        hex: '58423F',
        code: '19-1012',
      },
      {
        name: 'Deep Taupe',
        main: 'brown',
        hex: '7B6660',
        code: '18-1312',
      },
      {
        name: 'Catacombs',
        main: 'brown',
        hex: '534D4D',
        code: '19-0610',
      },
      {
        name: 'Wren',
        main: 'brown',
        hex: '4B4139',
        code: '19-0614',
      },
      {
        name: 'Teak',
        main: 'brown',
        hex: '655341',
        code: '19-0617',
      },
      {
        name: 'Morel',
        main: 'brown',
        hex: '685C53',
        code: '19-0808',
      },
      {
        name: 'Major Brown',
        main: 'brown',
        hex: '5B5149',
        code: '19-0810',
      },
      {
        name: 'Rain Drum',
        main: 'brown',
        hex: '5F4C40',
        code: '19-0916',
      },
      {
        name: 'Bracken',
        main: 'brown',
        hex: '4F3F3B',
        code: '19-1015',
      },
      {
        name: 'Coffee Quartz',
        main: 'brown',
        hex: '695650',
        code: '18-1307',
      },
      {
        name: 'Peppercorn',
        main: 'brown',
        hex: '6C5656',
        code: '18-1409',
      },
      {
        name: 'Tire Tread',
        main: 'brown',
        hex: '4F494A',
        code: '18-3901',
      },
      {
        name: 'Chocolate Chip',
        main: 'brown',
        hex: '685A4E',
        code: '19-0809',
      },
      {
        name: 'Tarmac',
        main: 'brown',
        hex: '5A5348',
        code: '19-0822',
      },
      {
        name: 'Crocodile',
        main: 'brown',
        hex: '5D5348',
        code: '19-0823',
      },
      {
        name: 'Chocolate Brown',
        main: 'brown',
        hex: '4E403B',
        code: '19-0912',
      },
      {
        name: 'Black Sea',
        main: 'brown',
        hex: '3D373B',
        code: '19-1011',
      },
      {
        name: 'Dark Earth',
        main: 'brown',
        hex: '5C4939',
        code: '19-1020',
      },
      {
        name: 'After Dark',
        main: 'brown',
        hex: '3D3535',
        code: '19-1101',
      },
      {
        name: 'Molé',
        main: 'brown',
        hex: '392D2B',
        code: '19-1106',
      },
      {
        name: 'Chocolate Torte',
        main: 'brown',
        hex: '392E2D',
        code: '19-1109',
      },
      {
        name: 'Chocolate Plum',
        main: 'brown',
        hex: '3D2D2E',
        code: '19-1110',
      },
      {
        name: 'Pinecone',
        main: 'brown',
        hex: '62473B',
        code: '19-1121',
      },
      {
        name: 'Cappuccino',
        main: 'brown',
        hex: '633F33',
        code: '19-1220',
      },
      {
        name: 'Fondue Fudge',
        main: 'brown',
        hex: '5D4236',
        code: '19-1224',
      },
      {
        name: 'Root Beer',
        main: 'brown',
        hex: '714A41',
        code: '19-1228',
      },
      {
        name: 'Brown Stone',
        main: 'brown',
        hex: '593C39',
        code: '19-1322',
      },
      {
        name: 'Chicory Coffee',
        main: 'brown',
        hex: '4B342F',
        code: '19-1419',
      },
      {
        name: 'Decadent Chocolate',
        main: 'brown',
        hex: '513235',
        code: '19-1625',
      },
      {
        name: 'Shaved Chocolate',
        main: 'brown',
        hex: '553C36',
        code: '19-1215',
      },
      {
        name: 'Downtown Brown',
        main: 'brown',
        hex: '5E3F32',
        code: '19-1223',
      },
      {
        name: 'Sable',
        main: 'brown',
        hex: '6E3F3C',
        code: '19-1320',
      },
      {
        name: 'Deep Mahogany',
        main: 'brown',
        hex: '553B39',
        code: '19-1420',
      },
      {
        name: 'Carafe',
        main: 'brown',
        hex: '5D473A',
        code: '19-1116',
      },
      {
        name: 'Black Coffee',
        main: 'brown',
        hex: '3C302F',
        code: '19-1111',
      },
      {
        name: 'Chestnut',
        main: 'brown',
        hex: '584039',
        code: '19-1118',
      },
      {
        name: 'Cocoa',
        main: 'brown',
        hex: '594536',
        code: '19-1119',
      },
      {
        name: 'Chocolate Lab',
        main: 'brown',
        hex: '5C3E35',
        code: '19-1214',
      },
      {
        name: 'Chocolate Martini',
        main: 'brown',
        hex: '56443F',
        code: '19-1216',
      },
      {
        name: 'Mustang',
        main: 'brown',
        hex: '684B40',
        code: '19-1217',
      },
      {
        name: 'Potting Soil',
        main: 'brown',
        hex: '54392D',
        code: '19-1218',
      },
      {
        name: 'Rocky Road',
        main: 'brown',
        hex: '5B3F36',
        code: '19-1234',
      },
      {
        name: 'Brunette',
        main: 'brown',
        hex: '664238',
        code: '19-1235',
      },
      {
        name: 'Cambridge Brown',
        main: 'brown',
        hex: '6F4433',
        code: '19-1242',
      },
      {
        name: 'Bitter Chocolate',
        main: 'brown',
        hex: '503130',
        code: '19-1317',
      },
      {
        name: 'Rum Raisin',
        main: 'brown',
        hex: '583432',
        code: '19-1321',
      },
      {
        name: 'Chocolate Fondant',
        main: 'brown',
        hex: '56352D',
        code: '19-1432',
      },
      {
        name: 'Shopping Bag',
        main: 'brown',
        hex: '5A4743',
        code: '19-1213',
      },
      {
        name: 'Sorrel Horse',
        main: 'brown',
        hex: '6B4331',
        code: '19-1227',
      },
      {
        name: 'Friar Brown',
        main: 'brown',
        hex: '6E493A',
        code: '19-1230',
      },
      {
        name: 'Tiramisu',
        main: 'brown',
        hex: '644235',
        code: '19-1233',
      },
      {
        name: 'Seal Brown',
        main: 'brown',
        hex: '493B39',
        code: '19-1314',
      },
      {
        name: 'Hot Chocolate',
        main: 'brown',
        hex: '683B39',
        code: '19-1325',
      },
      {
        name: 'Brown Out',
        main: 'brown',
        hex: '714439',
        code: '19-1428',
      },
      {
        name: 'Mink',
        main: 'brown',
        hex: '734B42',
        code: '19-1430',
      },
      {
        name: 'Fudgesickle',
        main: 'brown',
        hex: '63403A',
        code: '19-1431',
      },
      {
        name: 'Puce',
        main: 'brown',
        hex: '503938',
        code: '19-1518',
      },
      {
        name: 'Fudge',
        main: 'brown',
        hex: '493338',
        code: '19-1619',
      },
      {
        name: 'Driftwood',
        main: 'gray',
        hex: '847A75',
        code: '18-1210',
      },
      {
        name: 'Iron',
        main: 'gray',
        hex: '736460',
        code: '18-1306',
      },
      {
        name: 'Lamp Black',
        main: 'black',
        hex: '484744',
        code: '19-0507',
      },
      {
        name: 'Raisin',
        main: 'black',
        hex: '524144',
        code: '19-1606',
      },
      {
        name: 'Rosin',
        main: 'black',
        hex: '36362D',
        code: '19-0509',
      },
      {
        name: 'Espresso',
        main: 'black',
        hex: '363031',
        code: '19-1103',
      },
      {
        name: 'Slate Black',
        main: 'black',
        hex: '4B3D33',
        code: '19-0814',
      },
      {
        name: 'Shale',
        main: 'black',
        hex: '4A3F41',
        code: '19-3903',
      },
    ],
  },
  {
    _id: 'Cinnamon',
    colors: [
      {
        name: 'Roasted Pecan',
        main: 'brown',
        hex: '945A2C',
        code: '17-1052',
      },
      {
        name: 'Adobe',
        main: 'brown',
        hex: 'A3623B',
        code: '17-1340',
      },
      {
        name: 'Autumnal',
        main: 'brown',
        hex: 'A15325',
        code: '17-1342',
      },
      {
        name: 'Sugar Almond',
        main: 'brown',
        hex: '94562A',
        code: '18-1155',
      },
      {
        name: 'Glazed Ginger',
        main: 'brown',
        hex: '91552B',
        code: '18-1154',
      },
      {
        name: 'Caramel Café',
        main: 'brown',
        hex: '864C24',
        code: '18-1148',
      },
      {
        name: 'Cinnamon',
        main: 'brown',
        hex: '6B4139',
        code: '19-1436',
      },
      {
        name: 'Gold Flame',
        main: 'orange',
        hex: 'B45422',
        code: '16-1449',
      },
      {
        name: 'Mango',
        main: 'orange',
        hex: 'B75E41',
        code: '17-1446',
      },
      {
        name: 'Leather Brown',
        main: 'orange',
        hex: '97572B',
        code: '18-1142',
      },
      {
        name: 'Bombay Brown',
        main: 'orange',
        hex: '9F5130',
        code: '18-1250',
      },
      {
        name: 'Cinnamon Stick',
        main: 'orange',
        hex: '9C4722',
        code: '18-1345',
      },
      {
        name: "Potter's Clay",
        main: 'orange',
        hex: '9E4624',
        code: '18-1340',
      },
      {
        name: 'Umber',
        main: 'orange',
        hex: '944A20',
        code: '18-1246',
      },
      {
        name: 'Burnt Brick',
        main: 'orange',
        hex: 'A14D3A',
        code: '18-1350',
      },
      {
        name: 'Autumn Glaze',
        main: 'orange',
        hex: 'B3573F',
        code: '18-1451',
      },
      {
        name: 'Ginger Spice',
        main: 'orange',
        hex: 'B65D4B',
        code: '18-1535',
      },
      {
        name: 'Redwood',
        main: 'red',
        hex: 'A6594B',
        code: '18-1443',
      },
      {
        name: 'Redwood Burl',
        main: 'red',
        hex: '9B574B',
        code: '18-1432',
      },
      {
        name: 'Etruscan Red',
        main: 'red',
        hex: 'A2574B',
        code: '18-1434',
      },
      {
        name: 'Marsala',
        main: 'red',
        hex: '964F4C',
        code: '18-1438',
      },
      {
        name: 'Baked Clay',
        main: 'red',
        hex: '9C5642',
        code: '18-1441',
      },
    ],
  },
  {
    _id: 'Citrine',
    colors: [
      {
        name: 'Evening Primrose',
        main: 'yellow',
        hex: 'CDDC20',
        code: '13-0651',
      },
      {
        name: 'Sulphur Spring',
        main: 'yellow',
        hex: 'D5D717',
        code: '13-0650',
      },
      {
        name: 'Aurora',
        main: 'yellow',
        hex: 'EDDD59',
        code: '12-0642',
      },
      {
        name: 'Yellow Jasper',
        main: 'yellow',
        hex: 'F7EF70',
        code: '12-0719',
      },
      {
        name: 'Buttercup',
        main: 'yellow',
        hex: 'FAE03C',
        code: '12-0752',
      },
      {
        name: 'Golden Kiwi',
        main: 'yellow',
        hex: 'F4DD40',
        code: '13-0644',
      },
      {
        name: 'Summer Sun',
        main: 'yellow',
        hex: 'EFE237',
        code: '12-0650',
      },
      {
        name: 'Blazing Yellow',
        main: 'yellow',
        hex: 'FEE715',
        code: '12-0643',
      },
      {
        name: 'Meadowlark',
        main: 'yellow',
        hex: 'EADA4F',
        code: '13-0646',
      },
      {
        name: 'Illuminating',
        main: 'yellow',
        hex: 'F5DF4D',
        code: '13-0647',
      },
      {
        name: 'Maize',
        main: 'yellow',
        hex: 'EEC843',
        code: '13-0746',
      },
      {
        name: 'Cyber Yellow',
        main: 'yellow',
        hex: 'FFD500',
        code: '14-0760',
      },
      {
        name: 'Slicker',
        main: 'yellow',
        hex: 'F1CD39',
        code: '13-0749',
      },
      {
        name: 'Quince',
        main: 'yellow',
        hex: 'E8D11F',
        code: '13-0753',
      },
      {
        name: 'Habañero Gold',
        main: 'yellow',
        hex: 'FFD551',
        code: '13-0849',
      },
      {
        name: 'Green Sheen',
        main: 'yellow',
        hex: 'D9CE52',
        code: '13-0648',
      },
      {
        name: 'Lemon Zest',
        main: 'yellow',
        hex: 'F9D857',
        code: '13-0756',
      },
      {
        name: 'Dandelion',
        main: 'yellow',
        hex: 'FFD02E',
        code: '13-0758',
      },
      {
        name: 'Yellow Balloon',
        main: 'yellow',
        hex: 'F0CC4A',
        code: '13-0760',
      },
      {
        name: 'High Visibility',
        main: 'yellow',
        hex: 'F1C700',
        code: '13-0751',
      },
      {
        name: 'Vibrant Yellow',
        main: 'yellow',
        hex: 'FFDA29',
        code: '13-0858',
      },
      {
        name: 'Empire Yellow',
        main: 'yellow',
        hex: 'F7D000',
        code: '14-0756',
      },
    ],
  },
  {
    _id: 'Coral ',
    colors: [
      {
        name: 'Sugar Coral',
        main: 'pink',
        hex: 'F56C73',
        code: '16-1640',
      },
      {
        name: 'Hot Coral',
        main: 'pink',
        hex: 'F35B54',
        code: '17-1656',
      },
      {
        name: 'Burnt Sienna',
        main: 'red',
        hex: 'C65D52',
        code: '17-1544',
      },
      {
        name: 'Rose of Sharon',
        main: 'red',
        hex: 'DC5B62',
        code: '17-1635',
      },
      {
        name: 'Spiced Coral',
        main: 'red',
        hex: 'D75C5D',
        code: '17-1644',
      },
      {
        name: 'Dusted Clay',
        main: 'red',
        hex: 'CC7457',
        code: '15-1429',
      },
      {
        name: 'Dubarry',
        main: 'red',
        hex: 'F25F66',
        code: '17-1647',
      },
      {
        name: 'Dusty Coral',
        main: 'red',
        hex: 'D29B83',
        code: '15-1322',
      },
      {
        name: 'Copper Coin',
        main: 'red',
        hex: 'BA6B57',
        code: '18-1537',
      },
      {
        name: 'Deep Sea Coral',
        main: 'red',
        hex: 'D9615B',
        code: '18-1649',
      },
      {
        name: 'Cayenne',
        main: 'red',
        hex: 'E04951',
        code: '18-1651',
      },
    ],
  },
  {
    _id: 'Ecru',
    colors: [
      {
        name: 'Egret',
        main: 'beige',
        hex: 'F3ECE0',
        code: '11-0103',
      },
      {
        name: 'Winter White',
        main: 'beige',
        hex: 'F5ECD2',
        code: '11-0507',
      },
      {
        name: 'Shortbread',
        main: 'beige',
        hex: 'EFDCC3',
        code: '12-0710',
      },
      {
        name: 'Afterglow',
        main: 'beige',
        hex: 'F3E6C9',
        code: '11-0510',
      },
      {
        name: 'Papyrus',
        main: 'beige',
        hex: 'F5EDD6',
        code: '11-0107',
      },
      {
        name: 'Pearled Ivory',
        main: 'beige',
        hex: 'F0DFCC',
        code: '11-0907',
      },
      {
        name: 'Cloud Cream',
        main: 'beige',
        hex: 'E6DDC5',
        code: '12-0804',
      },
      {
        name: 'Alabaster Gleam',
        main: 'beige',
        hex: 'F0DEBD',
        code: '12-0812',
      },
      {
        name: 'Vanilla Ice',
        main: 'beige',
        hex: 'F0EADA',
        code: '11-0104',
      },
      {
        name: 'Sweet Corn',
        main: 'beige',
        hex: 'F0EBD7',
        code: '11-0106',
      },
      {
        name: 'Solitary Star',
        main: 'beige',
        hex: 'F3EBD6',
        code: '11-0108',
      },
      {
        name: 'Pear Sorbet',
        main: 'beige',
        hex: 'F3EAC3',
        code: '11-0615',
      },
      {
        name: 'Cannoli Cream',
        main: 'beige',
        hex: 'F1F0E2',
        code: '11-4302',
      },
      {
        name: 'Rutabaga',
        main: 'beige',
        hex: 'EDDCBE',
        code: '12-0806',
      },
      {
        name: 'Vanilla Custard',
        main: 'beige',
        hex: 'F3E0BE',
        code: '12-0815',
      },
      {
        name: 'Buttercream',
        main: 'brown',
        hex: 'EFE1CE',
        code: '11-0110',
      },
      {
        name: 'Seedpearl',
        main: 'brown',
        hex: 'E6DAC4',
        code: '12-0703',
      },
      {
        name: 'White Smoke',
        main: 'brown',
        hex: 'EDDCC9',
        code: '12-0704',
      },
      {
        name: 'Dawn',
        main: 'orange',
        hex: 'EBD2B7',
        code: '12-0811',
      },
      {
        name: 'Ecru',
        main: 'orange',
        hex: 'F3DFCA',
        code: '11-0809',
      },
    ],
  },
  {
    _id: 'Emerald',
    colors: [
      {
        name: 'Peapod',
        main: 'green',
        hex: '82B185',
        code: '14-6324',
      },
      {
        name: 'Zephyr Green',
        main: 'green',
        hex: '7CB083',
        code: '14-6327',
      },
      {
        name: 'Breezy Green',
        main: 'green',
        hex: '6DD0A9',
        code: '14-5828',
      },
      {
        name: 'Neptune Green',
        main: 'green',
        hex: '7FBB9E',
        code: '14-6017',
      },
      {
        name: 'Absinthe Green',
        main: 'green',
        hex: '76B583',
        code: '14-6329',
      },
      {
        name: 'Bright Green',
        main: 'green',
        hex: '009B5C',
        code: '15-5534',
      },
      {
        name: 'Light Grass Green',
        main: 'green',
        hex: '7CB68E',
        code: '15-6322',
      },
      {
        name: 'Mentholated',
        main: 'green',
        hex: '60BD98',
        code: '15-5810',
      },
      {
        name: 'Ming',
        main: 'green',
        hex: '7CB08A',
        code: '15-6120',
      },
      {
        name: 'Green Spruce',
        main: 'green',
        hex: '589F7E',
        code: '16-5820',
      },
      {
        name: 'Crème de Menthe',
        main: 'green',
        hex: '70A38D',
        code: '16-5919',
      },
      {
        name: 'Blarney',
        main: 'green',
        hex: '00A776',
        code: '16-5942',
      },
      {
        name: 'Mint',
        main: 'green',
        hex: '00A170',
        code: '16-5938',
      },
      {
        name: 'Peppermint',
        main: 'green',
        hex: '699E6D',
        code: '16-6329',
      },
      {
        name: 'Online Lime',
        main: 'green',
        hex: '44883C',
        code: '17-0145',
      },
      {
        name: 'Viridis',
        main: 'green',
        hex: '00846B',
        code: '17-5734',
      },
      {
        name: 'Jelly Bean',
        main: 'green',
        hex: '008658',
        code: '17-6030',
      },
      {
        name: 'Green Bee',
        main: 'green',
        hex: '008C4E',
        code: '17-6154',
      },
      {
        name: 'Medium Green',
        main: 'green',
        hex: '3C824E',
        code: '17-6229',
      },
      {
        name: 'Pepper Green',
        main: 'green',
        hex: '007D60',
        code: '18-5841',
      },
      {
        name: 'Deep Mint',
        main: 'green',
        hex: '009E6D',
        code: '17-5937',
      },
      {
        name: 'Fern Green',
        main: 'green',
        hex: '008C45',
        code: '17-6153',
      },
      {
        name: 'Mint Green',
        main: 'green',
        hex: '487D49',
        code: '17-6333',
      },
      {
        name: 'Rolling Hills',
        main: 'green',
        hex: '227949',
        code: '18-6028',
      },
      {
        name: 'Jolly Green',
        main: 'green',
        hex: '007844',
        code: '18-6030',
      },
      {
        name: 'Deep Green',
        main: 'green',
        hex: '009276',
        code: '17-5633',
      },
      {
        name: 'Emerald',
        main: 'green',
        hex: '009473',
        code: '17-5641',
      },
      {
        name: 'Green Tambourine',
        main: 'green',
        hex: '007449',
        code: '18-6033',
      },
      {
        name: 'Parakeet',
        main: 'green',
        hex: '008C69',
        code: '17-5735',
      },
      {
        name: 'Bosphorus',
        main: 'green',
        hex: '007558',
        code: '18-5633',
      },
      {
        name: 'Golf Green',
        main: 'green',
        hex: '008763',
        code: '18-5642',
      },
      {
        name: 'Amazon',
        main: 'green',
        hex: '207349',
        code: '18-6024',
      },
      {
        name: 'First Tee',
        main: 'green',
        hex: '00723B',
        code: '18-6031',
      },
      {
        name: 'Fairway',
        main: 'green',
        hex: '477050',
        code: '18-6320',
      },
      {
        name: 'Juniper',
        main: 'green',
        hex: '3D7245',
        code: '18-6330',
      },
      {
        name: 'Cascade',
        main: 'aqua',
        hex: '76C1B2',
        code: '14-5713',
      },
      {
        name: 'Opal',
        main: 'aqua',
        hex: '77CFB7',
        code: '14-5718',
      },
      {
        name: 'Beveled Glass',
        main: 'aqua',
        hex: '7ACCB8',
        code: '14-5714',
      },
      {
        name: 'Electric Green',
        main: 'aqua',
        hex: '4BC3A7',
        code: '14-5721',
      },
      {
        name: 'Peacock Green',
        main: 'aqua',
        hex: '00A78B',
        code: '16-5431',
      },
      {
        name: 'Marine Green',
        main: 'aqua',
        hex: '40A48E',
        code: '16-5721',
      },
      {
        name: 'Gumdrop Green',
        main: 'aqua',
        hex: '2FA785',
        code: '16-5825',
      },
      {
        name: 'Vivid Green',
        main: 'aqua',
        hex: '009E82',
        code: '17-5638',
      },
      {
        name: 'Simply Green',
        main: 'aqua',
        hex: '009B74',
        code: '17-5936',
      },
      {
        name: 'Holly Green',
        main: 'aqua',
        hex: '0F9D76',
        code: '16-5932',
      },
      {
        name: 'Mint Leaf',
        main: 'aqua',
        hex: '00B694',
        code: '15-5728',
      },
      {
        name: 'Biscay Green',
        main: 'aqua',
        hex: '56C6A9',
        code: '15-5718',
      },
      {
        name: 'Ming Green',
        main: 'yellow',
        hex: '3AA278',
        code: '16-5930',
      },
      {
        name: 'Island Green',
        main: 'yellow',
        hex: '2CAE66',
        code: '16-6240',
      },
      {
        name: 'Classic Green',
        main: 'yellow',
        hex: '39A845',
        code: '16-6340',
      },
      {
        name: 'Leprechaun',
        main: 'yellow',
        hex: '378661',
        code: '18-6022',
      },
      {
        name: 'Jade Cream',
        main: 'yellow',
        hex: '60B892',
        code: '15-6123',
      },
      {
        name: 'Greenbriar',
        main: 'yellow',
        hex: '4B9B69',
        code: '16-6127',
      },
      {
        name: 'Vibrant Green',
        main: 'yellow',
        hex: '55A860',
        code: '16-6339',
      },
      {
        name: 'Poison Green',
        main: 'yellow',
        hex: '4DB560',
        code: '16-6444',
      },
      {
        name: 'Spearmint',
        main: 'yellow',
        hex: '64BFA4',
        code: '15-5819',
      },
      {
        name: 'Irish Green',
        main: 'yellow',
        hex: '45BE76',
        code: '15-6340',
      },
      {
        name: 'Winter Green',
        main: 'yellow',
        hex: '4F9E81',
        code: '16-5924',
      },
      {
        name: 'Katydid',
        main: 'yellow',
        hex: '66BC91',
        code: '16-6030',
      },
      {
        name: 'Kelly Green',
        main: 'yellow',
        hex: '349C5E',
        code: '16-6138',
      },
    ],
  },
  {
    _id: 'Forest Green',
    colors: [
      {
        name: 'Sap Green',
        main: 'green',
        hex: 'AFCB80',
        code: '13-0331',
      },
      {
        name: 'Herbal Garden',
        main: 'green',
        hex: '9CAD60',
        code: '15-0336',
      },
      {
        name: 'Leaf Green',
        main: 'green',
        hex: '9FAF6C',
        code: '15-0332',
      },
      {
        name: 'Opaline Green',
        main: 'green',
        hex: 'A3C57D',
        code: '14-0226',
      },
      {
        name: 'Greenery',
        main: 'green',
        hex: '88B04B',
        code: '15-0343',
      },
      {
        name: 'Forest Shade',
        main: 'green',
        hex: '91AC80',
        code: '15-6423',
      },
      {
        name: 'Shamrock',
        main: 'green',
        hex: '6FA26B',
        code: '15-6432',
      },
      {
        name: 'Loden Frost',
        main: 'green',
        hex: '788F74',
        code: '17-0210',
      },
      {
        name: 'Meadow Green',
        main: 'green',
        hex: '739957',
        code: '16-0233',
      },
      {
        name: 'Kiwi',
        main: 'green',
        hex: '7AAB55',
        code: '16-0235',
      },
      {
        name: 'Shale Green',
        main: 'green',
        hex: '739072',
        code: '16-6116',
      },
      {
        name: 'Basil',
        main: 'green',
        hex: '879F84',
        code: '16-6216',
      },
      {
        name: 'Turf Green',
        main: 'green',
        hex: '6F8C69',
        code: '17-0119',
      },
      {
        name: 'Parrot Green',
        main: 'green',
        hex: '8DB051',
        code: '15-0341',
      },
      {
        name: 'Grass Green',
        main: 'green',
        hex: '7BB369',
        code: '15-6437',
      },
      {
        name: 'Mistletoe',
        main: 'green',
        hex: '8AA282',
        code: '16-0220',
      },
      {
        name: 'Foliage',
        main: 'green',
        hex: '75A14F',
        code: '16-0237',
      },
      {
        name: 'Jadesheen',
        main: 'green',
        hex: '77A276',
        code: '16-6324',
      },
      {
        name: 'Stone Green',
        main: 'green',
        hex: '658E67',
        code: '17-0123',
      },
      {
        name: 'Watercress',
        main: 'green',
        hex: '748C69',
        code: '17-0220',
      },
      {
        name: 'Green Tea',
        main: 'green',
        hex: '86A96F',
        code: '15-6428',
      },
      {
        name: 'Bud Green',
        main: 'green',
        hex: '79B465',
        code: '15-6442',
      },
      {
        name: 'Tendril',
        main: 'green',
        hex: '89A06B',
        code: '16-0123',
      },
      {
        name: 'Green Eyes',
        main: 'green',
        hex: '7D946D',
        code: '16-0224',
      },
      {
        name: 'Jade Green',
        main: 'green',
        hex: '759465',
        code: '16-0228',
      },
      {
        name: 'Seagrass',
        main: 'green',
        hex: '959889',
        code: '16-6008',
      },
      {
        name: 'Mineral Green',
        main: 'green',
        hex: '7A9B77',
        code: '16-6318',
      },
      {
        name: 'Fluorite Green',
        main: 'green',
        hex: '699158',
        code: '17-0133',
      },
      {
        name: 'Aspen Green',
        main: 'green',
        hex: '7E9B75',
        code: '17-0215',
      },
      {
        name: 'Salted Lime',
        main: 'green',
        hex: '60843C',
        code: '17-0232',
      },
      {
        name: 'Piquant Green',
        main: 'green',
        hex: '769358',
        code: '17-0235',
      },
      {
        name: 'Spindle Tree',
        main: 'green',
        hex: '6D8F3E',
        code: '17-0332',
      },
      {
        name: 'Peridot',
        main: 'green',
        hex: '819448',
        code: '17-0336',
      },
      {
        name: 'Hedge Green',
        main: 'green',
        hex: '768A75',
        code: '17-6323',
      },
      {
        name: 'Agave Green',
        main: 'green',
        hex: '6B7169',
        code: '18-5806',
      },
      {
        name: 'Forest Elf',
        main: 'green',
        hex: '395228',
        code: '19-0231',
      },
      {
        name: 'Rifle Green',
        main: 'green',
        hex: '424832',
        code: '19-0419',
      },
      {
        name: 'Turtle Green',
        main: 'green',
        hex: '81894E',
        code: '17-0330',
      },
      {
        name: 'Olivine',
        main: 'green',
        hex: '666B54',
        code: '18-0316',
      },
      {
        name: 'Winter Moss',
        main: 'green',
        hex: '5B5A41',
        code: '18-0523',
      },
      {
        name: 'Sphagnum',
        main: 'green',
        hex: '62633D',
        code: '18-0529',
      },
      {
        name: 'Kashmir',
        main: 'green',
        hex: '6F8D6A',
        code: '17-6319',
      },
      {
        name: 'Vineyard Green',
        main: 'green',
        hex: '5F7355',
        code: '18-0117',
      },
      {
        name: 'Willow Bough',
        main: 'green',
        hex: '59754D',
        code: '18-0119',
      },
      {
        name: 'Treetop',
        main: 'green',
        hex: '476A30',
        code: '18-0135',
      },
      {
        name: 'Cypress',
        main: 'green',
        hex: '545A3E',
        code: '18-0322',
      },
      {
        name: 'Four Leaf Clover',
        main: 'green',
        hex: '616652',
        code: '18-0420',
      },
      {
        name: 'Douglas Fir',
        main: 'green',
        hex: '36492E',
        code: '19-0220',
      },
      {
        name: 'Black Forest',
        main: 'green',
        hex: '424F3C',
        code: '19-0315',
      },
      {
        name: 'Kombu Green',
        main: 'green',
        hex: '3A4132',
        code: '19-0417',
      },
      {
        name: 'Sage',
        main: 'green',
        hex: '91946E',
        code: '16-0421',
      },
      {
        name: 'Oil Green',
        main: 'green',
        hex: '80856D',
        code: '17-0115',
      },
      {
        name: 'Pesto',
        main: 'green',
        hex: '595F34',
        code: '18-0228',
      },
      {
        name: 'Cedar Green',
        main: 'green',
        hex: '5E6737',
        code: '18-0328',
      },
      {
        name: 'Epsom',
        main: 'green',
        hex: '849161',
        code: '17-0324',
      },
      {
        name: 'Kale',
        main: 'green',
        hex: '5A7247',
        code: '18-0107',
      },
      {
        name: 'Dill',
        main: 'green',
        hex: '647755',
        code: '18-0108',
      },
      {
        name: 'Elm Green',
        main: 'green',
        hex: '547053',
        code: '18-0121',
      },
      {
        name: 'Artichoke Green',
        main: 'green',
        hex: '4B6D41',
        code: '18-0125',
      },
      {
        name: 'Cactus',
        main: 'green',
        hex: '53713D',
        code: '18-0130',
      },
      {
        name: 'Courtyard',
        main: 'green',
        hex: '3E5E32',
        code: '18-0226',
      },
      {
        name: 'Bronze Green',
        main: 'green',
        hex: '525F48',
        code: '18-0317',
      },
      {
        name: 'Thyme',
        main: 'green',
        hex: '50574C',
        code: '19-0309',
      },
      {
        name: 'Deep Depths',
        main: 'green',
        hex: '46483D',
        code: '19-0413',
      },
      {
        name: 'Duffel Bag',
        main: 'green',
        hex: '394034',
        code: '19-0415',
      },
      {
        name: 'Deep Forest',
        main: 'green',
        hex: '37413A',
        code: '19-6110',
      },
      {
        name: 'Olive Branch',
        main: 'green',
        hex: '6A6A45',
        code: '18-0527',
      },
      {
        name: 'Chive',
        main: 'green',
        hex: '4B5335',
        code: '19-0323',
      },
      {
        name: 'Kambaba',
        main: 'green',
        hex: '4C4D44',
        code: '19-0404',
      },
      {
        name: 'Forest Green',
        main: 'green',
        hex: '6B8D53',
        code: '17-0230',
      },
      {
        name: 'Minced Herb',
        main: 'green',
        hex: '6B8542',
        code: '17-0345',
      },
      {
        name: 'English Ivy',
        main: 'green',
        hex: '61845B',
        code: '18-0110',
      },
      {
        name: 'Banana Palm',
        main: 'green',
        hex: '4F6C35',
        code: '18-0230',
      },
      {
        name: 'Garden Green',
        main: 'green',
        hex: '495E35',
        code: '19-0230',
      },
      {
        name: 'Kelp Forest',
        main: 'green',
        hex: '728839',
        code: '17-0340',
      },
      {
        name: 'Campsite',
        main: 'green',
        hex: '627C4C',
        code: '18-0323',
      },
      {
        name: 'Calliste Green',
        main: 'green',
        hex: '757A4E',
        code: '18-0324',
      },
      {
        name: 'Twist of Lime',
        main: 'green',
        hex: '4F632D',
        code: '18-0330',
      },
      {
        name: 'Grape Leaf',
        main: 'green',
        hex: '545244',
        code: '19-0511',
      },
    ],
  },
  {
    _id: 'Fuchsia',
    colors: [
      {
        name: 'Pink Tulle',
        main: 'pink',
        hex: 'F0C9E1',
        code: '13-2800',
      },
      {
        name: 'Full Blooming Pink',
        main: 'pink',
        hex: 'E0AAC8',
        code: '14-2609',
      },
      {
        name: 'Morning Glory',
        main: 'pink',
        hex: 'EC809E',
        code: '15-1920',
      },
      {
        name: 'Thinking Pink',
        main: 'pink',
        hex: 'EE91A9',
        code: '15-2122',
      },
      {
        name: 'Rosebloom',
        main: 'pink',
        hex: 'E290B2',
        code: '15-2214',
      },
      {
        name: 'Sachet Pink',
        main: 'pink',
        hex: 'F18AAD',
        code: '15-2216',
      },
      {
        name: 'Pink Frosting',
        main: 'pink',
        hex: 'F1A9CB',
        code: '15-2218',
      },
      {
        name: 'Pink Cosmos',
        main: 'pink',
        hex: 'F07BA5',
        code: '16-2122',
      },
      {
        name: 'Carmine Rose',
        main: 'pink',
        hex: 'E35B8F',
        code: '17-2230',
      },
      {
        name: 'Strawberry Moon',
        main: 'pink',
        hex: 'D96BA1',
        code: '17-2619',
      },
      {
        name: 'Fiji Flower',
        main: 'pink',
        hex: 'CA669D',
        code: '17-2623',
      },
      {
        name: 'Beetroot Purple',
        main: 'pink',
        hex: 'CF2D71',
        code: '18-2143',
      },
      {
        name: 'Fuchsia Red',
        main: 'pink',
        hex: 'AB3475',
        code: '18-2328',
      },
      {
        name: 'Fuchsia Purple',
        main: 'pink',
        hex: 'D4367A',
        code: '18-2436',
      },
      {
        name: 'Romance Rose',
        main: 'pink',
        hex: 'E8B4C2',
        code: '13-2008',
      },
      {
        name: 'Pink Drink',
        main: 'pink',
        hex: 'F899B4',
        code: '14-2216',
      },
      {
        name: 'Prism Pink',
        main: 'pink',
        hex: 'F0A1BF',
        code: '14-2311',
      },
      {
        name: 'Fuchsia Pink',
        main: 'pink',
        hex: 'DF88B7',
        code: '15-2718',
      },
      {
        name: 'Wild Rose',
        main: 'pink',
        hex: 'CE8498',
        code: '16-1715',
      },
      {
        name: 'Pink Power',
        main: 'pink',
        hex: 'D56793',
        code: '16-2118',
      },
      {
        name: 'Pink Carnation',
        main: 'pink',
        hex: 'ED7A9E',
        code: '16-2124',
      },
      {
        name: 'Moonlite Mauve',
        main: 'pink',
        hex: 'D28FB0',
        code: '16-2614',
      },
      {
        name: 'Fuchsia Rose',
        main: 'pink',
        hex: 'C74375',
        code: '17-2031',
      },
      {
        name: 'Cone Flower',
        main: 'pink',
        hex: 'C35989',
        code: '17-2521',
      },
      {
        name: 'Phlox Pink',
        main: 'pink',
        hex: 'CE5E9A',
        code: '17-2627',
      },
      {
        name: 'Pink Flambé',
        main: 'pink',
        hex: 'D3507A',
        code: '18-2133',
      },
      {
        name: 'Bleached Mauve',
        main: 'pink',
        hex: 'D5ADB8',
        code: '14-2304',
      },
      {
        name: 'Bonbon',
        main: 'pink',
        hex: 'F1A6CC',
        code: '15-2213',
      },
      {
        name: 'Azalea Pink',
        main: 'pink',
        hex: 'E96A97',
        code: '16-2126',
      },
      {
        name: 'Chateau Rose',
        main: 'pink',
        hex: 'D2738F',
        code: '17-2120',
      },
      {
        name: 'Dahlia Mauve',
        main: 'pink',
        hex: 'A64F82',
        code: '17-2617',
      },
      {
        name: 'Rose Violet',
        main: 'pink',
        hex: 'C0428A',
        code: '17-2624',
      },
      {
        name: 'Super Pink',
        main: 'pink',
        hex: 'CE6BA4',
        code: '17-2625',
      },
      {
        name: 'Cameo Pink',
        main: 'pink',
        hex: 'DBA9B7',
        code: '14-2307',
      },
      {
        name: 'Sea Pink',
        main: 'pink',
        hex: 'DE98AB',
        code: '15-1912',
      },
      {
        name: 'Orchid Smoke',
        main: 'pink',
        hex: 'D294AA',
        code: '15-2210',
      },
      {
        name: 'Begonia Pink',
        main: 'pink',
        hex: 'EC9ABE',
        code: '15-2215',
      },
      {
        name: 'Aurora Pink',
        main: 'pink',
        hex: 'E881A6',
        code: '15-2217',
      },
      {
        name: 'Wild Orchid',
        main: 'pink',
        hex: 'D979A2',
        code: '16-2120',
      },
      {
        name: 'Cashmere Rose',
        main: 'pink',
        hex: 'CE879F',
        code: '16-2215',
      },
      {
        name: 'Shocking Pink',
        main: 'pink',
        hex: 'DE5B8C',
        code: '17-2127',
      },
      {
        name: 'Sangria Sunset',
        main: 'pink',
        hex: 'E96393',
        code: '17-2235',
      },
      {
        name: 'Gin Fizz',
        main: 'pink',
        hex: 'CB5B8A',
        code: '17-2519',
      },
      {
        name: 'Ibis Rose',
        main: 'pink',
        hex: 'CA628F',
        code: '17-2520',
      },
      {
        name: 'Fuchsia Fedora',
        main: 'pink',
        hex: 'CA4286',
        code: '18-2330',
      },
      {
        name: 'Raspberry Rose',
        main: 'pink',
        hex: 'CC4385',
        code: '18-2333',
      },
      {
        name: 'Festival Fuchsia',
        main: 'pink',
        hex: '9E2C6A',
        code: '19-2434',
      },
    ],
  },
  {
    _id: 'Grape',
    colors: [
      {
        name: 'Rosé Sorbet',
        main: 'pink',
        hex: 'A3497C',
        code: '18-2615',
      },
      {
        name: 'Valerian',
        main: 'pink',
        hex: '9F7A93',
        code: '17-3410',
      },
      {
        name: 'African Violet',
        main: 'blue',
        hex: 'B085B7',
        code: '16-3520',
      },
      {
        name: 'Lavender Mist',
        main: 'blue',
        hex: 'AE90A7',
        code: '16-3307',
      },
      {
        name: 'Smoky Grape',
        main: 'purple',
        hex: 'B88AAC',
        code: '16-3110',
      },
      {
        name: 'Rhapsody',
        main: 'purple',
        hex: '9F86AA',
        code: '16-3817',
      },
      {
        name: 'Orchid Haze',
        main: 'purple',
        hex: 'B0879B',
        code: '16-2107',
      },
      {
        name: 'Crocus',
        main: 'purple',
        hex: 'C67FAE',
        code: '16-3115',
      },
      {
        name: 'Violet',
        main: 'purple',
        hex: 'C180B5',
        code: '16-3320',
      },
      {
        name: 'Sheer Lilac',
        main: 'purple',
        hex: 'B793C0',
        code: '16-3617',
      },
      {
        name: 'Mulberry',
        main: 'purple',
        hex: 'A76C97',
        code: '17-3014',
      },
      {
        name: 'Dusty Lavender',
        main: 'blue',
        hex: 'A1759C',
        code: '17-3313',
      },
      {
        name: 'Purple Dove',
        main: 'purple',
        hex: '99888D',
        code: '16-1606',
      },
      {
        name: 'Mauve Orchid',
        main: 'purple',
        hex: 'B58299',
        code: '16-2111',
      },
      {
        name: 'First Bloom',
        main: 'purple',
        hex: 'B977AE',
        code: '16-3321',
      },
      {
        name: 'Spring Crocus',
        main: 'purple',
        hex: 'BA69A1',
        code: '17-3020',
      },
      {
        name: 'Liatris',
        main: 'purple',
        hex: 'A8639C',
        code: '17-3320',
      },
      {
        name: 'Iris Orchid',
        main: 'purple',
        hex: 'A767A2',
        code: '17-3323',
      },
      {
        name: 'Magenta Haze',
        main: 'purple',
        hex: '9D446E',
        code: '18-2525',
      },
      {
        name: 'Purple Wine',
        main: 'purple',
        hex: '8C3573',
        code: '18-2929',
      },
      {
        name: 'Purple Orchid',
        main: 'purple',
        hex: 'AD4D8C',
        code: '18-3027',
      },
      {
        name: 'Willowherb',
        main: 'purple',
        hex: '8F4584',
        code: '18-3120',
      },
      {
        name: 'Grapeade',
        main: 'purple',
        hex: '85677B',
        code: '18-3211',
      },
      {
        name: 'Very Grape',
        main: 'purple',
        hex: '927288',
        code: '18-3220',
      },
      {
        name: 'Cattleya Orchid',
        main: 'purple',
        hex: '9C4C8D',
        code: '18-3223',
      },
      {
        name: 'Dahlia',
        main: 'purple',
        hex: '853F84',
        code: '18-3324',
      },
      {
        name: 'Hyacinth Violet',
        main: 'purple',
        hex: '8D4687',
        code: '18-3331',
      },
      {
        name: 'Charisma',
        main: 'purple',
        hex: '642B60',
        code: '18-3340',
      },
      {
        name: 'Bellflower',
        main: 'purple',
        hex: '9469A2',
        code: '18-3628',
      },
      {
        name: 'Rosebud',
        main: 'purple',
        hex: 'B65F9A',
        code: '17-3023',
      },
      {
        name: 'Amethyst',
        main: 'purple',
        hex: '864D75',
        code: '18-3015',
      },
      {
        name: 'Striking Purple',
        main: 'purple',
        hex: '944E87',
        code: '18-3025',
      },
      {
        name: 'Concord Grape',
        main: 'purple',
        hex: '7C5379',
        code: '18-3218',
      },
      {
        name: 'Lavender Crystal',
        main: 'purple',
        hex: '936B99',
        code: '18-3530',
      },
      {
        name: 'Bodacious',
        main: 'purple',
        hex: 'B76CA4',
        code: '17-3240',
      },
      {
        name: 'Baton Rouge',
        main: 'purple',
        hex: '973C6C',
        code: '18-2527',
      },
      {
        name: 'Deep Orchid',
        main: 'purple',
        hex: '903F75',
        code: '18-3022',
      },
      {
        name: 'Beautyberry',
        main: 'purple',
        hex: '88447B',
        code: '18-3024',
      },
      {
        name: 'Radiant Orchid',
        main: 'purple',
        hex: 'AD5E99',
        code: '18-3224',
      },
      {
        name: 'Meadow Mauve',
        main: 'purple',
        hex: 'A9568C',
        code: '18-3230',
      },
      {
        name: 'Pale Pansy',
        main: 'purple',
        hex: 'B380AA',
        code: '17-3310',
      },
      {
        name: 'Grape Shake',
        main: 'purple',
        hex: '896971',
        code: '18-2109',
      },
      {
        name: 'Clover',
        main: 'purple',
        hex: '8A3371',
        code: '18-2320',
      },
      {
        name: 'Vivid Viola',
        main: 'purple',
        hex: '993C7C',
        code: '18-3339',
      },
      {
        name: 'Chinese Violet',
        main: 'purple',
        hex: '835E81',
        code: '18-3418',
      },
      {
        name: 'Hollyhock',
        main: 'purple',
        hex: '823270',
        code: '19-2924',
      },
      {
        name: 'Gloxinia',
        main: 'purple',
        hex: '632E5A',
        code: '19-3022',
      },
      {
        name: 'Byzantium',
        main: 'purple',
        hex: '853B7B',
        code: '19-3138',
      },
      {
        name: 'Wild Aster',
        main: 'purple',
        hex: '92316F',
        code: '19-2630',
      },
      {
        name: 'Phlox',
        main: 'purple',
        hex: '692D5D',
        code: '19-2820',
      },
      {
        name: 'Grape Juice',
        main: 'purple',
        hex: '682961',
        code: '19-3230',
      },
      {
        name: 'Sparkling Grape',
        main: 'purple',
        hex: '763374',
        code: '19-3336',
      },
    ],
  },
  {
    _id: 'Gunmetal',
    colors: [
      {
        name: 'Gunmetal',
        main: 'gray',
        hex: '5C5D5B',
        code: '18-0306',
      },
      {
        name: 'Asphalt',
        main: 'gray',
        hex: '444447',
        code: '19-0201',
      },
      {
        name: 'Gray Pinstripe',
        main: 'gray',
        hex: '4A494D',
        code: '19-0203',
      },
      {
        name: 'Raven',
        main: 'gray',
        hex: '413E3D',
        code: '19-0000',
      },
      {
        name: 'Peat',
        main: 'gray',
        hex: '3B3A36',
        code: '19-0508',
      },
      {
        name: 'Dark Shadow',
        main: 'gray',
        hex: '4A4B4D',
        code: '19-3906',
      },
      {
        name: 'Volcanic Ash',
        main: 'gray',
        hex: '484849',
        code: '19-3912',
      },
      {
        name: 'Magnet',
        main: 'gray',
        hex: '4D4B4F',
        code: '19-3901',
      },
      {
        name: 'Carbon Copy',
        main: 'gray',
        hex: '464143',
        code: '19-3904',
      },
      {
        name: 'Black Sand',
        main: 'gray',
        hex: '3C3E40',
        code: '19-4406',
      },
      {
        name: 'Urban Chic',
        main: 'gray',
        hex: '474E4D',
        code: '19-5004',
      },
      {
        name: 'Iron Gate',
        main: 'gray',
        hex: '4E5055',
        code: '19-3910',
      },
      {
        name: 'Black Oyster',
        main: 'gray',
        hex: '414143',
        code: '19-0205',
      },
      {
        name: 'Nine Iron',
        main: 'gray',
        hex: '46434A',
        code: '19-3908',
      },
      {
        name: 'Ebony',
        main: 'gray',
        hex: '41424A',
        code: '19-4104',
      },
      {
        name: 'Turbulence',
        main: 'gray',
        hex: '4E545B',
        code: '19-4215',
      },
      {
        name: 'Gray Whale',
        main: 'gray',
        hex: '4D5055',
        code: '19-4310',
      },
      {
        name: 'Clever Crows',
        main: 'black',
        hex: '3E3F40',
        code: '19-5303',
      },
    ],
  },
  {
    _id: 'Hazel ',
    colors: [
      {
        name: 'Hazelnut',
        main: 'brown',
        hex: 'CFB095',
        code: '14-1315',
      },
      {
        name: 'Tan',
        main: 'brown',
        hex: 'B69574',
        code: '16-1334',
      },
      {
        name: 'Argan Oil',
        main: 'brown',
        hex: '8B5A3F',
        code: '17-1142',
      },
      {
        name: 'Macchiato',
        main: 'brown',
        hex: 'AC8266',
        code: '17-1221',
      },
      {
        name: 'Taffy',
        main: 'brown',
        hex: 'C39B6A',
        code: '16-0940',
      },
      {
        name: 'Doe',
        main: 'brown',
        hex: 'B98E68',
        code: '16-1333',
      },
      {
        name: 'Tawny Birch',
        main: 'brown',
        hex: 'AE856C',
        code: '17-1225',
      },
      {
        name: 'Iced Coffee',
        main: 'brown',
        hex: 'B18F6A',
        code: '15-1040',
      },
      {
        name: 'Taos Taupe',
        main: 'brown',
        hex: 'BFA77F',
        code: '15-1119',
      },
      {
        name: 'Fenugreek',
        main: 'brown',
        hex: 'C1926C',
        code: '15-1425',
      },
      {
        name: 'Sandstorm',
        main: 'brown',
        hex: 'BD8B69',
        code: '16-1235',
      },
      {
        name: 'Brown Sugar',
        main: 'brown',
        hex: 'A17249',
        code: '17-1134',
      },
      {
        name: 'Pastry Shell',
        main: 'brown',
        hex: 'BD8D67',
        code: '15-1430',
      },
      {
        name: 'Biscuit',
        main: 'brown',
        hex: 'B4835B',
        code: '16-1336',
      },
      {
        name: 'Hazel',
        main: 'brown',
        hex: 'AE7250',
        code: '17-1143',
      },
      {
        name: 'Mocha Mousse',
        main: 'brown',
        hex: 'A47864',
        code: '17-1230',
      },
      {
        name: 'Bran',
        main: 'brown',
        hex: 'A66E4A',
        code: '17-1336',
      },
      {
        name: 'Rustic Brown',
        main: 'brown',
        hex: '855141',
        code: '18-1238',
      },
      {
        name: 'Brown Patina',
        main: 'brown',
        hex: '834F3D',
        code: '18-1242',
      },
      {
        name: 'Imperial Topaz',
        main: 'brown',
        hex: '875341',
        code: '18-1243',
      },
      {
        name: 'Chutney',
        main: 'brown',
        hex: '98594B',
        code: '18-1433',
      },
      {
        name: 'Brandy Brown',
        main: 'brown',
        hex: '73362A',
        code: '18-1541',
      },
      {
        name: 'Mahogany',
        main: 'brown',
        hex: '824D46',
        code: '18-1425',
      },
      {
        name: 'Mocha Bisque',
        main: 'brown',
        hex: '8C543B',
        code: '18-1140',
      },
      {
        name: 'Coconut Shell',
        main: 'brown',
        hex: '874E3C',
        code: '18-1230',
      },
      {
        name: 'Madder Brown',
        main: 'brown',
        hex: '6B3231',
        code: '19-1331',
      },
      {
        name: 'Russet Brown',
        main: 'brown',
        hex: '743332',
        code: '19-1338',
      },
      {
        name: 'Arabian Spice',
        main: 'brown',
        hex: '884332',
        code: '19-1245',
      },
      {
        name: 'Maple Sugar',
        main: 'red',
        hex: 'C9A38D',
        code: '15-1316',
      },
      {
        name: 'Mahogany Rose',
        main: 'red',
        hex: 'C5A193',
        code: '15-1511',
      },
    ],
  },
  {
    _id: 'Honey',
    colors: [
      {
        name: 'Cornsilk',
        main: 'orange',
        hex: 'EDC373',
        code: '13-0932',
      },
      {
        name: 'Beeswax',
        main: 'orange',
        hex: 'EBA851',
        code: '14-0941',
      },
      {
        name: 'Golden Rod',
        main: 'orange',
        hex: 'E2A829',
        code: '14-0951',
      },
      {
        name: 'Mango Mojito',
        main: 'orange',
        hex: 'D69D30',
        code: '15-0960',
      },
      {
        name: 'Mineral Yellow',
        main: 'orange',
        hex: 'D39C43',
        code: '15-1046',
      },
      {
        name: 'Yolk Yellow',
        main: 'orange',
        hex: 'E2B051',
        code: '14-0846',
      },
      {
        name: 'Golden Glow',
        main: 'orange',
        hex: 'D99938',
        code: '15-1050',
      },
      {
        name: 'Golden Orange',
        main: 'orange',
        hex: 'D7942E',
        code: '15-1051',
      },
      {
        name: 'Butterscotch',
        main: 'orange',
        hex: 'E19640',
        code: '15-1147',
      },
      {
        name: 'Tawny Olive',
        main: 'green',
        hex: 'C4962C',
        code: '16-0953',
      },
      {
        name: 'Goldfinch',
        main: 'yellow',
        hex: 'F8DC6C',
        code: '12-0737',
      },
      {
        name: 'Lemon Drop',
        main: 'yellow',
        hex: 'FDD878',
        code: '12-0736',
      },
      {
        name: 'Yarrow',
        main: 'yellow',
        hex: 'FACE6D',
        code: '12-0758',
      },
      {
        name: 'Maize',
        main: 'yellow',
        hex: 'EEC843',
        code: '13-0746',
      },
      {
        name: 'Lemon',
        main: 'yellow',
        hex: 'F3BF09',
        code: '13-0752',
      },
      {
        name: 'Solar Power',
        main: 'yellow',
        hex: 'F4BF3A',
        code: '13-0759',
      },
      {
        name: 'Snapdragon',
        main: 'yellow',
        hex: 'FED877',
        code: '13-0840',
      },
      {
        name: 'Aspen Gold',
        main: 'yellow',
        hex: 'FFD662',
        code: '13-0850',
      },
      {
        name: 'Minion YellowTM',
        main: 'yellow',
        hex: 'FFD55E',
        code: '13-0851',
      },
      {
        name: 'Pale Marigold',
        main: 'yellow',
        hex: 'FFC66E',
        code: '13-0945',
      },
      {
        name: 'Daisy Daze',
        main: 'yellow',
        hex: 'E9BE21',
        code: '14-0751',
      },
      {
        name: 'Hot Spot',
        main: 'yellow',
        hex: 'D7AC00',
        code: '14-0758',
      },
      {
        name: 'Mimosa',
        main: 'yellow',
        hex: 'F0C05A',
        code: '14-0848',
      },
      {
        name: 'Spicy Mustard',
        main: 'yellow',
        hex: 'D8AE48',
        code: '14-0952',
      },
      {
        name: 'Yellow Jasmine',
        main: 'yellow',
        hex: 'CEB00D',
        code: '15-0647',
      },
      {
        name: 'Golden Spice',
        main: 'yellow',
        hex: 'C6973F',
        code: '15-0948',
      },
      {
        name: 'Habañero Gold',
        main: 'yellow',
        hex: 'FFD551',
        code: '13-0849',
      },
      {
        name: 'Golden Cream',
        main: 'yellow',
        hex: 'F7BF68',
        code: '13-0939',
      },
      {
        name: 'Banana Cream',
        main: 'yellow',
        hex: 'FFCF73',
        code: '13-0941',
      },
      {
        name: 'Passion Fruit',
        main: 'yellow',
        hex: 'DCC13A',
        code: '14-0750',
      },
      {
        name: 'Sulphur',
        main: 'yellow',
        hex: 'DDB614',
        code: '14-0755',
      },
      {
        name: 'Buff Yellow',
        main: 'yellow',
        hex: 'F1BF70',
        code: '14-0847',
      },
      {
        name: 'Snake Eye',
        main: 'yellow',
        hex: 'CCB932',
        code: '15-0635',
      },
      {
        name: 'Lemon Curry',
        main: 'yellow',
        hex: 'CDA323',
        code: '15-0751',
      },
      {
        name: 'Sunstruck',
        main: 'yellow',
        hex: 'F6CD4C',
        code: '13-0754',
      },
      {
        name: 'Primrose Yellow',
        main: 'yellow',
        hex: 'F6D155',
        code: '13-0755',
      },
      {
        name: 'Lemon Zest',
        main: 'yellow',
        hex: 'F9D857',
        code: '13-0756',
      },
      {
        name: 'Yellow Balloon',
        main: 'yellow',
        hex: 'F0CC4A',
        code: '13-0760',
      },
      {
        name: 'Sunset Gold',
        main: 'yellow',
        hex: 'F7C46C',
        code: '13-0940',
      },
      {
        name: 'Beach Ball',
        main: 'yellow',
        hex: 'F3C65F',
        code: '14-0849',
      },
      {
        name: 'Daffodil',
        main: 'yellow',
        hex: 'FDC04E',
        code: '14-0850',
      },
      {
        name: 'Samoan Sun',
        main: 'yellow',
        hex: 'FBC85F',
        code: '14-0851',
      },
      {
        name: 'Freesia',
        main: 'yellow',
        hex: 'F3C12C',
        code: '14-0852',
      },
      {
        name: 'Bumblebee',
        main: 'yellow',
        hex: 'E7B200',
        code: '14-0958',
      },
      {
        name: 'Golden Yellow',
        main: 'yellow',
        hex: 'CB8E16',
        code: '15-0953',
      },
      {
        name: 'Calendula',
        main: 'yellow',
        hex: 'DFB136',
        code: '13-0757',
      },
      {
        name: 'Incaberry',
        main: 'yellow',
        hex: 'E2C207',
        code: '14-0745',
      },
      {
        name: 'Super Lemon',
        main: 'yellow',
        hex: 'E4BF45',
        code: '14-0754',
      },
      {
        name: 'Misted Marigold',
        main: 'yellow',
        hex: 'E3BD33',
        code: '14-0757',
      },
      {
        name: 'Spectra Yellow',
        main: 'yellow',
        hex: 'F7B718',
        code: '14-0957',
      },
      {
        name: 'Ceylon Yellow',
        main: 'yellow',
        hex: 'D4AE40',
        code: '15-0850',
      },
      {
        name: 'Honey Gold',
        main: 'yellow',
        hex: 'D1A054',
        code: '15-1142',
      },
      {
        name: 'Jurassic Gold',
        main: 'gold',
        hex: 'E7AA56',
        code: '13-0946',
      },
      {
        name: 'Chai Tea',
        main: 'gold',
        hex: 'B28330',
        code: '17-0949',
      },
      {
        name: 'Honey',
        main: 'yellow',
        hex: 'BA9238',
        code: '16-0946',
      },
      {
        name: 'Symphonic Sunset',
        main: 'yellow',
        hex: 'D79D00',
        code: '15-0954',
      },
      {
        name: 'Daylily',
        main: 'yellow',
        hex: 'E9A900',
        code: '15-0956',
      },
      {
        name: 'Sunflower',
        main: 'yellow',
        hex: 'D39237',
        code: '16-1054',
      },
      {
        name: 'Golden Nugget',
        main: 'gold',
        hex: 'DB9B59',
        code: '16-1142',
      },
      {
        name: 'Honey Yellow',
        main: 'yellow',
        hex: 'CA9456',
        code: '16-1143',
      },
      {
        name: 'Spruce Yellow',
        main: 'yellow',
        hex: 'BE8A4A',
        code: '17-1040',
      },
      {
        name: 'Golden Palm',
        main: 'gold',
        hex: 'AA8805',
        code: '17-0839',
      },
    ],
  },
  {
    _id: 'Lavender ',
    colors: [
      {
        name: 'Lavender Mittens',
        main: 'gray',
        hex: 'C3BDC7',
        code: '13-4005',
      },
      {
        name: 'Diaphanous Lilac',
        main: 'gray',
        hex: 'C6C2CD',
        code: '13-3905',
      },
      {
        name: 'Raindrops',
        main: 'gray',
        hex: 'B2ABB4',
        code: '14-3906',
      },
      {
        name: 'Early Hours',
        main: 'pink',
        hex: 'DBC5D0',
        code: '13-2930',
      },
      {
        name: 'Lilac Ash',
        main: 'blue',
        hex: 'D7CDCD',
        code: '13-3803',
      },
      {
        name: 'Iris',
        main: 'blue',
        hex: 'BAAFBC',
        code: '14-3805',
      },
      {
        name: 'Pastel Lilac',
        main: 'blue',
        hex: 'BCAFCF',
        code: '14-3812',
      },
      {
        name: 'Purple Heather',
        main: 'blue',
        hex: 'BAB8D3',
        code: '14-3911',
      },
      {
        name: 'Violet Ice',
        main: 'blue',
        hex: 'C2ACB1',
        code: '15-2706',
      },
      {
        name: 'Lavendula',
        main: 'blue',
        hex: 'BCA4CB',
        code: '15-3620',
      },
      {
        name: 'Lilac Gray',
        main: 'blue',
        hex: '9896A4',
        code: '16-3905',
      },
      {
        name: 'Thistle Down',
        main: 'blue',
        hex: '9499BB',
        code: '16-3930',
      },
      {
        name: 'Thistle',
        main: 'blue',
        hex: 'B9B3C5',
        code: '14-3907',
      },
      {
        name: 'Crocus Petal',
        main: 'blue',
        hex: 'B99BC5',
        code: '15-3520',
      },
      {
        name: 'Sand Verbena',
        main: 'blue',
        hex: '9F90C1',
        code: '16-3720',
      },
      {
        name: 'Wisteria',
        main: 'blue',
        hex: 'A198AF',
        code: '16-3810',
      },
      {
        name: 'Viola',
        main: 'blue',
        hex: 'A692BA',
        code: '16-3815',
      },
      {
        name: 'Lavender Aura',
        main: 'blue',
        hex: '9F99AA',
        code: '16-3911',
      },
      {
        name: 'Lavender Fog',
        main: 'blue',
        hex: 'D2C4D6',
        code: '13-3820',
      },
      {
        name: 'Orchid Petal',
        main: 'blue',
        hex: 'BFB4CB',
        code: '14-3710',
      },
      {
        name: 'Lavender Frost',
        main: 'blue',
        hex: 'BDABBE',
        code: '15-3507',
      },
      {
        name: 'Purple Rose',
        main: 'blue',
        hex: 'B09FCA',
        code: '15-3716',
      },
      {
        name: 'Misty Lilac',
        main: 'blue',
        hex: 'BCB4C4',
        code: '15-3807',
      },
      {
        name: 'Heirloom Lilac',
        main: 'blue',
        hex: '9D96B2',
        code: '16-3812',
      },
      {
        name: 'Baby Lavender',
        main: 'blue',
        hex: 'A2A9D1',
        code: '16-3923',
      },
      {
        name: 'Sweet Lavender',
        main: 'blue',
        hex: '9A9BC1',
        code: '16-3931',
      },
      {
        name: 'Fairy Wren',
        main: 'blue',
        hex: '957AB0',
        code: '17-3640',
      },
      {
        name: 'Lavender Gray',
        main: 'blue',
        hex: '9890A2',
        code: '17-3910',
      },
      {
        name: 'Scented Lavender',
        main: 'purple',
        hex: 'CCB5D6',
        code: '14-3512',
      },
      {
        name: "Queen's Wreath",
        main: 'purple',
        hex: 'ADACC3',
        code: '14-3813',
      },
      {
        name: 'Lavender Lily',
        main: 'purple',
        hex: 'B5A0B8',
        code: '15-3530',
      },
      {
        name: 'Lavender Oil',
        main: 'purple',
        hex: 'C3A6CD',
        code: '15-3615',
      },
      {
        name: 'Lavender Icing',
        main: 'purple',
        hex: '9999B5',
        code: '16-3928',
      },
      {
        name: 'English Lavender',
        main: 'blue',
        hex: '9D7BB0',
        code: '17-3617',
      },
      {
        name: 'Lilac Clematis',
        main: 'purple',
        hex: 'B3B0C9',
        code: '14-3915',
      },
      {
        name: 'Lavender Fields',
        main: 'purple',
        hex: 'ADAFD3',
        code: '14-3935',
      },
      {
        name: 'Deep Lavender',
        main: 'purple',
        hex: '775496',
        code: '18-3633',
      },
      {
        name: 'Amethyst Orchid',
        main: 'purple',
        hex: '926AA6',
        code: '17-3628',
      },
      {
        name: 'Royal Purple',
        main: 'purple',
        hex: '603F83',
        code: '19-3642',
      },
    ],
  },
  {
    _id: 'Lemon ',
    colors: [
      {
        name: 'Yellow Plum',
        main: 'yellow',
        hex: 'D5D859',
        code: '13-0643',
      },
      {
        name: 'Yellow Iris',
        main: 'yellow',
        hex: 'EEE78E',
        code: '11-0622',
      },
      {
        name: 'Aurora',
        main: 'yellow',
        hex: 'EDDD59',
        code: '12-0642',
      },
      {
        name: 'Yellow Jasper',
        main: 'yellow',
        hex: 'F7EF70',
        code: '12-0719',
      },
      {
        name: 'Limelight',
        main: 'yellow',
        hex: 'F0E87D',
        code: '12-0740',
      },
      {
        name: 'Lemon Verbena',
        main: 'yellow',
        hex: 'F4E87A',
        code: '12-0742',
      },
      {
        name: 'Golden Kiwi',
        main: 'yellow',
        hex: 'F4DD40',
        code: '13-0644',
      },
      {
        name: 'Summer Sun',
        main: 'yellow',
        hex: 'EFE237',
        code: '12-0650',
      },
      {
        name: 'Buzzing Bees',
        main: 'yellow',
        hex: 'F6F38E',
        code: '12-0743',
      },
      {
        name: 'Blazing Yellow',
        main: 'yellow',
        hex: 'FEE715',
        code: '12-0643',
      },
      {
        name: 'Celandine',
        main: 'yellow',
        hex: 'EBDF68',
        code: '12-0646',
      },
      {
        name: 'Yellowtail',
        main: 'yellow',
        hex: 'ECD966',
        code: '12-0735',
      },
      {
        name: 'Illuminating',
        main: 'yellow',
        hex: 'F5DF4D',
        code: '13-0647',
      },
      {
        name: 'Lemon',
        main: 'yellow',
        hex: 'F3BF09',
        code: '13-0752',
      },
      {
        name: 'Lemon Chrome',
        main: 'yellow',
        hex: 'FFC300',
        code: '13-0859',
      },
      {
        name: 'Slicker',
        main: 'yellow',
        hex: 'F1CD39',
        code: '13-0749',
      },
      {
        name: 'Sunstruck',
        main: 'yellow',
        hex: 'F6CD4C',
        code: '13-0754',
      },
      {
        name: 'Primrose Yellow',
        main: 'yellow',
        hex: 'F6D155',
        code: '13-0755',
      },
      {
        name: 'Lemon Zest',
        main: 'yellow',
        hex: 'F9D857',
        code: '13-0756',
      },
      {
        name: 'Dandelion',
        main: 'yellow',
        hex: 'FFD02E',
        code: '13-0758',
      },
      {
        name: 'High Visibility',
        main: 'yellow',
        hex: 'F1C700',
        code: '13-0751',
      },
    ],
  },
  {
    _id: 'Lime',
    colors: [
      {
        name: 'Daiquiri Green',
        main: 'green',
        hex: 'C9D77E',
        code: '12-0435',
      },
      {
        name: 'Pear Extract',
        main: 'green',
        hex: 'DFEB78',
        code: '12-0641',
      },
      {
        name: 'Butterfly',
        main: 'green',
        hex: 'CADEA5',
        code: '12-0322',
      },
      {
        name: 'Mellow Green',
        main: 'green',
        hex: 'D5D593',
        code: '12-0426',
      },
      {
        name: 'Asian Pear',
        main: 'green',
        hex: 'DBF097',
        code: '12-0430',
      },
      {
        name: 'Grossular Green',
        main: 'green',
        hex: 'DBE5AA',
        code: '12-0540',
      },
      {
        name: 'Citrus Essence',
        main: 'green',
        hex: 'D4EE7D',
        code: '12-0549',
      },
      {
        name: 'Minced Quince',
        main: 'green',
        hex: 'E0F17E',
        code: '12-0550',
      },
      {
        name: 'Snow Pear',
        main: 'green',
        hex: 'E3EEA3',
        code: '12-0745',
      },
      {
        name: 'Bright Lime Green',
        main: 'green',
        hex: '97BC62',
        code: '14-0244',
      },
      {
        name: 'Celery Green',
        main: 'green',
        hex: 'C5CC7B',
        code: '13-0532',
      },
      {
        name: 'Large Leafed Lime',
        main: 'green',
        hex: 'C4CB53',
        code: '14-0440',
      },
      {
        name: 'Green Flash',
        main: 'green',
        hex: '78C753',
        code: '15-0146',
      },
      {
        name: 'Lily Green',
        main: 'green',
        hex: 'C5CF98',
        code: '13-0317',
      },
      {
        name: 'Green Glow',
        main: 'green',
        hex: 'B0C965',
        code: '13-0442',
      },
      {
        name: 'Tender Shoots',
        main: 'green',
        hex: 'B5CC39',
        code: '14-0446',
      },
      {
        name: 'Lettuce Green',
        main: 'green',
        hex: 'BED38E',
        code: '13-0324',
      },
      {
        name: 'Jade Lime',
        main: 'green',
        hex: 'A1CA7B',
        code: '14-0232',
      },
      {
        name: 'Bright Chartreuse',
        main: 'green',
        hex: 'B5BF50',
        code: '14-0445',
      },
      {
        name: 'Jasmine Green',
        main: 'green',
        hex: '7FC844',
        code: '15-0545',
      },
      {
        name: 'Sharp Green',
        main: 'yellow',
        hex: 'C6E67A',
        code: '13-0535',
      },
      {
        name: 'Lime Punch',
        main: 'yellow',
        hex: 'C0D725',
        code: '13-0550',
      },
      {
        name: 'Limeade',
        main: 'yellow',
        hex: 'D3D95F',
        code: '13-0645',
      },
      {
        name: 'Shadow Lime',
        main: 'yellow',
        hex: 'CFE09D',
        code: '13-0319',
      },
      {
        name: 'White Jade',
        main: 'yellow',
        hex: 'D4DBB2',
        code: '12-0315',
      },
      {
        name: 'Pale Lime Yellow',
        main: 'yellow',
        hex: 'DFE69F',
        code: '12-0520',
      },
      {
        name: 'Young Wheat',
        main: 'yellow',
        hex: 'E1E3A9',
        code: '12-0521',
      },
      {
        name: 'Sunny Lime',
        main: 'yellow',
        hex: 'DFEF87',
        code: '12-0741',
      },
      {
        name: 'Lime Sherbet',
        main: 'yellow',
        hex: 'CDD78A',
        code: '13-0530',
      },
      {
        name: 'Luminary Green',
        main: 'yellow',
        hex: 'E3EAA5',
        code: '12-0525',
      },
      {
        name: 'Love Bird',
        main: 'yellow',
        hex: 'C1DB3C',
        code: '13-0443',
      },
      {
        name: 'Wild Lime',
        main: 'yellow',
        hex: 'C3D363',
        code: '13-0540',
      },
      {
        name: 'Acid Lime',
        main: 'yellow',
        hex: 'BBDF32',
        code: '14-0340',
      },
      {
        name: 'Kiwi Colada',
        main: 'yellow',
        hex: 'BCCA25',
        code: '14-0443',
      },
      {
        name: 'Lime Green',
        main: 'yellow',
        hex: '9FC131',
        code: '14-0452',
      },
    ],
  },
  {
    _id: 'Magenta',
    colors: [
      {
        name: 'Lilac Rose',
        main: 'pink',
        hex: 'BD4275',
        code: '17-2227',
      },
      {
        name: 'Raspberry',
        main: 'pink',
        hex: 'D32E5E',
        code: '18-1754',
      },
      {
        name: 'Raspberry Sorbet',
        main: 'pink',
        hex: 'D2386C',
        code: '18-2043',
      },
      {
        name: 'Cactus Flower',
        main: 'pink',
        hex: 'A83E6C',
        code: '18-2326',
      },
      {
        name: 'Granita',
        main: 'pink',
        hex: 'A52350',
        code: '19-2039',
      },
      {
        name: 'Sangria',
        main: 'pink',
        hex: '982551',
        code: '19-2047',
      },
      {
        name: 'Rethink Pink',
        main: 'pink',
        hex: 'C63C5F',
        code: '17-1739',
      },
      {
        name: 'Claret Red',
        main: 'pink',
        hex: 'C84B61',
        code: '17-1740',
      },
      {
        name: 'Party Punch',
        main: 'pink',
        hex: 'C54966',
        code: '17-1741',
      },
      {
        name: 'Azalea',
        main: 'pink',
        hex: 'D42E5B',
        code: '17-1842',
      },
      {
        name: 'Fandango Pink',
        main: 'pink',
        hex: 'E04F80',
        code: '17-2033',
      },
      {
        name: 'Rouge Red',
        main: 'pink',
        hex: 'E24666',
        code: '18-1755',
      },
      {
        name: 'Virtual Pink',
        main: 'pink',
        hex: 'C6174E',
        code: '18-1856',
      },
      {
        name: 'Jazzy',
        main: 'pink',
        hex: 'B61C50',
        code: '18-1950',
      },
      {
        name: 'Cabaret',
        main: 'pink',
        hex: 'CB3373',
        code: '18-2140',
      },
      {
        name: 'Cherries Jubilee',
        main: 'pink',
        hex: 'A22452',
        code: '19-2041',
      },
      {
        name: 'Holly Berry',
        main: 'pink',
        hex: 'B44E5D',
        code: '17-1633',
      },
      {
        name: 'Winterberry',
        main: 'pink',
        hex: 'BE394F',
        code: '17-1640',
      },
      {
        name: 'Raspberry Wine',
        main: 'pink',
        hex: 'B63753',
        code: '18-1741',
      },
      {
        name: 'Paradise Pink',
        main: 'pink',
        hex: 'E4455E',
        code: '17-1755',
      },
      {
        name: 'Carmine',
        main: 'pink',
        hex: 'BC4869',
        code: '17-1831',
      },
      {
        name: 'Pink Yarrow',
        main: 'pink',
        hex: 'CE3375',
        code: '17-2034',
      },
      {
        name: 'Magenta',
        main: 'pink',
        hex: 'D23C77',
        code: '17-2036',
      },
      {
        name: 'Love Potion',
        main: 'pink',
        hex: 'C01553',
        code: '18-1951',
      },
      {
        name: 'Innuendo',
        main: 'pink',
        hex: 'C43362',
        code: '18-2042',
      },
      {
        name: 'Pink Peacock',
        main: 'pink',
        hex: 'C62368',
        code: '18-2045',
      },
      {
        name: 'Honeysuckle',
        main: 'pink',
        hex: 'D94F70',
        code: '18-2120',
      },
      {
        name: 'Very Berry',
        main: 'pink',
        hex: 'B73275',
        code: '18-2336',
      },
      {
        name: 'Bright Rose',
        main: 'pink',
        hex: 'C51959',
        code: '18-1945',
      },
      {
        name: 'Cerise',
        main: 'pink',
        hex: 'A41247',
        code: '19-1955',
      },
      {
        name: 'Vivacious',
        main: 'pink',
        hex: 'A32857',
        code: '19-2045',
      },
      {
        name: 'Rose Red',
        main: 'red',
        hex: 'C82151',
        code: '18-1852',
      },
      {
        name: 'Persian Red',
        main: 'red',
        hex: 'A21441',
        code: '19-1860',
      },
    ],
  },
  {
    _id: 'Mahogany',
    colors: [
      {
        name: 'Dusty Cedar',
        main: 'pink',
        hex: 'AD5D5D',
        code: '18-1630',
      },
      {
        name: 'Chocolate Truffle',
        main: 'red',
        hex: '612E35',
        code: '19-1526',
      },
      {
        name: 'Windsor Wine',
        main: 'red',
        hex: '582B36',
        code: '19-1528',
      },
      {
        name: 'Huckleberry',
        main: 'red',
        hex: '5B4349',
        code: '19-1620',
      },
      {
        name: 'Catawba Grape',
        main: 'red',
        hex: '5D3C43',
        code: '19-1621',
      },
      {
        name: 'Vineyard Wine',
        main: 'red',
        hex: '58363D',
        code: '19-1623',
      },
      {
        name: 'Port Royale',
        main: 'red',
        hex: '512B33',
        code: '19-1627',
      },
      {
        name: 'Cordovan',
        main: 'red',
        hex: '702F3B',
        code: '19-1726',
      },
      {
        name: 'Pomegranate',
        main: 'red',
        hex: '6C2831',
        code: '19-1930',
      },
      {
        name: 'Winetasting',
        main: 'red',
        hex: '492A34',
        code: '19-2118',
      },
      {
        name: 'Marron',
        main: 'red',
        hex: '6E4C4B',
        code: '18-1415',
      },
      {
        name: 'Nocturne',
        main: 'red',
        hex: '7B4B56',
        code: '18-1614',
      },
      {
        name: 'Roan Rouge',
        main: 'red',
        hex: '885157',
        code: '18-1616',
      },
      {
        name: 'Tulipwood',
        main: 'red',
        hex: '805466',
        code: '18-1709',
      },
      {
        name: 'Andorra',
        main: 'red',
        hex: '603535',
        code: '19-1327',
      },
      {
        name: 'Henna',
        main: 'red',
        hex: '7C423C',
        code: '19-1334',
      },
      {
        name: 'New Maroon',
        main: 'red',
        hex: '6B3339',
        code: '19-1527',
      },
      {
        name: 'Maroon',
        main: 'red',
        hex: '844655',
        code: '18-1619',
      },
      {
        name: 'Dry Rose',
        main: 'red',
        hex: '8C4759',
        code: '18-1725',
      },
      {
        name: 'Zinfandel',
        main: 'red',
        hex: '5D2935',
        code: '19-1522',
      },
      {
        name: 'Oxblood Red',
        main: 'red',
        hex: '70393F',
        code: '19-1524',
      },
      {
        name: 'Port',
        main: 'red',
        hex: '663336',
        code: '19-1525',
      },
      {
        name: 'Cowhide',
        main: 'red',
        hex: '884344',
        code: '19-1533',
      },
      {
        name: 'Burgundy',
        main: 'red',
        hex: '64313E',
        code: '19-1617',
      },
      {
        name: 'Mauve Wine',
        main: 'red',
        hex: '5B3644',
        code: '19-1716',
      },
      {
        name: 'Fig',
        main: 'red',
        hex: '532E3B',
        code: '19-1718',
      },
      {
        name: 'Wild Ginger',
        main: 'red',
        hex: '7C4C53',
        code: '18-1420',
      },
      {
        name: 'Renaissance Rose',
        main: 'red',
        hex: '865560',
        code: '18-1613',
      },
      {
        name: 'Cherry Mahogany',
        main: 'red',
        hex: '66362C',
        code: '19-1435',
      },
      {
        name: 'Red Mahogany',
        main: 'red',
        hex: '60373D',
        code: '19-1521',
      },
      {
        name: 'Maroon Banner',
        main: 'red',
        hex: '5B2B38',
        code: '19-1529',
      },
      {
        name: 'Sassafras',
        main: 'red',
        hex: '54353B',
        code: '19-1624',
      },
      {
        name: 'Cabernet',
        main: 'red',
        hex: '64242E',
        code: '19-1724',
      },
      {
        name: 'Tawny Port',
        main: 'red',
        hex: '5C2C35',
        code: '19-1725',
      },
      {
        name: 'Prune',
        main: 'red',
        hex: '603749',
        code: '19-2014',
      },
    ],
  },
  {
    _id: 'Matcha',
    colors: [
      {
        name: 'Pale Aqua',
        main: 'gray',
        hex: 'C1CCC2',
        code: '13-5305',
      },
      {
        name: 'Mercury',
        main: 'gray',
        hex: 'BAC2BA',
        code: '14-4502',
      },
      {
        name: 'Aqua Gray',
        main: 'gray',
        hex: 'A5B2AA',
        code: '15-5205',
      },
      {
        name: 'Mineral Gray',
        main: 'gray',
        hex: 'B2B6AC',
        code: '15-5704',
      },
      {
        name: 'Seafoam Green',
        main: 'green',
        hex: 'CBD5B1',
        code: '12-0313',
      },
      {
        name: 'Gleam',
        main: 'green',
        hex: 'BFD1AD',
        code: '12-0317',
      },
      {
        name: 'Dewkist',
        main: 'green',
        hex: 'C4D1C2',
        code: '13-0107',
      },
      {
        name: 'Sprout Green',
        main: 'green',
        hex: 'CBD7D2',
        code: '12-5303',
      },
      {
        name: 'Seacrest',
        main: 'green',
        hex: 'BFD1B3',
        code: '13-0111',
      },
      {
        name: 'Lint',
        main: 'green',
        hex: 'B6BA99',
        code: '14-0216',
      },
      {
        name: 'Seedling',
        main: 'green',
        hex: 'C0CBA1',
        code: '14-0217',
      },
      {
        name: 'Cameo Green',
        main: 'green',
        hex: 'AAC0AD',
        code: '14-6312',
      },
      {
        name: 'Green Lily',
        main: 'green',
        hex: 'C1CEC1',
        code: '13-6107',
      },
      {
        name: 'Celadon Green',
        main: 'green',
        hex: 'B5C2A5',
        code: '14-0114',
      },
      {
        name: 'Foam Green',
        main: 'green',
        hex: 'B4C79C',
        code: '14-0115',
      },
      {
        name: 'Sea Foam',
        main: 'green',
        hex: 'B7C2B2',
        code: '14-6007',
      },
      {
        name: 'Spray',
        main: 'green',
        hex: 'BED3BB',
        code: '13-6007',
      },
      {
        name: 'Bok Choy',
        main: 'green',
        hex: 'BCCAB3',
        code: '13-6208',
      },
      {
        name: 'Tender Greens',
        main: 'green',
        hex: 'C5CFB6',
        code: '13-0212',
      },
      {
        name: 'Silt Green',
        main: 'green',
        hex: 'A9BDB1',
        code: '14-5706',
      },
      {
        name: 'Swamp',
        main: 'green',
        hex: 'A8B197',
        code: '15-6310',
      },
      {
        name: 'Frosty Green',
        main: 'green',
        hex: 'A3B5A6',
        code: '15-5706',
      },
      {
        name: 'Smoke Green',
        main: 'green',
        hex: 'A8BBA2',
        code: '15-6315',
      },
      {
        name: 'Fair Green',
        main: 'green',
        hex: '92AF88',
        code: '15-6316',
      },
      {
        name: 'Quiet Green',
        main: 'green',
        hex: '9EBC97',
        code: '15-6317',
      },
      {
        name: 'Desert Sage',
        main: 'green',
        hex: 'A7AE9E',
        code: '16-0110',
      },
      {
        name: 'Green Milieu',
        main: 'green',
        hex: '8A9992',
        code: '16-5806',
      },
      {
        name: 'Reseda',
        main: 'green',
        hex: 'A1AD92',
        code: '15-6414',
      },
      {
        name: 'Laurel Green',
        main: 'green',
        hex: 'ADBBA1',
        code: '15-6313',
      },
      {
        name: 'Jadeite',
        main: 'green',
        hex: '95A69F',
        code: '16-5304',
      },
      {
        name: 'Almost Aqua',
        main: 'aqua',
        hex: 'CAD3C1',
        code: '13-6006',
      },
      {
        name: 'Aqua Foam',
        main: 'aqua',
        hex: 'ADC3B4',
        code: '14-5707',
      },
    ],
  },
  {
    _id: 'Midnight Blue ',
    colors: [
      {
        name: 'Midnight',
        main: 'blue',
        hex: '325B74',
        code: '19-4127',
      },
      {
        name: 'Spring Lake',
        main: 'blue',
        hex: '667E91',
        code: '18-4221',
      },
      {
        name: 'Goblin Blue',
        main: 'blue',
        hex: '5F7278',
        code: '18-4011',
      },
      {
        name: 'Spellbound',
        main: 'black',
        hex: '333B4C',
        code: '19-4017',
      },
      {
        name: 'Baritone Blue',
        main: 'black',
        hex: '272836',
        code: '19-3812',
      },
      {
        name: 'China Blue',
        main: 'blue',
        hex: '546477',
        code: '18-3918',
      },
      {
        name: 'Bluefin',
        main: 'blue',
        hex: '536679',
        code: '18-3919',
      },
      {
        name: 'Oceanview',
        main: 'blue',
        hex: '4F677A',
        code: '18-3923',
      },
      {
        name: 'Midnight Sail',
        main: 'blue',
        hex: '323455',
        code: '19-3851',
      },
      {
        name: 'Nightshadow Blue',
        main: 'blue',
        hex: '4E5368',
        code: '19-3919',
      },
      {
        name: 'Patriot Blue',
        main: 'blue',
        hex: '363756',
        code: '19-3925',
      },
      {
        name: 'Medieval Blue',
        main: 'blue',
        hex: '2A304E',
        code: '19-3933',
      },
      {
        name: 'Ocean Cavern',
        main: 'blue',
        hex: '252A48',
        code: '19-3937',
      },
      {
        name: 'Eclipse',
        main: 'blue',
        hex: '353148',
        code: '19-3810',
      },
      {
        name: 'Maritime Blue',
        main: 'blue',
        hex: '27293D',
        code: '19-3831',
      },
      {
        name: 'Rhodonite',
        main: 'blue',
        hex: '302C4D',
        code: '19-3838',
      },
      {
        name: 'Blue Ribbon',
        main: 'blue',
        hex: '3A395F',
        code: '19-3839',
      },
      {
        name: 'Peacoat',
        main: 'blue',
        hex: '2B2E43',
        code: '19-3920',
      },
      {
        name: 'Navy Blazer',
        main: 'blue',
        hex: '282D3C',
        code: '19-3923',
      },
      {
        name: 'Oceana',
        main: 'blue',
        hex: '3C4862',
        code: '19-3934',
      },
      {
        name: 'Wild Wind',
        main: 'blue',
        hex: '657088',
        code: '17-3912',
      },
      {
        name: 'Coronet Blue',
        main: 'blue',
        hex: '59728E',
        code: '18-3922',
      },
      {
        name: 'Real Teal',
        main: 'blue',
        hex: '405D73',
        code: '18-4018',
      },
      {
        name: "Captain's Blue",
        main: 'blue',
        hex: '557088',
        code: '18-4020',
      },
      {
        name: 'Bering Sea',
        main: 'blue',
        hex: '4B5B6E',
        code: '18-4028',
      },
      {
        name: 'Provincial Blue',
        main: 'blue',
        hex: '5C798E',
        code: '18-4220',
      },
      {
        name: 'Tapestry',
        main: 'blue',
        hex: '436573',
        code: '18-4417',
      },
      {
        name: 'Inkling',
        main: 'blue',
        hex: '2F2C3F',
        code: '19-3811',
      },
      {
        name: 'Black Iris',
        main: 'blue',
        hex: '2B3042',
        code: '19-3921',
      },
      {
        name: 'Crown Blue',
        main: 'blue',
        hex: '464B65',
        code: '19-3926',
      },
      {
        name: 'Vintage Indigo',
        main: 'blue',
        hex: '4A556B',
        code: '19-3929',
      },
      {
        name: 'Copen Blue',
        main: 'blue',
        hex: '516B84',
        code: '18-4025',
      },
      {
        name: 'Blue Mirage',
        main: 'blue',
        hex: '5C6D7C',
        code: '18-4215',
      },
      {
        name: 'Bluestone',
        main: 'blue',
        hex: '587284',
        code: '18-4217',
      },
      {
        name: 'Blue Fusion',
        main: 'blue',
        hex: '496275',
        code: '18-4218',
      },
      {
        name: 'Aegean Blue',
        main: 'blue',
        hex: '4E6E81',
        code: '18-4320',
      },
      {
        name: 'Evening Blue',
        main: 'blue',
        hex: '2A293E',
        code: '19-3815',
      },
      {
        name: 'Astral Aura',
        main: 'blue',
        hex: '373151',
        code: '19-3830',
      },
      {
        name: 'Blue Indigo',
        main: 'blue',
        hex: '49516D',
        code: '19-3928',
      },
      {
        name: 'Naval Academy',
        main: 'blue',
        hex: '2E344A',
        code: '19-3932',
      },
      {
        name: 'Seaborne',
        main: 'blue',
        hex: '2E2E3B',
        code: '19-4018',
      },
      {
        name: 'Sailor Blue',
        main: 'blue',
        hex: '0F3B53',
        code: '19-4034',
      },
      {
        name: 'Stratified Sea',
        main: 'blue',
        hex: '313F4A',
        code: '19-4112',
      },
      {
        name: 'Majolica Blue',
        main: 'blue',
        hex: '284357',
        code: '19-4125',
      },
      {
        name: 'Titan',
        main: 'blue',
        hex: '263B51',
        code: '19-4128',
      },
      {
        name: 'Orion Blue',
        main: 'blue',
        hex: '3E4F5C',
        code: '19-4229',
      },
      {
        name: 'Legion Blue',
        main: 'blue',
        hex: '1F495B',
        code: '19-4324',
      },
      {
        name: 'Seaport',
        main: 'blue',
        hex: '005E7D',
        code: '19-4342',
      },
      {
        name: 'Dark Sea',
        main: 'blue',
        hex: '1D3F43',
        code: '19-5025',
      },
      {
        name: 'Bellwether Blue',
        main: 'blue',
        hex: '23305B',
        code: '19-3943',
      },
      {
        name: 'Dress Blues',
        main: 'blue',
        hex: '2A3244',
        code: '19-4024',
      },
      {
        name: 'Midnight Navy',
        main: 'blue',
        hex: '34414E',
        code: '19-4110',
      },
      {
        name: 'Dark Denim',
        main: 'blue',
        hex: '36465E',
        code: '19-4118',
      },
      {
        name: 'Blue Wing Teal',
        main: 'blue',
        hex: '2C4053',
        code: '19-4121',
      },
      {
        name: 'Moonlit Ocean',
        main: 'blue',
        hex: '2A3B4E',
        code: '19-4122',
      },
      {
        name: 'Key Largo',
        main: 'blue',
        hex: '34465A',
        code: '19-4129',
      },
      {
        name: 'Indian Teal',
        main: 'blue',
        hex: '3C586B',
        code: '19-4227',
      },
      {
        name: 'Moroccan Blue',
        main: 'blue',
        hex: '104E67',
        code: '19-4241',
      },
      {
        name: 'Lyons Blue',
        main: 'blue',
        hex: '015871',
        code: '19-4340',
      },
      {
        name: 'Blue Depths',
        main: 'blue',
        hex: '263056',
        code: '19-3940',
      },
      {
        name: 'Beacon Blue',
        main: 'blue',
        hex: '252A50',
        code: '19-3942',
      },
      {
        name: 'Overture',
        main: 'blue',
        hex: '343646',
        code: '19-3944',
      },
      {
        name: 'Dark Navy',
        main: 'blue',
        hex: '2E2F36',
        code: '19-4013',
      },
      {
        name: 'Inkwell',
        main: 'blue',
        hex: '363945',
        code: '19-4016',
      },
      {
        name: 'Mood Indigo',
        main: 'blue',
        hex: '353A4C',
        code: '19-4025',
      },
      {
        name: 'Insignia Blue',
        main: 'blue',
        hex: '303E55',
        code: '19-4028',
      },
      {
        name: 'Poseidon',
        main: 'blue',
        hex: '133955',
        code: '19-4033',
      },
      {
        name: 'Collegiate Blue',
        main: 'blue',
        hex: '323841',
        code: '19-4051',
      },
      {
        name: 'Polar Night',
        main: 'blue',
        hex: '343540',
        code: '19-4105',
      },
      {
        name: 'Blue Opal',
        main: 'blue',
        hex: '103B58',
        code: '19-4120',
      },
      {
        name: 'Deep Dive',
        main: 'blue',
        hex: '29495C',
        code: '19-4126',
      },
      {
        name: 'Stargazer',
        main: 'blue',
        hex: '39505C',
        code: '19-4316',
      },
      {
        name: 'Reflecting Pond',
        main: 'blue',
        hex: '203E4A',
        code: '19-4326',
      },
      {
        name: 'Outer Space',
        main: 'blue',
        hex: '2F3441',
        code: '19-4009',
      },
      {
        name: 'Estate Blue',
        main: 'blue',
        hex: '233658',
        code: '19-4027',
      },
      {
        name: 'Sargasso Sea',
        main: 'blue',
        hex: '36445A',
        code: '19-4031',
      },
      {
        name: 'Gibraltar Sea',
        main: 'blue',
        hex: '133951',
        code: '19-4038',
      },
      {
        name: 'Sea Storm',
        main: 'blue',
        hex: '323C46',
        code: '19-4108',
      },
      {
        name: 'Pageant Blue',
        main: 'blue',
        hex: '1F2C43',
        code: '19-4111',
      },
      {
        name: 'Big Dipper',
        main: 'blue',
        hex: '354253',
        code: '19-4117',
      },
      {
        name: 'Ink Blue',
        main: 'blue',
        hex: '0B5369',
        code: '19-4234',
      },
      {
        name: 'Mallard Blue',
        main: 'blue',
        hex: '3A5C6E',
        code: '19-4318',
      },
      {
        name: 'Corsair',
        main: 'blue',
        hex: '19576C',
        code: '19-4329',
      },
      {
        name: 'Blue Coral',
        main: 'blue',
        hex: '1C5366',
        code: '19-4526',
      },
      {
        name: 'Ocean Depths',
        main: 'blue',
        hex: '006175',
        code: '19-4535',
      },
      {
        name: 'Crystal Teal',
        main: 'blue',
        hex: '00637C',
        code: '19-4536',
      },
      {
        name: 'Deep Lagoon',
        main: 'blue',
        hex: '005366',
        code: '19-4540',
      },
      {
        name: 'Dragonfly',
        main: 'blue',
        hex: '2A5C6A',
        code: '19-4826',
      },
    ],
  },
  {
    _id: 'Mint ',
    colors: [
      {
        name: 'Glacier',
        main: 'blue',
        hex: 'C3DBD4',
        code: '12-5505',
      },
      {
        name: 'Hushed Green',
        main: 'white',
        hex: 'D8E9E5',
        code: '12-5508',
      },
      {
        name: 'Hint of Mint',
        main: 'white',
        hex: 'D8EBE6',
        code: '11-4805',
      },
      {
        name: 'Ambrosia',
        main: 'green',
        hex: 'D2E7CA',
        code: '12-0109',
      },
      {
        name: 'Scenic Green',
        main: 'green',
        hex: 'C2E4BA',
        code: '12-0230',
      },
      {
        name: 'Morning Mist',
        main: 'green',
        hex: 'CFDFDB',
        code: '12-5204',
      },
      {
        name: 'Veiled Vista',
        main: 'green',
        hex: 'C8E4CA',
        code: '12-6000',
      },
      {
        name: 'Clean Green',
        main: 'green',
        hex: '9DDEC4',
        code: '13-5615',
      },
      {
        name: 'Frosted Lime',
        main: 'green',
        hex: 'B2DBAE',
        code: '13-6226',
      },
      {
        name: 'Tender Stem',
        main: 'blue',
        hex: 'BCDBC2',
        code: '12-0107',
      },
      {
        name: 'Dewdrops',
        main: 'aqua',
        hex: 'A1DFD1',
        code: '13-5612',
      },
      {
        name: 'Brook Green',
        main: 'aqua',
        hex: 'AFDDCC',
        code: '13-6009',
      },
      {
        name: 'Dusty Aqua',
        main: 'aqua',
        hex: 'C0DCCD',
        code: '12-5506',
      },
      {
        name: 'Opal Blue',
        main: 'aqua',
        hex: 'C3DDD6',
        code: '12-5406',
      },
      {
        name: 'Clearly Aqua',
        main: 'aqua',
        hex: 'CEE1D4',
        code: '12-5504',
      },
      {
        name: 'Patina Green',
        main: 'yellow',
        hex: 'B9EAB3',
        code: '12-0225',
      },
      {
        name: 'Green Ash',
        main: 'yellow',
        hex: 'A0DAA9',
        code: '13-0117',
      },
      {
        name: 'Paradise Green',
        main: 'yellow',
        hex: 'B2E79F',
        code: '13-0220',
      },
    ],
  },
  {
    _id: 'Mushroom',
    colors: [
      {
        name: 'Birch',
        main: 'brown',
        hex: 'DDD5C7',
        code: '13-0905',
      },
      {
        name: 'White Swan',
        main: 'brown',
        hex: 'E4D7C5',
        code: '12-0000',
      },
      {
        name: 'Brown Rice',
        main: 'brown',
        hex: 'C8BCA5',
        code: '13-1105',
      },
      {
        name: 'Inscrutable',
        main: 'brown',
        hex: 'B5ACA7',
        code: '14-0702',
      },
      {
        name: 'Smoke Gray',
        main: 'brown',
        hex: 'CEBBA8',
        code: '14-1209',
      },
      {
        name: 'Sandshell',
        main: 'brown',
        hex: 'D8CCBB',
        code: '13-0907',
      },
      {
        name: 'Whisper Pink',
        main: 'brown',
        hex: 'DBCBBE',
        code: '13-1107',
      },
      {
        name: 'Mushroom',
        main: 'brown',
        hex: 'BDACA3',
        code: '14-1305',
      },
      {
        name: 'Island Fossil',
        main: 'brown',
        hex: 'B4AA9C',
        code: '16-1102',
      },
      {
        name: 'Plaza Taupe',
        main: 'brown',
        hex: 'AEA393',
        code: '16-1105',
      },
      {
        name: 'Doeskin',
        main: 'brown',
        hex: 'BDAB9B',
        code: '15-1308',
      },
      {
        name: 'Moonlight',
        main: 'brown',
        hex: 'C5B1A0',
        code: '15-1309',
      },
      {
        name: 'Cobblestone',
        main: 'brown',
        hex: 'A89A8E',
        code: '16-1407',
      },
      {
        name: 'Silver Gray',
        main: 'gray',
        hex: 'C1B7B0',
        code: '14-0000',
      },
      {
        name: 'Silver Lining',
        main: 'gray',
        hex: 'BDB6AB',
        code: '14-4501',
      },
      {
        name: 'Chateau Gray',
        main: 'gray',
        hex: 'BBB1A8',
        code: '15-4503',
      },
      {
        name: 'Nacreous Clouds',
        main: 'gray',
        hex: 'B9B1AB',
        code: '15-4504',
      },
      {
        name: 'Pure Cashmere',
        main: 'gray',
        hex: 'AEA396',
        code: '16-1103',
      },
      {
        name: 'Atmosphere',
        main: 'gray',
        hex: 'A89C94',
        code: '16-1406',
      },
    ],
  },
  {
    _id: 'Mustard',
    colors: [
      {
        name: 'Dried Moss',
        main: 'brown',
        hex: 'CCB97E',
        code: '14-0626',
      },
      {
        name: 'Hemp',
        main: 'brown',
        hex: 'C0AD7C',
        code: '14-0721',
      },
      {
        name: 'Cocoon',
        main: 'brown',
        hex: 'C9B27C',
        code: '14-1025',
      },
      {
        name: 'Fall Leaf',
        main: 'brown',
        hex: 'C9A86A',
        code: '15-1132',
      },
      {
        name: 'Southern Moss',
        main: 'brown',
        hex: 'BCA66A',
        code: '15-0730',
      },
      {
        name: 'Bamboo',
        main: 'orange',
        hex: 'D2B04C',
        code: '14-0740',
      },
      {
        name: 'Rattan',
        main: 'orange',
        hex: 'D1B272',
        code: '14-1031',
      },
      {
        name: 'Antique Gold',
        main: 'green',
        hex: 'B59E5F',
        code: '16-0730',
      },
      {
        name: 'Sundress',
        main: 'yellow',
        hex: 'EBCF89',
        code: '12-0729',
      },
      {
        name: 'Acacia',
        main: 'yellow',
        hex: 'DACD65',
        code: '13-0640',
      },
      {
        name: 'Dusty Yellow',
        main: 'yellow',
        hex: 'D4CC9A',
        code: '12-0619',
      },
      {
        name: 'Corn Kernels',
        main: 'yellow',
        hex: 'E3DA89',
        code: '12-0723',
      },
      {
        name: 'Lima Bean',
        main: 'yellow',
        hex: 'E1D590',
        code: '13-0333',
      },
      {
        name: 'Golden Mist',
        main: 'yellow',
        hex: 'D5CD94',
        code: '13-0624',
      },
      {
        name: 'Endive',
        main: 'yellow',
        hex: 'D2CC81',
        code: '13-0632',
      },
      {
        name: 'Celery',
        main: 'yellow',
        hex: 'CEC153',
        code: '14-0647',
      },
      {
        name: 'Canary Yellow',
        main: 'yellow',
        hex: 'DFD87E',
        code: '12-0633',
      },
      {
        name: 'Muted Lime',
        main: 'yellow',
        hex: 'D1C87C',
        code: '14-0636',
      },
      {
        name: 'Lemon Grass',
        main: 'yellow',
        hex: 'DCD494',
        code: '12-0626',
      },
      {
        name: 'Raffia',
        main: 'yellow',
        hex: 'DAC483',
        code: '13-0725',
      },
      {
        name: 'Italian Straw',
        main: 'yellow',
        hex: 'E7D1A1',
        code: '13-0917',
      },
      {
        name: 'Straw',
        main: 'yellow',
        hex: 'E0C992',
        code: '13-0922',
      },
      {
        name: 'Pampas',
        main: 'yellow',
        hex: 'CFBB7B',
        code: '14-0826',
      },
      {
        name: 'Custard',
        main: 'yellow',
        hex: 'E5D68E',
        code: '13-0720',
      },
      {
        name: 'Reed Yellow',
        main: 'yellow',
        hex: 'DCC99E',
        code: '13-0915',
      },
      {
        name: 'Misted Yellow',
        main: 'yellow',
        hex: 'DAB965',
        code: '14-0837',
      },
      {
        name: 'Ochre',
        main: 'yellow',
        hex: 'D6AF66',
        code: '14-1036',
      },
      {
        name: 'Oil Yellow',
        main: 'yellow',
        hex: 'C4A647',
        code: '15-0743',
      },
      {
        name: 'Sauterne',
        main: 'yellow',
        hex: 'C5A253',
        code: '15-0942',
      },
      {
        name: 'Cream Gold',
        main: 'yellow',
        hex: 'DFC160',
        code: '13-0739',
      },
      {
        name: 'Dusky Citron',
        main: 'yellow',
        hex: 'E3CC81',
        code: '14-0827',
      },
      {
        name: 'Jojoba',
        main: 'yellow',
        hex: 'DABE81',
        code: '14-0935',
      },
      {
        name: 'Rich Gold',
        main: 'yellow',
        hex: 'C8B273',
        code: '16-0836',
      },
    ],
  },
  {
    _id: 'Navy',
    colors: [
      {
        name: 'Ashleigh Blue',
        main: 'blue',
        hex: '6B86AA',
        code: '16-4018',
      },
      {
        name: 'English Manor',
        main: 'blue',
        hex: '7181A4',
        code: '17-3920',
      },
      {
        name: 'Adriatic Blue',
        main: 'blue',
        hex: '5C899B',
        code: '17-4320',
      },
      {
        name: 'Blue Moon',
        main: 'blue',
        hex: '3686A0',
        code: '17-4328',
      },
      {
        name: 'Caneel Bay',
        main: 'blue',
        hex: '00849F',
        code: '17-4730',
      },
      {
        name: 'Deep Water',
        main: 'blue',
        hex: '266691',
        code: '18-4032',
      },
      {
        name: 'Sea of Belize',
        main: 'blue',
        hex: '296C97',
        code: '18-4037',
      },
      {
        name: 'Bluesteel',
        main: 'blue',
        hex: '36637C',
        code: '18-4222',
      },
      {
        name: 'Faience',
        main: 'blue',
        hex: '2A6A8B',
        code: '18-4232',
      },
      {
        name: 'Mykonos Blue',
        main: 'blue',
        hex: '005780',
        code: '18-4434',
      },
      {
        name: 'Enamel Blue',
        main: 'blue',
        hex: '007A8E',
        code: '18-4733',
      },
      {
        name: 'Clematis Blue',
        main: 'blue',
        hex: '373B7C',
        code: '19-3951',
      },
      {
        name: 'Lapis Blue',
        main: 'blue',
        hex: '004C8E',
        code: '19-4045',
      },
      {
        name: 'Imperial Blue',
        main: 'blue',
        hex: '005A92',
        code: '19-4245',
      },
      {
        name: 'Colony Blue',
        main: 'blue',
        hex: '65769A',
        code: '17-3923',
      },
      {
        name: 'Quiet Harbor',
        main: 'blue',
        hex: '5B789A',
        code: '17-4029',
      },
      {
        name: 'Niagara',
        main: 'blue',
        hex: '5587A4',
        code: '17-4123',
      },
      {
        name: 'Barrier Reef',
        main: 'blue',
        hex: '0084A1',
        code: '17-4530',
      },
      {
        name: 'Algiers Blue',
        main: 'blue',
        hex: '00859C',
        code: '17-4728',
      },
      {
        name: 'Bleached Denim',
        main: 'blue',
        hex: '646F9B',
        code: '18-3930',
      },
      {
        name: 'Star Sapphire',
        main: 'blue',
        hex: '386192',
        code: '18-4041',
      },
      {
        name: 'Blue Sapphire',
        main: 'blue',
        hex: '0D597D',
        code: '18-4231',
      },
      {
        name: 'Blue Quartz',
        main: 'blue',
        hex: '274374',
        code: '19-3964',
      },
      {
        name: 'Set Sail',
        main: 'blue',
        hex: '284972',
        code: '19-4042',
      },
      {
        name: 'Baleine Blue',
        main: 'blue',
        hex: '165288',
        code: '19-4048',
      },
      {
        name: 'Snorkel Blue',
        main: 'blue',
        hex: '034F84',
        code: '19-4049',
      },
      {
        name: 'Blue Iolite',
        main: 'blue',
        hex: '1A4C8B',
        code: '19-4056',
      },
      {
        name: 'Blue Ice',
        main: 'blue',
        hex: '70789B',
        code: '17-3922',
      },
      {
        name: 'High Tide',
        main: 'blue',
        hex: '345E8B',
        code: '18-4022',
      },
      {
        name: 'Parisian Blue',
        main: 'blue',
        hex: '4F7CA4',
        code: '18-4036',
      },
      {
        name: 'Saxony Blue',
        main: 'blue',
        hex: '1F6680',
        code: '18-4225',
      },
      {
        name: 'Deep Ultramarine',
        main: 'blue',
        hex: '384883',
        code: '19-3950',
      },
      {
        name: 'Sodalite Blue',
        main: 'blue',
        hex: '253668',
        code: '19-3953',
      },
      {
        name: 'Déja Vu Blue',
        main: 'blue',
        hex: '2E5283',
        code: '19-4041',
      },
      {
        name: 'Classic Blue',
        main: 'blue',
        hex: '0F4C81',
        code: '19-4052',
      },
      {
        name: 'Turkish Sea',
        main: 'blue',
        hex: '1A5190',
        code: '19-4053',
      },
      {
        name: 'Galaxy Blue',
        main: 'blue',
        hex: '2A4C7D',
        code: '19-4055',
      },
      {
        name: 'True Blue',
        main: 'blue',
        hex: '1F4477',
        code: '19-4057',
      },
      {
        name: 'Beaucoup Blue',
        main: 'blue',
        hex: '32508E',
        code: '19-4058',
      },
      {
        name: 'Infinity',
        main: 'blue',
        hex: '6E7E99',
        code: '17-4015',
      },
      {
        name: 'Blue Heaven',
        main: 'blue',
        hex: '5B7E98',
        code: '17-4023',
      },
      {
        name: 'Riviera',
        main: 'blue',
        hex: '5979A2',
        code: '17-4027',
      },
      {
        name: 'Bluejay',
        main: 'blue',
        hex: '167EA0',
        code: '17-4427',
      },
      {
        name: 'Pagoda Blue',
        main: 'blue',
        hex: '1B7F8E',
        code: '17-4724',
      },
      {
        name: 'Dutch Blue',
        main: 'blue',
        hex: '4A638D',
        code: '18-3928',
      },
      {
        name: 'Vallarta Blue',
        main: 'blue',
        hex: '30658E',
        code: '18-4034',
      },
      {
        name: 'Fjord Blue',
        main: 'blue',
        hex: '007291',
        code: '18-4430',
      },
      {
        name: 'Caribbean Sea',
        main: 'blue',
        hex: '00819D',
        code: '18-4525',
      },
      {
        name: 'Mosaic Blue',
        main: 'blue',
        hex: '00758F',
        code: '18-4528',
      },
      {
        name: 'Mazarine Blue',
        main: 'blue',
        hex: '273C76',
        code: '19-3864',
      },
      {
        name: 'Surf the Web',
        main: 'blue',
        hex: '203C7F',
        code: '19-3952',
      },
      {
        name: 'Bluing',
        main: 'blue',
        hex: '333E83',
        code: '19-3954',
      },
      {
        name: 'Dark Blue',
        main: 'blue',
        hex: '305679',
        code: '19-4035',
      },
      {
        name: 'Limoges',
        main: 'blue',
        hex: '243F6C',
        code: '19-4044',
      },
      {
        name: 'Velvet Morning',
        main: 'blue',
        hex: '60688D',
        code: '18-3927',
      },
      {
        name: 'Blue Ashes',
        main: 'blue',
        hex: '3B5F78',
        code: '18-4023',
      },
      {
        name: 'Blue Shadow',
        main: 'blue',
        hex: '67829A',
        code: '17-4020',
      },
      {
        name: 'Gray Blue',
        main: 'blue',
        hex: '4E597B',
        code: '18-3917',
      },
      {
        name: 'Blue Horizon',
        main: 'blue',
        hex: '4E6582',
        code: '18-3929',
      },
      {
        name: 'Stellar',
        main: 'blue',
        hex: '46647E',
        code: '18-4026',
      },
      {
        name: 'Celestial',
        main: 'blue',
        hex: '006380',
        code: '18-4530',
      },
      {
        name: 'Navy Blue',
        main: 'blue',
        hex: '403F6F',
        code: '19-3832',
      },
      {
        name: 'Riverside',
        main: 'blue',
        hex: '4C6A93',
        code: '17-4028',
      },
      {
        name: 'Bijou Blue',
        main: 'blue',
        hex: '4E5E7F',
        code: '18-3921',
      },
      {
        name: 'Marlin',
        main: 'blue',
        hex: '515B87',
        code: '18-3932',
      },
      {
        name: 'Spectrum Blue',
        main: 'blue',
        hex: '3D3C7C',
        code: '18-3963',
      },
      {
        name: 'Moonlight Blue',
        main: 'blue',
        hex: '516886',
        code: '18-4027',
      },
      {
        name: 'Federal Blue',
        main: 'blue',
        hex: '43628B',
        code: '18-4029',
      },
      {
        name: 'Deep Cobalt',
        main: 'blue',
        hex: '404466',
        code: '19-3935',
      },
      {
        name: 'Coastal Fjord',
        main: 'blue',
        hex: '505D7E',
        code: '18-3920',
      },
      {
        name: 'Skipper Blue',
        main: 'blue',
        hex: '484A72',
        code: '19-3936',
      },
      {
        name: 'Blueprint',
        main: 'blue',
        hex: '2D3359',
        code: '19-3939',
      },
      {
        name: 'True Navy',
        main: 'blue',
        hex: '3F5277',
        code: '19-4030',
      },
      {
        name: 'Bright Cobalt',
        main: 'blue',
        hex: '395D8D',
        code: '19-4037',
      },
      {
        name: 'Twilight Blue',
        main: 'blue',
        hex: '313D64',
        code: '19-3938',
      },
      {
        name: 'Delft',
        main: 'blue',
        hex: '3D5E8C',
        code: '19-4039',
      },
      {
        name: 'Ensign Blue',
        main: 'blue',
        hex: '384C67',
        code: '19-4026',
      },
      {
        name: 'Navy Peony',
        main: 'blue',
        hex: '223A5E',
        code: '19-4029',
      },
      {
        name: 'Nouvean Navy',
        main: 'blue',
        hex: '3A5589',
        code: '19-4054',
      },
      {
        name: 'Larkspur',
        main: 'blue',
        hex: '3C7D90',
        code: '17-4421',
      },
      {
        name: 'Capri Breeze',
        main: 'blue',
        hex: '008799',
        code: '17-4735',
      },
      {
        name: 'Tahitian Teal',
        main: 'blue',
        hex: '00899B',
        code: '17-4725',
      },
      {
        name: 'Turkish Tile',
        main: 'blue',
        hex: '00698B',
        code: '18-4432',
      },
      {
        name: 'Exotic Plume',
        main: 'blue',
        hex: '006E7F',
        code: '18-4631',
      },
      {
        name: 'Brittany Blue',
        main: 'blue',
        hex: '4D7E86',
        code: '18-5610',
      },
      {
        name: 'Storm Blue',
        main: 'blue',
        hex: '47788A',
        code: '17-4716',
      },
      {
        name: 'Lake Blue',
        main: 'blue',
        hex: '008C96',
        code: '17-4928',
      },
      {
        name: 'Tahitian Tide',
        main: 'blue',
        hex: '006C7F',
        code: '18-4630',
      },
      {
        name: 'Colonial Blue',
        main: 'blue',
        hex: '2E6471',
        code: '18-4522',
      },
      {
        name: 'Biscay Bay',
        main: 'blue',
        hex: '097988',
        code: '18-4726',
      },
    ],
  },
  {
    _id: 'Oatmeal',
    colors: [
      {
        name: 'Turtledove',
        main: 'beige',
        hex: 'DED7C8',
        code: '12-5202',
      },
      {
        name: 'Pistachio Shell',
        main: 'brown',
        hex: 'D7D0BC',
        code: '12-0110',
      },
      {
        name: 'Bleached Sand',
        main: 'brown',
        hex: 'DACCB4',
        code: '13-1008',
      },
      {
        name: 'Sand Dollar',
        main: 'brown',
        hex: 'DECDBE',
        code: '13-1106',
      },
      {
        name: 'Brazilian Sand',
        main: 'brown',
        hex: 'DBCAB8',
        code: '13-1308',
      },
      {
        name: 'Angora',
        main: 'brown',
        hex: 'DFD1BB',
        code: '12-0605',
      },
      {
        name: 'Summer Sand',
        main: 'brown',
        hex: 'D6CBBA',
        code: '12-0908',
      },
      {
        name: 'Parchment',
        main: 'brown',
        hex: 'DFD1BE',
        code: '13-0908',
      },
      {
        name: 'Sea Pearl',
        main: 'brown',
        hex: 'E4DACE',
        code: '12-0705',
      },
      {
        name: 'Almond Milk',
        main: 'gray',
        hex: 'D6CFBE',
        code: '12-4301',
      },
      {
        name: 'Oatmeal',
        main: 'gray',
        hex: 'CBC3B4',
        code: '13-0401',
      },
      {
        name: 'Crystal Gray',
        main: 'gray',
        hex: 'D7CBC4',
        code: '13-3801',
      },
    ],
  },
  {
    _id: 'Olive',
    colors: [
      {
        name: 'Pale Green',
        main: 'green',
        hex: 'CBCE91',
        code: '13-0522',
      },
      {
        name: 'Nile',
        main: 'green',
        hex: 'B4BB85',
        code: '14-0223',
      },
      {
        name: 'Tarragon',
        main: 'green',
        hex: 'A4AE77',
        code: '15-0326',
      },
      {
        name: 'Margarita',
        main: 'green',
        hex: 'B5C38E',
        code: '14-0116',
      },
      {
        name: 'Beechnut',
        main: 'green',
        hex: 'C2C18D',
        code: '14-0425',
      },
      {
        name: 'White Grape',
        main: 'green',
        hex: 'A6BE47',
        code: '14-0442',
      },
      {
        name: 'Green Banana',
        main: 'green',
        hex: 'BABC72',
        code: '14-0434',
      },
      {
        name: 'Sweet Pea',
        main: 'green',
        hex: 'A3A969',
        code: '15-0531',
      },
      {
        name: 'Macaw Green',
        main: 'green',
        hex: '9BB53E',
        code: '16-0230',
      },
      {
        name: 'Linden Green',
        main: 'green',
        hex: 'C4BF71',
        code: '15-0533',
      },
      {
        name: 'Apple Green',
        main: 'green',
        hex: 'B5B644',
        code: '15-0543',
      },
      {
        name: 'Leek Green',
        main: 'green',
        hex: 'B7B179',
        code: '15-0628',
      },
      {
        name: 'Titanite',
        main: 'green',
        hex: '8FAB3A',
        code: '16-0229',
      },
      {
        name: 'Peridot',
        main: 'green',
        hex: '819448',
        code: '17-0336',
      },
      {
        name: 'Shadow Green',
        main: 'green',
        hex: 'CFC486',
        code: '14-0627',
      },
      {
        name: 'Sage Green',
        main: 'green',
        hex: 'B2AC88',
        code: '15-0318',
      },
      {
        name: 'Pale Olive Green',
        main: 'green',
        hex: 'B5AD88',
        code: '15-0522',
      },
      {
        name: 'Green Oasis',
        main: 'green',
        hex: 'B0B454',
        code: '15-0538',
      },
      {
        name: 'Pear Liqueur',
        main: 'green',
        hex: 'A29C1F',
        code: '15-0542',
      },
      {
        name: 'Grenoble Green',
        main: 'green',
        hex: 'A2A524',
        code: '15-0544',
      },
      {
        name: 'Gray Green',
        main: 'green',
        hex: 'A49A79',
        code: '16-0518',
      },
      {
        name: 'Cedar',
        main: 'green',
        hex: '928E64',
        code: '16-0526',
      },
      {
        name: 'Moss',
        main: 'green',
        hex: 'A09D59',
        code: '16-0532',
      },
      {
        name: 'Oasis',
        main: 'green',
        hex: 'A3A04D',
        code: '16-0540',
      },
      {
        name: 'Golden Lime',
        main: 'green',
        hex: '9A9739',
        code: '16-0543',
      },
      {
        name: 'Camping Gear',
        main: 'green',
        hex: '97913A',
        code: '16-0544',
      },
      {
        name: 'Willow',
        main: 'green',
        hex: '9A8B4F',
        code: '16-0632',
      },
      {
        name: 'Slate Green',
        main: 'green',
        hex: 'A0987C',
        code: '16-0713',
      },
      {
        name: 'Turtle Green',
        main: 'green',
        hex: '81894E',
        code: '17-0330',
      },
      {
        name: 'Cardamom Seed',
        main: 'green',
        hex: '757331',
        code: '17-0529',
      },
      {
        name: 'Dried Herb',
        main: 'green',
        hex: '847A59',
        code: '17-0627',
      },
      {
        name: 'Bronze Mist',
        main: 'green',
        hex: '9C7E41',
        code: '17-0843',
      },
      {
        name: 'Fennel Seed',
        main: 'green',
        hex: '998456',
        code: '17-0929',
      },
      {
        name: 'Capulet Olive',
        main: 'green',
        hex: '656344',
        code: '18-0426',
      },
      {
        name: 'Avocado',
        main: 'green',
        hex: '676232',
        code: '18-0430',
      },
      {
        name: 'Dusty Olive',
        main: 'green',
        hex: '646356',
        code: '18-0515',
      },
      {
        name: 'Golden Cypress',
        main: 'green',
        hex: '7D7F2E',
        code: '18-0537',
      },
      {
        name: 'Lima Bean Green',
        main: 'green',
        hex: '828B33',
        code: '18-0540',
      },
      {
        name: 'Martini Olive',
        main: 'green',
        hex: '726A4E',
        code: '18-0625',
      },
      {
        name: 'Fir Green',
        main: 'green',
        hex: '67592A',
        code: '18-0627',
      },
      {
        name: 'Nutria',
        main: 'green',
        hex: '75663E',
        code: '18-0825',
      },
      {
        name: 'Hay',
        main: 'green',
        hex: 'D3CCA3',
        code: '12-0418',
      },
      {
        name: 'Tidal Foam',
        main: 'green',
        hex: 'BFB9A3',
        code: '14-0210',
      },
      {
        name: 'Winter Pear',
        main: 'green',
        hex: 'B0B487',
        code: '15-0523',
      },
      {
        name: 'Spinach Green',
        main: 'green',
        hex: '909B4C',
        code: '16-0439',
      },
      {
        name: 'Split Pea',
        main: 'green',
        hex: '9C9A40',
        code: '16-0545',
      },
      {
        name: 'Burnished Gold',
        main: 'green',
        hex: 'AA9855',
        code: '16-0737',
      },
      {
        name: 'Dusky Green',
        main: 'green',
        hex: '746C57',
        code: '17-0517',
      },
      {
        name: 'Mosstone',
        main: 'green',
        hex: '858961',
        code: '17-0525',
      },
      {
        name: 'Green Olive',
        main: 'green',
        hex: '8D8B55',
        code: '17-0535',
      },
      {
        name: 'Aloe',
        main: 'green',
        hex: '817A60',
        code: '17-0620',
      },
      {
        name: 'Elmwood',
        main: 'green',
        hex: '8C7C61',
        code: '17-1019',
      },
      {
        name: 'Deep Lichen Green',
        main: 'green',
        hex: '6E6E5C',
        code: '18-0312',
      },
      {
        name: 'Loden Green',
        main: 'green',
        hex: '6E7153',
        code: '18-0422',
      },
      {
        name: 'Burnt Olive',
        main: 'green',
        hex: '646049',
        code: '18-0521',
      },
      {
        name: 'Covert Green',
        main: 'green',
        hex: '80765F',
        code: '18-0617',
      },
      {
        name: 'Lizard',
        main: 'green',
        hex: '71643E',
        code: '18-0629',
      },
      {
        name: 'Black Olive',
        main: 'green',
        hex: '48413B',
        code: '19-0608',
      },
      {
        name: 'Breen',
        main: 'green',
        hex: '795D34',
        code: '19-1034',
      },
      {
        name: 'Cumin',
        main: 'green',
        hex: '927240',
        code: '18-0939',
      },
      {
        name: 'Kalamata',
        main: 'green',
        hex: '5F5C4C',
        code: '19-0510',
      },
      {
        name: 'Olive Night',
        main: 'green',
        hex: '535040',
        code: '19-0515',
      },
      {
        name: 'Dark Olive',
        main: 'green',
        hex: '574D35',
        code: '19-0516',
      },
      {
        name: 'Military Olive',
        main: 'green',
        hex: '63563B',
        code: '19-0622',
      },
      {
        name: 'Epsom',
        main: 'green',
        hex: '849161',
        code: '17-0324',
      },
      {
        name: 'Bog',
        main: 'green',
        hex: 'BAB696',
        code: '14-0418',
      },
      {
        name: 'Palm',
        main: 'green',
        hex: 'AFAF5E',
        code: '15-0535',
      },
      {
        name: 'Citronelle',
        main: 'green',
        hex: 'B8AF23',
        code: '15-0548',
      },
      {
        name: 'Warm Olive',
        main: 'green',
        hex: 'C7B63C',
        code: '15-0646',
      },
      {
        name: 'Olivenite',
        main: 'green',
        hex: 'C1A65C',
        code: '15-0732',
      },
      {
        name: 'Perfect Pear',
        main: 'green',
        hex: '919047',
        code: '16-0531',
      },
      {
        name: 'Lentil Sprout',
        main: 'green',
        hex: 'ACA54E',
        code: '16-0550',
      },
      {
        name: 'Evergreen Sprig',
        main: 'green',
        hex: '8D8646',
        code: '16-0634',
      },
      {
        name: 'Avocado Oil',
        main: 'green',
        hex: '9B892F',
        code: '16-0640',
      },
      {
        name: 'Twill',
        main: 'green',
        hex: 'A79B82',
        code: '16-1108',
      },
      {
        name: 'Olive Gray',
        main: 'green',
        hex: 'A6997A',
        code: '16-1110',
      },
      {
        name: 'Guacamole',
        main: 'green',
        hex: '797B3B',
        code: '17-0530',
      },
      {
        name: 'Ecru Olive',
        main: 'green',
        hex: '927B3C',
        code: '17-0836',
      },
      {
        name: 'Mayfly',
        main: 'green',
        hex: '666740',
        code: '18-0220',
      },
      {
        name: 'Peat Moss',
        main: 'green',
        hex: '6D6B3A',
        code: '18-0428',
      },
      {
        name: 'Smokey Olive',
        main: 'green',
        hex: '6A685D',
        code: '18-0516',
      },
      {
        name: 'Tea Leaf',
        main: 'green',
        hex: '616054',
        code: '18-0517',
      },
      {
        name: 'Iguana',
        main: 'green',
        hex: '818455',
        code: '18-0525',
      },
      {
        name: 'Olive Drab',
        main: 'green',
        hex: '756D47',
        code: '18-0622',
      },
      {
        name: 'Butternut',
        main: 'green',
        hex: '7B643F',
        code: '18-0830',
      },
      {
        name: 'Plantation',
        main: 'green',
        hex: '7A6332',
        code: '18-0832',
      },
      {
        name: 'Ivy Green',
        main: 'green',
        hex: '585442',
        code: '19-0512',
      },
      {
        name: 'Silver Sage',
        main: 'silver',
        hex: '938B78',
        code: '17-0510',
      },
      {
        name: 'Weeping Willow',
        main: 'green',
        hex: 'B3B17B',
        code: '15-0525',
      },
      {
        name: 'Fern',
        main: 'green',
        hex: '9AA067',
        code: '16-0430',
      },
      {
        name: 'Dark Citron',
        main: 'green',
        hex: 'A0AC4F',
        code: '16-0435',
      },
      {
        name: 'Pickled Pepper',
        main: 'green',
        hex: '9EA450',
        code: '16-0436',
      },
      {
        name: 'Green Envy',
        main: 'green',
        hex: 'A19436',
        code: '16-0541',
      },
      {
        name: 'Khaki',
        main: 'green',
        hex: 'A39264',
        code: '16-0726',
      },
      {
        name: 'Mermaid',
        main: 'green',
        hex: '817A65',
        code: '17-0618',
      },
      {
        name: 'Boa',
        main: 'green',
        hex: '8E855F',
        code: '17-0625',
      },
      {
        name: 'Green Moss',
        main: 'green',
        hex: '857946',
        code: '17-0636',
      },
      {
        name: 'Amber Green',
        main: 'green',
        hex: '9A803A',
        code: '17-0840',
      },
      {
        name: 'Grasshopper',
        main: 'green',
        hex: '77824A',
        code: '18-0332',
      },
      {
        name: 'Calla Green',
        main: 'green',
        hex: '6A6F34',
        code: '18-0435',
      },
      {
        name: 'Going Green',
        main: 'green',
        hex: '7C823F',
        code: '18-0530',
      },
      {
        name: 'Woodbine',
        main: 'green',
        hex: '7B7F32',
        code: '18-0538',
      },
      {
        name: 'Gothic Olive',
        main: 'green',
        hex: '7C6E4E',
        code: '18-0724',
      },
      {
        name: 'Dried Tobacco',
        main: 'green',
        hex: '997B38',
        code: '18-0835',
      },
      {
        name: 'Beetle',
        main: 'green',
        hex: '56584C',
        code: '19-0312',
      },
      {
        name: 'Fragile Sprout',
        main: 'yellow',
        hex: 'B9BD2B',
        code: '15-0549',
      },
      {
        name: 'Golden Green',
        main: 'gold',
        hex: 'BDB369',
        code: '15-0636',
      },
      {
        name: 'Cress Green',
        main: 'gold',
        hex: 'BCA949',
        code: '15-0643',
      },
      {
        name: 'Golden Olive',
        main: 'gold',
        hex: 'AF9841',
        code: '16-0639',
      },
    ],
  },
  {
    _id: 'Orchid',
    colors: [
      {
        name: 'Lilac Chiffon',
        main: 'pink',
        hex: 'DE9BC4',
        code: '15-2913',
      },
      {
        name: 'Lilac Sachet',
        main: 'pink',
        hex: 'E9ADCA',
        code: '14-2710',
      },
      {
        name: 'Pastel Lavender',
        main: 'pink',
        hex: 'D8A1C4',
        code: '14-3209',
      },
      {
        name: 'Orchid',
        main: 'pink',
        hex: 'D198C5',
        code: '15-3214',
      },
      {
        name: 'Phalaenopsis',
        main: 'pink',
        hex: 'D39FC8',
        code: '15-3216',
      },
      {
        name: 'Orchid Bloom',
        main: 'blue',
        hex: 'C5AECF',
        code: '14-3612',
      },
      {
        name: 'Lilac Marble',
        main: 'blue',
        hex: 'C3BABF',
        code: '14-3903',
      },
      {
        name: 'Dawn Pink',
        main: 'blue',
        hex: 'BFA3AF',
        code: '15-2205',
      },
      {
        name: 'Keepsake Lilac',
        main: 'blue',
        hex: 'C0A5AE',
        code: '15-2705',
      },
      {
        name: 'Fair Orchid',
        main: 'blue',
        hex: 'C0AAC0',
        code: '15-3508',
      },
      {
        name: 'Sea Fog',
        main: 'blue',
        hex: 'A5929D',
        code: '16-3304',
      },
      {
        name: 'Violet Tulle',
        main: 'blue',
        hex: 'C193C0',
        code: '16-3416',
      },
      {
        name: 'Lupine',
        main: 'blue',
        hex: 'BE9CC1',
        code: '16-3521',
      },
      {
        name: 'Orchid Ice',
        main: 'blue',
        hex: 'E0D0DB',
        code: '13-3406',
      },
      {
        name: 'Orchid Bouquet',
        main: 'blue',
        hex: 'D1ACCE',
        code: '15-3412',
      },
      {
        name: 'Lavender Herb',
        main: 'blue',
        hex: 'B18EAA',
        code: '16-3310',
      },
      {
        name: 'Regal Orchid',
        main: 'blue',
        hex: 'A98BAF',
        code: '16-3525',
      },
      {
        name: 'Light Lilac',
        main: 'blue',
        hex: 'DEC6D3',
        code: '12-2903',
      },
      {
        name: 'Gray Lilac',
        main: 'blue',
        hex: 'D4CACD',
        code: '13-3804',
      },
      {
        name: 'Lavender Fog',
        main: 'blue',
        hex: 'D2C4D6',
        code: '13-3820',
      },
      {
        name: 'Pink Lavender',
        main: 'blue',
        hex: 'D9AFCA',
        code: '14-3207',
      },
      {
        name: 'Orchid Petal',
        main: 'blue',
        hex: 'BFB4CB',
        code: '14-3710',
      },
      {
        name: 'Mauve Mist',
        main: 'blue',
        hex: 'C49DB4',
        code: '15-3207',
      },
      {
        name: 'Lilac Snow',
        main: 'blue',
        hex: 'E0C7D7',
        code: '13-3405',
      },
      {
        name: 'Fragrant Lilac',
        main: 'blue',
        hex: 'CFADBE',
        code: '14-3204',
      },
      {
        name: 'Winsome Orchid',
        main: 'blue',
        hex: 'D4B9CB',
        code: '14-3206',
      },
      {
        name: 'Burnished Lilac',
        main: 'blue',
        hex: 'C5AEB1',
        code: '15-1905',
      },
      {
        name: 'Mauve Shadows',
        main: 'blue',
        hex: 'B598A3',
        code: '16-3205',
      },
      {
        name: 'Cyclamen',
        main: 'purple',
        hex: 'D687BA',
        code: '16-3118',
      },
      {
        name: 'Violet Dusk',
        main: 'purple',
        hex: 'C9B4CF',
        code: '14-3610',
      },
      {
        name: 'Crocus',
        main: 'purple',
        hex: 'C67FAE',
        code: '16-3115',
      },
      {
        name: 'English Hyacinth',
        main: 'purple',
        hex: 'C0AFCC',
        code: '14-3715',
      },
      {
        name: 'Opera Mauve',
        main: 'purple',
        hex: 'CA80B1',
        code: '16-3116',
      },
      {
        name: 'Diffused Orchid',
        main: 'purple',
        hex: '997AA3',
        code: '17-3520',
      },
      {
        name: 'Radiant Orchid',
        main: 'purple',
        hex: 'AD5E99',
        code: '18-3224',
      },
      {
        name: 'Pale Pansy',
        main: 'purple',
        hex: 'B380AA',
        code: '17-3310',
      },
      {
        name: 'Orchid Mist',
        main: 'purple',
        hex: '917798',
        code: '17-3612',
      },
    ],
  },
  {
    _id: 'Pastel Blue',
    colors: [
      {
        name: 'Ice Melt',
        main: 'blue',
        hex: 'D3E4F1',
        code: '13-4306',
      },
      {
        name: 'Quiet Tide',
        main: 'blue',
        hex: 'C0E3F0',
        code: '13-4401',
      },
      {
        name: 'Ocean Current',
        main: 'blue',
        hex: 'A1D5EB',
        code: '13-4520',
      },
      {
        name: 'Nantucket Breeze',
        main: 'blue',
        hex: 'B7D1EA',
        code: '14-4005',
      },
      {
        name: 'Floaty Blue',
        main: 'blue',
        hex: 'AFC3DD',
        code: '14-4015',
      },
      {
        name: 'Petit four',
        main: 'blue',
        hex: '87C2D4',
        code: '14-4516',
      },
      {
        name: 'Pure Pond',
        main: 'blue',
        hex: '9ECFD8',
        code: '14-4716',
      },
      {
        name: 'Delicate Blue',
        main: 'blue',
        hex: 'C2DAE8',
        code: '12-4202',
      },
      {
        name: 'Ice Water',
        main: 'blue',
        hex: 'BFD5EB',
        code: '13-4202',
      },
      {
        name: 'Angel Falls',
        main: 'blue',
        hex: 'A3BDD3',
        code: '15-4105',
      },
      {
        name: 'Clear Sky',
        main: 'blue',
        hex: '9BBCD8',
        code: '14-4123',
      },
      {
        name: 'Dream Blue',
        main: 'blue',
        hex: 'A0BCD0',
        code: '15-4005',
      },
      {
        name: 'Iced Aqua',
        main: 'aqua',
        hex: 'ACD3DC',
        code: '13-5410',
      },
      {
        name: 'Aquamarine',
        main: 'aqua',
        hex: '9DC3D4',
        code: '14-4313',
      },
      {
        name: 'Spun Sugar',
        main: 'aqua',
        hex: 'B4DCEA',
        code: '12-4401',
      },
    ],
  },
  {
    _id: 'Pastel Green',
    colors: [
      {
        name: 'Hushed Green',
        main: 'white',
        hex: 'D8E9E5',
        code: '12-5508',
      },
      {
        name: 'Summer Shower',
        main: 'white',
        hex: 'E5EBE3',
        code: '11-4802',
      },
      {
        name: 'Bluewash',
        main: 'beige',
        hex: 'E2E6E0',
        code: '12-4304',
      },
      {
        name: 'Hint of Mint',
        main: 'white',
        hex: 'D8EBE6',
        code: '11-4805',
      },
      {
        name: 'Mystic Blue',
        main: 'gray',
        hex: 'E1E3DE',
        code: '11-4303',
      },
      {
        name: 'Zephyr Blue',
        main: 'gray',
        hex: 'D3D9D1',
        code: '12-5603',
      },
      {
        name: 'Lightest Sky',
        main: 'green',
        hex: 'E4EADF',
        code: '11-4804',
      },
      {
        name: 'Canary Green',
        main: 'green',
        hex: 'D6DFC9',
        code: '12-0108',
      },
      {
        name: 'Ambrosia',
        main: 'green',
        hex: 'D2E7CA',
        code: '12-0109',
      },
      {
        name: 'Scenic Green',
        main: 'green',
        hex: 'C2E4BA',
        code: '12-0230',
      },
      {
        name: 'Fairest Jade',
        main: 'green',
        hex: 'D8E3D7',
        code: '12-6206',
      },
      {
        name: 'Water Lily',
        main: 'green',
        hex: 'DDE3D5',
        code: '11-0304',
      },
      {
        name: 'Ice',
        main: 'green',
        hex: 'E0E4D9',
        code: '11-4803',
      },
      {
        name: 'Sprout Green',
        main: 'green',
        hex: 'CBD7D2',
        code: '12-5303',
      },
      {
        name: 'Milky Green',
        main: 'green',
        hex: 'CFDBD1',
        code: '12-6205',
      },
      {
        name: 'Greenish',
        main: 'green',
        hex: 'C4E3D0',
        code: '12-5806',
      },
      {
        name: 'Veiled Vista',
        main: 'green',
        hex: 'C8E4CA',
        code: '12-6000',
      },
      {
        name: 'Phantom Green',
        main: 'green',
        hex: 'DCE4D7',
        code: '12-6208',
      },
      {
        name: 'Whisper Green',
        main: 'green',
        hex: 'E0E6D6',
        code: '12-5404',
      },
      {
        name: 'Frost',
        main: 'green',
        hex: 'DDE2D6',
        code: '12-6207',
      },
      {
        name: 'Aqua Glass',
        main: 'aqua',
        hex: 'D2E8DF',
        code: '12-5407',
      },
      {
        name: 'Lime Cream',
        main: 'yellow',
        hex: 'D7E8BC',
        code: '12-0312',
      },
    ],
  },
  {
    _id: 'Pastel Orange',
    colors: [
      {
        name: 'Pear Sorbet',
        main: 'beige',
        hex: 'F3EAC3',
        code: '11-0615',
      },
      {
        name: 'Cornhusk',
        main: 'orange',
        hex: 'F3D5AD',
        code: '12-0714',
      },
      {
        name: 'Golden Fleece',
        main: 'orange',
        hex: 'F2D1A0',
        code: '12-0822',
      },
      {
        name: 'Tender Peach',
        main: 'orange',
        hex: 'F8D5B8',
        code: '12-0912',
      },
      {
        name: 'Creamed Corn',
        main: 'orange',
        hex: 'F7E09F',
        code: '12-0730',
      },
      {
        name: 'Apricot Gelato',
        main: 'orange',
        hex: 'F5D7AF',
        code: '12-0817',
      },
      {
        name: 'Bleached Apricot',
        main: 'orange',
        hex: 'FCCAAC',
        code: '12-0917',
      },
      {
        name: 'Almond Cream',
        main: 'orange',
        hex: 'F4C29F',
        code: '13-1017',
      },
      {
        name: 'Apricot Sherbet',
        main: 'orange',
        hex: 'FACD9E',
        code: '13-1031',
      },
      {
        name: 'Ethereal Green',
        main: 'green',
        hex: 'F1ECCA',
        code: '11-0609',
      },
      {
        name: 'Transparent Yellow',
        main: 'yellow',
        hex: 'F4ECC2',
        code: '11-0617',
      },
      {
        name: 'Mellow Yellow',
        main: 'yellow',
        hex: 'F0DD9D',
        code: '12-0720',
      },
      {
        name: 'Flan',
        main: 'yellow',
        hex: 'F6E3B4',
        code: '11-0619',
      },
      {
        name: 'French Vanilla',
        main: 'yellow',
        hex: 'EFE1A7',
        code: '12-0722',
      },
      {
        name: 'Lemon Icing',
        main: 'yellow',
        hex: 'F6EBC8',
        code: '11-0515',
      },
      {
        name: 'Double Cream',
        main: 'yellow',
        hex: 'F3E0AC',
        code: '12-0715',
      },
      {
        name: 'Popcorn',
        main: 'yellow',
        hex: 'F8DE8D',
        code: '12-0825',
      },
      {
        name: 'Anise Flower',
        main: 'yellow',
        hex: 'F4E3B5',
        code: '12-0717',
      },
      {
        name: 'Pale Banana',
        main: 'yellow',
        hex: 'FAE199',
        code: '12-0824',
      },
      {
        name: 'Sunlight',
        main: 'yellow',
        hex: 'EDD59E',
        code: '13-0822',
      },
      {
        name: 'Golden Haze',
        main: 'gold',
        hex: 'FBD897',
        code: '12-0826',
      },
    ],
  },
  {
    _id: 'Pastel Pink',
    colors: [
      {
        name: 'Sheer Pink',
        main: 'beige',
        hex: 'F6E5DB',
        code: '12-1106',
      },
      {
        name: 'Shrinking Violet',
        main: 'pink',
        hex: 'F4E1E6',
        code: '11-2511',
      },
      {
        name: 'Angel Wing',
        main: 'pink',
        hex: 'F3DFD7',
        code: '11-1305',
      },
      {
        name: 'Rosewater',
        main: 'pink',
        hex: 'F6DBD8',
        code: '11-1408',
      },
      {
        name: 'Petal Pink',
        main: 'pink',
        hex: 'F2E2E0',
        code: '11-2309',
      },
      {
        name: 'Raindrops on Roses',
        main: 'pink',
        hex: 'ECD8DC',
        code: '11-1400',
      },
      {
        name: 'Delicacy',
        main: 'pink',
        hex: 'F5E3E2',
        code: '11-2409',
      },
      {
        name: 'Icy Pink',
        main: 'pink',
        hex: 'FBD3D9',
        code: '12-1310',
      },
      {
        name: 'Mauve Morn',
        main: 'pink',
        hex: 'ECD7D6',
        code: '12-2102',
      },
      {
        name: 'Cradle Pink',
        main: 'pink',
        hex: 'EDD0DD',
        code: '12-2905',
      },
      {
        name: 'Barely Pink',
        main: 'pink',
        hex: 'F8D7DD',
        code: '12-2906',
      },
      {
        name: 'Pink-a-boo',
        main: 'pink',
        hex: 'F7CDDB',
        code: '13-2801',
      },
      {
        name: "Mary's Rose",
        main: 'pink',
        hex: 'F8D1D4',
        code: '12-1813',
      },
      {
        name: 'Tender Touch',
        main: 'pink',
        hex: 'F3D4DF',
        code: '12-2807',
      },
      {
        name: 'Pink Marshmallow',
        main: 'pink',
        hex: 'F8E0E8',
        code: '12-2907',
      },
      {
        name: 'Festival Bloom',
        main: 'pink',
        hex: 'F5D6E6',
        code: '12-2908',
      },
      {
        name: 'Flushing Pink',
        main: 'pink',
        hex: 'FCCCDC',
        code: '13-2705',
      },
      {
        name: 'Ballerina',
        main: 'pink',
        hex: 'F2CFDC',
        code: '13-2807',
      },
      {
        name: 'Carinaria',
        main: 'pink',
        hex: 'F4DDDF',
        code: '12-1303',
      },
      {
        name: 'Cherry Blossom',
        main: 'pink',
        hex: 'F7CFE1',
        code: '13-3207',
      },
      {
        name: 'Bridal Blush',
        main: 'blue',
        hex: 'EEE2DD',
        code: '11-1005',
      },
      {
        name: 'Coconut Cream',
        main: 'blue',
        hex: 'EEE0DC',
        code: '11-1007',
      },
      {
        name: 'Calcite',
        main: 'blue',
        hex: 'EBDBE1',
        code: '11-2509',
      },
    ],
  },
  {
    _id: 'Pastel Purple',
    colors: [
      {
        name: 'Early Hours',
        main: 'pink',
        hex: 'DBC5D0',
        code: '13-2930',
      },
      {
        name: 'Pale Lilac',
        main: 'blue',
        hex: 'E1C6CC',
        code: '13-2803',
      },
      {
        name: 'Pastel Lilac',
        main: 'blue',
        hex: 'BCAFCF',
        code: '14-3812',
      },
      {
        name: 'Lavender Blue',
        main: 'blue',
        hex: 'C5C0D0',
        code: '14-3905',
      },
      {
        name: 'Orchid Ice',
        main: 'blue',
        hex: 'E0D0DB',
        code: '13-3406',
      },
      {
        name: 'Light Lilac',
        main: 'blue',
        hex: 'DEC6D3',
        code: '12-2903',
      },
      {
        name: 'Orchid Tint',
        main: 'blue',
        hex: 'DBD2DB',
        code: '13-3802',
      },
      {
        name: 'Gray Lilac',
        main: 'blue',
        hex: 'D4CACD',
        code: '13-3804',
      },
      {
        name: 'Lavender Fog',
        main: 'blue',
        hex: 'D2C4D6',
        code: '13-3820',
      },
      {
        name: 'Lilac Hint',
        main: 'blue',
        hex: 'D0D0DA',
        code: '13-4105',
      },
      {
        name: 'Orchid Petal',
        main: 'blue',
        hex: 'BFB4CB',
        code: '14-3710',
      },
      {
        name: 'Lilac Snow',
        main: 'blue',
        hex: 'E0C7D7',
        code: '13-3405',
      },
      {
        name: 'Orchid Hush',
        main: 'blue',
        hex: 'CEC3D2',
        code: '13-3805',
      },
      {
        name: 'Scented Lavender',
        main: 'purple',
        hex: 'CCB5D6',
        code: '14-3512',
      },
      {
        name: 'Violet Dusk',
        main: 'purple',
        hex: 'C9B4CF',
        code: '14-3610',
      },
      {
        name: 'Lacy Lilac',
        main: 'purple',
        hex: 'B3B1D2',
        code: '14-3926',
      },
      {
        name: 'English Hyacinth',
        main: 'purple',
        hex: 'C0AFCC',
        code: '14-3715',
      },
    ],
  },
  {
    _id: 'Pastel Yellow',
    colors: [
      {
        name: 'Winter White',
        main: 'beige',
        hex: 'F5ECD2',
        code: '11-0507',
      },
      {
        name: 'Papyrus',
        main: 'beige',
        hex: 'F5EDD6',
        code: '11-0107',
      },
      {
        name: 'Sweet Corn',
        main: 'beige',
        hex: 'F0EBD7',
        code: '11-0106',
      },
      {
        name: 'Solitary Star',
        main: 'beige',
        hex: 'F3EBD6',
        code: '11-0108',
      },
      {
        name: 'Pear Sorbet',
        main: 'beige',
        hex: 'F3EAC3',
        code: '11-0615',
      },
      {
        name: 'Cannoli Cream',
        main: 'beige',
        hex: 'F1F0E2',
        code: '11-4302',
      },
      {
        name: 'Almond Oil',
        main: 'orange',
        hex: 'F4E4C1',
        code: '12-0713',
      },
      {
        name: 'Glass Green',
        main: 'green',
        hex: 'ECEAD0',
        code: '11-0205',
      },
      {
        name: 'Ethereal Green',
        main: 'green',
        hex: 'F1ECCA',
        code: '11-0609',
      },
      {
        name: 'Sylvan Green',
        main: 'green',
        hex: 'E7EACB',
        code: '11-0410',
      },
      {
        name: 'Green Essence',
        main: 'yellow',
        hex: 'E9EAC8',
        code: '12-0607',
      },
      {
        name: 'Transparent Yellow',
        main: 'yellow',
        hex: 'F4ECC2',
        code: '11-0617',
      },
      {
        name: 'Wax Yellow',
        main: 'yellow',
        hex: 'EDE9AD',
        code: '11-0618',
      },
      {
        name: 'Lemonade',
        main: 'yellow',
        hex: 'F0E79D',
        code: '12-0721',
      },
      {
        name: 'Lemon Icing',
        main: 'yellow',
        hex: 'F6EBC8',
        code: '11-0515',
      },
      {
        name: 'Tender Yellow',
        main: 'yellow',
        hex: 'EDEEB7',
        code: '11-0710',
      },
      {
        name: 'Pastel Yellow',
        main: 'yellow',
        hex: 'F2E6B1',
        code: '11-0616',
      },
      {
        name: 'Anise Flower',
        main: 'yellow',
        hex: 'F4E3B5',
        code: '12-0717',
      },
    ],
  },
  {
    _id: 'Peach',
    colors: [
      {
        name: 'Linen',
        main: 'brown',
        hex: 'EDD2C0',
        code: '12-1008',
      },
      {
        name: 'Autumn Blonde',
        main: 'orange',
        hex: 'EECFAD',
        code: '12-0813',
      },
      {
        name: 'Peach Taffy',
        main: 'orange',
        hex: 'F2D3BC',
        code: '12-0911',
      },
      {
        name: 'Tender Peach',
        main: 'orange',
        hex: 'F8D5B8',
        code: '12-0912',
      },
      {
        name: 'Novelle Peach',
        main: 'orange',
        hex: 'E7CFBD',
        code: '12-1005',
      },
      {
        name: 'Alesan',
        main: 'orange',
        hex: 'F1CEB3',
        code: '12-0913',
      },
      {
        name: 'Scallop Shell',
        main: 'orange',
        hex: 'FBD8C9',
        code: '12-1010',
      },
      {
        name: 'Sun Kiss',
        main: 'orange',
        hex: 'EBD1BB',
        code: '12-0807',
      },
      {
        name: 'Pale Peach',
        main: 'orange',
        hex: 'FED1BD',
        code: '12-0915',
      },
      {
        name: 'Bleached Apricot',
        main: 'orange',
        hex: 'FCCAAC',
        code: '12-0917',
      },
      {
        name: 'Prairie Sunset',
        main: 'orange',
        hex: 'FFBB9E',
        code: '13-1021',
      },
      {
        name: 'Peach Quartz',
        main: 'orange',
        hex: 'F5B895',
        code: '13-1125',
      },
      {
        name: 'Beach Sand',
        main: 'orange',
        hex: 'FBB995',
        code: '14-1225',
      },
      {
        name: 'Peach',
        main: 'orange',
        hex: 'F2A987',
        code: '14-1227',
      },
      {
        name: 'Honeyed Apricot',
        main: 'orange',
        hex: 'F0A894',
        code: '14-1430',
      },
      {
        name: 'Peach Sorbet',
        main: 'orange',
        hex: 'FFAA88',
        code: '14-1431',
      },
      {
        name: 'Papaya Punch',
        main: 'orange',
        hex: 'FCA289',
        code: '15-1433',
      },
      {
        name: 'Apricot Ice',
        main: 'orange',
        hex: 'FBBE99',
        code: '13-1020',
      },
      {
        name: 'Caramel Cream',
        main: 'orange',
        hex: 'F4BA94',
        code: '13-1022',
      },
      {
        name: 'Creampuff',
        main: 'orange',
        hex: 'FFCDA8',
        code: '13-1026',
      },
      {
        name: 'Peach Parfait',
        main: 'orange',
        hex: 'F8BFA8',
        code: '14-1219',
      },
      {
        name: 'Peach Melba',
        main: 'orange',
        hex: 'FBBDAF',
        code: '14-1418',
      },
      {
        name: 'Peach Pink',
        main: 'orange',
        hex: 'FA9A85',
        code: '15-1530',
      },
      {
        name: 'Peach Purée',
        main: 'orange',
        hex: 'EFCFBA',
        code: '12-1011',
      },
      {
        name: 'Peach Nougat',
        main: 'orange',
        hex: 'E6AF91',
        code: '14-1220',
      },
      {
        name: 'Peach Nectar',
        main: 'orange',
        hex: 'FFB59B',
        code: '14-1228',
      },
      {
        name: 'Cream Blush',
        main: 'orange',
        hex: 'F8C19A',
        code: '13-1019',
      },
      {
        name: 'Peach Fuzz',
        main: 'orange',
        hex: 'FFBE98',
        code: '13-1023',
      },
      {
        name: 'Apricot Sherbet',
        main: 'orange',
        hex: 'FACD9E',
        code: '13-1031',
      },
      {
        name: 'Tropical Peach',
        main: 'orange',
        hex: 'FFC4B2',
        code: '13-1318',
      },
      {
        name: 'Peach Smoothie',
        main: 'orange',
        hex: 'F8A798',
        code: '14-1320',
      },
      {
        name: 'Taste of Peach',
        main: 'orange',
        hex: 'EA9F8A',
        code: '14-1417',
      },
      {
        name: 'Perfection Peach',
        main: 'orange',
        hex: 'FFB191',
        code: '14-1440',
      },
      {
        name: 'Bellini',
        main: 'pink',
        hex: 'F4C9B1',
        code: '13-1114',
      },
      {
        name: 'Pink Sand',
        main: 'pink',
        hex: 'DFB19B',
        code: '15-1318',
      },
      {
        name: 'Desert Flower',
        main: 'pink',
        hex: 'FF9687',
        code: '15-1435',
      },
      {
        name: 'Blooming Dahlia',
        main: 'pink',
        hex: 'EC9688',
        code: '15-1520',
      },
      {
        name: 'Candlelight Peach',
        main: 'pink',
        hex: 'F8A39D',
        code: '15-1621',
      },
      {
        name: 'Peach Adobe',
        main: 'pink',
        hex: 'FFCBB8',
        code: '13-1218',
      },
      {
        name: 'Peach Tree',
        main: 'pink',
        hex: 'FFC0AA',
        code: '13-1330',
      },
      {
        name: 'Dusky Peach',
        main: 'pink',
        hex: 'EEBAAA',
        code: '13-1418',
      },
      {
        name: 'Almost Apricot',
        main: 'pink',
        hex: 'E5B39B',
        code: '15-1319',
      },
      {
        name: 'Peach Mousse',
        main: 'pink',
        hex: 'FFCDB5',
        code: '12-1121',
      },
      {
        name: 'Peach Palazzo',
        main: 'pink',
        hex: 'FCB5A0',
        code: '13-1307',
      },
      {
        name: 'Burnt Coral',
        main: 'pink',
        hex: 'E9897E',
        code: '16-1529',
      },
    ],
  },
  {
    _id: 'Pine Green',
    colors: [
      {
        name: 'Oil Blue',
        main: 'blue',
        hex: '658C88',
        code: '17-5111',
      },
      {
        name: 'Arctic',
        main: 'blue',
        hex: '648589',
        code: '17-4911',
      },
      {
        name: 'Beryl Green',
        main: 'green',
        hex: '619187',
        code: '16-5515',
      },
      {
        name: 'Feldspar',
        main: 'green',
        hex: '729B8B',
        code: '16-5815',
      },
      {
        name: 'Granite Green',
        main: 'green',
        hex: '86A293',
        code: '16-5907',
      },
      {
        name: 'Iceberg Green',
        main: 'green',
        hex: '8C9C92',
        code: '16-5808',
      },
      {
        name: 'Green Bay',
        main: 'green',
        hex: '7E9285',
        code: '16-5810',
      },
      {
        name: 'Dusty Jade Green',
        main: 'green',
        hex: '7BB5A3',
        code: '15-5711',
      },
      {
        name: 'Lily Pad',
        main: 'green',
        hex: '818F84',
        code: '16-5807',
      },
      {
        name: 'Malachite Green',
        main: 'green',
        hex: '709A89',
        code: '16-5917',
      },
      {
        name: 'Dark Ivy',
        main: 'green',
        hex: '5B7763',
        code: '17-5912',
      },
      {
        name: 'Pine Green',
        main: 'green',
        hex: '3A795E',
        code: '17-5923',
      },
      {
        name: 'Sea Spray',
        main: 'green',
        hex: '727E6F',
        code: '17-6212',
      },
      {
        name: 'Deep Grass Green',
        main: 'green',
        hex: '558367',
        code: '17-6219',
      },
      {
        name: 'Ultramarine Green',
        main: 'green',
        hex: '006B54',
        code: '18-5338',
      },
      {
        name: 'Frosty Spruce',
        main: 'green',
        hex: '578270',
        code: '18-5622',
      },
      {
        name: 'Lush Meadow',
        main: 'green',
        hex: '006E52',
        code: '18-5845',
      },
      {
        name: 'Duck Green',
        main: 'green',
        hex: '53665C',
        code: '18-6011',
      },
      {
        name: 'Trekking Green',
        main: 'green',
        hex: '355048',
        code: '19-5411',
      },
      {
        name: 'Pineneedle',
        main: 'green',
        hex: '344D41',
        code: '19-5920',
      },
      {
        name: 'Balsam Green',
        main: 'green',
        hex: '576664',
        code: '18-5606',
      },
      {
        name: 'Dark Forest',
        main: 'green',
        hex: '556962',
        code: '18-5611',
      },
      {
        name: 'Sagebrush Green',
        main: 'green',
        hex: '567572',
        code: '18-5612',
      },
      {
        name: 'Fir',
        main: 'green',
        hex: '3B725F',
        code: '18-5621',
      },
      {
        name: 'Garden Topiary',
        main: 'green',
        hex: '3F524B',
        code: '18-5913',
      },
      {
        name: 'Darkest Spruce',
        main: 'green',
        hex: '303D3C',
        code: '19-5212',
      },
      {
        name: 'Bistro Green',
        main: 'green',
        hex: '395551',
        code: '19-5408',
      },
      {
        name: 'Aventurine',
        main: 'green',
        hex: '005348',
        code: '19-5421',
      },
      {
        name: 'Jungle Green',
        main: 'green',
        hex: '3C4E47',
        code: '19-5914',
      },
      {
        name: 'Mountain View',
        main: 'green',
        hex: '2E3D30',
        code: '19-5918',
      },
      {
        name: 'Verdant Green',
        main: 'green',
        hex: '12674A',
        code: '19-6026',
      },
      {
        name: 'Chinois Green',
        main: 'green',
        hex: '7C8C87',
        code: '17-5107',
      },
      {
        name: 'Bottle Green',
        main: 'green',
        hex: '437D6D',
        code: '17-5722',
      },
      {
        name: 'Laurel Wreath',
        main: 'green',
        hex: '616F65',
        code: '17-6009',
      },
      {
        name: 'Blue Spruce',
        main: 'green',
        hex: '486B67',
        code: '18-5308',
      },
      {
        name: 'Foliage Green',
        main: 'green',
        hex: '3E6F58',
        code: '18-6018',
      },
      {
        name: 'Green Tambourine',
        main: 'green',
        hex: '007449',
        code: '18-6033',
      },
      {
        name: 'Evergreen',
        main: 'green',
        hex: '12574A',
        code: '19-5420',
      },
      {
        name: 'Cilantro',
        main: 'green',
        hex: '43544B',
        code: '19-5621',
      },
      {
        name: 'Sycamore',
        main: 'green',
        hex: '35463D',
        code: '19-5917',
      },
      {
        name: 'Green Jacket',
        main: 'green',
        hex: '005D43',
        code: '19-6027',
      },
      {
        name: 'Greener Pastures',
        main: 'green',
        hex: '37503D',
        code: '19-6311',
      },
      {
        name: 'Greenlake',
        main: 'green',
        hex: '007D69',
        code: '17-5528',
      },
      {
        name: 'Bosphorus',
        main: 'green',
        hex: '007558',
        code: '18-5633',
      },
      {
        name: 'Amazon',
        main: 'green',
        hex: '207349',
        code: '18-6024',
      },
      {
        name: 'Abundant Green',
        main: 'green',
        hex: '006339',
        code: '18-6026',
      },
      {
        name: 'First Tee',
        main: 'green',
        hex: '00723B',
        code: '18-6031',
      },
      {
        name: 'Myrtle',
        main: 'green',
        hex: '506B58',
        code: '18-6114',
      },
      {
        name: 'Comfrey',
        main: 'green',
        hex: '5B7961',
        code: '18-6216',
      },
      {
        name: 'Pine Grove',
        main: 'green',
        hex: '223631',
        code: '19-5406',
      },
      {
        name: 'Hunter Green',
        main: 'green',
        hex: '335749',
        code: '19-5511',
      },
      {
        name: 'Dark Green',
        main: 'green',
        hex: '324F40',
        code: '19-5513',
      },
      {
        name: 'Eden',
        main: 'green',
        hex: '264E36',
        code: '19-6050',
      },
      {
        name: 'Formal Garden',
        main: 'green',
        hex: '1F5129',
        code: '19-6350',
      },
      {
        name: 'Sea Pine',
        main: 'blue',
        hex: '4C6969',
        code: '18-5112',
      },
      {
        name: 'Deep Sea',
        main: 'blue',
        hex: '4F7C74',
        code: '17-5513',
      },
      {
        name: 'North Atlantic',
        main: 'blue',
        hex: '546D70',
        code: '18-4612',
      },
      {
        name: 'Silver Pine',
        main: 'blue',
        hex: '4E6866',
        code: '18-5410',
      },
    ],
  },
  {
    _id: 'Pistachio',
    colors: [
      {
        name: 'Ambrosia',
        main: 'green',
        hex: 'D2E7CA',
        code: '12-0109',
      },
      {
        name: 'Scenic Green',
        main: 'green',
        hex: 'C2E4BA',
        code: '12-0230',
      },
      {
        name: 'Greenish',
        main: 'green',
        hex: 'C4E3D0',
        code: '12-5806',
      },
      {
        name: 'Veiled Vista',
        main: 'green',
        hex: 'C8E4CA',
        code: '12-6000',
      },
      {
        name: 'Whisper Green',
        main: 'green',
        hex: 'E0E6D6',
        code: '12-5404',
      },
      {
        name: "Bird's Egg Green",
        main: 'green',
        hex: 'AACCB8',
        code: '13-5911',
      },
      {
        name: 'Spring Leaf',
        main: 'green',
        hex: '9BCE97',
        code: '14-0225',
      },
      {
        name: 'Reed',
        main: 'green',
        hex: 'C3D3A8',
        code: '13-0215',
      },
      {
        name: 'Key Lime Pie',
        main: 'green',
        hex: 'B2DC9E',
        code: '13-0216',
      },
      {
        name: 'Gossamer Green',
        main: 'green',
        hex: 'B2CFBE',
        code: '13-5907',
      },
      {
        name: 'Gremlin Green',
        main: 'green',
        hex: '8FC796',
        code: '14-0120',
      },
      {
        name: 'Sprucestone',
        main: 'green',
        hex: '9FC09C',
        code: '14-6316',
      },
      {
        name: 'Spray',
        main: 'green',
        hex: 'BED3BB',
        code: '13-6007',
      },
      {
        name: 'Arcadian Green',
        main: 'green',
        hex: 'A3C893',
        code: '14-0123',
      },
      {
        name: 'Serene Green',
        main: 'green',
        hex: 'A6D3C0',
        code: '14-5710',
      },
      {
        name: 'Meadow',
        main: 'green',
        hex: '8BBA94',
        code: '14-6319',
      },
      {
        name: 'Pastel Green',
        main: 'green',
        hex: 'B4D3B2',
        code: '13-0116',
      },
      {
        name: 'Pistachio Green',
        main: 'green',
        hex: 'A9D39E',
        code: '13-0221',
      },
      {
        name: 'Misty Jade',
        main: 'green',
        hex: 'BCD9C8',
        code: '13-6008',
      },
      {
        name: 'Nile Green',
        main: 'green',
        hex: 'A7C796',
        code: '14-0121',
      },
      {
        name: 'Lichen',
        main: 'green',
        hex: '9BC2B1',
        code: '15-5812',
      },
      {
        name: 'Hemlock',
        main: 'green',
        hex: '97C1A1',
        code: '15-6114',
      },
      {
        name: 'Green Ash',
        main: 'yellow',
        hex: 'A0DAA9',
        code: '13-0117',
      },
      {
        name: 'Paradise Green',
        main: 'yellow',
        hex: 'B2E79F',
        code: '13-0220',
      },
      {
        name: 'Greengage',
        main: 'yellow',
        hex: '8BC28C',
        code: '14-0127',
      },
      {
        name: 'Summer Green',
        main: 'yellow',
        hex: '7FD37F',
        code: '14-0156',
      },
      {
        name: 'Spring Bouquet',
        main: 'yellow',
        hex: '6DCE87',
        code: '14-6340',
      },
      {
        name: 'Spring Bud',
        main: 'yellow',
        hex: '6BCD9C',
        code: '14-6330',
      },
    ],
  },
  {
    _id: 'Platinum',
    colors: [
      {
        name: 'Blanc de Blanc',
        main: 'white',
        hex: 'E7E9E7',
        code: '11-4800',
      },
      {
        name: 'Stalactite',
        main: 'white',
        hex: 'F7F6F3',
        code: '11-4101',
      },
      {
        name: 'Dawn Blue',
        main: 'gray',
        hex: 'CACCCB',
        code: '13-4303',
      },
      {
        name: 'Nimbus Cloud',
        main: 'gray',
        hex: 'D5D5D8',
        code: '13-4108',
      },
      {
        name: 'Vaporous Gray',
        main: 'gray',
        hex: 'DFDDD7',
        code: '12-4302',
      },
    ],
  },
  {
    _id: 'Plum ',
    colors: [
      {
        name: 'Purple Ash',
        main: 'blue',
        hex: '8F8395',
        code: '17-3810',
      },
      {
        name: 'Purple Sage',
        main: 'blue',
        hex: '76697E',
        code: '18-3712',
      },
      {
        name: 'Montana Grape',
        main: 'blue',
        hex: '6C5971',
        code: '18-3715',
      },
      {
        name: 'Cadet',
        main: 'blue',
        hex: '6A6378',
        code: '18-3812',
      },
      {
        name: 'Grape Compote',
        main: 'blue',
        hex: '6B5876',
        code: '18-3513',
      },
      {
        name: 'Mulled Grape',
        main: 'blue',
        hex: '675A74',
        code: '18-3714',
      },
      {
        name: 'Twilight Purple',
        main: 'blue',
        hex: '66648B',
        code: '18-3820',
      },
      {
        name: 'Heron',
        main: 'blue',
        hex: '62617E',
        code: '18-3817',
      },
      {
        name: 'Patrician Purple',
        main: 'purple',
        hex: '6C4E79',
        code: '18-3518',
      },
      {
        name: 'Purple Heart',
        main: 'purple',
        hex: '745587',
        code: '18-3520',
      },
      {
        name: 'Crushed Grape',
        main: 'purple',
        hex: '7A547F',
        code: '18-3522',
      },
      {
        name: 'Mystical',
        main: 'purple',
        hex: '5F4E72',
        code: '18-3620',
      },
      {
        name: 'Vintage Violet',
        main: 'purple',
        hex: '634F62',
        code: '18-3410',
      },
      {
        name: 'Plum Jam',
        main: 'purple',
        hex: '634177',
        code: '18-3521',
      },
      {
        name: 'Purple Sapphire',
        main: 'purple',
        hex: '6F4785',
        code: '18-3540',
      },
      {
        name: 'Imperial Palace',
        main: 'purple',
        hex: '604E7A',
        code: '18-3615',
      },
      {
        name: 'Grape Jam',
        main: 'purple',
        hex: '725671',
        code: '18-3415',
      },
      {
        name: 'Royal Lilac',
        main: 'purple',
        hex: '774D8E',
        code: '18-3531',
      },
      {
        name: 'Dewberry',
        main: 'purple',
        hex: '8B5897',
        code: '18-3533',
      },
      {
        name: 'Picasso Lily',
        main: 'purple',
        hex: '644878',
        code: '18-3635',
      },
      {
        name: 'Purple Haze',
        main: 'purple',
        hex: '817396',
        code: '18-3718',
      },
      {
        name: 'Shadow Purple',
        main: 'purple',
        hex: '4E334E',
        code: '19-3217',
      },
      {
        name: 'Blackberry Cordial',
        main: 'purple',
        hex: '402A47',
        code: '19-3520',
      },
      {
        name: 'Loganberry',
        main: 'purple',
        hex: '5A4769',
        code: '19-3622',
      },
      {
        name: 'Deep Purple',
        main: 'purple',
        hex: '50314C',
        code: '19-3323',
      },
      {
        name: 'Spiced Plum',
        main: 'purple',
        hex: '6E4874',
        code: '19-3425',
      },
      {
        name: 'Purple Pennant',
        main: 'purple',
        hex: '432C47',
        code: '19-3519',
      },
      {
        name: 'Amaranth Purple',
        main: 'purple',
        hex: '6A397B',
        code: '19-3536',
      },
      {
        name: 'Pansy',
        main: 'purple',
        hex: '653D7C',
        code: '19-3542',
      },
      {
        name: 'Sweet Grape',
        main: 'purple',
        hex: '4B3B4F',
        code: '19-3619',
      },
      {
        name: 'Acai',
        main: 'purple',
        hex: '46295A',
        code: '19-3628',
      },
      {
        name: 'Petunia',
        main: 'purple',
        hex: '4F3466',
        code: '19-3632',
      },
      {
        name: 'Navy Cosmos',
        main: 'purple',
        hex: '503B53',
        code: '19-3714',
      },
      {
        name: 'Wineberry',
        main: 'purple',
        hex: '5A395B',
        code: '19-2814',
      },
      {
        name: 'Purple Passion',
        main: 'purple',
        hex: '683D62',
        code: '19-3223',
      },
      {
        name: 'Plum Perfect',
        main: 'purple',
        hex: '473442',
        code: '19-3316',
      },
      {
        name: 'Majesty',
        main: 'purple',
        hex: '593761',
        code: '19-3514',
      },
      {
        name: 'Purple Magic',
        main: 'purple',
        hex: '663271',
        code: '19-3540',
      },
      {
        name: 'Nightshade',
        main: 'purple',
        hex: '433748',
        code: '19-3712',
      },
      {
        name: 'Gentian Violet',
        main: 'purple',
        hex: '544275',
        code: '19-3730',
      },
      {
        name: 'Indigo',
        main: 'purple',
        hex: '4C3957',
        code: '19-3215',
      },
      {
        name: 'Plum Purple',
        main: 'purple',
        hex: '51304E',
        code: '19-3218',
      },
      {
        name: 'Plum',
        main: 'purple',
        hex: '5A315D',
        code: '19-3220',
      },
      {
        name: 'Wood Violet',
        main: 'purple',
        hex: '75406A',
        code: '19-3325',
      },
      {
        name: 'Sunset Purple',
        main: 'purple',
        hex: '6F456E',
        code: '19-3424',
      },
      {
        name: 'Grape Royale',
        main: 'purple',
        hex: '4F2D54',
        code: '19-3518',
      },
      {
        name: 'Imperial Purple',
        main: 'purple',
        hex: '542B5D',
        code: '19-3528',
      },
      {
        name: 'Purple Reign',
        main: 'purple',
        hex: '56456B',
        code: '19-3620',
      },
      {
        name: 'Tillandsia Purple',
        main: 'purple',
        hex: '563474',
        code: '19-3638',
      },
      {
        name: 'Crown Jewel',
        main: 'purple',
        hex: '482D54',
        code: '19-3640',
      },
      {
        name: 'Purple Plumeria',
        main: 'purple',
        hex: '473854',
        code: '19-3716',
      },
      {
        name: 'Gothic Grape',
        main: 'purple',
        hex: '473951',
        code: '19-3720',
      },
      {
        name: 'Mulberry Purple',
        main: 'purple',
        hex: '493C61',
        code: '19-3722',
      },
      {
        name: 'Purple Velvet',
        main: 'purple',
        hex: '41354D',
        code: '19-3725',
      },
      {
        name: 'Grape',
        main: 'purple',
        hex: '433455',
        code: '19-3728',
      },
      {
        name: 'Heliotrope',
        main: 'purple',
        hex: '4F3872',
        code: '19-3737',
      },
      {
        name: 'Prism Violet',
        main: 'purple',
        hex: '543D7D',
        code: '19-3748',
      },
      {
        name: 'Violet Indigo',
        main: 'purple',
        hex: '3E295C',
        code: '19-3750',
      },
      {
        name: 'Parachute Purple',
        main: 'blue',
        hex: '392852',
        code: '19-3731',
      },
    ],
  },
  {
    _id: 'Powder Blue',
    colors: [
      {
        name: 'Sheer Blue',
        main: 'blue',
        hex: 'B1BFD1',
        code: '14-4111',
      },
      {
        name: 'Dutch Canal',
        main: 'blue',
        hex: '96BAE0',
        code: '14-4124',
      },
      {
        name: 'Subdued Blue',
        main: 'blue',
        hex: 'A4B4C6',
        code: '14-4209',
      },
      {
        name: 'Powder Blue',
        main: 'blue',
        hex: '96B3D2',
        code: '14-4214',
      },
      {
        name: 'Kentucky Blue',
        main: 'blue',
        hex: 'A5B3CC',
        code: '15-3915',
      },
      {
        name: 'Open Air',
        main: 'blue',
        hex: '94B2DF',
        code: '15-3922',
      },
      {
        name: 'Fairview Blue',
        main: 'blue',
        hex: '8BA1C3',
        code: '15-3931',
      },
      {
        name: 'Windsurfer',
        main: 'blue',
        hex: 'A3BBE0',
        code: '15-4031',
      },
      {
        name: 'Lavender Lustre',
        main: 'blue',
        hex: '8C9CC0',
        code: '16-3920',
      },
      {
        name: 'Brunnera Blue',
        main: 'blue',
        hex: '9BA9CA',
        code: '16-3922',
      },
      {
        name: 'Dusty Blue',
        main: 'blue',
        hex: '8C9DAD',
        code: '16-4010',
      },
      {
        name: 'Allure',
        main: 'blue',
        hex: '7291B4',
        code: '16-4021',
      },
      {
        name: 'Glacier Lake',
        main: 'blue',
        hex: '81A0BA',
        code: '16-4118',
      },
      {
        name: 'Zen Blue',
        main: 'blue',
        hex: 'A0AABF',
        code: '14-3912',
      },
      {
        name: 'Floaty Blue',
        main: 'blue',
        hex: 'AFC3DD',
        code: '14-4015',
      },
      {
        name: 'Polaris Star',
        main: 'blue',
        hex: '90A9CE',
        code: '15-3926',
      },
      {
        name: 'Vista Blue',
        main: 'blue',
        hex: '80A0D4',
        code: '15-3930',
      },
      {
        name: 'Forget-Me-Not',
        main: 'blue',
        hex: '8FADBD',
        code: '15-4312',
      },
      {
        name: 'Eventide',
        main: 'blue',
        hex: '959FB7',
        code: '16-3919',
      },
      {
        name: 'Della Robbia Blue',
        main: 'blue',
        hex: '7A9DCB',
        code: '16-4020',
      },
      {
        name: 'Azurine',
        main: 'blue',
        hex: '7291C1',
        code: '16-4029',
      },
      {
        name: 'Mountain Bluebird',
        main: 'blue',
        hex: '758FBF',
        code: '16-4039',
      },
      {
        name: 'Dusk Blue',
        main: 'blue',
        hex: '7BA0C0',
        code: '16-4120',
      },
      {
        name: 'Crystal Blue',
        main: 'blue',
        hex: 'A1C8DB',
        code: '13-4411',
      },
      {
        name: 'Heather',
        main: 'blue',
        hex: 'B7C0D6',
        code: '14-4110',
      },
      {
        name: 'Blue Bell',
        main: 'blue',
        hex: '93B4D7',
        code: '14-4121',
      },
      {
        name: 'Airy Blue',
        main: 'blue',
        hex: '93B7D5',
        code: '14-4122',
      },
      {
        name: 'Celestial Blue',
        main: 'blue',
        hex: 'A3B4C4',
        code: '14-4210',
      },
      {
        name: 'Winter Sky',
        main: 'blue',
        hex: 'A9C0CB',
        code: '14-4307',
      },
      {
        name: 'Sky Blue',
        main: 'blue',
        hex: '8ABAD3',
        code: '14-4318',
      },
      {
        name: 'Cosmic Sky',
        main: 'blue',
        hex: 'AAAAC4',
        code: '15-3909',
      },
      {
        name: 'Serenity',
        main: 'blue',
        hex: '93A9D1',
        code: '15-3919',
      },
      {
        name: 'Placid Blue',
        main: 'blue',
        hex: '8CACD3',
        code: '15-3920',
      },
      {
        name: 'Ashley Blue',
        main: 'blue',
        hex: '8699AB',
        code: '16-4013',
      },
      {
        name: 'Soft Chambray',
        main: 'blue',
        hex: '91A5BE',
        code: '16-4016',
      },
      {
        name: 'Forever Blue',
        main: 'blue',
        hex: '899BB8',
        code: '16-4019',
      },
      {
        name: 'Rain Washed',
        main: 'blue',
        hex: '8192AB',
        code: '16-4023',
      },
      {
        name: 'Hydrangea',
        main: 'blue',
        hex: '849BCC',
        code: '16-4030',
      },
      {
        name: 'Xenon Blue',
        main: 'blue',
        hex: 'B7C0D7',
        code: '14-3949',
      },
      {
        name: 'Clear Sky',
        main: 'blue',
        hex: '9BBCD8',
        code: '14-4123',
      },
      {
        name: 'Niagara Mist',
        main: 'blue',
        hex: 'ABB9C9',
        code: '14-4211',
      },
      {
        name: 'Icelandic Blue',
        main: 'blue',
        hex: 'AAADC2',
        code: '15-3908',
      },
      {
        name: 'Frozen Fjord',
        main: 'blue',
        hex: '89A5CB',
        code: '15-3917',
      },
      {
        name: 'Bel Air Blue',
        main: 'blue',
        hex: '819AC1',
        code: '15-3932',
      },
      {
        name: 'Dream Blue',
        main: 'blue',
        hex: 'A0BCD0',
        code: '15-4005',
      },
      {
        name: 'Blue Fog',
        main: 'blue',
        hex: '9BABBB',
        code: '15-4008',
      },
      {
        name: 'Blue Blizzard',
        main: 'blue',
        hex: '939EB3',
        code: '15-4009',
      },
      {
        name: 'Chambray Blue',
        main: 'blue',
        hex: '9EB4D3',
        code: '15-4030',
      },
      {
        name: 'Blue Heron',
        main: 'blue',
        hex: '96A3C7',
        code: '16-3921',
      },
      {
        name: 'Endless Sky',
        main: 'blue',
        hex: '7E95B5',
        code: '16-4022',
      },
      {
        name: 'Arona',
        main: 'blue',
        hex: '879BA3',
        code: '16-4109',
      },
      {
        name: 'Blissful Blue',
        main: 'blue',
        hex: '7198BB',
        code: '16-4121',
      },
      {
        name: 'Tempest',
        main: 'blue',
        hex: '79839B',
        code: '17-3915',
      },
      {
        name: 'Stonewash',
        main: 'blue',
        hex: '74809A',
        code: '17-3917',
      },
      {
        name: 'Purple Impression',
        main: 'blue',
        hex: '858FB1',
        code: '17-3919',
      },
      {
        name: 'Faded Denim',
        main: 'blue',
        hex: '798EA4',
        code: '17-4021',
      },
      {
        name: 'Troposphere',
        main: 'blue',
        hex: '76849B',
        code: '17-3926',
      },
      {
        name: 'Tourmaline',
        main: 'blue',
        hex: '86A1A9',
        code: '16-4411',
      },
      {
        name: 'Country Blue',
        main: 'blue',
        hex: '717F9B',
        code: '17-3918',
      },
      {
        name: 'Mountain Spring',
        main: 'blue',
        hex: '7A92A5',
        code: '17-4019',
      },
      {
        name: 'Blue Granite',
        main: 'blue',
        hex: '717388',
        code: '18-3933',
      },
      {
        name: 'Languid Lavender',
        main: 'blue',
        hex: 'A2A1BA',
        code: '15-3910',
      },
      {
        name: 'Easter Egg',
        main: 'blue',
        hex: '919BC9',
        code: '16-3925',
      },
      {
        name: 'Evening Haze',
        main: 'blue',
        hex: 'BDB8C7',
        code: '14-3904',
      },
      {
        name: 'Grapemist',
        main: 'blue',
        hex: '8398CA',
        code: '16-3929',
      },
      {
        name: 'Aleutian',
        main: 'blue',
        hex: '9A9EB3',
        code: '15-3912',
      },
      {
        name: 'Smoke Blue',
        main: 'blue',
        hex: '6D8994',
        code: '17-4412',
      },
      {
        name: 'Windward Blue',
        main: 'blue',
        hex: '708497',
        code: '17-4018',
      },
      {
        name: 'Stone Blue',
        main: 'blue',
        hex: '829CA5',
        code: '16-4114',
      },
      {
        name: 'Mineral Blue',
        main: 'blue',
        hex: '6D9192',
        code: '16-4712',
      },
      {
        name: 'Ether',
        main: 'blue',
        hex: '9EB6B8',
        code: '14-4506',
      },
      {
        name: 'Aquifer',
        main: 'blue',
        hex: '89ACAC',
        code: '15-5207',
      },
      {
        name: 'Cameo Blue',
        main: 'blue',
        hex: '769DA6',
        code: '16-4414',
      },
      {
        name: 'Blue Surf',
        main: 'blue',
        hex: '90A8A4',
        code: '16-5106',
      },
    ],
  },
  {
    _id: 'Pumpkin',
    colors: [
      {
        name: 'Banana',
        main: 'orange',
        hex: 'FCB953',
        code: '13-0947',
      },
      {
        name: 'Old Gold',
        main: 'orange',
        hex: 'ECA825',
        code: '15-0955',
      },
      {
        name: 'Dark Cheddar',
        main: 'orange',
        hex: 'E08119',
        code: '15-1150',
      },
      {
        name: 'Flame Orange',
        main: 'orange',
        hex: 'FB8B23',
        code: '15-1157',
      },
      {
        name: 'Autumn Glory',
        main: 'orange',
        hex: 'FF8813',
        code: '15-1263',
      },
      {
        name: 'Turmeric',
        main: 'orange',
        hex: 'FF850F',
        code: '15-1264',
      },
      {
        name: 'Saffron',
        main: 'orange',
        hex: 'FFA500',
        code: '14-1064',
      },
      {
        name: 'Autumn Blaze',
        main: 'orange',
        hex: 'D9932F',
        code: '15-1045',
      },
      {
        name: 'Apricot',
        main: 'orange',
        hex: 'F19035',
        code: '15-1153',
      },
      {
        name: 'Carrot Curl',
        main: 'orange',
        hex: 'FF8D19',
        code: '15-1262',
      },
      {
        name: 'Flax',
        main: 'orange',
        hex: 'FFC87D',
        code: '13-0935',
      },
      {
        name: 'Citrus',
        main: 'orange',
        hex: 'F9AC2F',
        code: '14-0955',
      },
      {
        name: 'Zinnia',
        main: 'orange',
        hex: 'FFA00D',
        code: '14-1159',
      },
      {
        name: 'Blazing Orange',
        main: 'orange',
        hex: 'FFA74F',
        code: '15-1160',
      },
      {
        name: 'Bright Marigold',
        main: 'orange',
        hex: 'FF8D00',
        code: '15-1164',
      },
      {
        name: 'Tangelo',
        main: 'orange',
        hex: 'FF7E04',
        code: '15-1335',
      },
      {
        name: "Artisan's Gold",
        main: 'orange',
        hex: 'F2AB46',
        code: '15-1049',
      },
      {
        name: 'Cadmium Yellow',
        main: 'orange',
        hex: 'EE9626',
        code: '15-1054',
      },
      {
        name: 'Radiant Yellow',
        main: 'orange',
        hex: 'FC9E21',
        code: '15-1058',
      },
      {
        name: 'Gold Fusion',
        main: 'orange',
        hex: 'FFB000',
        code: '15-1062',
      },
      {
        name: 'Iceland Poppy',
        main: 'orange',
        hex: 'F4973B',
        code: '15-1151',
      },
      {
        name: 'Orange Pepper',
        main: 'orange',
        hex: 'DF7500',
        code: '16-1164',
      },
      {
        name: 'Oriole',
        main: 'orange',
        hex: 'FF7913',
        code: '17-1350',
      },
    ],
  },
  {
    _id: 'Rose ',
    colors: [
      {
        name: 'Shrinking Violet',
        main: 'pink',
        hex: 'F4E1E6',
        code: '11-2511',
      },
      {
        name: 'Pastel Rose Tan',
        main: 'pink',
        hex: 'E9D1BF',
        code: '12-1007',
      },
      {
        name: 'Petal Pink',
        main: 'pink',
        hex: 'F2E2E0',
        code: '11-2309',
      },
      {
        name: 'Raindrops on Roses',
        main: 'pink',
        hex: 'ECD8DC',
        code: '11-1400',
      },
      {
        name: 'Crystal Pink',
        main: 'pink',
        hex: 'EDD0CE',
        code: '12-1605',
      },
      {
        name: 'Pink Dogwood',
        main: 'pink',
        hex: 'F7D1D1',
        code: '12-1706',
      },
      {
        name: 'Pinkesque',
        main: 'pink',
        hex: 'F7C9CF',
        code: '12-1808',
      },
      {
        name: 'Mauve Chalk',
        main: 'pink',
        hex: 'E5D0CF',
        code: '12-2902',
      },
      {
        name: 'Primrose Pink',
        main: 'pink',
        hex: 'EED4D9',
        code: '12-2904',
      },
      {
        name: 'Rose Quartz',
        main: 'pink',
        hex: 'F7CACA',
        code: '13-1520',
      },
      {
        name: 'Strawberry Cream',
        main: 'pink',
        hex: 'F4C3C4',
        code: '13-2005',
      },
      {
        name: 'Orchid Pink',
        main: 'pink',
        hex: 'F3BBCA',
        code: '13-2010',
      },
      {
        name: 'Pink Lady',
        main: 'pink',
        hex: 'EFC1D6',
        code: '13-2806',
      },
      {
        name: 'Tickled Pink',
        main: 'pink',
        hex: 'F7B9C2',
        code: '14-1910',
      },
      {
        name: 'Veiled Rose',
        main: 'pink',
        hex: 'F8CDC9',
        code: '12-1212',
      },
      {
        name: 'Pearl',
        main: 'pink',
        hex: 'F9DBD8',
        code: '12-1304',
      },
      {
        name: 'Icy Pink',
        main: 'pink',
        hex: 'FBD3D9',
        code: '12-1310',
      },
      {
        name: 'Barely Pink',
        main: 'pink',
        hex: 'F8D7DD',
        code: '12-2906',
      },
      {
        name: 'Blushing Rose',
        main: 'pink',
        hex: 'F7C0BF',
        code: '13-2003',
      },
      {
        name: 'Pink-a-boo',
        main: 'pink',
        hex: 'F7CDDB',
        code: '13-2801',
      },
      {
        name: 'Fairy Tale',
        main: 'pink',
        hex: 'F2C1D1',
        code: '13-2802',
      },
      {
        name: 'Ballet Slipper',
        main: 'pink',
        hex: 'ECCED5',
        code: '13-2808',
      },
      {
        name: 'Heavenly Pink',
        main: 'pink',
        hex: 'F4DEDE',
        code: '12-1305',
      },
      {
        name: 'Crystal Rose',
        main: 'pink',
        hex: 'FDC3C6',
        code: '12-1708',
      },
      {
        name: "Mary's Rose",
        main: 'pink',
        hex: 'F8D1D4',
        code: '12-1813',
      },
      {
        name: 'Tender Touch',
        main: 'pink',
        hex: 'F3D4DF',
        code: '12-2807',
      },
      {
        name: 'Pink Marshmallow',
        main: 'pink',
        hex: 'F8E0E8',
        code: '12-2907',
      },
      {
        name: 'Festival Bloom',
        main: 'pink',
        hex: 'F5D6E6',
        code: '12-2908',
      },
      {
        name: 'English Rose',
        main: 'pink',
        hex: 'F4C6C3',
        code: '13-1310',
      },
      {
        name: 'Almond Blossom',
        main: 'pink',
        hex: 'F5BEC7',
        code: '13-2006',
      },
      {
        name: 'Rosy Touch',
        main: 'pink',
        hex: 'EFBBBE',
        code: '13-2009',
      },
      {
        name: 'Flushing Pink',
        main: 'pink',
        hex: 'FCCCDC',
        code: '13-2705',
      },
      {
        name: 'Parfait Pink',
        main: 'pink',
        hex: 'E9C3CF',
        code: '13-2804',
      },
      {
        name: 'Ballerina',
        main: 'pink',
        hex: 'F2CFDC',
        code: '13-2807',
      },
      {
        name: 'Sweet Dreams',
        main: 'pink',
        hex: 'EFBED4',
        code: '13-2809',
      },
      {
        name: 'Pirouette',
        main: 'pink',
        hex: 'EDBEDC',
        code: '14-3205',
      },
      {
        name: 'Carinaria',
        main: 'pink',
        hex: 'F4DDDF',
        code: '12-1303',
      },
      {
        name: 'Pale Rosette',
        main: 'pink',
        hex: 'FFBBB9',
        code: '13-1716',
      },
      {
        name: 'Chalk Pink',
        main: 'pink',
        hex: 'E6C5CA',
        code: '13-1904',
      },
      {
        name: 'Rose Shadow',
        main: 'pink',
        hex: 'F8C1CC',
        code: '13-1906',
      },
      {
        name: 'Roseate Spoonbill',
        main: 'pink',
        hex: 'E6BCCD',
        code: '13-2805',
      },
      {
        name: 'Cherry Blossom',
        main: 'pink',
        hex: 'F7CFE1',
        code: '13-3207',
      },
      {
        name: 'Nosegay',
        main: 'pink',
        hex: 'F0BDD5',
        code: '14-2806',
      },
      {
        name: 'Sweet Lilac',
        main: 'pink',
        hex: 'E8B5CE',
        code: '14-2808',
      },
      {
        name: 'Corsage',
        main: 'pink',
        hex: 'DBB4C8',
        code: '14-3203',
      },
      {
        name: 'Sweet Taffy',
        main: 'purple',
        hex: 'E4CCD6',
        code: '12-2900',
      },
    ],
  },
  {
    _id: 'Royal Blue',
    colors: [
      {
        name: 'River Blue',
        main: 'blue',
        hex: '38AECD',
        code: '15-4720',
      },
      {
        name: 'Ethereal Blue',
        main: 'blue',
        hex: '5CA6CE',
        code: '15-4323',
      },
      {
        name: 'Crystal Seas',
        main: 'blue',
        hex: '5EB0CF',
        code: '15-4428',
      },
      {
        name: 'Cornflower Blue',
        main: 'blue',
        hex: '7391C8',
        code: '16-4031',
      },
      {
        name: 'Provence',
        main: 'blue',
        hex: '658DC6',
        code: '16-4032',
      },
      {
        name: 'Blue Grotto',
        main: 'blue',
        hex: '5DACCE',
        code: '15-4421',
      },
      {
        name: 'Norse Blue',
        main: 'blue',
        hex: '4CA5C7',
        code: '15-4427',
      },
      {
        name: 'Granada Sky',
        main: 'blue',
        hex: '5D81BC',
        code: '16-4033',
      },
      {
        name: 'Heritage Blue',
        main: 'blue',
        hex: '5D96BC',
        code: '16-4127',
      },
      {
        name: 'Little Boy Blue',
        main: 'blue',
        hex: '6EA1D4',
        code: '16-4132',
      },
      {
        name: 'Swim Cap',
        main: 'blue',
        hex: '38A4D0',
        code: '16-4526',
      },
      {
        name: 'Bonnie Blue',
        main: 'blue',
        hex: '539CCC',
        code: '16-4134',
      },
      {
        name: 'Maui Blue',
        main: 'blue',
        hex: '52A2B4',
        code: '16-4525',
      },
      {
        name: 'Very Peri',
        main: 'blue',
        hex: '6667AB',
        code: '17-3938',
      },
      {
        name: 'Azure Blue',
        main: 'blue',
        hex: '4D91C6',
        code: '17-4139',
      },
      {
        name: 'All Aboard',
        main: 'blue',
        hex: '5C97CB',
        code: '17-4140',
      },
      {
        name: 'Ibiza Blue',
        main: 'blue',
        hex: '007CB8',
        code: '17-4245',
      },
      {
        name: 'Blithe',
        main: 'blue',
        hex: '0084BD',
        code: '17-4336',
      },
      {
        name: 'Hawaiian Ocean',
        main: 'blue',
        hex: '008DB9',
        code: '17-4540',
      },
      {
        name: 'Corsican Blue',
        main: 'blue',
        hex: '646093',
        code: '18-3828',
      },
      {
        name: 'Amparo Blue',
        main: 'blue',
        hex: '4A5FA5',
        code: '18-3945',
      },
      {
        name: 'Baja Blue',
        main: 'blue',
        hex: '5F6EAE',
        code: '18-3946',
      },
      {
        name: 'Victoria Blue',
        main: 'blue',
        hex: '09589D',
        code: '18-4148',
      },
      {
        name: 'Indigo Bunting',
        main: 'blue',
        hex: '006CA9',
        code: '18-4250',
      },
      {
        name: 'Blue Jewel',
        main: 'blue',
        hex: '0079A7',
        code: '18-4535',
      },
      {
        name: 'Methyl Blue',
        main: 'blue',
        hex: '0074A8',
        code: '18-4537',
      },
      {
        name: 'Lapis Blue',
        main: 'blue',
        hex: '004C8E',
        code: '19-4045',
      },
      {
        name: 'Skydiver',
        main: 'blue',
        hex: '00589B',
        code: '19-4151',
      },
      {
        name: 'Blue Bonnet',
        main: 'blue',
        hex: '6384B8',
        code: '17-3936',
      },
      {
        name: 'Lichen Blue',
        main: 'blue',
        hex: '5D89B4',
        code: '17-4032',
      },
      {
        name: 'Pacific Coast',
        main: 'blue',
        hex: '5580AD',
        code: '17-4033',
      },
      {
        name: 'Cendre Blue',
        main: 'blue',
        hex: '3F7FA5',
        code: '17-4131',
      },
      {
        name: 'Diva Blue',
        main: 'blue',
        hex: '007BB2',
        code: '17-4247',
      },
      {
        name: 'Vivid Blue',
        main: 'blue',
        hex: '0088B0',
        code: '17-4432',
      },
      {
        name: 'Blue Danube',
        main: 'blue',
        hex: '0087B6',
        code: '17-4440',
      },
      {
        name: 'Wedgewood',
        main: 'blue',
        hex: '6479B3',
        code: '18-3935',
      },
      {
        name: 'Ebb and Flow',
        main: 'blue',
        hex: '5E79A8',
        code: '18-3936',
      },
      {
        name: 'Regatta',
        main: 'blue',
        hex: '497AB7',
        code: '18-4039',
      },
      {
        name: 'Super Sonic',
        main: 'blue',
        hex: '3073B7',
        code: '18-4143',
      },
      {
        name: 'Swedish Blue',
        main: 'blue',
        hex: '007EB1',
        code: '18-4330',
      },
      {
        name: 'Hawaiian Surf',
        main: 'blue',
        hex: '0079A8',
        code: '18-4538',
      },
      {
        name: 'Royal Blue',
        main: 'blue',
        hex: '3E428B',
        code: '19-3955',
      },
      {
        name: 'Blue Iolite',
        main: 'blue',
        hex: '1A4C8B',
        code: '19-4056',
      },
      {
        name: 'Blue Mist',
        main: 'blue',
        hex: '5BACC3',
        code: '16-4421',
      },
      {
        name: 'Cyan Blue',
        main: 'blue',
        hex: '15A3C7',
        code: '16-4529',
      },
      {
        name: 'Persian Jewel',
        main: 'blue',
        hex: '6E81BE',
        code: '17-3934',
      },
      {
        name: 'Silver Lake Blue',
        main: 'blue',
        hex: '618BB9',
        code: '17-4030',
      },
      {
        name: 'Dresden Blue',
        main: 'blue',
        hex: '0086BB',
        code: '17-4433',
      },
      {
        name: 'Malibu Blue',
        main: 'blue',
        hex: '008CC1',
        code: '17-4435',
      },
      {
        name: 'Dazzling Blue',
        main: 'blue',
        hex: '3950A0',
        code: '18-3949',
      },
      {
        name: 'Blue Jasper',
        main: 'blue',
        hex: '527EA9',
        code: '18-4033',
      },
      {
        name: 'Palace Blue',
        main: 'blue',
        hex: '356CB0',
        code: '18-4043',
      },
      {
        name: 'Daphne',
        main: 'blue',
        hex: '0F5F9A',
        code: '18-4045',
      },
      {
        name: 'Nebulas Blue',
        main: 'blue',
        hex: '2E62A3',
        code: '18-4048',
      },
      {
        name: 'French Blue',
        main: 'blue',
        hex: '0072B5',
        code: '18-4140',
      },
      {
        name: 'Directoire Blue',
        main: 'blue',
        hex: '0061A3',
        code: '18-4244',
      },
      {
        name: 'Mediterranian Blue',
        main: 'blue',
        hex: '1578A7',
        code: '18-4334',
      },
      {
        name: 'Deep Ultramarine',
        main: 'blue',
        hex: '384883',
        code: '19-3950',
      },
      {
        name: 'Nautical Blue',
        main: 'blue',
        hex: '1B5091',
        code: '19-4050',
      },
      {
        name: 'Turkish Sea',
        main: 'blue',
        hex: '1A5190',
        code: '19-4053',
      },
      {
        name: 'Princess Blue',
        main: 'blue',
        hex: '00539C',
        code: '19-4150',
      },
      {
        name: 'Horizon Blue',
        main: 'blue',
        hex: '289DBE',
        code: '16-4427',
      },
      {
        name: 'Blue Atoll',
        main: 'blue',
        hex: '00B1D2',
        code: '16-4535',
      },
      {
        name: 'Ultramarine',
        main: 'blue',
        hex: '5B7EBD',
        code: '17-4037',
      },
      {
        name: 'Marina',
        main: 'blue',
        hex: '5085C3',
        code: '17-4041',
      },
      {
        name: 'Bluejay',
        main: 'blue',
        hex: '167EA0',
        code: '17-4427',
      },
      {
        name: 'Navagio Bay',
        main: 'blue',
        hex: '3284A1',
        code: '17-4429',
      },
      {
        name: 'Blue Yonder',
        main: 'blue',
        hex: '5A77A8',
        code: '18-3937',
      },
      {
        name: 'Blue Iris',
        main: 'blue',
        hex: '5A5B9F',
        code: '18-3943',
      },
      {
        name: 'Violet Storm',
        main: 'blue',
        hex: '5C619D',
        code: '18-3944',
      },
      {
        name: 'Iris Bloom',
        main: 'blue',
        hex: '5C609F',
        code: '18-3950',
      },
      {
        name: 'Cyaneus',
        main: 'blue',
        hex: '557EA2',
        code: '18-4035',
      },
      {
        name: 'Strong Blue',
        main: 'blue',
        hex: '1F5DA0',
        code: '18-4051',
      },
      {
        name: 'Campanula',
        main: 'blue',
        hex: '3272AF',
        code: '18-4141',
      },
      {
        name: 'Brilliant Blue',
        main: 'blue',
        hex: '0075B3',
        code: '18-4247',
      },
      {
        name: 'Blue Aster',
        main: 'blue',
        hex: '0077B3',
        code: '18-4252',
      },
      {
        name: 'Cloisonné',
        main: 'blue',
        hex: '0075AF',
        code: '18-4440',
      },
      {
        name: 'Deep Wisteria',
        main: 'blue',
        hex: '453F6F',
        code: '19-3842',
      },
      {
        name: 'Deep Blue',
        main: 'blue',
        hex: '44377B',
        code: '19-3847',
      },
      {
        name: 'Liberty',
        main: 'blue',
        hex: '4E4489',
        code: '19-3850',
      },
      {
        name: 'Orient Blue',
        main: 'blue',
        hex: '48457A',
        code: '19-3947',
      },
    ],
  },
  {
    _id: 'Rust',
    colors: [
      {
        name: 'Topaz',
        main: 'orange',
        hex: 'D08344',
        code: '16-1150',
      },
      {
        name: 'Orange Ochre',
        main: 'orange',
        hex: 'DC793A',
        code: '16-1253',
      },
      {
        name: 'Buckskin',
        main: 'orange',
        hex: 'D18E54',
        code: '16-1342',
      },
      {
        name: 'Peach Caramel',
        main: 'orange',
        hex: 'C6733D',
        code: '16-1347',
      },
      {
        name: 'Harvest Pumpkin',
        main: 'orange',
        hex: 'D56231',
        code: '16-1260',
      },
      {
        name: 'Copper',
        main: 'orange',
        hex: 'C47E5A',
        code: '16-1325',
      },
      {
        name: 'Pheasant',
        main: 'orange',
        hex: 'C68463',
        code: '16-1332',
      },
      {
        name: 'Butterum',
        main: 'orange',
        hex: 'C68F65',
        code: '16-1341',
      },
      {
        name: 'Yam',
        main: 'orange',
        hex: 'D0893F',
        code: '16-1140',
      },
      {
        name: 'Desert Sun',
        main: 'orange',
        hex: 'C87629',
        code: '16-1149',
      },
      {
        name: 'Copper Tan',
        main: 'orange',
        hex: 'DE8E65',
        code: '16-1338',
      },
      {
        name: 'Brandied Melon',
        main: 'orange',
        hex: 'CE7B5B',
        code: '16-1340',
      },
      {
        name: 'Golden Ochre',
        main: 'orange',
        hex: 'C77943',
        code: '16-1346',
      },
      {
        name: 'Tomato Cream',
        main: 'orange',
        hex: 'C57644',
        code: '16-1348',
      },
      {
        name: 'Clay',
        main: 'orange',
        hex: 'D2A172',
        code: '15-1231',
      },
      {
        name: 'Oak Buff',
        main: 'orange',
        hex: 'CF9C63',
        code: '16-1144',
      },
      {
        name: 'Nugget',
        main: 'orange',
        hex: 'CF8948',
        code: '16-1148',
      },
      {
        name: 'Toast',
        main: 'orange',
        hex: 'CA9978',
        code: '16-1331',
      },
      {
        name: 'Coral Gold',
        main: 'orange',
        hex: 'D27D56',
        code: '16-1337',
      },
      {
        name: 'Sun Baked',
        main: 'orange',
        hex: 'D38063',
        code: '16-1345',
      },
      {
        name: 'Caramel',
        main: 'orange',
        hex: 'C37C54',
        code: '16-1439',
      },
      {
        name: 'Arabesque',
        main: 'orange',
        hex: 'D16F52',
        code: '16-1441',
      },
      {
        name: 'Apricot Buff',
        main: 'orange',
        hex: 'CD7E4D',
        code: '16-1443',
      },
      {
        name: 'Jaffa Orange',
        main: 'orange',
        hex: 'D86E39',
        code: '16-1454',
      },
      {
        name: 'Inca Gold',
        main: 'orange',
        hex: 'BB7A2C',
        code: '17-1048',
      },
      {
        name: 'Marmalade',
        main: 'orange',
        hex: 'C16512',
        code: '17-1140',
      },
      {
        name: 'Spice Route',
        main: 'orange',
        hex: 'BA5B3F',
        code: '17-1345',
      },
      {
        name: 'Apricot Orange',
        main: 'orange',
        hex: 'C96B3C',
        code: '17-1353',
      },
      {
        name: 'Flamingo',
        main: 'orange',
        hex: 'DF7253',
        code: '16-1450',
      },
      {
        name: 'Tawny Orange',
        main: 'orange',
        hex: 'D3806F',
        code: '17-1341',
      },
      {
        name: 'Autumn Leaf',
        main: 'orange',
        hex: 'B5684C',
        code: '17-1347',
      },
      {
        name: 'Ginger',
        main: 'orange',
        hex: 'C96551',
        code: '17-1444',
      },
      {
        name: 'Thai Curry',
        main: 'orange',
        hex: 'AC681A',
        code: '18-1049',
      },
      {
        name: 'Amberglow',
        main: 'orange',
        hex: 'DC793E',
        code: '16-1350',
      },
      {
        name: 'Langoustino',
        main: 'orange',
        hex: 'CA6C56',
        code: '16-1440',
      },
      {
        name: 'Burnt Orange',
        main: 'orange',
        hex: 'C86733',
        code: '16-1448',
      },
      {
        name: 'Gold Flame',
        main: 'orange',
        hex: 'B45422',
        code: '16-1449',
      },
      {
        name: 'Golden Oak',
        main: 'orange',
        hex: 'BE752C',
        code: '17-1046',
      },
      {
        name: 'Mango',
        main: 'orange',
        hex: 'B75E41',
        code: '17-1446',
      },
      {
        name: 'Koi',
        main: 'orange',
        hex: 'D15837',
        code: '17-1452',
      },
      {
        name: 'Honey Ginger',
        main: 'orange',
        hex: 'A96318',
        code: '18-1050',
      },
      {
        name: 'Sudan Brown',
        main: 'orange',
        hex: 'AC6C29',
        code: '18-1160',
      },
      {
        name: 'Carnelian',
        main: 'orange',
        hex: 'CE785D',
        code: '16-1435',
      },
      {
        name: 'Autumn Maple',
        main: 'orange',
        hex: 'C46316',
        code: '17-1145',
      },
      {
        name: 'Leather Brown',
        main: 'orange',
        hex: '97572B',
        code: '18-1142',
      },
      {
        name: 'Pumpkin Spice',
        main: 'orange',
        hex: 'A05C17',
        code: '18-1163',
      },
      {
        name: 'Rust',
        main: 'orange',
        hex: 'B55A30',
        code: '18-1248',
      },
      {
        name: 'Mecca Orange',
        main: 'orange',
        hex: 'BD5745',
        code: '18-1450',
      },
      {
        name: 'Hawaiian Sunset',
        main: 'orange',
        hex: 'BB5C14',
        code: '18-1249',
      },
      {
        name: 'Burnt Brick',
        main: 'orange',
        hex: 'A14D3A',
        code: '18-1350',
      },
      {
        name: 'Orange Rust',
        main: 'orange',
        hex: 'C25A3C',
        code: '18-1447',
      },
      {
        name: 'Cinnabar',
        main: 'orange',
        hex: '9C453B',
        code: '18-1540',
      },
      {
        name: 'Ginger Spice',
        main: 'orange',
        hex: 'B65D4B',
        code: '18-1535',
      },
    ],
  },
  {
    _id: 'Sage',
    colors: [
      {
        name: 'Frosted Mint',
        main: 'gray',
        hex: 'CDD4CB',
        code: '12-5703',
      },
      {
        name: 'Surf Spray',
        main: 'gray',
        hex: 'B4C8C2',
        code: '14-4807',
      },
      {
        name: 'Canary Green',
        main: 'green',
        hex: 'D6DFC9',
        code: '12-0108',
      },
      {
        name: 'Water Lily',
        main: 'green',
        hex: 'DDE3D5',
        code: '11-0304',
      },
      {
        name: 'Icicle',
        main: 'green',
        hex: 'DBDDD1',
        code: '12-5201',
      },
      {
        name: 'Milky Green',
        main: 'green',
        hex: 'CFDBD1',
        code: '12-6205',
      },
      {
        name: "Bird's Egg Green",
        main: 'green',
        hex: 'AACCB8',
        code: '13-5911',
      },
      {
        name: 'Green Tint',
        main: 'green',
        hex: 'C5CCC0',
        code: '13-6106',
      },
      {
        name: 'Subtle Green',
        main: 'green',
        hex: 'B5CBBB',
        code: '14-6008',
      },
      {
        name: 'Gossamer Green',
        main: 'green',
        hex: 'B2CFBE',
        code: '13-5907',
      },
      {
        name: 'Grayed Jade',
        main: 'green',
        hex: '9BBEA9',
        code: '14-6011',
      },
      {
        name: 'Bok Choy',
        main: 'green',
        hex: 'BCCAB3',
        code: '13-6208',
      },
      {
        name: 'Celadon',
        main: 'green',
        hex: 'B8CCBA',
        code: '13-6108',
      },
      {
        name: 'Mist Green',
        main: 'green',
        hex: 'AACEBC',
        code: '13-6110',
      },
      {
        name: 'Smoke Green',
        main: 'green',
        hex: 'A8BBA2',
        code: '15-6315',
      },
      {
        name: 'Quiet Green',
        main: 'green',
        hex: '9EBC97',
        code: '15-6317',
      },
      {
        name: 'Silver Green',
        main: 'silver',
        hex: 'D7D7C7',
        code: '12-6204',
      },
      {
        name: 'Aqua Foam',
        main: 'aqua',
        hex: 'ADC3B4',
        code: '14-5707',
      },
      {
        name: 'Opal Blue',
        main: 'aqua',
        hex: 'C3DDD6',
        code: '12-5406',
      },
    ],
  },
  {
    _id: 'Salmon',
    colors: [
      {
        name: 'Coral Pink',
        main: 'orange',
        hex: 'E8A798',
        code: '14-1318',
      },
      {
        name: 'Whispering Peach',
        main: 'pink',
        hex: 'EEC8B9',
        code: '12-1015',
      },
      {
        name: 'Bellini',
        main: 'pink',
        hex: 'F4C9B1',
        code: '13-1114',
      },
      {
        name: 'Salmon',
        main: 'pink',
        hex: 'FAAA94',
        code: '14-1323',
      },
      {
        name: "Peaches N' Cream",
        main: 'pink',
        hex: 'F4A6A3',
        code: '14-1521',
      },
      {
        name: 'Peach Beige',
        main: 'pink',
        hex: 'D3A297',
        code: '15-1516',
      },
      {
        name: 'Coral Almond',
        main: 'pink',
        hex: 'E29D93',
        code: '16-1434',
      },
      {
        name: 'Rosette',
        main: 'pink',
        hex: 'CE8F8B',
        code: '16-1518',
      },
      {
        name: 'Seashell Pink',
        main: 'pink',
        hex: 'F7C8C2',
        code: '13-1409',
      },
      {
        name: 'Blushing Rose',
        main: 'pink',
        hex: 'F7C0BF',
        code: '13-2003',
      },
      {
        name: 'Dusty Pink',
        main: 'pink',
        hex: 'DEAA9B',
        code: '14-1316',
      },
      {
        name: 'Peach Bud',
        main: 'pink',
        hex: 'FDB2A8',
        code: '14-1324',
      },
      {
        name: 'Coral Cloud',
        main: 'pink',
        hex: 'E2A9A1',
        code: '15-1415',
      },
      {
        name: 'Murex Shell',
        main: 'pink',
        hex: 'F8A3A4',
        code: '15-1712',
      },
      {
        name: 'Lobster Bisque',
        main: 'pink',
        hex: 'DD9289',
        code: '16-1520',
      },
      {
        name: 'Mangano Calcite',
        main: 'pink',
        hex: 'F5D4CD',
        code: '13-1501',
      },
      {
        name: 'Pink Salt',
        main: 'pink',
        hex: 'F8CDC7',
        code: '13-1511',
      },
      {
        name: 'Spanish Villa',
        main: 'pink',
        hex: 'DFBAA9',
        code: '14-1314',
      },
      {
        name: 'Peach Pearl',
        main: 'pink',
        hex: 'FFB2A5',
        code: '14-1419',
      },
      {
        name: 'Blossom',
        main: 'pink',
        hex: 'F2B2AE',
        code: '14-1513',
      },
      {
        name: 'Peach Amber',
        main: 'pink',
        hex: 'FB9F93',
        code: '15-1423',
      },
      {
        name: 'Mellow Rose',
        main: 'pink',
        hex: 'D9A6A1',
        code: '15-1515',
      },
      {
        name: 'Rose Tan',
        main: 'pink',
        hex: 'D19C97',
        code: '16-1511',
      },
      {
        name: 'Impatiens Pink',
        main: 'pink',
        hex: 'FFC4BC',
        code: '13-1510',
      },
      {
        name: 'Gossamer Pink',
        main: 'pink',
        hex: 'FAC8C3',
        code: '13-1513',
      },
      {
        name: 'Rose Cloud',
        main: 'pink',
        hex: 'DBB0A2',
        code: '14-1313',
      },
      {
        name: 'Apricot Blush',
        main: 'pink',
        hex: 'FEAEA5',
        code: '14-1420',
      },
      {
        name: 'Shrimp',
        main: 'pink',
        hex: 'E29A86',
        code: '15-1523',
      },
    ],
  },
  {
    _id: 'Sand ',
    colors: [
      {
        name: 'Sugar Swizzle',
        main: 'white',
        hex: 'F4EEE8',
        code: '11-0607',
      },
      {
        name: 'Cloud Dancer',
        main: 'white',
        hex: 'F0EEE9',
        code: '11-4201',
      },
      {
        name: 'Eggnog',
        main: 'beige',
        hex: 'ECE1D3',
        code: '12-0601',
      },
      {
        name: 'Snow White',
        main: 'white',
        hex: 'F2F0EB',
        code: '11-0602',
      },
      {
        name: 'Coconut Milk',
        main: 'white',
        hex: 'F1EEE6',
        code: '11-0608',
      },
      {
        name: 'White Down',
        main: 'white',
        hex: 'E5E2D9',
        code: '12-0508',
      },
      {
        name: 'Antique White',
        main: 'beige',
        hex: 'EEE5D3',
        code: '11-0105',
      },
      {
        name: "Baby's Breath",
        main: 'beige',
        hex: 'E9E2D1',
        code: '11-0202',
      },
      {
        name: 'Pristine',
        main: 'beige',
        hex: 'F2E8DA',
        code: '11-0606',
      },
      {
        name: 'Snowy Peaks',
        main: 'beige',
        hex: 'E6DFD8',
        code: '12-0003',
      },
      {
        name: 'Arctic Wolf',
        main: 'beige',
        hex: 'E6DECF',
        code: '12-0602',
      },
      {
        name: 'Dew',
        main: 'beige',
        hex: 'EEDED1',
        code: '12-1108',
      },
      {
        name: 'Nova Scotia',
        main: 'beige',
        hex: 'E7DACE',
        code: '12-1306',
      },
      {
        name: 'Snowfall',
        main: 'beige',
        hex: 'E7DFD8',
        code: '11-0002',
      },
      {
        name: 'Jet Stream',
        main: 'beige',
        hex: 'EDE6DE',
        code: '11-0605',
      },
      {
        name: 'Whisper White',
        main: 'beige',
        hex: 'EDE6DB',
        code: '11-0701',
      },
      {
        name: 'Marshmallow',
        main: 'beige',
        hex: 'F0EEE4',
        code: '11-4300',
      },
      {
        name: 'Turtledove',
        main: 'beige',
        hex: 'DED7C8',
        code: '12-5202',
      },
      {
        name: 'Gardenia',
        main: 'beige',
        hex: 'F1E9DF',
        code: '11-0604',
      },
      {
        name: 'White Asparagus',
        main: 'beige',
        hex: 'E1DBC9',
        code: '12-0104',
      },
      {
        name: 'Bleached Sand',
        main: 'brown',
        hex: 'DACCB4',
        code: '13-1008',
      },
      {
        name: 'Gray Sand',
        main: 'brown',
        hex: 'E5CCAF',
        code: '13-1010',
      },
      {
        name: 'Sand Dollar',
        main: 'brown',
        hex: 'DECDBE',
        code: '13-1106',
      },
      {
        name: 'Brazilian Sand',
        main: 'brown',
        hex: 'DBCAB8',
        code: '13-1308',
      },
      {
        name: 'Summer Sand',
        main: 'brown',
        hex: 'D6CBBA',
        code: '12-0908',
      },
      {
        name: 'Sea Pearl',
        main: 'brown',
        hex: 'E4DACE',
        code: '12-0705',
      },
      {
        name: 'Shell',
        main: 'brown',
        hex: 'E1CFC6',
        code: '13-1405',
      },
      {
        name: 'Maple Sugar',
        main: 'red',
        hex: 'C9A38D',
        code: '15-1316',
      },
      {
        name: 'Pastel Parchment',
        main: 'blue',
        hex: 'E5D9D3',
        code: '11-0603',
      },
      {
        name: 'Morganite',
        main: 'blue',
        hex: 'E0CDC6',
        code: '12-2901',
      },
      {
        name: 'Sea Salt',
        main: 'blue',
        hex: 'F1E6DF',
        code: '11-1302',
      },
    ],
  },
  {
    _id: 'Scarlet',
    colors: [
      {
        name: 'Garnet',
        main: 'red',
        hex: '953640',
        code: '19-1655',
      },
      {
        name: 'Ketchup',
        main: 'red',
        hex: '9A382D',
        code: '18-1449',
      },
      {
        name: 'Fire Whirl',
        main: 'red',
        hex: 'A73730',
        code: '18-1453',
      },
      {
        name: 'Cardinal',
        main: 'pink',
        hex: 'AD3E48',
        code: '18-1643',
      },
      {
        name: 'Rooibos Tea',
        main: 'red',
        hex: 'A23C26',
        code: '18-1355',
      },
      {
        name: 'Hot Sauce',
        main: 'red',
        hex: 'AB4F41',
        code: '18-1536',
      },
      {
        name: 'Mineral Red',
        main: 'red',
        hex: 'B35457',
        code: '17-1537',
      },
      {
        name: 'Baked Apple',
        main: 'red',
        hex: 'B34646',
        code: '18-1648',
      },
      {
        name: 'Samba',
        main: 'red',
        hex: 'A2242F',
        code: '19-1662',
      },
      {
        name: 'Ribbon Red',
        main: 'red',
        hex: 'B92636',
        code: '19-1663',
      },
      {
        name: 'Adrenaline Rush',
        main: 'red',
        hex: 'B31A23',
        code: '18-1553',
      },
      {
        name: 'Molten Lava',
        main: 'red',
        hex: 'B5332E',
        code: '18-1555',
      },
      {
        name: 'Toreador',
        main: 'red',
        hex: 'B71233',
        code: '18-1653',
      },
      {
        name: 'Flame Scarlet',
        main: 'red',
        hex: 'CD212A',
        code: '18-1662',
      },
      {
        name: 'Chinese Red',
        main: 'red',
        hex: 'BE132D',
        code: '18-1663',
      },
      {
        name: 'Ski Patrol',
        main: 'red',
        hex: 'BB1237',
        code: '18-1761',
      },
      {
        name: 'Scarlet Sage',
        main: 'red',
        hex: '9D202F',
        code: '19-1559',
      },
      {
        name: 'Urban Red',
        main: 'red',
        hex: 'B01F32',
        code: '19-1756',
      },
      {
        name: 'Scarlet',
        main: 'red',
        hex: 'BC2C3D',
        code: '19-1760',
      },
      {
        name: 'Jester Red',
        main: 'red',
        hex: '9E1030',
        code: '19-1862',
      },
      {
        name: 'Goji Berry',
        main: 'red',
        hex: 'B91428',
        code: '18-1659',
      },
      {
        name: 'Chili Pepper',
        main: 'red',
        hex: '9B1B30',
        code: '19-1557',
      },
      {
        name: 'True Red',
        main: 'red',
        hex: 'BF1932',
        code: '19-1664',
      },
      {
        name: 'Equestrian Red',
        main: 'red',
        hex: '9E1528',
        code: '19-1755',
      },
      {
        name: 'Tango Red',
        main: 'red',
        hex: 'AC0C2D',
        code: '19-1761',
      },
      {
        name: 'Emboldened',
        main: 'red',
        hex: '8E1F2C',
        code: '19-1875',
      },
      {
        name: 'Aura Orange',
        main: 'red',
        hex: 'B4272B',
        code: '18-1551',
      },
      {
        name: 'Lava Falls',
        main: 'red',
        hex: '9A2B2E',
        code: '18-1552',
      },
      {
        name: 'Rubicondo',
        main: 'red',
        hex: 'BB3339',
        code: '18-1563',
      },
      {
        name: 'Mars Red',
        main: 'red',
        hex: 'BC2732',
        code: '18-1655',
      },
      {
        name: 'Salsa',
        main: 'red',
        hex: 'AA182B',
        code: '18-1657',
      },
      {
        name: 'Pompeian Red',
        main: 'red',
        hex: 'A42A2E',
        code: '18-1658',
      },
      {
        name: 'Savvy Red',
        main: 'red',
        hex: '951528',
        code: '19-1554',
      },
      {
        name: 'Scarlet Smile',
        main: 'red',
        hex: '9F2436',
        code: '19-1558',
      },
      {
        name: 'Haute Red',
        main: 'red',
        hex: 'A11729',
        code: '19-1758',
      },
    ],
  },
  {
    _id: 'Seafoam',
    colors: [
      {
        name: 'Zephyr Blue',
        main: 'gray',
        hex: 'D3D9D1',
        code: '12-5603',
      },
      {
        name: 'Lightest Sky',
        main: 'green',
        hex: 'E4EADF',
        code: '11-4804',
      },
      {
        name: 'Canary Green',
        main: 'green',
        hex: 'D6DFC9',
        code: '12-0108',
      },
      {
        name: 'Asparagus Green',
        main: 'green',
        hex: 'D2CDB4',
        code: '12-0311',
      },
      {
        name: 'Fairest Jade',
        main: 'green',
        hex: 'D8E3D7',
        code: '12-6206',
      },
      {
        name: 'Water Lily',
        main: 'green',
        hex: 'DDE3D5',
        code: '11-0304',
      },
      {
        name: 'Ice',
        main: 'green',
        hex: 'E0E4D9',
        code: '11-4803',
      },
      {
        name: 'Phantom Green',
        main: 'green',
        hex: 'DCE4D7',
        code: '12-6208',
      },
      {
        name: 'Whisper Green',
        main: 'green',
        hex: 'E0E6D6',
        code: '12-5404',
      },
      {
        name: 'Frost',
        main: 'green',
        hex: 'DDE2D6',
        code: '12-6207',
      },
      {
        name: 'Fog Green',
        main: 'green',
        hex: 'C2CBB4',
        code: '13-0210',
      },
      {
        name: 'Aloe Wash',
        main: 'green',
        hex: 'D0D3B7',
        code: '13-0608',
      },
      {
        name: 'Sea Foam',
        main: 'green',
        hex: 'B7C2B2',
        code: '14-6007',
      },
      {
        name: 'Celadon Tint',
        main: 'green',
        hex: 'CBCEBE',
        code: '13-6105',
      },
      {
        name: 'Meadow Mist',
        main: 'yellow',
        hex: 'D3DEC4',
        code: '12-0106',
      },
    ],
  },
  {
    _id: 'Sepia',
    colors: [
      {
        name: 'Vetiver',
        main: 'brown',
        hex: '807D6F',
        code: '17-0613',
      },
      {
        name: 'Overland Trek',
        main: 'brown',
        hex: '898270',
        code: '17-0619',
      },
      {
        name: 'Desert Taupe',
        main: 'brown',
        hex: '8D7E71',
        code: '17-1311',
      },
      {
        name: 'Fossil',
        main: 'brown',
        hex: '806F63',
        code: '17-0909',
      },
      {
        name: 'Petrified Oak',
        main: 'brown',
        hex: '8D7961',
        code: '17-1115',
      },
      {
        name: 'Lead Gray',
        main: 'brown',
        hex: '8A7963',
        code: '17-1118',
      },
      {
        name: 'Taupe Gray',
        main: 'brown',
        hex: '8E7C71',
        code: '17-0808',
      },
      {
        name: 'Roasted Cashew',
        main: 'brown',
        hex: '928679',
        code: '17-1105',
      },
      {
        name: 'Leafless Tree',
        main: 'brown',
        hex: '837067',
        code: '17-1412',
      },
      {
        name: 'Tapenade',
        main: 'brown',
        hex: '805D24',
        code: '18-0840',
      },
      {
        name: 'Cub',
        main: 'brown',
        hex: '6E5C4B',
        code: '18-1016',
      },
      {
        name: 'Foxtrot',
        main: 'brown',
        hex: '8A6749',
        code: '18-1025',
      },
      {
        name: 'Bison',
        main: 'brown',
        hex: '6E4F3A',
        code: '18-1027',
      },
      {
        name: 'Toasted Coconut',
        main: 'brown',
        hex: '8B6A4F',
        code: '18-1029',
      },
      {
        name: 'Sepia Tint',
        main: 'brown',
        hex: '8A7661',
        code: '17-1314',
      },
      {
        name: 'Malt Ball',
        main: 'brown',
        hex: '816852',
        code: '18-1014',
      },
      {
        name: 'Shitake',
        main: 'brown',
        hex: '746253',
        code: '18-1015',
      },
      {
        name: 'Dachshund',
        main: 'brown',
        hex: '704F37',
        code: '18-1033',
      },
      {
        name: "Monk's Robe",
        main: 'brown',
        hex: '704822',
        code: '18-1048',
      },
      {
        name: 'Partridge',
        main: 'brown',
        hex: '725440',
        code: '18-1124',
      },
      {
        name: 'Pecan Pie',
        main: 'brown',
        hex: '7B5947',
        code: '18-1125',
      },
      {
        name: 'Rubber',
        main: 'brown',
        hex: '815B37',
        code: '18-0933',
      },
      {
        name: 'Ermine',
        main: 'brown',
        hex: '836B4F',
        code: '18-1022',
      },
      {
        name: 'Emperador',
        main: 'brown',
        hex: '694833',
        code: '18-1028',
      },
      {
        name: 'Toffee',
        main: 'brown',
        hex: '755139',
        code: '18-1031',
      },
      {
        name: 'Cinnamon Swirl',
        main: 'brown',
        hex: '9C7961',
        code: '17-1324',
      },
      {
        name: 'Kangaroo',
        main: 'brown',
        hex: '725E43',
        code: '18-0920',
      },
      {
        name: 'Sepia',
        main: 'brown',
        hex: '6C543E',
        code: '18-0928',
      },
      {
        name: 'Coffee Liqueúr',
        main: 'brown',
        hex: '6A513B',
        code: '18-0930',
      },
      {
        name: 'Otter',
        main: 'brown',
        hex: '7F674F',
        code: '18-1018',
      },
      {
        name: 'Nuthatch',
        main: 'brown',
        hex: '815B3C',
        code: '18-1024',
      },
      {
        name: 'Thrush',
        main: 'brown',
        hex: '936B4F',
        code: '18-1030',
      },
      {
        name: 'Cacao Nibs',
        main: 'brown',
        hex: '7A5747',
        code: '18-1130',
      },
      {
        name: 'Acorn',
        main: 'brown',
        hex: '7E5E52',
        code: '18-1314',
      },
      {
        name: 'Baltic Amber',
        main: 'brown',
        hex: '8B645A',
        code: '18-1421',
      },
      {
        name: 'Rawhide',
        main: 'brown',
        hex: '865E49',
        code: '18-1137',
      },
      {
        name: 'Soft Silt',
        main: 'brown',
        hex: '6D4D3E',
        code: '18-1232',
      },
      {
        name: 'Russet',
        main: 'brown',
        hex: '905F50',
        code: '18-1235',
      },
      {
        name: 'Clove',
        main: 'brown',
        hex: '876155',
        code: '18-1320',
      },
      {
        name: 'Nutmeg',
        main: 'brown',
        hex: '7E5C54',
        code: '18-1326',
      },
      {
        name: 'Myristica',
        main: 'brown',
        hex: '846157',
        code: '18-1422',
      },
      {
        name: 'Cocoa Brown',
        main: 'brown',
        hex: '6C5042',
        code: '18-1222',
      },
      {
        name: 'Carob Brown',
        main: 'brown',
        hex: '855C4B',
        code: '18-1229',
      },
      {
        name: 'Tortoise Shell',
        main: 'brown',
        hex: '754734',
        code: '19-1241',
      },
      {
        name: 'Sequoia',
        main: 'brown',
        hex: '804839',
        code: '19-1333',
      },
    ],
  },
  {
    _id: 'Sky ',
    colors: [
      {
        name: 'Chalk Blue',
        main: 'blue',
        hex: 'CCDAD7',
        code: '12-4806',
      },
      {
        name: 'Arctic Ice',
        main: 'blue',
        hex: 'BFC7D6',
        code: '13-4110',
      },
      {
        name: 'Quiet Tide',
        main: 'blue',
        hex: 'C0E3F0',
        code: '13-4401',
      },
      {
        name: 'Ocean Current',
        main: 'blue',
        hex: 'A1D5EB',
        code: '13-4520',
      },
      {
        name: 'Pale Blue',
        main: 'blue',
        hex: 'C4D6D3',
        code: '13-4804',
      },
      {
        name: 'Bit of Blue',
        main: 'blue',
        hex: 'E2EAEB',
        code: '11-4601',
      },
      {
        name: 'Starlight Blue',
        main: 'blue',
        hex: 'B5CED4',
        code: '12-4609',
      },
      {
        name: 'Illusion Blue',
        main: 'blue',
        hex: 'C9D3DC',
        code: '13-4103',
      },
      {
        name: 'Plein Air',
        main: 'blue',
        hex: 'BFCAD6',
        code: '13-4111',
      },
      {
        name: 'Baby Blue',
        main: 'blue',
        hex: 'B5C7D3',
        code: '13-4308',
      },
      {
        name: 'Nantucket Breeze',
        main: 'blue',
        hex: 'B7D1EA',
        code: '14-4005',
      },
      {
        name: 'Cloud Blue',
        main: 'blue',
        hex: 'A2B6B9',
        code: '14-4306',
      },
      {
        name: 'Bachelor Button',
        main: 'blue',
        hex: '4ABBD5',
        code: '14-4522',
      },
      {
        name: 'Clear Tides',
        main: 'blue',
        hex: '85D9E9',
        code: '14-4714',
      },
      {
        name: 'Alaskan Blue',
        main: 'blue',
        hex: '6DA9D2',
        code: '15-4225',
      },
      {
        name: 'Air Blue',
        main: 'blue',
        hex: '77ACC7',
        code: '15-4319',
      },
      {
        name: 'Skylight',
        main: 'blue',
        hex: 'C8E0E0',
        code: '12-4604',
      },
      {
        name: 'Whispering Blue',
        main: 'blue',
        hex: 'C9DCDC',
        code: '12-4610',
      },
      {
        name: 'Wan Blue',
        main: 'blue',
        hex: 'CBDCDF',
        code: '12-4805',
      },
      {
        name: 'Ballad Blue',
        main: 'blue',
        hex: 'C0CEDA',
        code: '13-4304',
      },
      {
        name: 'Crystal Blue',
        main: 'blue',
        hex: 'A1C8DB',
        code: '13-4411',
      },
      {
        name: 'Blue Topaz',
        main: 'blue',
        hex: '78BDD4',
        code: '14-4310',
      },
      {
        name: 'Sky Blue',
        main: 'blue',
        hex: '8ABAD3',
        code: '14-4318',
      },
      {
        name: 'Stratosphere',
        main: 'blue',
        hex: '9EC1CC',
        code: '14-4508',
      },
      {
        name: 'Gulf Stream',
        main: 'blue',
        hex: '89C3D0',
        code: '14-4511',
      },
      {
        name: 'Canal Blue',
        main: 'blue',
        hex: '9CC2C5',
        code: '14-4810',
      },
      {
        name: 'Cerulean',
        main: 'blue',
        hex: '9BB7D4',
        code: '15-4020',
      },
      {
        name: 'Tropical Breeze',
        main: 'blue',
        hex: '88CDE7',
        code: '13-4307',
      },
      {
        name: 'Cooling Spray',
        main: 'blue',
        hex: 'C3E1EE',
        code: '13-4410',
      },
      {
        name: 'Plume',
        main: 'blue',
        hex: 'A5CFD5',
        code: '13-4809',
      },
      {
        name: 'Cashmere Blue',
        main: 'blue',
        hex: 'A5B8D0',
        code: '14-4115',
      },
      {
        name: 'Baltic Sea',
        main: 'blue',
        hex: '7AB6D9',
        code: '14-4320',
      },
      {
        name: 'Cabana Blue',
        main: 'blue',
        hex: '76B5C7',
        code: '14-4513',
      },
      {
        name: 'Splish Splash',
        main: 'blue',
        hex: '69BBDD',
        code: '16-4520',
      },
      {
        name: 'Billowing Sail',
        main: 'white',
        hex: 'D8E7E7',
        code: '11-4604',
      },
      {
        name: 'Sea Angel',
        main: 'aqua',
        hex: '98BFCB',
        code: '14-4315',
      },
      {
        name: 'Aquatic',
        main: 'aqua',
        hex: '9AC1CC',
        code: '14-4510',
      },
      {
        name: 'Atomizer',
        main: 'aqua',
        hex: '9AD4EA',
        code: '14-4514',
      },
      {
        name: 'Island Paradise',
        main: 'aqua',
        hex: '96DFE4',
        code: '14-4620',
      },
      {
        name: 'Angel Blue',
        main: 'aqua',
        hex: '83C5CD',
        code: '14-4814',
      },
      {
        name: 'Tanager Turquoise',
        main: 'aqua',
        hex: '91DDE8',
        code: '13-4720',
      },
    ],
  },
  {
    _id: 'Slate',
    colors: [
      {
        name: 'Weathervane',
        main: 'gray',
        hex: '8B9198',
        code: '15-4304',
      },
      {
        name: 'Tradewinds',
        main: 'gray',
        hex: '7F8792',
        code: '15-4307',
      },
      {
        name: 'Gypsum',
        main: 'gray',
        hex: '8C8887',
        code: '16-1502',
      },
      {
        name: 'Wisdom Gray',
        main: 'gray',
        hex: '847C7B',
        code: '17-0400',
      },
      {
        name: 'Sharkskin',
        main: 'gray',
        hex: '848587',
        code: '17-3914',
      },
      {
        name: 'Absorption',
        main: 'gray',
        hex: '9D989A',
        code: '16-3804',
      },
      {
        name: 'Abyss',
        main: 'gray',
        hex: '8F9E9D',
        code: '16-4404',
      },
      {
        name: 'Dried Sage',
        main: 'gray',
        hex: '8C8A7F',
        code: '17-0208',
      },
      {
        name: 'Storm Front',
        main: 'gray',
        hex: '787376',
        code: '17-1503',
      },
      {
        name: 'Silent Storm',
        main: 'gray',
        hex: '7C7977',
        code: '17-1508',
      },
      {
        name: 'Silver Filigree',
        main: 'gray',
        hex: '7F7C81',
        code: '17-3911',
      },
      {
        name: 'Sleet',
        main: 'gray',
        hex: '92949B',
        code: '16-3916',
      },
      {
        name: 'Slate',
        main: 'gray',
        hex: '8C9FA1',
        code: '16-4408',
      },
      {
        name: 'Slate Gray',
        main: 'gray',
        hex: '8A9691',
        code: '16-5804',
      },
      {
        name: 'Wrought Iron',
        main: 'gray',
        hex: '999E98',
        code: '16-5904',
      },
      {
        name: 'Elephant Skin',
        main: 'gray',
        hex: '8F8982',
        code: '17-0205',
      },
      {
        name: 'Forest Fog',
        main: 'gray',
        hex: '909189',
        code: '17-0209',
      },
      {
        name: 'Wild Dove',
        main: 'gray',
        hex: '8B8C89',
        code: '17-1501',
      },
      {
        name: 'Cloudburst',
        main: 'gray',
        hex: '837F7F',
        code: '17-1502',
      },
      {
        name: 'Gull',
        main: 'gray',
        hex: '918C8F',
        code: '17-3802',
      },
      {
        name: 'Quicksilver',
        main: 'gray',
        hex: '7E7D88',
        code: '17-3907',
      },
      {
        name: 'Storm Cloud',
        main: 'gray',
        hex: '8C8588',
        code: '17-3927',
      },
      {
        name: 'Dapple Gray',
        main: 'gray',
        hex: '9C9BA7',
        code: '16-3907',
      },
      {
        name: 'Ghost Gray',
        main: 'gray',
        hex: '9C9B98',
        code: '16-4703',
      },
      {
        name: 'Rock Ridge',
        main: 'gray',
        hex: '918C86',
        code: '17-0207',
      },
      {
        name: 'Laurel Oak',
        main: 'gray',
        hex: '918C7E',
        code: '17-0610',
      },
      {
        name: 'Zinc',
        main: 'gray',
        hex: '92898A',
        code: '17-2601',
      },
      {
        name: 'Minimal Gray',
        main: 'gray',
        hex: '948D99',
        code: '17-3906',
      },
      {
        name: 'Skyrocket',
        main: 'gray',
        hex: '86868C',
        code: '17-3913',
      },
      {
        name: 'Silver Bullet',
        main: 'gray',
        hex: '81839A',
        code: '17-3933',
      },
      {
        name: 'Underworld',
        main: 'gray',
        hex: '83807B',
        code: '17-4005',
      },
      {
        name: 'Gray Quill',
        main: 'gray',
        hex: '7B797B',
        code: '17-4013',
      },
      {
        name: 'Gray Flannel',
        main: 'gray',
        hex: '858282',
        code: '17-4016',
      },
      {
        name: 'Shadow',
        main: 'gray',
        hex: '888D82',
        code: '17-6206',
      },
      {
        name: 'Cliffside Gray',
        main: 'gray',
        hex: '75706D',
        code: '18-1611',
      },
      {
        name: 'Folkstone Gray',
        main: 'gray',
        hex: '626879',
        code: '18-3910',
      },
      {
        name: 'Flint Stone',
        main: 'gray',
        hex: '677283',
        code: '18-3916',
      },
      {
        name: 'December Sky',
        main: 'gray',
        hex: '767275',
        code: '18-4016',
      },
      {
        name: 'Moon Mist',
        main: 'gray',
        hex: '80817D',
        code: '18-4105',
      },
      {
        name: 'Gray Shingles',
        main: 'gray',
        hex: '666E75',
        code: '18-4205',
      },
      {
        name: 'Griffin',
        main: 'gray',
        hex: '8D8F8F',
        code: '17-5102',
      },
      {
        name: 'City Skyline',
        main: 'gray',
        hex: '6F6C70',
        code: '18-4010',
      },
      {
        name: 'Trooper',
        main: 'gray',
        hex: '697A7E',
        code: '18-4510',
      },
      {
        name: 'Brushed Nickel',
        main: 'gray',
        hex: '73706F',
        code: '18-5102',
      },
      {
        name: 'Titanium',
        main: 'gray',
        hex: '807D7F',
        code: '17-4014',
      },
      {
        name: 'Citadel',
        main: 'gray',
        hex: '748995',
        code: '17-4111',
      },
      {
        name: 'Monument',
        main: 'gray',
        hex: '84898C',
        code: '17-4405',
      },
      {
        name: 'Lead',
        main: 'gray',
        hex: '7A898F',
        code: '17-4408',
      },
      {
        name: 'Profile Gray',
        main: 'gray',
        hex: '898887',
        code: '17-5103',
      },
      {
        name: 'Organic Gray',
        main: 'gray',
        hex: '6D6563',
        code: '18-0407',
      },
      {
        name: 'Grisaille',
        main: 'gray',
        hex: '585E6F',
        code: '18-3912',
      },
      {
        name: 'Arctic Seal',
        main: 'gray',
        hex: '666772',
        code: '18-3924',
      },
      {
        name: 'Mockingbird',
        main: 'gray',
        hex: '77777A',
        code: '18-4015',
      },
      {
        name: 'Abalone Shell',
        main: 'gray',
        hex: '7E7F86',
        code: '17-4017',
      },
      {
        name: 'Castor Gray',
        main: 'gray',
        hex: '646762',
        code: '18-0510',
      },
      {
        name: 'Gray Ridge',
        main: 'gray',
        hex: '847986',
        code: '18-3710',
      },
      {
        name: 'Gray Maze',
        main: 'gray',
        hex: '6A6668',
        code: '18-4012',
      },
      {
        name: 'Night Owl',
        main: 'gray',
        hex: '79797F',
        code: '18-4017',
      },
      {
        name: 'Foghorn',
        main: 'gray',
        hex: '777670',
        code: '18-4331',
      },
      {
        name: 'Gray Matter',
        main: 'gray',
        hex: '6B6C6B',
        code: '18-4602',
      },
      {
        name: 'Stormy Sea',
        main: 'gray',
        hex: '6E8082',
        code: '18-4711',
      },
      {
        name: 'Debonair Gray',
        main: 'green',
        hex: '88857C',
        code: '16-4803',
      },
      {
        name: 'Neutral Gray',
        main: 'green',
        hex: '8E918F',
        code: '17-4402',
      },
    ],
  },
  {
    _id: 'Smoke',
    colors: [
      {
        name: 'Cumulus Cloud',
        main: 'gray',
        hex: 'B5B0AB',
        code: '14-0207',
      },
      {
        name: 'Oyster Gray',
        main: 'gray',
        hex: 'CBC1AE',
        code: '14-1107',
      },
      {
        name: 'Steel Wool',
        main: 'gray',
        hex: 'BBB4B3',
        code: '14-3802',
      },
      {
        name: 'Harbor Mist',
        main: 'gray',
        hex: 'B0B2B5',
        code: '14-4202',
      },
      {
        name: 'Vapor Blue',
        main: 'gray',
        hex: 'BEBDBD',
        code: '14-4203',
      },
      {
        name: 'Gray Daze',
        main: 'gray',
        hex: 'BCB3B2',
        code: '14-3700',
      },
      {
        name: 'Alabaster Gray',
        main: 'gray',
        hex: 'BCB7B8',
        code: '14-4109',
      },
      {
        name: 'Gray Morning',
        main: 'gray',
        hex: 'B0AAAB',
        code: '14-4116',
      },
      {
        name: 'Cathedral Spire',
        main: 'gray',
        hex: 'A0A3A7',
        code: '14-4205',
      },
      {
        name: 'Quiet Gray',
        main: 'gray',
        hex: 'BABBBD',
        code: '14-4107',
      },
      {
        name: 'Tracing Gray',
        main: 'gray',
        hex: 'BAB9B5',
        code: '14-4605',
      },
      {
        name: 'Feather Gray',
        main: 'gray',
        hex: 'B8AD9E',
        code: '15-1305',
      },
      {
        name: 'Quarry',
        main: 'gray',
        hex: '98A0A5',
        code: '15-4305',
      },
      {
        name: 'Sweatshirt Gray',
        main: 'gray',
        hex: 'AEA7A3',
        code: '15-4401',
      },
      {
        name: 'Puritan Gray',
        main: 'gray',
        hex: 'A8B0AE',
        code: '15-4702',
      },
      {
        name: 'Abbey Stone',
        main: 'gray',
        hex: 'ABA798',
        code: '14-6408',
      },
      {
        name: 'Eucalyptus',
        main: 'gray',
        hex: 'B1A992',
        code: '15-0513',
      },
      {
        name: 'Cloud Gray',
        main: 'gray',
        hex: 'B7A9AC',
        code: '15-3802',
      },
      {
        name: 'High-rise',
        main: 'gray',
        hex: 'AEB2B5',
        code: '15-4101',
      },
      {
        name: 'Belgian Block',
        main: 'gray',
        hex: 'A3A9A6',
        code: '15-4306',
      },
      {
        name: 'Silver Cloud',
        main: 'gray',
        hex: 'BEB7B0',
        code: '15-4502',
      },
      {
        name: 'Mirage Gray',
        main: 'gray',
        hex: 'ABAFAE',
        code: '15-4703',
      },
      {
        name: 'Pearl Blue',
        main: 'gray',
        hex: 'B0B7BE',
        code: '14-4206',
      },
      {
        name: 'Blue Fox',
        main: 'gray',
        hex: 'B9BCB6',
        code: '14-4804',
      },
      {
        name: 'Ashes of Roses',
        main: 'gray',
        hex: 'B5ACAB',
        code: '15-0703',
      },
      {
        name: 'Porpoise',
        main: 'gray',
        hex: 'A7A19E',
        code: '15-3800',
      },
      {
        name: 'Gray Mist',
        main: 'gray',
        hex: '99AEAE',
        code: '15-4706',
      },
      {
        name: 'Smoke',
        main: 'gray',
        hex: 'BFC8C3',
        code: '14-4505',
      },
      {
        name: 'Harbor Gray',
        main: 'gray',
        hex: 'A8C0BB',
        code: '14-4908',
      },
      {
        name: 'Spray Green',
        main: 'gray',
        hex: 'AEA692',
        code: '15-0309',
      },
      {
        name: 'Storm Gray',
        main: 'gray',
        hex: 'B5BAB6',
        code: '15-4003',
      },
      {
        name: 'Pigeon',
        main: 'gray',
        hex: 'A9AFAA',
        code: '15-4704',
      },
      {
        name: 'Cityscape',
        main: 'gray',
        hex: '9C9CA0',
        code: '15-5212',
      },
      {
        name: 'Moss Gray',
        main: 'gray',
        hex: 'AFAB97',
        code: '15-6410',
      },
      {
        name: 'Agate Gray',
        main: 'gray',
        hex: 'B3B1A1',
        code: '15-6307',
      },
      {
        name: 'Full Moon',
        main: 'gray',
        hex: '919095',
        code: '16-0005',
      },
      {
        name: 'London Fog',
        main: 'gray',
        hex: 'A29E92',
        code: '16-0207',
      },
      {
        name: 'Satellite',
        main: 'gray',
        hex: '978D89',
        code: '16-3800',
      },
      {
        name: 'Ash',
        main: 'gray',
        hex: 'A09998',
        code: '16-3802',
      },
      {
        name: 'Silver Sconce',
        main: 'gray',
        hex: 'A19FA5',
        code: '16-3850',
      },
      {
        name: 'Drizzle',
        main: 'gray',
        hex: 'A09E9C',
        code: '16-4402',
      },
      {
        name: 'Formal Gray',
        main: 'gray',
        hex: '979799',
        code: '16-5102',
      },
      {
        name: 'Steeple Gray',
        main: 'gray',
        hex: '827E7C',
        code: '17-1500',
      },
      {
        name: 'Gray Contours',
        main: 'gray',
        hex: '827F7F',
        code: '17-1504',
      },
      {
        name: 'Misted Morn',
        main: 'gray',
        hex: '9B9898',
        code: '16-1503',
      },
      {
        name: 'Absorption',
        main: 'gray',
        hex: '9D989A',
        code: '16-3804',
      },
      {
        name: 'Gray Illusions',
        main: 'gray',
        hex: '9F9E9D',
        code: '16-4705',
      },
      {
        name: 'Wet Weather',
        main: 'gray',
        hex: '929090',
        code: '16-5101',
      },
      {
        name: 'Flint Gray',
        main: 'gray',
        hex: 'A09C98',
        code: '16-5803',
      },
      {
        name: 'Pussywillow Gray',
        main: 'gray',
        hex: 'AEACA1',
        code: '15-6304',
      },
      {
        name: 'Paloma',
        main: 'gray',
        hex: '9F9C99',
        code: '16-0000',
      },
      {
        name: 'Vintage Khaki',
        main: 'gray',
        hex: '9A9186',
        code: '16-0205',
      },
      {
        name: 'Aluminum',
        main: 'gray',
        hex: '9F9586',
        code: '16-1107',
      },
      {
        name: 'Opal Gray',
        main: 'gray',
        hex: 'A49E9E',
        code: '16-3801',
      },
      {
        name: 'Gull Gray',
        main: 'gray',
        hex: 'A49C9F',
        code: '16-3803',
      },
      {
        name: 'Alloy',
        main: 'gray',
        hex: '98979A',
        code: '16-3915',
      },
      {
        name: 'Chiseled Stone',
        main: 'gray',
        hex: '8D8E92',
        code: '16-3917',
      },
      {
        name: 'Limestone',
        main: 'gray',
        hex: '989A98',
        code: '16-4702',
      },
      {
        name: 'Silver Blue',
        main: 'gray',
        hex: '8A9A9A',
        code: '16-4706',
      },
      {
        name: 'Gull',
        main: 'gray',
        hex: '918C8F',
        code: '17-3802',
      },
      {
        name: 'Cloud Cover',
        main: 'gray',
        hex: '999393',
        code: '16-1523',
      },
      {
        name: 'Dapple Gray',
        main: 'gray',
        hex: '9C9BA7',
        code: '16-3907',
      },
      {
        name: 'Mourning Dove',
        main: 'gray',
        hex: '95908B',
        code: '16-4400',
      },
      {
        name: 'Frost Gray',
        main: 'gray',
        hex: '848283',
        code: '17-0000',
      },
      {
        name: 'Ultimate Gray',
        main: 'gray',
        hex: '939597',
        code: '17-5104',
      },
    ],
  },
  {
    _id: 'Soil',
    colors: [
      {
        name: 'Ivory Cream',
        main: 'brown',
        hex: 'DAC0A7',
        code: '13-1011',
      },
      {
        name: 'Irish Cream',
        main: 'brown',
        hex: 'C0AC93',
        code: '14-1208',
      },
      {
        name: 'Macadamia',
        main: 'brown',
        hex: 'E4CFB6',
        code: '12-0709',
      },
      {
        name: 'Toasted Almond',
        main: 'brown',
        hex: 'D2B49C',
        code: '14-1213',
      },
      {
        name: 'Almond Buff',
        main: 'brown',
        hex: 'CCB390',
        code: '14-1116',
      },
      {
        name: 'Beige',
        main: 'brown',
        hex: 'D5BA98',
        code: '14-1118',
      },
      {
        name: 'Frappé',
        main: 'brown',
        hex: 'D1B7A0',
        code: '14-1212',
      },
      {
        name: 'Alfalfa',
        main: 'brown',
        hex: 'B7B59F',
        code: '14-6308',
      },
      {
        name: 'Fields of Rye',
        main: 'brown',
        hex: 'B7A990',
        code: '15-1115',
      },
      {
        name: 'Safari',
        main: 'brown',
        hex: 'BBAA91',
        code: '15-1116',
      },
      {
        name: 'Sesame',
        main: 'brown',
        hex: 'BAA38B',
        code: '15-1215',
      },
      {
        name: 'Semolina',
        main: 'brown',
        hex: 'CEB899',
        code: '15-1218',
      },
      {
        name: 'Humus',
        main: 'brown',
        hex: 'B7A793',
        code: '15-1304',
      },
      {
        name: 'Rugby Tan',
        main: 'brown',
        hex: 'C2A594',
        code: '15-1315',
      },
      {
        name: 'Seneca Rock',
        main: 'brown',
        hex: '9A927F',
        code: '17-1107',
      },
      {
        name: 'Weathered Teak',
        main: 'brown',
        hex: '9B8F7F',
        code: '17-1112',
      },
      {
        name: 'Pale Khaki',
        main: 'brown',
        hex: 'BFAF92',
        code: '15-1216',
      },
      {
        name: 'Elm',
        main: 'brown',
        hex: 'A39F86',
        code: '16-0613',
      },
      {
        name: 'Ginger Root',
        main: 'brown',
        hex: 'C0A68B',
        code: '15-1020',
      },
      {
        name: 'Tea',
        main: 'brown',
        hex: '999B85',
        code: '16-0213',
      },
      {
        name: 'Trench Coat',
        main: 'brown',
        hex: 'AA9D89',
        code: '16-1101',
      },
      {
        name: 'Tuffet',
        main: 'brown',
        hex: 'A59788',
        code: '16-1106',
      },
      {
        name: 'Sirocco',
        main: 'brown',
        hex: 'C39D88',
        code: '15-1317',
      },
      {
        name: 'Crockery',
        main: 'brown',
        hex: 'A49887',
        code: '16-1104',
      },
      {
        name: 'Lemon Pepper',
        main: 'brown',
        hex: 'A1947D',
        code: '16-1119',
      },
      {
        name: 'Oxford Tan',
        main: 'gray',
        hex: 'B8A99A',
        code: '15-1306',
      },
      {
        name: 'Gravel',
        main: 'green',
        hex: 'CBBFA2',
        code: '14-1014',
      },
    ],
  },
  {
    _id: 'Steel',
    colors: [
      {
        name: 'Harbor Mist',
        main: 'gray',
        hex: 'B0B2B5',
        code: '14-4202',
      },
      {
        name: 'Cathedral Spire',
        main: 'gray',
        hex: 'A0A3A7',
        code: '14-4205',
      },
      {
        name: 'Silver',
        main: 'gray',
        hex: 'A2A2A1',
        code: '14-5002',
      },
      {
        name: 'Mirage Gray',
        main: 'gray',
        hex: 'ABAFAE',
        code: '15-4703',
      },
      {
        name: 'Weathervane',
        main: 'gray',
        hex: '8B9198',
        code: '15-4304',
      },
      {
        name: 'Cityscape',
        main: 'gray',
        hex: '9C9CA0',
        code: '15-5212',
      },
      {
        name: 'Formal Gray',
        main: 'gray',
        hex: '979799',
        code: '16-5102',
      },
      {
        name: 'Dolphin',
        main: 'gray',
        hex: '9A9699',
        code: '16-2901',
      },
      {
        name: 'Gray Illusions',
        main: 'gray',
        hex: '9F9E9D',
        code: '16-4705',
      },
      {
        name: 'Ghost Gray',
        main: 'gray',
        hex: '9C9B98',
        code: '16-4703',
      },
      {
        name: 'Frost Gray',
        main: 'gray',
        hex: '848283',
        code: '17-0000',
      },
      {
        name: 'Quiet Shade',
        main: 'gray',
        hex: '66676D',
        code: '18-4006',
      },
      {
        name: 'Gray Shingles',
        main: 'gray',
        hex: '666E75',
        code: '18-4205',
      },
      {
        name: 'Griffin',
        main: 'gray',
        hex: '8D8F8F',
        code: '17-5102',
      },
      {
        name: 'Sedona Sage',
        main: 'gray',
        hex: '686D6C',
        code: '18-5105',
      },
      {
        name: 'Monument',
        main: 'gray',
        hex: '84898C',
        code: '17-4405',
      },
      {
        name: 'Profile Gray',
        main: 'gray',
        hex: '898887',
        code: '17-5103',
      },
      {
        name: 'Stone Mountain',
        main: 'gray',
        hex: '686A6C',
        code: '18-4705',
      },
      {
        name: 'Neutral Gray',
        main: 'green',
        hex: '8E918F',
        code: '17-4402',
      },
    ],
  },
  {
    _id: 'Stone',
    colors: [
      {
        name: 'Murmur',
        main: 'gray',
        hex: 'D2D8D2',
        code: '12-5203',
      },
      {
        name: 'Silver Birch',
        main: 'gray',
        hex: 'D2CFC4',
        code: '13-4403',
      },
      {
        name: 'Ice Flow',
        main: 'gray',
        hex: 'C6D2D2',
        code: '13-4404',
      },
      {
        name: 'White Onyx',
        main: 'gray',
        hex: 'D7D6CE',
        code: '12-4300',
      },
      {
        name: 'Antarctica',
        main: 'gray',
        hex: 'C6C6C7',
        code: '13-4104',
      },
      {
        name: 'Nimbus Cloud',
        main: 'gray',
        hex: 'D5D5D8',
        code: '13-4108',
      },
      {
        name: 'Oyster Mushroom',
        main: 'gray',
        hex: 'C4C6C8',
        code: '13-4201',
      },
      {
        name: 'Rainy Day',
        main: 'gray',
        hex: 'CFC8BD',
        code: '13-5304',
      },
      {
        name: 'Gray Violet',
        main: 'gray',
        hex: 'BBBCBC',
        code: '14-4103',
      },
      {
        name: 'Northern Droplet',
        main: 'gray',
        hex: 'BABEBF',
        code: '14-4104',
      },
      {
        name: 'Light Gray',
        main: 'gray',
        hex: 'DAD8C9',
        code: '12-0404',
      },
      {
        name: 'Vaporous Gray',
        main: 'gray',
        hex: 'DFDDD7',
        code: '12-4302',
      },
      {
        name: 'Pumice Stone',
        main: 'gray',
        hex: 'CAC2B9',
        code: '14-0002',
      },
      {
        name: 'Wind Chime',
        main: 'gray',
        hex: 'CAC5C2',
        code: '14-4002',
      },
      {
        name: 'Gray Morn',
        main: 'gray',
        hex: 'CABEB5',
        code: '13-0403',
      },
      {
        name: 'Foggy Dew',
        main: 'gray',
        hex: 'D1D5D0',
        code: '13-4305',
      },
      {
        name: 'Overcast',
        main: 'gray',
        hex: 'C3BDAB',
        code: '14-0105',
      },
      {
        name: 'Glacier Gray',
        main: 'gray',
        hex: 'C5C6C7',
        code: '14-4102',
      },
      {
        name: 'Micro Chip',
        main: 'gray',
        hex: 'BABCC0',
        code: '14-4105',
      },
      {
        name: 'Gray Dawn',
        main: 'gray',
        hex: 'BBC1CC',
        code: '14-4106',
      },
      {
        name: 'Lunar Rock',
        main: 'gray',
        hex: 'C5C5C5',
        code: '14-4201',
      },
      {
        name: 'Monsoon Mist',
        main: 'gray',
        hex: 'B7B9B8',
        code: '14-4216',
      },
      {
        name: 'Secret Spaces',
        main: 'gray',
        hex: 'B6BBC0',
        code: '14-4309',
      },
      {
        name: 'Dove',
        main: 'gray',
        hex: 'B3ADA7',
        code: '15-0000',
      },
      {
        name: 'Metal',
        main: 'gray',
        hex: 'BABFBC',
        code: '14-4503',
      },
      {
        name: 'Sky Gray',
        main: 'gray',
        hex: 'BCC8C6',
        code: '14-4504',
      },
      {
        name: 'Pelican',
        main: 'gray',
        hex: 'C1BCAC',
        code: '14-6305',
      },
      {
        name: 'Moonstruck',
        main: 'gray',
        hex: 'C2BEB6',
        code: '14-4500',
      },
      {
        name: 'Smoke',
        main: 'gray',
        hex: 'BFC8C3',
        code: '14-4505',
      },
      {
        name: 'Temple Gray',
        main: 'silver',
        hex: 'C9C3C1',
        code: '13-0005',
      },
    ],
  },
  {
    _id: 'Sunshine',
    colors: [
      {
        name: 'Creamed Corn',
        main: 'orange',
        hex: 'F7E09F',
        code: '12-0730',
      },
      {
        name: 'Summer Melon',
        main: 'orange',
        hex: 'EAD3AE',
        code: '13-0814',
      },
      {
        name: 'Chamomile',
        main: 'orange',
        hex: 'E8D0A7',
        code: '13-0916',
      },
      {
        name: 'Glass Green',
        main: 'green',
        hex: 'ECEAD0',
        code: '11-0205',
      },
      {
        name: 'Ethereal Green',
        main: 'green',
        hex: 'F1ECCA',
        code: '11-0609',
      },
      {
        name: 'Transparent Yellow',
        main: 'yellow',
        hex: 'F4ECC2',
        code: '11-0617',
      },
      {
        name: 'Pineapple Slice',
        main: 'yellow',
        hex: 'E7D391',
        code: '12-0718',
      },
      {
        name: 'Mellow Yellow',
        main: 'yellow',
        hex: 'F0DD9D',
        code: '12-0720',
      },
      {
        name: 'Yellow Cream',
        main: 'yellow',
        hex: 'EFDC75',
        code: '12-0738',
      },
      {
        name: 'Flan',
        main: 'yellow',
        hex: 'F6E3B4',
        code: '11-0619',
      },
      {
        name: 'French Vanilla',
        main: 'yellow',
        hex: 'EFE1A7',
        code: '12-0722',
      },
      {
        name: 'Lemon Icing',
        main: 'yellow',
        hex: 'F6EBC8',
        code: '11-0515',
      },
      {
        name: 'Lemon Meringue',
        main: 'yellow',
        hex: 'F6E199',
        code: '12-0711',
      },
      {
        name: 'Double Cream',
        main: 'yellow',
        hex: 'F3E0AC',
        code: '12-0715',
      },
      {
        name: 'Sunshine',
        main: 'yellow',
        hex: 'FADE85',
        code: '12-0727',
      },
      {
        name: 'Popcorn',
        main: 'yellow',
        hex: 'F8DE8D',
        code: '12-0825',
      },
      {
        name: 'Pastel Yellow',
        main: 'yellow',
        hex: 'F2E6B1',
        code: '11-0616',
      },
      {
        name: 'Anise Flower',
        main: 'yellow',
        hex: 'F4E3B5',
        code: '12-0717',
      },
      {
        name: 'Pale Banana',
        main: 'yellow',
        hex: 'FAE199',
        code: '12-0824',
      },
      {
        name: 'Banana Crepe',
        main: 'yellow',
        hex: 'E7D3AD',
        code: '13-0815',
      },
      {
        name: 'Golden Haze',
        main: 'gold',
        hex: 'FBD897',
        code: '12-0826',
      },
      {
        name: 'Corn Confection',
        main: 'gold',
        hex: 'F6E482',
        code: '12-0640',
      },
    ],
  },
  {
    _id: 'Tangerine',
    colors: [
      {
        name: 'Vanilla',
        main: 'orange',
        hex: 'F4E1C1',
        code: '12-0712',
      },
      {
        name: 'Golden Straw',
        main: 'orange',
        hex: 'E6BD8F',
        code: '12-0921',
      },
      {
        name: 'Impala',
        main: 'orange',
        hex: 'F8CE97',
        code: '13-1025',
      },
      {
        name: 'Apricot Cream',
        main: 'orange',
        hex: 'F1BD89',
        code: '13-1027',
      },
      {
        name: 'Sunburst',
        main: 'orange',
        hex: 'F6C289',
        code: '13-1030',
      },
      {
        name: 'Apricot Illusion',
        main: 'orange',
        hex: 'E2C4A6',
        code: '14-1120',
      },
      {
        name: 'Desert Mist',
        main: 'orange',
        hex: 'E0B589',
        code: '14-1127',
      },
      {
        name: 'Gold Earth',
        main: 'orange',
        hex: 'DD9C6B',
        code: '15-1234',
      },
      {
        name: 'Cantaloupe',
        main: 'orange',
        hex: 'FFA177',
        code: '15-1239',
      },
      {
        name: 'Desert Dust',
        main: 'orange',
        hex: 'E3BC8E',
        code: '13-1018',
      },
      {
        name: 'Buff Orange',
        main: 'orange',
        hex: 'FFBB7C',
        code: '14-1128',
      },
      {
        name: 'Apricot Wash',
        main: 'orange',
        hex: 'FBAC82',
        code: '14-1230',
      },
      {
        name: 'Papaya',
        main: 'orange',
        hex: 'FFA266',
        code: '15-1243',
      },
      {
        name: 'Cadmium Orange',
        main: 'orange',
        hex: 'F99471',
        code: '15-1340',
      },
      {
        name: 'Coral Haze',
        main: 'orange',
        hex: 'E38E84',
        code: '16-1329',
      },
      {
        name: 'Bisque',
        main: 'orange',
        hex: 'EDCAB5',
        code: '13-1109',
      },
      {
        name: 'Salmon Buff',
        main: 'orange',
        hex: 'FEAA7B',
        code: '14-1135',
      },
      {
        name: 'Amberlight',
        main: 'orange',
        hex: 'E2BEA2',
        code: '14-1217',
      },
      {
        name: 'Coral Sands',
        main: 'orange',
        hex: 'EDAA86',
        code: '14-1224',
      },
      {
        name: 'Orange Chiffon',
        main: 'orange',
        hex: 'FAAB7E',
        code: '14-1241',
      },
      {
        name: 'Peach Infusion',
        main: 'orange',
        hex: 'E19A8B',
        code: '14-1602',
      },
      {
        name: 'Porcini',
        main: 'orange',
        hex: 'CCA580',
        code: '15-1125',
      },
      {
        name: 'Mock Orange',
        main: 'orange',
        hex: 'FFA368',
        code: '15-1245',
      },
      {
        name: 'Tangerine',
        main: 'orange',
        hex: 'F88F58',
        code: '15-1247',
      },
      {
        name: 'Shell Coral',
        main: 'orange',
        hex: 'EA9575',
        code: '15-1334',
      },
      {
        name: 'Autumn Sunset',
        main: 'orange',
        hex: 'F38554',
        code: '16-1343',
      },
      {
        name: 'Buff',
        main: 'orange',
        hex: 'EBC396',
        code: '13-1024',
      },
      {
        name: 'Apricot Nectar',
        main: 'orange',
        hex: 'ECAA79',
        code: '14-1133',
      },
      {
        name: 'Pumpkin',
        main: 'orange',
        hex: 'F5A26F',
        code: '14-1139',
      },
      {
        name: 'Peach Cobbler',
        main: 'orange',
        hex: 'FFB181',
        code: '14-1231',
      },
      {
        name: 'Melon Ice',
        main: 'orange',
        hex: 'FFAC82',
        code: '14-1242',
      },
      {
        name: 'Persian Melon',
        main: 'orange',
        hex: 'FEA56A',
        code: '15-1232',
      },
      {
        name: 'Muskmelon',
        main: 'orange',
        hex: 'EC935E',
        code: '15-1242',
      },
      {
        name: 'Peach Bloom',
        main: 'orange',
        hex: 'D99B7C',
        code: '15-1327',
      },
      {
        name: 'Coral Reef',
        main: 'orange',
        hex: 'FAA181',
        code: '15-1331',
      },
      {
        name: 'Canyon Sunset',
        main: 'orange',
        hex: 'E1927A',
        code: '15-1333',
      },
      {
        name: 'Bird of Paradise',
        main: 'orange',
        hex: 'FF8C55',
        code: '16-1357',
      },
      {
        name: 'Coral',
        main: 'orange',
        hex: 'ED7464',
        code: '16-1539',
      },
      {
        name: 'Fresh Salmon',
        main: 'orange',
        hex: 'FF7F6A',
        code: '16-1542',
      },
      {
        name: 'Fusion Coral',
        main: 'orange',
        hex: 'FF8576',
        code: '16-1543',
      },
      {
        name: 'Living Coral',
        main: 'orange',
        hex: 'FF6F61',
        code: '16-1546',
      },
      {
        name: 'Melon',
        main: 'orange',
        hex: 'FE8863',
        code: '16-1442',
      },
      {
        name: 'Nasturtium',
        main: 'orange',
        hex: 'FE6347',
        code: '16-1451',
      },
      {
        name: 'Firecracker',
        main: 'orange',
        hex: 'F36944',
        code: '16-1452',
      },
      {
        name: 'Peach Echo',
        main: 'orange',
        hex: 'F8786B',
        code: '16-1548',
      },
      {
        name: 'Nectarine',
        main: 'orange',
        hex: 'FF8656',
        code: '16-1360',
      },
      {
        name: 'Camellia',
        main: 'orange',
        hex: 'F6745F',
        code: '16-1541',
      },
      {
        name: 'Persimmon',
        main: 'orange',
        hex: 'F67866',
        code: '16-1544',
      },
      {
        name: 'Coral Quartz',
        main: 'orange',
        hex: 'F77565',
        code: '16-1545',
      },
      {
        name: 'Emberglow',
        main: 'orange',
        hex: 'EA6759',
        code: '17-1547',
      },
    ],
  },
  {
    _id: 'Taupe ',
    colors: [
      {
        name: 'Gilded Beige',
        main: 'brown',
        hex: 'B39F8D',
        code: '14-1012',
      },
      {
        name: 'Roebuck',
        main: 'brown',
        hex: 'B09080',
        code: '16-1221',
      },
      {
        name: 'Natural',
        main: 'brown',
        hex: 'AA907D',
        code: '16-1310',
      },
      {
        name: 'Chanterelle',
        main: 'brown',
        hex: 'A28776',
        code: '16-1414',
      },
      {
        name: 'Sphinx',
        main: 'brown',
        hex: 'AB9895',
        code: '16-1703',
      },
      {
        name: 'Winter Twig',
        main: 'brown',
        hex: '958A7A',
        code: '17-1108',
      },
      {
        name: 'Coriander',
        main: 'brown',
        hex: '948772',
        code: '17-1113',
      },
      {
        name: 'Fungi',
        main: 'brown',
        hex: '8F8177',
        code: '17-1212',
      },
      {
        name: 'Café au Lait',
        main: 'brown',
        hex: 'AE8774',
        code: '17-1227',
      },
      {
        name: 'White Pepper',
        main: 'brown',
        hex: 'B6A893',
        code: '15-1307',
      },
      {
        name: 'Etherea',
        main: 'brown',
        hex: 'A5958F',
        code: '15-1506',
      },
      {
        name: 'Goat',
        main: 'brown',
        hex: 'A89A91',
        code: '16-0806',
      },
      {
        name: 'Light Taupe',
        main: 'brown',
        hex: 'B19D8D',
        code: '16-1210',
      },
      {
        name: 'Mocha Meringue',
        main: 'brown',
        hex: '9F8D81',
        code: '16-1211',
      },
      {
        name: 'Savannah Tan',
        main: 'brown',
        hex: 'A69483',
        code: '16-1213',
      },
      {
        name: 'Adobe Rose',
        main: 'brown',
        hex: 'BA9F99',
        code: '16-1508',
      },
      {
        name: 'Tree House',
        main: 'brown',
        hex: '998D76',
        code: '17-0630',
      },
      {
        name: 'Tawny Brown',
        main: 'brown',
        hex: 'AB856F',
        code: '17-1226',
      },
      {
        name: 'Silver Mink',
        main: 'brown',
        hex: '9F8E7C',
        code: '17-1312',
      },
      {
        name: 'Simply Taupe',
        main: 'brown',
        hex: 'AD9F93',
        code: '16-0906',
      },
      {
        name: 'Nomad',
        main: 'brown',
        hex: 'B49F89',
        code: '16-1212',
      },
      {
        name: 'Warm Taupe',
        main: 'brown',
        hex: 'AF9483',
        code: '16-1318',
      },
      {
        name: 'Nougat',
        main: 'brown',
        hex: 'B69885',
        code: '16-1320',
      },
      {
        name: 'Bark',
        main: 'brown',
        hex: 'A99592',
        code: '16-1506',
      },
      {
        name: 'Shadow Gray',
        main: 'brown',
        hex: 'BBA5A0',
        code: '16-1509',
      },
      {
        name: 'Dune',
        main: 'brown',
        hex: '998978',
        code: '17-1009',
      },
      {
        name: 'Chinchilla',
        main: 'brown',
        hex: '9C8E7B',
        code: '17-1109',
      },
      {
        name: 'Incense',
        main: 'brown',
        hex: 'B09A7D',
        code: '16-1010',
      },
      {
        name: 'Greige',
        main: 'brown',
        hex: '928475',
        code: '16-1109',
      },
      {
        name: 'Cornstalk',
        main: 'brown',
        hex: 'A9947A',
        code: '16-1315',
      },
      {
        name: 'Brush',
        main: 'brown',
        hex: 'B99984',
        code: '16-1317',
      },
      {
        name: 'Stucco',
        main: 'brown',
        hex: 'A58D7F',
        code: '16-1412',
      },
      {
        name: 'Almondine',
        main: 'brown',
        hex: 'A78C7B',
        code: '16-1415',
      },
      {
        name: 'Fawn',
        main: 'brown',
        hex: 'AE9490',
        code: '16-1510',
      },
      {
        name: 'Crushed Rock',
        main: 'brown',
        hex: '9F958F',
        code: '16-3710',
      },
      {
        name: 'Mountain Trail',
        main: 'brown',
        hex: '897569',
        code: '17-0807',
      },
      {
        name: "Tiger's Eye",
        main: 'brown',
        hex: '977C61',
        code: '17-1038',
      },
      {
        name: 'Timber Wolf',
        main: 'brown',
        hex: '8D8070',
        code: '17-1310',
      },
      {
        name: 'Portabella',
        main: 'brown',
        hex: '937B6A',
        code: '17-1316',
      },
      {
        name: 'Affogat',
        main: 'brown',
        hex: 'A28774',
        code: '17-1318',
      },
      {
        name: 'Amphora',
        main: 'brown',
        hex: '9F8672',
        code: '17-1319',
      },
      {
        name: 'Brown Lentil',
        main: 'brown',
        hex: '7E695F',
        code: '17-1409',
      },
      {
        name: 'Beaver Fur',
        main: 'brown',
        hex: '997867',
        code: '17-1417',
      },
      {
        name: 'Raw Umber',
        main: 'brown',
        hex: '92705F',
        code: '17-1422',
      },
      {
        name: 'Cocoa Crème',
        main: 'brown',
        hex: '876D58',
        code: '18-1020',
      },
      {
        name: 'Sinopia Fresco',
        main: 'brown',
        hex: '917567',
        code: '17-1317',
      },
      {
        name: 'Tannin',
        main: 'brown',
        hex: 'A68A6D',
        code: '17-1320',
      },
      {
        name: 'Antler',
        main: 'brown',
        hex: '957A76',
        code: '17-1510',
      },
      {
        name: 'Coca Mocha',
        main: 'brown',
        hex: '856A57',
        code: '18-1019',
      },
      {
        name: 'Woodsmoke',
        main: 'brown',
        hex: '947764',
        code: '17-1321',
      },
      {
        name: 'Burro',
        main: 'brown',
        hex: '9E7E67',
        code: '17-1322',
      },
      {
        name: 'Cartouche',
        main: 'brown',
        hex: 'A88968',
        code: '17-1325',
      },
      {
        name: 'Pine Bark',
        main: 'brown',
        hex: '847064',
        code: '17-1410',
      },
      {
        name: 'Ginger Snap',
        main: 'brown',
        hex: '977D70',
        code: '17-1418',
      },
      {
        name: 'Brownie',
        main: 'brown',
        hex: '8F7265',
        code: '18-1321',
      },
      {
        name: 'Chocolate Malt',
        main: 'brown',
        hex: '907162',
        code: '18-1324',
      },
      {
        name: 'String',
        main: 'gray',
        hex: 'AA9F96',
        code: '16-1305',
      },
      {
        name: 'Moon Rock',
        main: 'gray',
        hex: '958B84',
        code: '17-1210',
      },
      {
        name: 'Burlwood',
        main: 'red',
        hex: '9B716B',
        code: '17-1516',
      },
    ],
  },
  {
    _id: 'Teal',
    colors: [
      {
        name: 'Wave Ride',
        main: 'blue',
        hex: '2D80A7',
        code: '17-4129',
      },
      {
        name: 'Tile Blue',
        main: 'blue',
        hex: '008491',
        code: '18-4735',
      },
      {
        name: 'Wasabi',
        main: 'green',
        hex: '73A89E',
        code: '16-5109',
      },
      {
        name: 'Dynasty Green',
        main: 'green',
        hex: '008E80',
        code: '17-5330',
      },
      {
        name: 'Alhambra',
        main: 'green',
        hex: '008778',
        code: '17-5430',
      },
      {
        name: 'Alpine Green',
        main: 'green',
        hex: '005F56',
        code: '18-5322',
      },
      {
        name: 'Antique Green',
        main: 'green',
        hex: '2A675C',
        code: '18-5418',
      },
      {
        name: 'Posy Green',
        main: 'green',
        hex: '325B51',
        code: '18-5616',
      },
      {
        name: 'Green Gables',
        main: 'green',
        hex: '324241',
        code: '19-4906',
      },
      {
        name: 'Spruced-up',
        main: 'green',
        hex: '075055',
        code: '19-4918',
      },
      {
        name: 'Sea Moss',
        main: 'green',
        hex: '264445',
        code: '19-5030',
      },
      {
        name: 'Forest Biome',
        main: 'green',
        hex: '194B46',
        code: '19-5230',
      },
      {
        name: 'Rain Forest',
        main: 'green',
        hex: '16463F',
        code: '19-5232',
      },
      {
        name: 'June Bug',
        main: 'green',
        hex: '264A48',
        code: '19-5414',
      },
      {
        name: 'Cadmium Green',
        main: 'green',
        hex: '00675B',
        code: '18-5424',
      },
      {
        name: 'Bear Grass',
        main: 'green',
        hex: '00695B',
        code: '18-5425',
      },
      {
        name: 'Storm',
        main: 'green',
        hex: '035453',
        code: '19-5217',
      },
      {
        name: 'Ponderosa Pine',
        main: 'green',
        hex: '203B3D',
        code: '19-5320',
      },
      {
        name: 'Sporting Green',
        main: 'green',
        hex: '007667',
        code: '17-5527',
      },
      {
        name: 'Tidepool',
        main: 'green',
        hex: '0B6F69',
        code: '18-5619',
      },
      {
        name: 'Shady Glade',
        main: 'green',
        hex: '006E5B',
        code: '18-5624',
      },
      {
        name: 'Smoke Pine',
        main: 'green',
        hex: '3E6257',
        code: '18-5718',
      },
      {
        name: 'Mallard Green',
        main: 'green',
        hex: '405E5C',
        code: '19-4818',
      },
      {
        name: 'Magical Forest',
        main: 'green',
        hex: '2E3C41',
        code: '19-4908',
      },
      {
        name: 'Botanical Garden',
        main: 'green',
        hex: '12403C',
        code: '19-5220',
      },
      {
        name: 'Columbia',
        main: 'green',
        hex: '009288',
        code: '17-5130',
      },
      {
        name: 'Porcelain Green',
        main: 'green',
        hex: '108780',
        code: '17-5421',
      },
      {
        name: 'Slushy',
        main: 'green',
        hex: '008A7A',
        code: '17-5730',
      },
      {
        name: 'Galapagos Green',
        main: 'green',
        hex: '29685F',
        code: '18-5725',
      },
      {
        name: 'Hydro',
        main: 'blue',
        hex: '426972',
        code: '18-4718',
      },
      {
        name: 'Gulf Coast',
        main: 'blue',
        hex: '0A4F5C',
        code: '19-4523',
      },
      {
        name: 'Bright Aqua',
        main: 'aqua',
        hex: '30A299',
        code: '16-5422',
      },
      {
        name: 'Bristol Blue',
        main: 'blue',
        hex: '568F91',
        code: '17-4818',
      },
      {
        name: 'Latigo Bay',
        main: 'blue',
        hex: '379190',
        code: '17-5122',
      },
      {
        name: 'Viridian Green',
        main: 'blue',
        hex: '009499',
        code: '17-5126',
      },
      {
        name: 'Deep Lake',
        main: 'blue',
        hex: '00656B',
        code: '18-4834',
      },
      {
        name: 'Fanfare',
        main: 'blue',
        hex: '006D70',
        code: '18-4936',
      },
      {
        name: 'Proud Peacock',
        main: 'blue',
        hex: '006D65',
        code: '18-5016',
      },
      {
        name: 'Parasailing',
        main: 'blue',
        hex: '00736C',
        code: '18-5020',
      },
      {
        name: 'North Sea',
        main: 'blue',
        hex: '316C6B',
        code: '18-5115',
      },
      {
        name: 'Bayou',
        main: 'blue',
        hex: '20706F',
        code: '18-5121',
      },
      {
        name: 'Shaded Spruce',
        main: 'blue',
        hex: '00585E',
        code: '19-4524',
      },
      {
        name: 'Atlantic Deep',
        main: 'blue',
        hex: '274E55',
        code: '19-4726',
      },
      {
        name: 'Balsam',
        main: 'blue',
        hex: '33565E',
        code: '19-4820',
      },
      {
        name: 'Pacific',
        main: 'blue',
        hex: '1F595C',
        code: '19-4916',
      },
      {
        name: 'Jasper',
        main: 'blue',
        hex: '335959',
        code: '19-5413',
      },
      {
        name: 'Sea Green',
        main: 'aqua',
        hex: '159C88',
        code: '16-5421',
      },
      {
        name: 'Water Garden',
        main: 'aqua',
        hex: '1BA491',
        code: '16-5426',
      },
      {
        name: 'Arcadia',
        main: 'aqua',
        hex: '00A28A',
        code: '16-5533',
      },
      {
        name: 'Spectra Green',
        main: 'aqua',
        hex: '009B8C',
        code: '17-5335',
      },
      {
        name: 'Canton',
        main: 'blue',
        hex: '6DA29E',
        code: '16-5112',
      },
      {
        name: 'Teal Blue',
        main: 'blue',
        hex: '007F7B',
        code: '17-5024',
      },
      {
        name: 'Navigate',
        main: 'blue',
        hex: '008583',
        code: '17-5025',
      },
      {
        name: 'Trellis',
        main: 'blue',
        hex: '6A8988',
        code: '17-5110',
      },
      {
        name: 'Green-Blue Slate',
        main: 'blue',
        hex: '358082',
        code: '17-5117',
      },
      {
        name: 'Ocean Floor',
        main: 'blue',
        hex: '00847A',
        code: '17-5440',
      },
      {
        name: 'Blue Grass',
        main: 'blue',
        hex: '007C7A',
        code: '18-5128',
      },
      {
        name: 'Bayberry',
        main: 'blue',
        hex: '255958',
        code: '18-5315',
      },
      {
        name: 'Everglade',
        main: 'blue',
        hex: '005B5D',
        code: '19-5226',
      },
      {
        name: 'Sea Blue',
        main: 'blue',
        hex: '549F98',
        code: '16-5119',
      },
      {
        name: 'Meadowbrook',
        main: 'blue',
        hex: '60A0A3',
        code: '16-5121',
      },
      {
        name: 'Agate Green',
        main: 'blue',
        hex: '599F99',
        code: '16-5412',
      },
      {
        name: 'Teal',
        main: 'blue',
        hex: '478589',
        code: '17-4919',
      },
      {
        name: 'Lapis',
        main: 'blue',
        hex: '008684',
        code: '17-5034',
      },
      {
        name: 'Kayaking',
        main: 'blue',
        hex: '00656A',
        code: '18-4833',
      },
      {
        name: 'Alexandrite',
        main: 'blue',
        hex: '00666C',
        code: '18-4835',
      },
      {
        name: 'Quetzal Green',
        main: 'blue',
        hex: '006866',
        code: '18-5025',
      },
      {
        name: 'Deep Jungle',
        main: 'blue',
        hex: '36716F',
        code: '18-5618',
      },
      {
        name: 'Mediterranea',
        main: 'blue',
        hex: '32575D',
        code: '19-4517',
      },
      {
        name: 'Chesapeake Bay',
        main: 'blue',
        hex: '16525A',
        code: '19-4534',
      },
      {
        name: 'Green Heron',
        main: 'blue',
        hex: '27535A',
        code: '19-4727',
      },
      {
        name: 'Deep Teal',
        main: 'blue',
        hex: '19454B',
        code: '19-4914',
      },
      {
        name: 'Billiard',
        main: 'aqua',
        hex: '00AA92',
        code: '16-5427',
      },
      {
        name: 'Stillwater',
        main: 'blue',
        hex: '70A4B0',
        code: '16-4610',
      },
      {
        name: 'Reef Waters',
        main: 'blue',
        hex: '6F9FA9',
        code: '16-4612',
      },
      {
        name: 'Lagoon',
        main: 'blue',
        hex: '4D9E9A',
        code: '16-5418',
      },
      {
        name: 'Deep Peacock Blue',
        main: 'blue',
        hex: '008381',
        code: '17-5029',
      },
      {
        name: 'Harbor Blue',
        main: 'blue',
        hex: '00656E',
        code: '18-4728',
      },
      {
        name: 'Tropical Green',
        main: 'blue',
        hex: '008786',
        code: '18-4930',
      },
      {
        name: 'Ivy',
        main: 'blue',
        hex: '226C63',
        code: '18-5620',
      },
      {
        name: 'Teal Green',
        main: 'blue',
        hex: '006361',
        code: '19-4922',
      },
    ],
  },
  {
    _id: 'Terra Cotta',
    colors: [
      {
        name: 'Muted Clay',
        main: 'brown',
        hex: 'D29380',
        code: '16-1330',
      },
      {
        name: 'Cork',
        main: 'brown',
        hex: 'BA8671',
        code: '16-1422',
      },
      {
        name: 'Tuscany',
        main: 'brown',
        hex: 'BE9785',
        code: '16-1219',
      },
      {
        name: 'Café Crème',
        main: 'brown',
        hex: 'C79685',
        code: '16-1220',
      },
      {
        name: 'Raw Sienna',
        main: 'brown',
        hex: 'B9714F',
        code: '17-1436',
      },
      {
        name: 'Maple Syrup',
        main: 'brown',
        hex: '884D40',
        code: '18-1341',
      },
      {
        name: 'Copper Brown',
        main: 'brown',
        hex: '9A6051',
        code: '18-1336',
      },
      {
        name: 'Picante',
        main: 'brown',
        hex: '8D3F2D',
        code: '19-1250',
      },
      {
        name: 'Canyon Clay',
        main: 'orange',
        hex: 'CE8477',
        code: '16-1431',
      },
      {
        name: 'Crabapple',
        main: 'orange',
        hex: 'D77E70',
        code: '16-1532',
      },
      {
        name: 'Terra Cotta',
        main: 'orange',
        hex: 'D38377',
        code: '16-1526',
      },
      {
        name: 'Cameo Brown',
        main: 'red',
        hex: 'C08A80',
        code: '16-1516',
      },
      {
        name: 'Brick Dust',
        main: 'red',
        hex: 'B07069',
        code: '17-1424',
      },
      {
        name: 'Withered Rose',
        main: 'red',
        hex: 'A26666',
        code: '18-1435',
      },
      {
        name: 'Auburn',
        main: 'red',
        hex: 'A15843',
        code: '18-1343',
      },
      {
        name: 'Rose Dawn',
        main: 'pink',
        hex: 'C2877B',
        code: '16-1522',
      },
      {
        name: 'Aragon',
        main: 'red',
        hex: 'B06454',
        code: '17-1532',
      },
      {
        name: 'Bruschetta',
        main: 'red',
        hex: 'A75949',
        code: '18-1346',
      },
      {
        name: 'Desert Sand',
        main: 'red',
        hex: 'BD7B74',
        code: '17-1524',
      },
      {
        name: 'Cedar Wood',
        main: 'red',
        hex: 'A1665B',
        code: '17-1525',
      },
    ],
  },
  {
    _id: 'Tomato Red',
    colors: [
      {
        name: "Puffin's Bill",
        main: 'orange',
        hex: 'E95C20',
        code: '16-1363',
      },
      {
        name: 'Scarlet Ibis',
        main: 'orange',
        hex: 'F45720',
        code: '17-1361',
      },
      {
        name: 'Mandarin Red',
        main: 'orange',
        hex: 'E74A33',
        code: '17-1562',
      },
      {
        name: 'Orangeade',
        main: 'orange',
        hex: 'E2552D',
        code: '17-1461',
      },
      {
        name: 'Flame',
        main: 'orange',
        hex: 'F2552C',
        code: '17-1462',
      },
      {
        name: 'Red Orange',
        main: 'orange',
        hex: 'F05627',
        code: '17-1464',
      },
      {
        name: 'Pureed Pumpkin',
        main: 'orange',
        hex: 'C34121',
        code: '17-1449',
      },
      {
        name: 'Tigerlily',
        main: 'orange',
        hex: 'E2583E',
        code: '17-1456',
      },
      {
        name: 'Tangerine Tango',
        main: 'orange',
        hex: 'DD4124',
        code: '17-1463',
      },
      {
        name: 'Spicy Orange',
        main: 'orange',
        hex: 'D73D26',
        code: '18-1445',
      },
      {
        name: 'Chili',
        main: 'red',
        hex: 'BE5141',
        code: '18-1448',
      },
      {
        name: 'Summer Fig',
        main: 'red',
        hex: 'BE4B3B',
        code: '17-1450',
      },
      {
        name: 'Paprika',
        main: 'red',
        hex: 'CE4D42',
        code: '17-1553',
      },
      {
        name: 'Grenadine',
        main: 'red',
        hex: 'DF3F32',
        code: '17-1558',
      },
      {
        name: 'Cherry Tomato',
        main: 'red',
        hex: 'EB3C27',
        code: '17-1563',
      },
      {
        name: 'Fiesta',
        main: 'red',
        hex: 'DD4132',
        code: '17-1564',
      },
      {
        name: 'Burnt Ochre',
        main: 'red',
        hex: 'BB4F35',
        code: '18-1354',
      },
      {
        name: 'Red Clay',
        main: 'red',
        hex: 'C2452D',
        code: '18-1454',
      },
      {
        name: 'Aurora Red',
        main: 'red',
        hex: 'B93A32',
        code: '18-1550',
      },
      {
        name: 'Orange.com',
        main: 'red',
        hex: 'DA321C',
        code: '18-1561',
      },
      {
        name: 'Hibiscus',
        main: 'red',
        hex: 'DE3848',
        code: '18-1762',
      },
      {
        name: 'Red Alert',
        main: 'red',
        hex: 'D0342C',
        code: '18-1559',
      },
      {
        name: 'Tomato',
        main: 'red',
        hex: 'CE2939',
        code: '18-1660',
      },
      {
        name: 'Valiant Poppy',
        main: 'red',
        hex: 'BD332D',
        code: '18-1549',
      },
      {
        name: 'Poinciana',
        main: 'red',
        hex: 'CA3422',
        code: '18-1564',
      },
      {
        name: 'Tomato Puree',
        main: 'red',
        hex: 'C53346',
        code: '18-1661',
      },
    ],
  },
  {
    _id: 'Turquoise',
    colors: [
      {
        name: 'Milky Blue',
        main: 'blue',
        hex: '72A8BA',
        code: '15-4415',
      },
      {
        name: 'Sea Jet',
        main: 'blue',
        hex: '54AFBC',
        code: '15-4713',
      },
      {
        name: 'Water Sign',
        main: 'blue',
        hex: '67B2BD',
        code: '15-4512',
      },
      {
        name: 'Coastal Waters',
        main: 'blue',
        hex: '5DA6B1',
        code: '15-4719',
      },
      {
        name: 'Blue Curacao',
        main: 'blue',
        hex: '33BECC',
        code: '15-4825',
      },
      {
        name: 'Fluidity',
        main: 'blue',
        hex: '62B4B8',
        code: '15-4912',
      },
      {
        name: 'Bluebird',
        main: 'blue',
        hex: '009DAE',
        code: '16-4834',
      },
      {
        name: 'Aquarius',
        main: 'blue',
        hex: '3CADD4',
        code: '16-4530',
      },
      {
        name: 'Peacock Blue',
        main: 'blue',
        hex: '00A0B0',
        code: '16-4728',
      },
      {
        name: 'Scuba Blue',
        main: 'blue',
        hex: '00ABC0',
        code: '16-4725',
      },
      {
        name: 'Dreamy Green',
        main: 'green',
        hex: '91D9BC',
        code: '13-5710',
      },
      {
        name: 'Graciously Green',
        main: 'green',
        hex: 'ABDDC9',
        code: '13-6010',
      },
      {
        name: 'Ice Castle',
        main: 'aqua',
        hex: 'DAF2F1',
        code: '11-4606',
      },
      {
        name: 'Pastel Turquoise',
        main: 'aqua',
        hex: '99C5C4',
        code: '13-5309',
      },
      {
        name: 'Beach Glass',
        main: 'aqua',
        hex: '96DFCE',
        code: '13-5412',
      },
      {
        name: 'Cabbage',
        main: 'aqua',
        hex: '87D7BE',
        code: '13-5714',
      },
      {
        name: 'Aqua Wash',
        main: 'aqua',
        hex: '4AC2BB',
        code: '14-5125',
      },
      {
        name: 'Ocean Wave',
        main: 'aqua',
        hex: '8EC5B6',
        code: '14-5711',
      },
      {
        name: 'Barbados Beach',
        main: 'aqua',
        hex: '63B7C5',
        code: '15-4710',
      },
      {
        name: 'Capri',
        main: 'aqua',
        hex: '44BBCA',
        code: '15-4722',
      },
      {
        name: 'Aqua Haze',
        main: 'aqua',
        hex: '87B9BB',
        code: '15-5209',
      },
      {
        name: 'Mint Julep',
        main: 'aqua',
        hex: 'D1EFED',
        code: '12-5208',
      },
      {
        name: 'Soothing Sea',
        main: 'aqua',
        hex: 'C3E9E4',
        code: '12-5209',
      },
      {
        name: 'Fair Aqua',
        main: 'aqua',
        hex: 'B8E2DC',
        code: '12-5409',
      },
      {
        name: 'Honeydew',
        main: 'aqua',
        hex: 'BAE1D3',
        code: '12-5808',
      },
      {
        name: 'Icy Morn',
        main: 'aqua',
        hex: 'B0D3D1',
        code: '13-5306',
      },
      {
        name: 'Ice Green',
        main: 'aqua',
        hex: '87D8C3',
        code: '13-5414',
      },
      {
        name: 'Ocean Air',
        main: 'aqua',
        hex: 'A9D8D0',
        code: '13-5512',
      },
      {
        name: 'Blue Radiance',
        main: 'aqua',
        hex: '58C9D4',
        code: '14-4816',
      },
      {
        name: 'Rinsing Rivulet',
        main: 'aqua',
        hex: '5CC6C3',
        code: '14-4912',
      },
      {
        name: 'Aquacade',
        main: 'aqua',
        hex: '6FB5B5',
        code: '14-5117',
      },
      {
        name: 'Dusky Aqua',
        main: 'aqua',
        hex: '68C3C0',
        code: '14-5320',
      },
      {
        name: 'Pool Cabana',
        main: 'aqua',
        hex: '74C1B6',
        code: '14-5419',
      },
      {
        name: 'Ipanema',
        main: 'aqua',
        hex: '62BBC5',
        code: '15-4716',
      },
      {
        name: 'Pool Blue',
        main: 'aqua',
        hex: '67BCB3',
        code: '15-5218',
      },
      {
        name: 'Bleached Aqua',
        main: 'aqua',
        hex: 'BCE3DF',
        code: '12-5410',
      },
      {
        name: 'Limpet Shell',
        main: 'aqua',
        hex: '98DDDF',
        code: '13-4810',
      },
      {
        name: 'Aruba Blue',
        main: 'aqua',
        hex: '81D7D3',
        code: '13-5313',
      },
      {
        name: 'Yucca',
        main: 'aqua',
        hex: 'A1D7C9',
        code: '13-5409',
      },
      {
        name: 'Antigua Sand',
        main: 'aqua',
        hex: '84C3CE',
        code: '14-4615',
      },
      {
        name: 'Tibetan Stone',
        main: 'aqua',
        hex: '83C2C7',
        code: '14-4710',
      },
      {
        name: 'Eggshell Blue',
        main: 'aqua',
        hex: 'A3CCC9',
        code: '14-4809',
      },
      {
        name: 'Aqua Splash',
        main: 'aqua',
        hex: '85CED1',
        code: '14-4812',
      },
      {
        name: 'Frostbite',
        main: 'aqua',
        hex: '8EC7C0',
        code: '14-5412',
      },
      {
        name: 'Bermuda',
        main: 'aqua',
        hex: '60C8B3',
        code: '14-5416',
      },
      {
        name: 'Cockatoo',
        main: 'aqua',
        hex: '58C8B6',
        code: '14-5420',
      },
      {
        name: 'Cyan Sea',
        main: 'aqua',
        hex: '7FC6C2',
        code: '14-5520',
      },
      {
        name: 'Cool Caribbean',
        main: 'aqua',
        hex: '7EC5B7',
        code: '14-5618',
      },
      {
        name: 'Aqua',
        main: 'aqua',
        hex: '65A1AD',
        code: '15-4717',
      },
      {
        name: 'Salt Air',
        main: 'aqua',
        hex: 'C9EDED',
        code: '12-5207',
      },
      {
        name: 'Bay',
        main: 'aqua',
        hex: 'BAE5D6',
        code: '12-5507',
      },
      {
        name: 'Blue Light',
        main: 'aqua',
        hex: 'ACDFDD',
        code: '13-4909',
      },
      {
        name: 'Blue Tint',
        main: 'aqua',
        hex: '9FD9D7',
        code: '13-4910',
      },
      {
        name: 'Aqua Frost',
        main: 'aqua',
        hex: 'A1DBD2',
        code: '13-5320',
      },
      {
        name: 'Aqua Sky',
        main: 'aqua',
        hex: '7BC4C4',
        code: '14-4811',
      },
      {
        name: 'Leisure Time',
        main: 'aqua',
        hex: '7FBFC5',
        code: '14-4815',
      },
      {
        name: 'Holiday',
        main: 'aqua',
        hex: '81C3B4',
        code: '14-5413',
      },
      {
        name: 'Starling Egg',
        main: 'aqua',
        hex: '85C4B9',
        code: '14-5612',
      },
      {
        name: 'Aquarium',
        main: 'aqua',
        hex: '59B3AE',
        code: '15-5220',
      },
      {
        name: 'Aqua Green',
        main: 'aqua',
        hex: '00B89F',
        code: '15-5421',
      },
      {
        name: 'Atlantis',
        main: 'aqua',
        hex: '00AF9F',
        code: '15-5425',
      },
      {
        name: 'Delphinium Blue',
        main: 'aqua',
        hex: '6198AE',
        code: '16-4519',
      },
      {
        name: 'Amazonite',
        main: 'blue',
        hex: '77BABE',
        code: '14-4818',
      },
      {
        name: 'Ceramic',
        main: 'aqua',
        hex: '00AAA9',
        code: '16-5127',
      },
      {
        name: 'Aquarelle',
        main: 'blue',
        hex: '61AAB1',
        code: '15-4714',
      },
      {
        name: 'Nile Blue',
        main: 'blue',
        hex: '76A7AB',
        code: '15-5210',
      },
      {
        name: 'Blue Turquoise',
        main: 'blue',
        hex: '53B0AE',
        code: '15-5217',
      },
      {
        name: 'Porcelain',
        main: 'blue',
        hex: '5D9CA4',
        code: '16-4719',
      },
      {
        name: 'Florida Keys',
        main: 'aqua',
        hex: '57BEAB',
        code: '15-5416',
      },
      {
        name: 'Waterfall',
        main: 'aqua',
        hex: '3AB0A2',
        code: '15-5516',
      },
      {
        name: 'Baltic',
        main: 'aqua',
        hex: '279D9F',
        code: '16-5123',
      },
      {
        name: 'Coastal Shade',
        main: 'blue',
        hex: '6AA8AF',
        code: '15-4711',
      },
      {
        name: 'Marine Blue',
        main: 'blue',
        hex: '76AFB6',
        code: '15-4712',
      },
      {
        name: 'Aqua Sea',
        main: 'blue',
        hex: '6BAAAE',
        code: '15-4715',
      },
      {
        name: 'Dusty Turquoise',
        main: 'blue',
        hex: '649B9E',
        code: '16-5114',
      },
      {
        name: 'Turquoise',
        main: 'aqua',
        hex: '45B5AA',
        code: '15-5519',
      },
      {
        name: 'Pool Green',
        main: 'aqua',
        hex: '00AF9D',
        code: '16-5425',
      },
    ],
  },
  {
    _id: 'Violet',
    colors: [
      {
        name: 'Iolite',
        main: 'blue',
        hex: '707BB4',
        code: '17-3940',
      },
      {
        name: 'Dusted Peri',
        main: 'blue',
        hex: '696BA0',
        code: '18-3833',
      },
      {
        name: 'Pastel Lilac',
        main: 'blue',
        hex: 'BCAFCF',
        code: '14-3812',
      },
      {
        name: 'Lavendula',
        main: 'blue',
        hex: 'BCA4CB',
        code: '15-3620',
      },
      {
        name: 'Crocus Petal',
        main: 'blue',
        hex: 'B99BC5',
        code: '15-3520',
      },
      {
        name: 'Lavender Herb',
        main: 'blue',
        hex: 'B18EAA',
        code: '16-3310',
      },
      {
        name: 'Sand Verbena',
        main: 'blue',
        hex: '9F90C1',
        code: '16-3720',
      },
      {
        name: 'Violet Tulip',
        main: 'blue',
        hex: '9E91C3',
        code: '16-3823',
      },
      {
        name: 'Lilac Breeze',
        main: 'blue',
        hex: 'B4A0C9',
        code: '15-3720',
      },
      {
        name: 'Lavender',
        main: 'blue',
        hex: 'AFA4CE',
        code: '15-3817',
      },
      {
        name: 'Bougainvillea',
        main: 'blue',
        hex: '9883B9',
        code: '17-3725',
      },
      {
        name: 'Nirvana',
        main: 'blue',
        hex: 'A2919B',
        code: '17-3808',
      },
      {
        name: 'Aster Purple',
        main: 'blue',
        hex: '7D74A8',
        code: '17-3826',
      },
      {
        name: 'Lavender Violet',
        main: 'blue',
        hex: '767BA5',
        code: '17-3924',
      },
      {
        name: 'Pale Iris',
        main: 'blue',
        hex: '8896C6',
        code: '17-3929',
      },
      {
        name: 'Violetta',
        main: 'purple',
        hex: 'AB9CB5',
        code: '16-3615',
      },
      {
        name: 'Rhapsody',
        main: 'purple',
        hex: '9F86AA',
        code: '16-3817',
      },
      {
        name: 'Daybreak',
        main: 'blue',
        hex: '8981A0',
        code: '17-3817',
      },
      {
        name: 'Persian Violet',
        main: 'blue',
        hex: '8C8EB2',
        code: '17-3925',
      },
      {
        name: 'Deep Periwinkle',
        main: 'blue',
        hex: '7C83BC',
        code: '17-3932',
      },
      {
        name: 'Violet Dusk',
        main: 'purple',
        hex: 'C9B4CF',
        code: '14-3610',
      },
      {
        name: 'Jacaranda',
        main: 'blue',
        hex: '848DC5',
        code: '17-3930',
      },
      {
        name: 'Lacy Lilac',
        main: 'purple',
        hex: 'B3B1D2',
        code: '14-3926',
      },
      {
        name: 'Lavender Fields',
        main: 'purple',
        hex: 'ADAFD3',
        code: '14-3935',
      },
      {
        name: 'Chalk Violet',
        main: 'blue',
        hex: '8F7DA5',
        code: '17-3615',
      },
      {
        name: 'Sunlit Allium',
        main: 'blue',
        hex: '9787B8',
        code: '17-3735',
      },
      {
        name: 'Dusk',
        main: 'blue',
        hex: '897F98',
        code: '17-3812',
      },
      {
        name: 'Quiet Violet',
        main: 'purple',
        hex: 'A693AC',
        code: '16-3610',
      },
      {
        name: 'Hyacinth',
        main: 'purple',
        hex: '936CA7',
        code: '17-3619',
      },
      {
        name: 'Dahlia Purple',
        main: 'purple',
        hex: '7E6EAC',
        code: '17-3834',
      },
      {
        name: 'Purple Corallites',
        main: 'purple',
        hex: '5B4F8F',
        code: '18-3839',
      },
      {
        name: 'Paisley Purple',
        main: 'purple',
        hex: '8B79B1',
        code: '17-3730',
      },
      {
        name: 'Chive Blossom',
        main: 'purple',
        hex: '7E5E9A',
        code: '18-3634',
      },
      {
        name: 'Veronica',
        main: 'purple',
        hex: '6D6695',
        code: '18-3834',
      },
      {
        name: 'Ultra Violet',
        main: 'purple',
        hex: '5F4B8B',
        code: '18-3838',
      },
      {
        name: 'Purple Opulence',
        main: 'purple',
        hex: '60569A',
        code: '18-3840',
      },
      {
        name: 'Purple Haze',
        main: 'purple',
        hex: '817396',
        code: '18-3718',
      },
      {
        name: 'Passion Flower',
        main: 'purple',
        hex: '6D5698',
        code: '18-3737',
      },
      {
        name: 'Bright Violet',
        main: 'purple',
        hex: '794384',
        code: '19-3438',
      },
      {
        name: 'Meadow Violet',
        main: 'purple',
        hex: '764F82',
        code: '19-3526',
      },
    ],
  },
  {
    _id: 'White',
    colors: [
      {
        name: 'Bit of Blue',
        main: 'blue',
        hex: 'E2EAEB',
        code: '11-4601',
      },
      {
        name: 'Brilliant White',
        main: 'white',
        hex: 'EDF1FF',
        code: '11-4001',
      },
      {
        name: 'Blanc de Blanc',
        main: 'white',
        hex: 'E7E9E7',
        code: '11-4800',
      },
      {
        name: 'Snow White',
        main: 'white',
        hex: 'F2F0EB',
        code: '11-0602',
      },
      {
        name: 'Summer Shower',
        main: 'white',
        hex: 'E5EBE3',
        code: '11-4802',
      },
      {
        name: 'Bright White',
        main: 'white',
        hex: 'F4F9FF',
        code: '11-0601',
      },
      {
        name: 'Stalactite',
        main: 'white',
        hex: 'F7F6F3',
        code: '11-4101',
      },
      {
        name: 'Lucent White',
        main: 'white',
        hex: 'F4F7FF',
        code: '11-0700',
      },
      {
        name: 'Wispy Clouds',
        main: 'gray',
        hex: 'F0EFEE',
        code: '11-3900',
      },
      {
        name: 'Ice Castle',
        main: 'aqua',
        hex: 'DAF2F1',
        code: '11-4606',
      },
      {
        name: 'Diaphonous',
        main: 'aqua',
        hex: 'E7F4F2',
        code: '11-4607',
      },
    ],
  },
  {
    _id: 'Wine',
    colors: [
      {
        name: 'Quail',
        main: 'brown',
        hex: '98868B',
        code: '17-1505',
      },
      {
        name: 'Magnetite',
        main: 'brown',
        hex: '81787A',
        code: '17-2501',
      },
      {
        name: 'Lilas',
        main: 'pink',
        hex: 'B88995',
        code: '16-1708',
      },
      {
        name: 'Polignac',
        main: 'pink',
        hex: 'C28799',
        code: '16-1712',
      },
      {
        name: 'Mauvewood',
        main: 'pink',
        hex: 'A75D67',
        code: '17-1522',
      },
      {
        name: 'Mesa Rose',
        main: 'pink',
        hex: 'A76E7A',
        code: '17-1609',
      },
      {
        name: 'Deco Rose',
        main: 'pink',
        hex: '985F68',
        code: '17-1614',
      },
      {
        name: 'Nostalgia Rose',
        main: 'pink',
        hex: 'A4777E',
        code: '17-1512',
      },
      {
        name: 'Dusky Orchid',
        main: 'pink',
        hex: '9A7182',
        code: '17-1610',
      },
      {
        name: 'Rose Taupe',
        main: 'pink',
        hex: '806062',
        code: '18-1612',
      },
      {
        name: 'Woodrose',
        main: 'red',
        hex: 'AE8C8E',
        code: '16-1806',
      },
      {
        name: 'Violet Quartz',
        main: 'red',
        hex: '8B4963',
        code: '18-1720',
      },
      {
        name: 'Hawthorn Rose',
        main: 'red',
        hex: '884C5E',
        code: '18-1718',
      },
      {
        name: 'Zephyr',
        main: 'pink',
        hex: 'C89FA5',
        code: '15-1906',
      },
      {
        name: 'Foxglove',
        main: 'pink',
        hex: 'B98391',
        code: '16-1710',
      },
      {
        name: 'Heather Rose',
        main: 'pink',
        hex: 'AD6D7F',
        code: '17-1608',
      },
      {
        name: 'Malaga',
        main: 'pink',
        hex: '9F5069',
        code: '17-1723',
      },
      {
        name: 'Rose Brown',
        main: 'pink',
        hex: '80565B',
        code: '18-1512',
      },
      {
        name: 'Twilight Mauve',
        main: 'pink',
        hex: '8B6F70',
        code: '18-1807',
      },
      {
        name: 'Deauville Mauve',
        main: 'red',
        hex: 'AF9293',
        code: '16-1707',
      },
      {
        name: 'Damson',
        main: 'red',
        hex: '854C65',
        code: '18-1716',
      },
      {
        name: 'Elderberry',
        main: 'blue',
        hex: '9D848E',
        code: '17-1605',
      },
      {
        name: 'Wistful Mauve',
        main: 'purple',
        hex: '946C74',
        code: '17-1511',
      },
      {
        name: 'Toadstool',
        main: 'purple',
        hex: '998089',
        code: '17-2411',
      },
      {
        name: 'Flint',
        main: 'blue',
        hex: '705861',
        code: '18-1405',
      },
      {
        name: 'Ephemera',
        main: 'blue',
        hex: '705A66',
        code: '18-1708',
      },
      {
        name: 'Mellow Mauve',
        main: 'purple',
        hex: '996378',
        code: '17-1612',
      },
      {
        name: 'Arctic Dusk',
        main: 'blue',
        hex: '735B6A',
        code: '18-1705',
      },
      {
        name: 'Pale Mauve',
        main: 'purple',
        hex: 'C6A4A4',
        code: '15-1607',
      },
      {
        name: 'Bordeaux',
        main: 'purple',
        hex: '96637B',
        code: '17-1710',
      },
      {
        name: 'Red Violet',
        main: 'purple',
        hex: 'A35776',
        code: '17-1818',
      },
      {
        name: 'Moonscape',
        main: 'blue',
        hex: '73606A',
        code: '17-1708',
      },
      {
        name: 'Black Plum',
        main: 'blue',
        hex: '6D5765',
        code: '18-1706',
      },
      {
        name: 'Rose Wine',
        main: 'purple',
        hex: 'A4596D',
        code: '17-1623',
      },
      {
        name: 'Grape Nectar',
        main: 'purple',
        hex: '8D5C74',
        code: '18-1710',
      },
      {
        name: 'Plum Wine',
        main: 'purple',
        hex: '674550',
        code: '18-1411',
      },
      {
        name: 'Crushed Berry',
        main: 'purple',
        hex: '804F5A',
        code: '18-1418',
      },
      {
        name: 'Purple Gumdrop',
        main: 'purple',
        hex: '7A596F',
        code: '18-3012',
      },
      {
        name: 'Berry Conserve',
        main: 'purple',
        hex: '765269',
        code: '18-3013',
      },
      {
        name: 'Beaujolais',
        main: 'purple',
        hex: '80304C',
        code: '18-2027',
      },
      {
        name: 'Grape Kiss',
        main: 'purple',
        hex: '7B4468',
        code: '18-3014',
      },
      {
        name: 'Raspberry Coulis',
        main: 'purple',
        hex: '833A5B',
        code: '18-1722',
      },
      {
        name: 'Argyle Purple',
        main: 'purple',
        hex: '895C79',
        code: '18-3011',
      },
      {
        name: 'Prune Purple',
        main: 'purple',
        hex: '5C3A4D',
        code: '19-1608',
      },
      {
        name: 'Red Plum',
        main: 'purple',
        hex: '7C2946',
        code: '19-2025',
      },
      {
        name: 'Purple Potion',
        main: 'purple',
        hex: '692746',
        code: '19-2430',
      },
      {
        name: 'Winter Bloom',
        main: 'purple',
        hex: '47253C',
        code: '19-2620',
      },
      {
        name: 'Blackberry Wine',
        main: 'purple',
        hex: '4E3246',
        code: '19-2816',
      },
      {
        name: 'Mysterioso',
        main: 'purple',
        hex: '46394B',
        code: '19-3617',
      },
      {
        name: 'Anemone',
        main: 'purple',
        hex: '842C48',
        code: '19-2033',
      },
      {
        name: 'Crushed Violets',
        main: 'purple',
        hex: '643A4C',
        code: '19-2312',
      },
      {
        name: 'Magenta Purple',
        main: 'purple',
        hex: '6C244C',
        code: '19-2428',
      },
      {
        name: 'Plum Caspia',
        main: 'purple',
        hex: '61234A',
        code: '19-2429',
      },
      {
        name: 'Boysenberry',
        main: 'purple',
        hex: '85325C',
        code: '19-2431',
      },
      {
        name: 'Raspberry Radiance',
        main: 'purple',
        hex: '802A50',
        code: '19-2432',
      },
      {
        name: 'Dark Purple',
        main: 'purple',
        hex: '592147',
        code: '19-2524',
      },
      {
        name: 'Eggplant',
        main: 'purple',
        hex: '623F4C',
        code: '19-2311',
      },
      {
        name: 'Grape Wine',
        main: 'purple',
        hex: '5A2F43',
        code: '19-2315',
      },
      {
        name: 'Pickled Beet',
        main: 'purple',
        hex: '4D243D',
        code: '19-2420',
      },
      {
        name: 'Hortensia',
        main: 'purple',
        hex: '553B50',
        code: '19-2009',
      },
      {
        name: 'Amaranth',
        main: 'purple',
        hex: '6F3C56',
        code: '19-2410',
      },
      {
        name: 'Italian Plum',
        main: 'purple',
        hex: '533146',
        code: '19-2514',
      },
      {
        name: 'Potent Purple',
        main: 'purple',
        hex: '462639',
        code: '19-2520',
      },
    ],
  },
];

const getLightness = (hex) => {
  const r = parseInt(hex.slice(1, 3), 16) / 255;
  const g = parseInt(hex.slice(3, 5), 16) / 255;
  const b = parseInt(hex.slice(5, 7), 16) / 255;
  const adjustedR =
    r <= 0.03928 ? r / 12.92 : Math.pow((r + 0.055) / 1.055, 2.4);
  const adjustedG =
    g <= 0.03928 ? g / 12.92 : Math.pow((g + 0.055) / 1.055, 2.4);
  const adjustedB =
    b <= 0.03928 ? b / 12.92 : Math.pow((b + 0.055) / 1.055, 2.4);
  return 0.2126 * adjustedR + 0.7152 * adjustedG + 0.0722 * adjustedB;
};

const groupedAndSort = [
  'Scarlet',
  'Magenta',
  'Cherry',
  'Wine',
  'Tomato Red',
  'Burgundy',
  'Mahogany',
  'Rose',
  'Blush',
  'Bubblegum',
  'Fuchsia',
  'Magenta',
  'Lavender',
  'Orchid',
  'Plum',
  'Grape',
  'Violet',
  'Midnight Blue',
  'Navy',
  'Royal Blue',
  'Powder Blue',
  'Baby Blue',
  'Sky',
  'Pastel Blue',
  'Turquoise',
  'Pistachio',
  'Teal',
  'Emerald',
  'Pine Green',
  'Forest Green',
  'Olive',
  'Seafoam',
  'Sage',
  'Mint',
  'Matcha',
  'Lime',
  'Citrine',
  'Lemon',
  'Sunshine',
  'Canary',
  'Mustard',
  'Pumpkin',
  'Tangerine',
  'Apricot',
  'Peach',
  'Coral',
  'Salmon',
  'Champagne',
  'Sand',
  'Oatmeal',
  'Ecru',
  'Almond',
  'Bronze',
  'Caramel',
  'Hazel',
  'Cinnamon',
  'Honey',
  'Camel',
  'Taupe',
  'Stone',
  'Mushroom',
  'Soil',
  'Sepia',
  'Chocolate',
  'Rust',
  'Terra Cotta',
  'Charcoal',
  'Gunmetal',
  'Steel',
  'Platinum',
  'Smoke',
  'Slate',
  'Black',
  'White',
  'Peach',
  'Pastel Green',
  'Pastel Orange',
  'Pastel Pink',
  'Pastel Purple',
  'Pastel Yellow',
];

export const subColorOptions = exportSubColors
  .map((item) => ({
    hex: '#' + item.colors[0].hex,
    hexList: item.colors
      .map((color) => '#' + color.hex)
      .reduce((p, n, i, arr) => {
        if (p.length === 10) {
          return [...new Set(p)];
        }
        if (i % 3 === 0) {
          p.push(n);
        }
        if (i === arr.length - 1 && p.length < 10) {
          p.push(...arr);
          if ([...new Set(p)].length >= 10) {
            return [...new Set(p)];
          } else {
            return [...new Set(p), ...arr];
          }
        }
        return p;
      }, [])
      .sort((a, b) => {
        return getLightness(b) - getLightness(a);
      })
      .slice(0, 10),
    label: item._id.trim(),
    value: item._id.toLowerCase().trim(),
  }))
  .sort((a, b) => {
    return groupedAndSort.indexOf(a.label) - groupedAndSort.indexOf(b.label);
  });

export const subColorsNumerate = Object.values({
  almond: {
    id: 1,
    name: 'almond',
    enumName: 'ALMOND',
  },
  apricot: {
    id: 2,
    name: 'apricot',
    enumName: 'APRICOT',
  },
  'baby blue': {
    id: 3,
    name: 'baby blue',
    enumName: 'BABY_BLUE',
  },
  black: {
    id: 4,
    name: 'black',
    enumName: 'BLACK',
  },
  blush: {
    id: 5,
    name: 'blush',
    enumName: 'BLUSH',
  },
  bronze: {
    id: 6,
    name: 'bronze',
    enumName: 'BRONZE',
  },
  bubblegum: {
    id: 7,
    name: 'bubblegum',
    enumName: 'BUBBLEGUM',
  },
  burgundy: {
    id: 8,
    name: 'burgundy',
    enumName: 'BURGUNDY',
  },
  camel: {
    id: 9,
    name: 'camel',
    enumName: 'CAMEL',
  },
  canary: {
    id: 10,
    name: 'canary',
    enumName: 'CANARY',
  },
  caramel: {
    id: 11,
    name: 'caramel',
    enumName: 'CARAMEL',
  },
  champagne: {
    id: 12,
    name: 'champagne',
    enumName: 'CHAMPAGNE',
  },
  charcoal: {
    id: 13,
    name: 'charcoal',
    enumName: 'CHARCOAL',
  },
  cherry: {
    id: 14,
    name: 'cherry',
    enumName: 'CHERRY',
  },
  chocolate: {
    id: 15,
    name: 'chocolate',
    enumName: 'CHOCOLATE',
  },
  cinnamon: {
    id: 16,
    name: 'cinnamon',
    enumName: 'CINNAMON',
  },
  citrine: {
    id: 17,
    name: 'citrine',
    enumName: 'CITRINE',
  },
  coral: {
    id: 18,
    name: 'coral',
    enumName: 'CORAL',
  },
  ecru: {
    id: 19,
    name: 'ecru',
    enumName: 'ECRU',
  },
  emerald: {
    id: 20,
    name: 'emerald',
    enumName: 'EMERALD',
  },
  'forest green': {
    id: 21,
    name: 'forest green',
    enumName: 'FOREST_GREEN',
  },
  fuchsia: {
    id: 22,
    name: 'fuchsia',
    enumName: 'FUCHSIA',
  },
  grape: {
    id: 23,
    name: 'grape',
    enumName: 'GRAPE',
  },
  gunmetal: {
    id: 24,
    name: 'gunmetal',
    enumName: 'GUNMETAL',
  },
  hazel: {
    id: 25,
    name: 'hazel',
    enumName: 'HAZEL',
  },
  honey: {
    id: 26,
    name: 'honey',
    enumName: 'HONEY',
  },
  lavender: {
    id: 27,
    name: 'lavender',
    enumName: 'LAVENDER',
  },
  lemon: {
    id: 28,
    name: 'lemon',
    enumName: 'LEMON',
  },
  lime: {
    id: 29,
    name: 'lime',
    enumName: 'LIME',
  },
  magenta: {
    id: 30,
    name: 'magenta',
    enumName: 'MAGENTA',
  },
  mahogany: {
    id: 31,
    name: 'mahogany',
    enumName: 'MAHOGANY',
  },
  matcha: {
    id: 32,
    name: 'matcha',
    enumName: 'MATCHA',
  },
  'midnight blue': {
    id: 33,
    name: 'midnight blue',
    enumName: 'MIDNIGHT_BLUE',
  },
  mint: {
    id: 34,
    name: 'mint',
    enumName: 'MINT',
  },
  mushroom: {
    id: 35,
    name: 'mushroom',
    enumName: 'MUSHROOM',
  },
  mustard: {
    id: 36,
    name: 'mustard',
    enumName: 'MUSTARD',
  },
  navy: {
    id: 37,
    name: 'navy',
    enumName: 'NAVY',
  },
  oatmeal: {
    id: 38,
    name: 'oatmeal',
    enumName: 'OATMEAL',
  },
  olive: {
    id: 39,
    name: 'olive',
    enumName: 'OLIVE',
  },
  orchid: {
    id: 40,
    name: 'orchid',
    enumName: 'ORCHID',
  },
  'pastel blue': {
    id: 41,
    name: 'pastel blue',
    enumName: 'PASTEL_BLUE',
  },
  'pastel green': {
    id: 42,
    name: 'pastel green',
    enumName: 'PASTEL_GREEN',
  },
  'pastel orange': {
    id: 43,
    name: 'pastel orange',
    enumName: 'PASTEL_ORANGE',
  },
  'pastel pink': {
    id: 44,
    name: 'pastel pink',
    enumName: 'PASTEL_PINK',
  },
  'pastel purple': {
    id: 45,
    name: 'pastel purple',
    enumName: 'PASTEL_PURPLE',
  },
  'pastel yellow': {
    id: 46,
    name: 'pastel yellow',
    enumName: 'PASTEL_YELLOW',
  },
  peach: {
    id: 47,
    name: 'peach',
    enumName: 'PEACH',
  },
  'pine green': {
    id: 48,
    name: 'pine green',
    enumName: 'PINE_GREEN',
  },
  pistachio: {
    id: 49,
    name: 'pistachio',
    enumName: 'PISTACHIO',
  },
  platinum: {
    id: 50,
    name: 'platinum',
    enumName: 'PLATINUM',
  },
  plum: {
    id: 51,
    name: 'plum',
    enumName: 'PLUM',
  },
  'powder blue': {
    id: 52,
    name: 'powder blue',
    enumName: 'POWDER_BLUE',
  },
  pumpkin: {
    id: 53,
    name: 'pumpkin',
    enumName: 'PUMPKIN',
  },
  rose: {
    id: 54,
    name: 'rose',
    enumName: 'ROSE',
  },
  'royal blue': {
    id: 55,
    name: 'royal blue',
    enumName: 'ROYAL_BLUE',
  },
  rust: {
    id: 56,
    name: 'rust',
    enumName: 'RUST',
  },
  sage: {
    id: 57,
    name: 'sage',
    enumName: 'SAGE',
  },
  salmon: {
    id: 58,
    name: 'salmon',
    enumName: 'SALMON',
  },
  sand: {
    id: 59,
    name: 'sand',
    enumName: 'SAND',
  },
  scarlet: {
    id: 60,
    name: 'scarlet',
    enumName: 'SCARLET',
  },
  seafoam: {
    id: 61,
    name: 'seafoam',
    enumName: 'SEAFOAM',
  },
  sepia: {
    id: 62,
    name: 'sepia',
    enumName: 'SEPIA',
  },
  sky: {
    id: 63,
    name: 'sky',
    enumName: 'SKY',
  },
  slate: {
    id: 64,
    name: 'slate',
    enumName: 'SLATE',
  },
  smoke: {
    id: 65,
    name: 'smoke',
    enumName: 'SMOKE',
  },
  soil: {
    id: 66,
    name: 'soil',
    enumName: 'SOIL',
  },
  steel: {
    id: 67,
    name: 'steel',
    enumName: 'STEEL',
  },
  stone: {
    id: 68,
    name: 'stone',
    enumName: 'STONE',
  },
  sunshine: {
    id: 69,
    name: 'sunshine',
    enumName: 'SUNSHINE',
  },
  tangerine: {
    id: 70,
    name: 'tangerine',
    enumName: 'TANGERINE',
  },
  taupe: {
    id: 71,
    name: 'taupe',
    enumName: 'TAUPE',
  },
  teal: {
    id: 72,
    name: 'teal',
    enumName: 'TEAL',
  },
  'terra cotta': {
    id: 73,
    name: 'terra cotta',
    enumName: 'TERRA_COTTA',
  },
  'tomato red': {
    id: 74,
    name: 'tomato red',
    enumName: 'TOMATO_RED',
  },
  turquoise: {
    id: 75,
    name: 'turquoise',
    enumName: 'TURQUOISE',
  },
  violet: {
    id: 76,
    name: 'violet',
    enumName: 'VIOLET',
  },
  white: {
    id: 77,
    name: 'white',
    enumName: 'WHITE',
  },
  wine: {
    id: 78,
    name: 'wine',
    enumName: 'WINE',
  },
}).reduce((acc, curr) => {
  acc[curr.id] = curr;
  return acc;
}, {});
