import { useEffect, useMemo, useState } from 'react';
import {
  useLocation,
  useNavigate,
  useNavigationType,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import { NetworkStatus } from '@apollo/client';
import * as Tabs from '@radix-ui/react-tabs';

import { useTrendsQuery } from '@/api/trends/hook';
import { DemoAccountModal } from '@/components/DemoAccountPopup';
import { TabHeading } from '@/components/TabHeading';
import { useAppFilters } from '@/contexts/AppFiltersContext';
import { useTrendsContext } from '@/contexts/TrendsContext';
import { cn } from '@/helpers/utils';

import { LazyLoadButton } from '../../explore/components/utils';
import { TrendsInsights } from '../pages/insights';

import { SnapsCard } from './Card';
import { TrendsMetricsChart } from './TrendsMetricsChart';

const tabs = [
  {
    label: 'Insights',
    value: 'insights',
    Component: TrendsInsights,
  },
  {
    label: 'Snapshots',
    value: 'snapshots',
    Component: TrendsSnapshots,
  },
  {
    label: 'Fashion Weeks',
    value: 'fashion-weeks',
    Component: TrendsFashionWeeks,
  },
  {
    label: 'Brands',
    value: 'brands',
    Component: TrendsBrands,
  },
  {
    label: 'Buzzwords',
    value: 'buzzwords',
    Component: TrendsFashionWeeks,
  },
];

// should move to a utils file or where it belongs

const timeframeMapping = {
  lastWeek: 'weeklyData',
  lastTwoWeeks: 'twoWeeklyData',
  lastFourWeeks: 'fourWeeklyData',
};

function TrendsSnapshots({ snapshots, loading, network, variant, title }) {
  const [timeframeData, setTimeframeData] = useState('weeklyData');
  const [searchParams] = useSearchParams();

  const selectedTimeframe = searchParams.get('timeframe') || 'lastWeek';

  useEffect(() => {
    if (selectedTimeframe) {
      setTimeframeData(timeframeMapping[selectedTimeframe] || 'weeklyData');
    }
  }, [selectedTimeframe]);

  if (snapshots === null || snapshots === undefined) {
    return (
      <div className="flex h-full flex-col items-center justify-center">
        {/* <h2 className="text-3xl font-bold">No Snapshots Found</h2> */}
      </div>
    );
  }

  const slicedData = snapshots.slice(0, 6);

  const visibilityMetricList = slicedData?.map((item) => ({
    name: item.node.title,
    value: Number(
      (item.node[timeframeData].at(-1).visibility * 100).toFixed(1),
    ),
  }));

  const engagementMetricList = slicedData?.map((item) => ({
    name: item.node.title,
    value: Number(item.node[timeframeData].at(-1).qualityEngagement.toFixed(0)),
  }));

  const sortedVisibility = visibilityMetricList.sort(
    (a, b) => b.value - a.value,
  );

  const sortedEngagement = engagementMetricList.sort(
    (a, b) => b.value - a.value,
  );

  if (variant !== 'main') {
    return (
      <div className="flex flex-col">
        <h2 className="text-3xl font-bold">{title}</h2>
        <div className="my-4 grid grid-cols-3 gap-8">
          {loading &&
          network !== NetworkStatus.fetchMore &&
          snapshots?.length === 0 ? (
            [...Array(6)].map((_, index) => <GhostItem key={index} />)
          ) : (
            <>
              {snapshots?.map((item, index) => (
                <SnapsCard key={index} variant="md" snapshotItem={item.node} />
              ))}
              {loading &&
                [...Array(6)].map((_, index) => <GhostItem key={index} />)}
            </>
          )}
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col">
      <div className="flex w-full flex-col gap-x-40 gap-y-8 md:flex-row">
        <TrendsMetricsChart
          metric={'Visibility'}
          metricList={sortedVisibility}
        />
        <TrendsMetricsChart
          metric={'Quality Engagement'}
          metricList={sortedEngagement}
        />
      </div>
      <div className="flex flex-col">
        <h2 className="mt-6 text-3xl font-bold">{title}</h2>
        <div className="my-4 grid grid-cols-3 gap-8">
          {snapshots?.map((item, index) => (
            <SnapsCard key={index} variant="md" snapshotItem={item.node} />
          ))}
          {loading &&
            [...Array(6)].map((_, index) => <GhostItem key={index} />)}
        </div>
      </div>
    </div>
  );
}

function TrendsFashionWeeks({ items }) {
  return <TrendsWorkInProgressCard />;
}

function TrendsWorkInProgressCard() {
  return (
    <div
      className="mx-auto my-10 max-w-md rounded-lg
border border-gray-200 bg-white p-6 text-center text-lg text-gray-600 shadow-md"
    >
      This feature is currently under development. Please check back later for
      updates.
    </div>
  );
}

function TrendsBrands() {
  return <TrendsWorkInProgressCard />;
}

const GhostItem = () => {
  return (
    <div className="flex flex-col">
      <div className={'ghost aspect-[54/25] rounded-xl'}></div>
      <h3 className="ghost mt-4 w-max text-2xl text-ghost">Black Shoes</h3>
      <p className="ghost mt-1 h-6 w-max text-md text-ghost">
        Year over Year Growth %40
      </p>
    </div>
  );
};

const TrendSection = ({ title, section, variant }) => {
  const navigationType = useNavigationType();
  const { selectedTrendsCategory } = useAppFilters();
  const [searchParams] = useSearchParams();
  const {
    selectedSocialWatch,
    groupIdFilter,
    sectionLoadCounts,
    incrementSectionLoadCount,
    resetSectionLoadCount,
  } = useTrendsContext();

  // loadMoreCount state'ini kaldırıyoruz
  const [localData, setLocalData] = useState([]);
  const [hasNextPage, setHasNextPage] = useState(true);
  const [currentCursor, setCurrentCursor] = useState(null);

  const selectedTimeframe = searchParams.get('timeframe') || 'lastWeek';
  const selectedSnapshotSort = searchParams.get('snapshotSort') || null;

  const snapshotsQueryVariables = useMemo(
    () => ({
      cursor: currentCursor,
      filters: {
        apparel: selectedTrendsCategory?.length
          ? selectedTrendsCategory.map((a) => a.value.toString())
          : null,
        timeframe: selectedTimeframe,
        section,
      },
      groupId: groupIdFilter,
      sortField: selectedSnapshotSort,
    }),
    [
      selectedTrendsCategory,
      selectedTimeframe,
      groupIdFilter,
      selectedSnapshotSort,
      section,
      currentCursor,
    ],
  );

  useEffect(() => {
    setLocalData([]);
    setCurrentCursor(null);
    setHasNextPage(true);
  }, [
    selectedTrendsCategory,
    selectedTimeframe,
    groupIdFilter,
    selectedSnapshotSort,
    section,
  ]);

  const {
    data,
    error,
    loading,
    fetchMore,
    networkStatus: network,
    refetch,
  } = useTrendsQuery('snapshotItems', {
    variables: snapshotsQueryVariables,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-first',
  });

  const location = useLocation();
  const isDetailPage =
    location.pathname.substring(location.pathname.length - 6) === 'detail';

  useEffect(() => {
    if (data?.snapshotItems?.edges) {
      if (currentCursor === null) {
        if (section === 'TOP_TRENDS') {
          setLocalData((prev) => {
            const newData = [...prev, ...data.snapshotItems.edges];
            return newData.filter(
              (item, index, self) =>
                index === self.findIndex((t) => t.node.id === item.node.id),
            );
          });
        } else {
          setLocalData(data.snapshotItems.edges);
        }
      } else {
        setLocalData((prev) => {
          const newData = [...prev, ...data.snapshotItems.edges];
          return newData.filter(
            (item, index, self) =>
              index === self.findIndex((t) => t.node.id === item.node.id),
          );
        });
      }
      setHasNextPage(data.snapshotItems.pageInfo.hasNextPage);
    }
  }, [data, currentCursor, section]);

  useEffect(() => {
    if (navigationType === 'POP' && !isDetailPage) {
      return;
    }

    if (!isDetailPage) {
      setLocalData([]);
      setCurrentCursor(null);
      setHasNextPage(true);
      refetch().then((result) => {
        if (result.data?.snapshotItems?.edges) {
          setLocalData(result.data.snapshotItems.edges);
        }
      });
    }
  }, [
    refetch,
    navigationType,
    isDetailPage,
    groupIdFilter,
    selectedTimeframe,
    selectedSnapshotSort,
    selectedTrendsCategory,
  ]);

  // Filtre değişikliklerinde section counter'ı sıfırla
  useEffect(() => {
    resetSectionLoadCount(section);
  }, [
    selectedTrendsCategory,
    selectedTimeframe,
    groupIdFilter,
    selectedSnapshotSort,
    section,
  ]);

  const handleFetchMore = async () => {
    if (sectionLoadCounts[section] >= 4) {
      setHasNextPage(false);
      return;
    }

    if (!hasNextPage || loading) return;

    const nextCursor = data?.snapshotItems?.pageInfo?.endCursor;
    if (nextCursor) {
      await fetchMore({
        variables: {
          ...snapshotsQueryVariables,
          cursor: nextCursor,
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev;

          return {
            snapshotItems: {
              ...fetchMoreResult.snapshotItems,
              edges: [
                ...prev.snapshotItems.edges,
                ...fetchMoreResult.snapshotItems.edges,
              ],
              __typename: fetchMoreResult.snapshotItems.__typename,
            },
          };
        },
      });

      setCurrentCursor(nextCursor);
      incrementSectionLoadCount(section);
    }
  };

  if (error) {
    return (
      <div className="flex h-full w-full items-center justify-center">
        <p>{error.message}</p>
      </div>
    );
  }

  if (loading && network !== NetworkStatus.fetchMore) {
    if (localData.length === 0) {
      return (
        <Tabs.Content value="snapshots" className={'mt-6 h-full w-full pb-16'}>
          <LoadingView title={title} />
        </Tabs.Content>
      );
    }

    if (variant !== 'main') {
      return (
        <Tabs.Content value="snapshots" className={'mt-6 h-full w-full pb-16'}>
          <LoadingViewWithCharts title={title} />
        </Tabs.Content>
      );
    }
  }

  if (localData.length === 0) {
    if (variant === 'main') {
      if (!loading) {
        if (selectedSocialWatch === undefined || selectedSocialWatch === true) {
          return <EmptyStateMessage message="No Snapshots Found" />;
        }

        if (selectedSocialWatch === false) {
          return <InactiveMarketMessage />;
        }
      }
    }

    return <></>;
  }

  return (
    <div className="mt-6">
      <TrendsSnapshots
        title={title}
        snapshots={localData}
        loading={loading}
        network={network}
        variant={section === 'TOP_TRENDS' ? 'main' : null}
      />
      <div
        className={cn(
          'flex items-center justify-center',
          sectionLoadCounts[section] >= 4 && 'hidden',
        )}
      >
        <LazyLoadButton
          fetchMore={handleFetchMore}
          cursorNext={hasNextPage}
          disabled={loading || !hasNextPage}
        />
      </div>
    </div>
  );
};

const LoadingView = ({ title }) => (
  <div className="flex flex-col">
    <h2 className="text-3xl font-bold">{title}</h2>
    <div className="my-4 grid grid-cols-3 gap-8">
      {[...Array(6)].map((_, index) => (
        <GhostItem key={index} />
      ))}
    </div>
    <div className="flex items-center justify-center">
      <LazyLoadButton disabled={true} fetchMore={() => {}} cursorNext={null} />
    </div>
  </div>
);

const LoadingViewWithCharts = ({ title }) => (
  <>
    <div className="mb-6 flex flex-col">
      <div className="flex w-full flex-col gap-x-40 gap-y-8 md:flex-row">
        <div className="ghost h-52 w-full rounded md:w-1/2"></div>
        <div className="ghost h-52 w-full rounded md:w-1/2"></div>
      </div>
    </div>
    <LoadingView title={title} />
  </>
);

const EmptyStateMessage = ({ message }) => (
  <div className="flex h-full items-center justify-center pt-10">
    <span className="text-3xl font-bold">{message}</span>
  </div>
);

const InactiveMarketMessage = () => (
  <div className="flex h-full w-full items-start justify-center pt-40">
    <div className="h-max w-2/5 rounded-lg border border-secondary/30 bg-secondary/5 px-8 py-6 text-center shadow-sm">
      <h1 className="text-xl font-bold leading-8 text-black">
        Snapshot feature is not active on this market.
      </h1>
      <p className="mt-2 text-lg">
        If you believe this is an error, please contact us at{' '}
        <a
          href="mailto:hello@tfashion.ai"
          className="font-semibold text-primary hover:underline hover:underline-offset-4"
        >
          hello@tfashion.ai
        </a>
      </p>
    </div>
  </div>
);

export function TrendsTabs() {
  const { activeTab, setActiveTab } = useTrendsContext();

  const navigate = useNavigate();
  const params = useParams();
  const [demoPopupState, setDemoPopupState] = useState(false);

  const [searchParams] = useSearchParams();

  const snapshotSort = searchParams.get('snapshotSort');
  const timeframe = searchParams.get('timeframe');

  const {
    trendItemsData,
    trendItemsLoading,
    trendItemsError,
    fetchMoreTrendItems,
    trendItemsNetworkStatus,
    trendTonesData,
    trendTonesLoading,
    trendTonesError,
    fetchMoreTrendTones,
    trendTonesNetworkStatus,
    cursorNext,
    tonesCursorNext,
  } = useTrendsContext();

  if (trendItemsError) {
    return (
      <div className="flex h-full items-center justify-center">
        <span>Unexpected error {trendItemsError.message}</span>
      </div>
    );
  }

  if (trendTonesError) {
    return (
      <div className="flex h-full items-center justify-center">
        <span>Unexpected error {trendTonesError.message}</span>
      </div>
    );
  }

  const renderData = trendItemsData?.trendItems?.data || [];
  const tonesData = trendTonesData?.tones?.data || [];

  const sections = [
    { title: 'Top Trends', cursor: 'TOP_TRENDS' },
    // { title: 'Top Designs', cursor: 'TOP_DESIGNS' },
    { title: 'Top Colors', cursor: 'TOP_SUBCOLORS' },
    { title: 'Top Fabrics', cursor: 'TOP_FABRICS' },
    { title: 'Top Patterns', cursor: 'TOP_PATTERNS' },
  ];

  return (
    <>
      <DemoAccountModal
        isOpen={demoPopupState}
        onClose={() => setDemoPopupState(false)}
      />
      <div className="relative w-full overflow-y-auto py-[2.25rem]">
        <Tabs.Root
          value={activeTab}
          onValueChange={(tab) => {
            const url = `/trends/${tab}/${params.marketId}/${params.audienceId}`;
            const newSearchParams = new URLSearchParams(searchParams);

            newSearchParams.set('timeframe', timeframe || 'lastWeek');
            if (snapshotSort) {
              newSearchParams.set('snapshotSort', snapshotSort);
            }

            setActiveTab(tab);
            navigate(`${url}?${newSearchParams.toString()}`);
          }}
          className={'relative'}
        >
          <Tabs.List
            aria-label="tabs"
            className={
              'flex items-center justify-between px-[3.75rem] pb-[1.5rem] pt-[1.125rem]'
            }
          >
            <div className={'flex gap-16 '}>
              {tabs.map((tab, i) =>
                tab.value === 'insights' &&
                import.meta.env.VITE_APP_ENV !== 'development' ? null : (
                  <TabHeading label={tab.label} value={tab.value} key={i} />
                ),
              )}
            </div>
          </Tabs.List>
          {activeTab === 'snapshots' && (
            <Tabs.Content
              value="snapshots"
              className={'h-full w-full px-[3.75rem]'}
            >
              {sections.map((section, i) => (
                <TrendSection
                  key={i}
                  title={section.title}
                  section={section.cursor}
                  variant={section.cursor === 'TOP_TRENDS' ? 'main' : null}
                />
              ))}
            </Tabs.Content>
          )}
          {activeTab === 'insights' && (
            <Tabs.Content
              value="insights"
              className={'h-full w-full px-[3.75rem]'}
            >
              <TrendsInsights />
            </Tabs.Content>
          )}
          {renderData.length > 0 &&
            activeTab !== 'snapshots' &&
            activeTab !== 'insights' &&
            tabs.map((tab, i) => (
              <Tabs.Content
                key={i}
                value={tab.value}
                className={'h-full w-full px-[3.75rem]'}
              >
                <tab.Component
                  items={renderData}
                  tones={tonesData}
                  network={trendItemsNetworkStatus}
                  networkStatus={trendTonesNetworkStatus}
                  loading={trendItemsLoading}
                  loadingTones={trendTonesLoading}
                  fetchMore={fetchMoreTrendItems}
                  fetchTones={fetchMoreTrendTones}
                  cursorNext={cursorNext}
                  tonesCursor={tonesCursorNext}
                />
              </Tabs.Content>
            ))}
        </Tabs.Root>
      </div>
    </>
  );
}
