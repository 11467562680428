import React from 'react';

export const TrashIcon = ({ color = '#FFF' }) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.5 5H4.16667H17.5"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="square"
      strokeLinejoin="round"
    />
    <path
      d="M15.8334 4.99935V16.666C15.8334 17.108 15.6578 17.532 15.3453 17.8445C15.0327 18.1571 14.6088 18.3327 14.1667 18.3327H5.83341C5.39139 18.3327 4.96746 18.1571 4.6549 17.8445C4.34234 17.532 4.16675 17.108 4.16675 16.666V4.99935M6.66675 4.99935V3.33268C6.66675 2.89065 6.84234 2.46673 7.1549 2.15417C7.46746 1.84161 7.89139 1.66602 8.33341 1.66602H11.6667C12.1088 1.66602 12.5327 1.84161 12.8453 2.15417C13.1578 2.46673 13.3334 2.89065 13.3334 3.33268V4.99935"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="square"
      strokeLinejoin="round"
    />
    <path
      d="M8.33325 9.16602V14.166"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="square"
      strokeLinejoin="round"
    />
    <path
      d="M11.6667 9.16602V14.166"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="square"
      strokeLinejoin="round"
    />
  </svg>
);
