import React, { useState } from 'react';
import { CircleAlert } from 'lucide-react';

import { useExploreMutation } from '@/api/explore/hooks';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';

export const ReportButton = ({
  id: itemId,
  preview,
  itemType = 'SOCIAL_MEDIA',
  reportColor = '#fff',
}) => {
  const [reason, setReason] = useState('Wrong apparel');
  const [message, setMessage] = useState('');

  const [mutation] = useExploreMutation('reportItem');

  const reportReasons = [
    'Wrong apparel',
    'Missing apparel',
    'Wrong color/color tone',
    'Wrong design detail',
    'Inappropriate',
  ];

  const handleSubmit = (e) => {
    e.stopPropagation();
    mutation({
      variables: {
        itemId,
        itemType,
        message,
        reason,
      },
    }).then(() => {});
    setReason('Wrong apparel');
    setMessage('');
  };

  // if (import.meta.env.VITE_APP_ENV !== 'development') {
  //   return null;
  // }

  const buttonStyle = preview
    ? 'rounded-full border border-secondary/20 bg-secondary/5 p-2 focus:outline-none focus:ring-0'
    : 'text-white';

  const reportButtonStyle = (type) =>
    reason === type ? 'bg-primary/10 px-4 text-primary' : 'bg-white text-black';

  return (
    <DropdownMenu>
      <DropdownMenuTrigger className={buttonStyle}>
        <div className="flex items-center justify-center">
          <CircleAlert size={18} strokeWidth={2} stroke={reportColor} />
        </div>
      </DropdownMenuTrigger>
      <DropdownMenuContent
        sideOffset={4}
        className="gap-y-1 rounded-none bg-white text-black"
      >
        <div className="flex flex-col items-start justify-center">
          {reportReasons.map((type) => (
            <button
              key={type}
              onClick={(e) => {
                e.stopPropagation();
                setReason(type);
              }}
              className={`${reportButtonStyle(type)} w-full px-4 py-2 text-left text-sm font-semibold hover:bg-primary/5 hover:text-primary`}
            >
              {type.charAt(0).toUpperCase() + type.slice(1)}
            </button>
          ))}
        </div>
        <div className="p-2">
          <textarea
            type="text"
            placeholder="Add a comment (optional)"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            className="mb-2 w-full border px-4 py-2 text-sm focus:border-black focus:outline-none"
          />
          <DropdownMenuItem
            className="flex cursor-pointer justify-center"
            asChild
          >
            <button
              onClick={(e) => handleSubmit(e)}
              className="w-full bg-primary py-2 text-sm font-bold text-white hover:bg-primaryHover"
            >
              Submit
            </button>
          </DropdownMenuItem>
        </div>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
