import { Outlet } from 'react-router-dom';
import { Search, SlidersHorizontal } from 'lucide-react';

import { TFashion } from '@/assets/svg/TFashion';
import { useAppFilters } from '@/contexts/AppFiltersContext';
import { useExploreContext } from '@/contexts/ExploreContext';
import { cn } from '@/helpers/utils';
import { ExploreFilters } from '@/pages/general/explore/components/ExploreFilters';
import {
  ExploreFashionWeekSearch,
  ExploreSocialMediaSearch,
  ExploreTabs,
} from '@/pages/general/explore/components/ExploreTabs';

import { FashionWeekItems } from './FashionItems';
import { SocialMediaImages } from './SocialMediaImages';

export function ExploreContent() {
  const { mode } = useAppFilters();

  if (!mode) {
    return <SocialMediaImages />;
  }

  if (mode === 'explore-fashion-week') {
    return <FashionWeekItems />;
  }

  if (mode === 'explore-trends') {
    return <SocialMediaImages />;
  }

  return <SocialMediaImages />;
}

export const ExploreMobileHeader = () => {
  const { isFiltersOpen, setIsFiltersOpen, isSearchOpen, setIsSearchOpen } =
    useExploreContext();

  if (isFiltersOpen || isSearchOpen) return null;

  return (
    <div className="flex w-full items-center justify-between bg-white px-6 py-2">
      <div className="flex w-2/5">
        <TFashion />
      </div>
      <div className="flex w-2/5 items-center justify-end gap-x-2">
        <div
          className="flex h-10 w-10 items-center justify-center rounded-lg bg-transparent pt-1 hover:bg-secondary/10"
          onClick={() => {
            setIsSearchOpen(!isSearchOpen);
            setIsFiltersOpen(false);
          }}
        >
          <Search strokeWidth={2.5} stroke="black" size={22} />
        </div>
        <div
          className="flex h-8 w-8 items-center justify-center rounded-lg bg-primary hover:bg-primaryHover"
          onClick={() => {
            setIsFiltersOpen(!isFiltersOpen);
            setIsSearchOpen(false);
          }}
        >
          <SlidersHorizontal strokeWidth={2.5} stroke="white" size={20} />
        </div>
      </div>
    </div>
  );
};

export function Explore() {
  const { isExploreMobile, isSearchOpen, activeTab } = useExploreContext();

  const isFashionWeekSearchOpen =
    isExploreMobile && activeTab === 'fashion-weeks' && isSearchOpen;

  const isSocialMediaSearchOpen =
    isExploreMobile && activeTab === 'social-media' && isSearchOpen;

  return (
    <div
      className={cn(
        'h-full w-full overflow-hidden',
        isExploreMobile ? 'relative' : 'flex flex-row',
      )}
    >
      {isExploreMobile && <ExploreMobileHeader />}
      <ExploreFilters />
      {isFashionWeekSearchOpen && <ExploreFashionWeekSearch />}
      {isSocialMediaSearchOpen && <ExploreSocialMediaSearch />}
      <ExploreTabs />
      <Outlet />
    </div>
  );
}
