import { useState } from 'react';
import Select, { components } from 'react-select';
import { capitalize } from 'lodash';

import { FlexColumn } from '@/components/Flex';
import { Text } from '@/components/Typography';
import { cn } from '@/helpers/utils';

const formattedSelectedOptions = (selectedOptions) =>
  selectedOptions
    ? Array.isArray(selectedOptions)
      ? selectedOptions
          .map((l) => capitalize(l.label))
          .join(', ')
          .substring(0, 30)
      : selectedOptions.label
    : null;

export function FilterDropdownTrigger({ selectedOptions, label, children }) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <FlexColumn
      gap="sm"
      items="start"
      className="w-full cursor-pointer justify-center bg-primary pl-8 text-white"
      onClick={() => {
        if (isOpen) return;

        setIsOpen((prev) => !prev);
      }}
    >
      <Text
        size="lg"
        className={`${
          selectedOptions
            ? 'border-b border-solid border-b-[#282828] capitalize'
            : 'capitalize'
        }`}
        onClick={() => setIsOpen((prev) => !prev)}
      >
        {label}
      </Text>
      {isOpen ? children : null}
      {selectedOptions ? (
        <Text className="capitalize text-gray-600">
          {formattedSelectedOptions(selectedOptions)}
        </Text>
      ) : null}
    </FlexColumn>
  );
}

const HideGroupHeading = ({ className, ...props }) => {
  return (
    <div
      className={cn(
        'collapse-group-heading text-primary',
        props.getClassNames(),
      )}
      onClick={() => {
        document
          .querySelector(`#${props.id}`)
          .parentElement.parentElement.classList.toggle('collapsed-group');
      }}
    >
      <components.GroupHeading {...props} />
    </div>
  );
};

const HideGroupMenuList = (props) => {
  const isSearch = props.selectProps.inputValue.length > 0;

  const newProps = {
    ...props,
    children: Array.isArray(props.children)
      ? props.children.map((c, idx) =>
          isSearch || idx === 0
            ? c
            : {
                ...c,
                props: {
                  ...c.props,
                  className: 'collapsed-group text-primary',
                },
              },
        )
      : props.children,
  };

  return <components.MenuList {...newProps} />;
};

const controlColorScheme = {
  default: 'bg-white text-black',
  primary: 'bg-primary text-white',
};

function Control(props) {
  const isMulti = props.isMulti;
  const { label, colorScheme } = props.selectProps;
  const selected = (props.selectProps.value?.length || 0) > 0;

  const newProps = {
    ...props,
    children: (
      <div
        className={`flex w-full min-w-[200px] shrink-0 flex-col gap-2 border border-l-0 border-t-0 border-[#A1A1A1] px-8 py-3  ${
          controlColorScheme[colorScheme] || 'bg-white text-black'
        }`}
      >
        <Text
          size="lg"
          className={`self-start border-b border-solid border-b-white ${
            isMulti && selected ? '-mb-1.5' : ''
          }`}
          weight="medium"
        >
          {label}
        </Text>
        <div className="flex">{props.children}</div>
      </div>
    ),
  };

  return <components.Control {...newProps} />;
}

export const FilterDropdownContent = ({
  placeholder,
  options,
  classNames,
  label,
  selectedOptions,
  colorScheme = 'default',
  selectRef,
  ...props
}) => {
  const isGroup = Boolean(
    options && Array.isArray(options)
      ? options.some((o) => !!o?.options)
      : false,
  );

  const components = {
    Control,
    // (props) => (
    //   <Control label={label} colorScheme={colorScheme} {...props} />
    // )
  };

  if (isGroup) {
    components.GroupHeading = HideGroupHeading;
    components.MenuList = HideGroupMenuList;
  }

  return (
    <Select
      classNames={{
        valueContainer: (state) =>
          'flex flex-row flex-nowrap gap-2 w-full min-w-full',
        menu: (state) => 'outline-none w-full bg-white',
        option: (state) =>
          cn(
            'hover:bg-gray-100 capitalize cursor-pointer px-4 py-2 text-black w-full',
            state.isFocused && 'bg-gray-100',
          ),
        group: (state) => cn('border-b-2 border-primary/20'),
        groupHeading: (state) => {
          return cn(
            'px-4 py-4 text-sm font-semibold text-charcoal border-b-2 border-primary/20 w-full last:border-b-0',
          );
        },
        loadingMessage: (state) => 'text-center px-4 py-2',
        noOptionsMessage: (state) => 'text-center px-4 py-2',
        singleValue: (state) => 'font-normal',
        multiValue: (state) => {
          const color = state.data?.hex;

          return cn(
            'text-charcoal px-1 py-1 rounded-full text-sm font-medium leading-none border-4 border-primary/0',
            color ? '' : 'bg-primary/50',
          );
        },
        multiValueRemove: (state) => 'cursor-pointer ml-2',
        dropdownIndicator: (state) =>
          cn(state.hasValue && '!hidden', 'text-primary'),
        clearIndicator: (state) => 'text-charcoal z-50',
      }}
      styles={{
        multiValue: (provided, state) => {
          const color = state.data?.hex;

          return {
            ...provided,
            border: `2px solid ${color}`,
          };
        },
      }}
      components={components}
      placeholder={placeholder}
      options={options}
      unstyled={true}
      ref={selectRef}
      innerRef={selectRef}
      label={label}
      colorScheme={colorScheme}
      {...props}
    />
  );
};

export function FilterDropdown({
  selectedOptions,
  label,
  options,
  placeholder,
  classNames,
  ...props
}) {
  return (
    <FilterDropdownContent
      label={label}
      placeholder={placeholder}
      options={options}
      selectedOptions={selectedOptions}
      {...props}
    />
  );
}

// import { useState } from 'react'
// import { capitalize } from 'lodash'
//
// import { Text } from '@/components/Typography'

//
// export function FilterDropdown ({ label, selectedOptions }) {
//   const [isOpen, setIsOpen] = useState(false)
//
//   return (
//     <div className='relative w-full flex'>
//       <div className='flex w-full flex-col items-start justify-center border-b border-r border-[#A1A1A1] bg-primary px-8 text-white' onClick={() => setIsOpen(prev => !prev)}>
//         <Text
//           size="lg"
//           className="border-b border-solid border-b-[#282828] capitalize"
//         >
//           Source
//         </Text>
//         {selectedOptions
//           ? (
//             <Text className="capitalize text-gray-600">
//               {formattedSelectedOptions(selectedOptions)}
//             </Text>
//             )
//           : null}
//
//         {isOpen ? <input className='z-50' onClick={(e) => e.preventDefault()} /> : null}
//       </div>
//       {isOpen && <div className='absolute -bottom-5 left-0 h-5 w-full border border-t-0 bg-black'></div>}
//     </div>
//   )
// }
