import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { GET_MARKETS } from '@/api/filters/queries';
import { useMarketsMutation, useMarketsQuery } from '@/api/market/hook';
import { TREND_OVERVIEWS } from '@/api/trends/queries';
import { useToast } from '@/hooks/useToast';

import { ArrowRightIcon, ArrowUpIcon } from '../../icons';

import { FinalizeCaption } from './';

const FinalizeReportCategories = ({
  apparelGroups,
  isOpen,
  onChange,
  marketId,
  selectedApparels,
}) => {
  const [isTypesOpen, setIsTypesOpen] = useState([]);

  useEffect(() => {
    setIsTypesOpen([...Array(apparelGroups.length).fill({ isOpen: false })]);
  }, [apparelGroups]);

  const handleTypesAccordion = (index) => {
    const newState = isTypesOpen.map((obj, i) => {
      if (index === i) {
        return { isOpen: !obj.isOpen };
      }
      return obj;
    });
    setIsTypesOpen([...newState]);
  };

  return (
    <div
      className={`mb-[24px] mt-[40px] w-full flex-col ${
        isOpen ? 'flex' : 'hidden'
      }`}
    >
      {apparelGroups?.map((apparelGroup, index) => (
        <div
          className="flex w-full flex-col border-b border-[#a1a1a166]"
          key={index}
        >
          <div
            className="flex cursor-pointer items-center space-x-[14px]"
            onClick={() => handleTypesAccordion(index)}
          >
            <span className="mb-[13px] mt-[18px]">
              {isTypesOpen?.[index]?.isOpen ? (
                <ArrowUpIcon />
              ) : (
                <ArrowRightIcon />
              )}
            </span>
            <span className="mb-[13px] mt-[18px] pt-1.5 text-[24px] font-semibold leading-[28px]">
              {apparelGroup.name}
            </span>
          </div>
          <div
            className={`w-full pb-[24px] ${
              isTypesOpen?.[index]?.isOpen ? 'grid grid-cols-4 gap-4' : 'hidden'
            }`}
          >
            {apparelGroup.items.map((item, index) => (
              <div className="flex items-center" key={index}>
                <input
                  className="form-check-input float-left h-[14px] w-[14px] cursor-pointer appearance-none rounded-none border border-charcoal bg-white bg-contain bg-center bg-no-repeat align-top transition duration-200 checked:border-charcoal checked:bg-primary focus:outline-none"
                  type="checkbox"
                  value={selectedApparels?.some(
                    (_item) => item.value === _item.value,
                  )}
                  onChange={() => onChange(marketId, item)}
                  id={item.label + index}
                />
                <label
                  htmlFor={item.label + index}
                  className="font-semi-light ml-[8px] flex cursor-pointer justify-center pt-[2px] text-[14px] capitalize"
                >
                  {item.label}
                </label>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

const FinalizeReport = ({ market, onChange, selectedApparels }) => {
  const [isOpen, setIsOpen] = useState(true);
  return (
    <div className="flex w-full cursor-pointer select-none flex-col">
      <div
        className="flex h-[48px] w-full items-center gap-x-[14px] bg-primary px-[22px] text-white"
        onClick={() => setIsOpen((prev) => !prev)}
      >
        <span>
          {isOpen ? (
            <ArrowUpIcon color="#fff" />
          ) : (
            <ArrowRightIcon color="#fff" />
          )}
        </span>
        <span className="pt-1 text-[24px] font-semibold leading-[28px]">
          Market: {market?.location.name}
        </span>
      </div>
      <FinalizeReportCategories
        apparelGroups={market?.apparelGroups}
        isOpen={isOpen}
        onChange={onChange}
        marketId={market?.marketId}
        selectedApparels={selectedApparels}
      />
    </div>
  );
};

export const Finalize = () => {
  // const navigate = useNavigate();
  // const { reports, activeReport } = useSelector((state) => state.report);

  // useEffect(() => {
  //   !activeReport && navigate('/create-trend-report')
  // }, [activeReport, navigate]);

  const [searchParams] = useSearchParams();

  const navigate = useNavigate();

  const cartId = searchParams.get('cartId');

  const { data } = useMarketsQuery('finalizeMarketApparels', {
    variables: {
      cartId,
    },
  });

  const toast = useToast();

  const [finalApparels, setFinalApparels] = useState([]);

  const [finalizeReport, { loading: finalizeReportLoading }] =
    useMarketsMutation('finalizeReport', {
      refetchQueries: [TREND_OVERVIEWS, GET_MARKETS],
      onCompleted: () => {
        toast.createToast({
          message: 'Report finalized successfully',
          type: 'success',
          showCloseButton: true,
          position: 'top-right',
        });

        navigate('/overview');
      },
    });

  function handleApparelSelection(market, apparel) {
    const index = finalApparels.findIndex((item) => item.marketId === market);
    if (index === -1) {
      setFinalApparels([
        ...finalApparels,
        { marketId: market, apparels: [apparel] },
      ]);
    } else {
      const apparels = finalApparels[index].apparels;

      if (apparels.some((_apparel) => _apparel.value === apparel.value)) {
        setFinalApparels([
          ...finalApparels.slice(0, index),
          {
            ...finalApparels[index],
            apparels: apparels.filter(
              (_apparel) => _apparel.value !== apparel.value,
            ),
          },
          ...finalApparels.slice(index + 1),
        ]);
      } else {
        setFinalApparels([
          ...finalApparels.slice(0, index),
          {
            ...finalApparels[index],
            apparels: [...apparels, apparel],
          },
          ...finalApparels.slice(index + 1),
        ]);
      }
    }
  }

  const isPredefinedPlan = data?.finalizeMarketApparels[0].predefinedPlan;

  if (isPredefinedPlan) {
    navigate('/overview');
  }

  return (
    <div className="my-[52px] flex w-full flex-col items-center">
      <FinalizeCaption />
      <div className="flex w-full flex-col space-y-[18px]">
        {data?.finalizeMarketApparels.map((market, index) => (
          <FinalizeReport
            market={market}
            key={index}
            onChange={handleApparelSelection}
            selectedApparels={
              finalApparels.find(
                (markets) => markets.marketId === market.marketId,
              )?.apparels
            }
          />
        ))}
      </div>
      <button
        onClick={() =>
          finalizeReport({
            variables: {
              input: finalApparels.map((item) => ({
                marketId: item.marketId,
                apparels: item.apparels.map((apparel) => ({
                  value: apparel.value,
                  label: apparel.label,
                })),
              })),
            },
          })
        }
        disabled={finalizeReportLoading}
        className="b font-book mt-[36px] h-[52px] w-[50%] border border-primary bg-white text-[18px] leading-[27px] text-primary transition-all hover:bg-primary hover:text-white"
      >
        Complete
      </button>
    </div>
  );
};
