import React, { useEffect, useState } from 'react';
import numeral from 'numeral';
import { Line, LineChart, ResponsiveContainer } from 'recharts';

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table';

export const InsightListView = ({
  insights,
  setModalActive,
  modalActive,
  insightData,
  getInsight,
  refetchInsight,
}) => {
  if (!insights) return null;

  return (
    <div className="flex w-full flex-row">
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead className="w-2/6 px-2"> Trends</TableHead>
            <TableHead className="px-2">Year over Year Growth</TableHead>
            <TableHead className="px-2">Quality Engagement</TableHead>
            <TableHead className="px-2">Trend Type</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {insights.map((insight, index) => (
            <TableRow
              className="border-none"
              key={index}
              onClick={() => {
                if (modalActive === false) {
                  getInsight({
                    variables: {
                      insightId: insight.node.id,
                      filters: {
                        timeframeRange: [
                          { date: new Date('2025-01-01').toISOString() },
                        ],
                      },
                    },
                  });

                  setTimeout(() => {
                    setModalActive(true);
                  }, 175);
                } else {
                  setModalActive(false);
                }
              }}
            >
              <TableCell className="flex flex-row gap-2 p-2">
                <InsightPhotosView
                  featuredImages={insight.node.featuredImages}
                />
                <div className="flex flex-col justify-center">
                  <span className="cursor-pointer text-sm font-bold capitalize hover:underline">
                    {insight.node.title}
                  </span>
                </div>
              </TableCell>
              <TableCell className="p-2 font-medium capitalize">
                <TrendLineChart
                  value={insight.node.yoyGrowth}
                  monthlyForecast={insight.node.monthlyForecast}
                  valueType="yoyGrowth"
                  labelType="percentage"
                />
              </TableCell>
              <TableCell className="p-2 text-right font-medium capitalize">
                <TrendLineChart
                  value={insight.node.qualityEngagement}
                  monthlyForecast={insight.node.monthlyForecast}
                  labelType="numeric"
                  valueType="qualityEngagement"
                />
              </TableCell>
              <TableCell className="p-2 font-medium capitalize">
                <TrendTypeCard
                  value={insight.node.trendType || 'Top Trends'}
                  colors={{
                    background: '#D6F4EA',
                    text: '#1CA974',
                  }}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

export const TrendLineChart = ({
  value,
  labelType,
  monthlyForecast,
  valueType,
}) => {
  const data = monthlyForecast.map((item) => ({
    value: item[valueType],
  }));

  return (
    <div className="flex items-center gap-2">
      <span className="text-sm font-bold text-green">
        {labelType === 'percentage'
          ? numeral(value).format('0.00') + '%'
          : numeral(value).format('0,0.00a').toUpperCase()}
      </span>
      <div className="h-[30px] w-[100px]">
        <ResponsiveContainer width="100%" height="100%">
          <LineChart
            data={data}
            margin={{
              top: 5,
              right: 5,
              left: 5,
              bottom: 5,
            }}
          >
            <Line
              type="linear"
              dataKey="value"
              stroke="green"
              strokeWidth={2}
              dot={false}
              isAnimationActive={false}
            />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export const TrendTypeCard = ({ value, colors }) => {
  return (
    <div
      className="flex items-center justify-center gap-2 rounded-md p-2 text-center font-bold"
      style={{
        backgroundColor: colors.background,
        color: colors.text,
        border: `1px solid ${colors.border}`,
      }}
    >
      {value}
    </div>
  );
};

export const InsightPhotosView = ({ featuredImages }) => {
  const [images, setImages] = useState([]);

  // TODO: Remove this once we have the actual data

  useEffect(() => {
    const { fashionWeekImages, socialMediaImages, studioImages } =
      featuredImages;

    const images = [];
    images.push(...fashionWeekImages);
    images.push(...socialMediaImages);
    images.push(...studioImages);

    if (images?.length < 3) {
      images.push(
        {
          __typename: 'Image',
          url: 'https://assets.tfashion.ai/v3/images/fashion-weeks/images/woman-brunello-cucinelli-fall-winter-2024-milan-26-brunellocucinellifw24womencollection26-14a91254.jpg?format=jpeg',
        },
        {
          __typename: 'Image',
          url: 'https://assets.tfashion.ai/v3/images/fashion-weeks/images/couture-yanina-couture-fall-winter-2024-paris-22-yaninacaw240022-b5b74ed4519732bf0b94.JPG?format=jpeg',
        },
        {
          __typename: 'Image',
          url: 'https://assets.tfashion.ai/v3/images/fashion-weeks/images/woman-prabal-gurung-fall-winter-2024-new-york-21-prabalgurungaw240022-39fb1b0ec5f2b3e728fe.jpg?format=jpeg',
        },
      );
    }
    setImages(images);
  }, []);

  return (
    <div className="flex items-center">
      <div className="flex -space-x-2">
        {images.slice(0, 3).map((image, index) => (
          <div
            key={index}
            className="h-24 w-12 border-2 border-white"
            style={{ zIndex: 3 - index }}
          >
            <img src={image.url} alt={image.alt} className="h-full w-full" />
          </div>
        ))}
      </div>
    </div>
  );
};
