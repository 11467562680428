import { useNavigate } from 'react-router-dom';
import { Triangle } from 'lucide-react';

import { useExploreContext } from '@/contexts/ExploreContext';
import { isBright } from '@/helpers/isBright';
import { stringFormatter } from '@/helpers/utils';

import { HoverTooltip } from './ui/tooltip';

export function AttributePill({ value, label }) {
  const navigate = useNavigate();

  const { handleSocialMediaFiltersOnChange } = useExploreContext();

  const name = label.toLowerCase().replaceAll(' ', '-');

  const newFilter = {
    label: stringFormatter(label || '') + ': ' + stringFormatter(value || ''),
    name,
    value,
  };

  const handleClick = () => {
    const attributeFilterParam = newFilter.label.split(':')[0].toLowerCase();
    navigate(`/explore?design=${attributeFilterParam}:${newFilter.value}`);
    handleSocialMediaFiltersOnChange(null, 'category');
    handleSocialMediaFiltersOnChange([newFilter], 'attribute.custom');
  };

  if (value) {
    return (
      <button
        className="flex items-center rounded-full border border-primary text-xs font-medium uppercase leading-none shadow-sm focus-visible:outline-none"
        onClick={handleClick}
      >
        <div className="rounded-l-full bg-primary px-3 py-2 text-white">
          {label}
        </div>
        <div className="rounded-r-full px-3 text-primary">{value}</div>
      </button>
    );
  }

  return (
    <div className="flex items-center rounded-full border border-primary text-xs font-medium uppercase leading-none shadow-sm focus-visible:outline-none">
      <div className="rounded-full bg-primary px-3 py-2 text-white">
        {label}
      </div>
    </div>
  );
}

export function TonePill({ type = 'default', ...tone }) {
  const bright = isBright(tone.hex);

  const navigate = useNavigate();

  const { handleSocialMediaFiltersOnChange } = useExploreContext();

  const toneFilter = {
    hex: tone.hex,
    label: stringFormatter(tone.name || '') + ' - ' + tone.code,
    value: tone.code,
    code: tone.code,
  };

  const handleClick = () => {
    const toneFilterParam = toneFilter.label
      .split(' - ')[0]
      .replaceAll(' ', '-')
      .toLowerCase();
    navigate(`/explore?tone=${toneFilterParam}`);
    handleSocialMediaFiltersOnChange(toneFilter, 'tone');
  };

  if (type === 'fashionWeekItem') {
    return (
      <div
        className="cursor-default rounded-full px-4 py-2 text-xs font-medium uppercase leading-none shadow-sm focus-visible:outline-none"
        style={{
          backgroundColor: tone.hex,
          color: bright ? 'black' : 'white',
        }}
      >
        {tone.code || tone.value}
      </div>
    );
  }

  if (tone) {
    return (
      <HoverTooltip tooltipContent={<p>Click to filter</p>}>
        <button
          className="rounded-full px-4 py-2 text-xs font-medium uppercase leading-none shadow-sm focus-visible:outline-none"
          style={{
            backgroundColor: tone.hex,
            color: bright ? 'black' : 'white',
          }}
          onClick={handleClick}
        >
          {tone.code || tone.value}
        </button>
      </HoverTooltip>
    );
  }
}

export function MetricPill({
  color,
  metric,
  isIncrease,
  metricValue,
  timePeriod,
}) {
  const statusColor =
    isIncrease == null
      ? '#C29B77'
      : isIncrease === true
        ? '#1C9A6D'
        : '#EA3323';
  const bgColor = color ? color + '20' : statusColor + '20';
  return (
    <div
      className="flex h-full flex-col items-start justify-between gap-y-2.5 rounded-md p-3"
      style={{ backgroundColor: bgColor }}
    >
      <div className="flex w-full flex-row items-start justify-between gap-x-2">
        <p className="text-xs font-bold leading-4">{metric}</p>
        <div
          className="flex flex-row items-center gap-x-0.5"
          style={{ color: color || statusColor }}
        >
          {metricValue !== 'FLAT' && isIncrease !== null ? (
            !isIncrease ? (
              <Triangle
                style={{ transform: 'rotate(180deg)' }}
                size={16}
                fill={color || statusColor}
              />
            ) : (
              <Triangle size={16} fill={color || statusColor} />
            )
          ) : null}
        </div>
      </div>
      <div className="relative flex w-full flex-row items-start justify-end gap-x-2">
        <p
          className="absolute bottom-0 left-0 h-8 text-lg font-bold leading-6"
          style={{ color: color || statusColor }}
        >
          {metric !== 'Visibility'
            ? metricValue === 'UP'
              ? 'Rising'
              : metricValue === 'DOWN'
                ? 'Declining'
                : metricValue === 'FLAT'
                  ? 'Flat'
                  : metricValue
            : metricValue + '%'}
        </p>
        {timePeriod?.value && timePeriod?.label ? (
          <div className="flex w-1/2 flex-wrap justify-end gap-x-1 text-xs font-medium leading-3">
            {metric === 'Visibility'
              ? timePeriod.value + '%'
              : timePeriod.value}{' '}
            <span className="shrink-0 pt-0.5 text-[0.6rem] font-medium">
              {timePeriod.label}
            </span>
          </div>
        ) : (
          <div className="h-8">{/* For empty area card */}</div>
        )}
      </div>
    </div>
  );
}
