import { useEffect, useRef, useState } from 'react';
import { RgbaColorPicker } from 'react-colorful';
import ReactTimeAgo from 'react-time-ago';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en.json';
import { debounce } from 'lodash';
import { RefreshCw, Trash } from 'lucide-react';

import { GET_SIMILAR_TONES } from '@/api/explore/queries';
import { UPDATE_FILE_STATUS } from '@/api/studio/mutations';
import { GENERATE_FILE_UPLOAD_URL } from '@/api/studio/queries';
import { EDIT_TOOLS_CONFIG } from '@/constants/studio';
import { useStudioContext } from '@/contexts/StudioContext';
import { cn } from '@/helpers/utils';

import { SidebarTitle } from './SidebarMenu';

TimeAgo.addDefaultLocale(en);

// const SelectedAreaSettingsComponent = ({
//   selectedAreaType,
//   setSelectedAreaType,
// }) => {
//   return (
//     <div className="ml-12 flex justify-center gap-1">
//       <button
//         className={cn(
//           'flex h-8 w-8 items-center justify-center rounded border',
//           selectedAreaType === 'landscape'
//             ? 'border-primary'
//             : 'border-gray-300',
//         )}
//         onClick={() => setSelectedAreaType('landscape')}
//       >
//         <RectangleHorizontal
//           className={cn(selectedAreaType === 'landscape' ? 'text-primary' : '')}
//           size={18}
//         />
//       </button>
//       <button
//         className={cn(
//           'flex h-8 w-8 items-center justify-center rounded border',
//           selectedAreaType === 'portrait'
//             ? 'border-primary'
//             : 'border-gray-300',
//         )}
//         onClick={() => setSelectedAreaType('portrait')}
//       >
//         <RectangleVertical
//           className={cn(selectedAreaType === 'portrait' ? 'text-primary' : '')}
//           size={18}
//         />
//       </button>
//       <button
//         className={cn(
//           'flex h-8 w-8 items-center justify-center rounded border',
//           selectedAreaType === 'square' ? 'border-primary' : 'border-gray-300',
//         )}
//         onClick={() => setSelectedAreaType('square')}
//       >
//         <Square
//           className={cn(selectedAreaType === 'square' ? 'text-primary' : '')}
//           size={18}
//         />
//       </button>
//       <button
//         className={cn(
//           'flex h-8 w-8 items-center justify-center rounded border',
//           selectedAreaType === 'custom' ? 'border-primary' : 'border-gray-300',
//         )}
//         onClick={() => setSelectedAreaType('custom')}
//       >
//         <Scan
//           className={cn(selectedAreaType === 'custom' ? 'text-primary' : '')}
//           size={18}
//         />
//       </button>
//     </div>
//   );
// };

// const BrushSettingsComponent = ({
//   selectedBrushSize,
//   setSelectedBrushSize,
// }) => {
//   return (
//     <div className="ml-12 flex justify-center gap-1">
//       <button
//         className={cn(
//           'flex h-8 w-8 items-center justify-center rounded border',
//           selectedBrushSize === 10 ? 'border-primary' : 'border-gray-300',
//         )}
//         onClick={() => setSelectedBrushSize(10)}
//       >
//         <Brush
//           className={cn(selectedBrushSize === 10 ? 'text-primary' : '')}
//           size={14}
//         />
//       </button>
//       <button
//         className={cn(
//           'flex h-8 w-8 items-center justify-center rounded border',
//           selectedBrushSize === 20 ? 'border-primary' : 'border-gray-300',
//         )}
//         onClick={() => setSelectedBrushSize(20)}
//       >
//         <Brush
//           className={cn(selectedBrushSize === 20 ? 'text-primary' : '')}
//           size={18}
//         />
//       </button>
//       <button
//         className={cn(
//           'flex h-8 w-8 items-center justify-center rounded border',
//           selectedBrushSize === 30 ? 'border-primary' : 'border-gray-300',
//         )}
//         onClick={() => setSelectedBrushSize(30)}
//       >
//         <Brush
//           className={cn(selectedBrushSize === 30 ? 'text-primary' : '')}
//           size={24}
//         />
//       </button>
//     </div>
//   );
// };

export const SettingsButtonGroup = ({
  variant = 'area',
  selectedValue,
  onValueChange,
  className,
}) => {
  const config = EDIT_TOOLS_CONFIG[variant];

  if (!config) {
    return null;
  }

  return (
    <div
      className={cn(
        'flex border border-black/5 rounded bg-[#a1a1a108] p-[1px] justify-center items-center',
        className,
      )}
    >
      {config.options.map((option) => {
        const Icon = option.icon;
        const isSelected = selectedValue === option.value;

        return (
          <button
            key={option.value}
            className={cn(
              'flex w-8 h-8 items-center justify-center rounded border-transparent bg-[#a1a1a108]',
              isSelected && '-m-[2px] h-9 border border-primary',
            )}
            onClick={() => onValueChange(option.value)}
          >
            <Icon
              className={cn(isSelected ? 'text-primary' : '')}
              size={option.size}
            />
          </button>
        );
      })}
    </div>
  );
};

export const GenerationElapsed = () => {
  const { studioTask } = useStudioContext();
  return studioTask && studioTask.createdAt ? (
    <span className={'tabular-nums'} style={{ fontSize: 14, color: '#a1a1a1' }}>
      <ReactTimeAgo
        date={new Date(studioTask.createdAt)}
        timeStyle={{
          steps: [
            {
              formatAs: 'second',
            },
          ],
          labels: 'mini',
        }}
        tooltip={false}
      />
    </span>
  ) : null;
};

export const UploadBox = ({
  title,
  icon: Icon,
  text,
  type,
  disabled = false,
  fileVisibility = 'PRIVATE',
  fileEntityType = 'APPAREL',
  variant = 'default',
  className,
}) => {
  const [generateFileUploadUrl] = useLazyQuery(GENERATE_FILE_UPLOAD_URL);
  const [updateFileStatus] = useMutation(UPDATE_FILE_STATUS);
  const [uploading, setUploading] = useState(false);
  const [uploadedImage, setUploadedImage] = useState(null);

  const [isDragging, setIsDragging] = useState(false);
  const {
    setReferenceImage,
    setReferencePattern,
    setReferenceError,
    setReferenceOtherImage,
    setReferenceGraphic,
    referenceError,
    referenceImage,
    referencePattern,
    referenceSketch,
    setReferenceSketch,
    referenceOtherImage,
    referenceGraphic,
    createItem,
    draggingItem,
    setDraggingItem,
  } = useStudioContext();

  useEffect(() => {
    if (fileEntityType === 'APPAREL' && referenceImage) {
      setUploadedImage({
        id: referenceImage.entityId,
        name: 'image',
        type: 'image',
        url: referenceImage.url,
      });
    } else if (fileEntityType === 'PATTERN' && referencePattern) {
      setUploadedImage({
        id: referencePattern.entityId,
        name: 'pattern',
        type: 'pattern',
        url: referencePattern.url,
      });
    } else if (fileEntityType === 'SKETCH' && referenceSketch) {
      setUploadedImage({
        id: referenceSketch.entityId,
        name: 'sketch',
        type: 'image',
        url: referenceSketch.url,
      });
    } else if (fileEntityType === 'GRAPHIC' && referenceGraphic) {
      setUploadedImage({
        id: referenceGraphic.entityId,
        name: 'graphic',
        type: 'image',
        url: referenceGraphic.url,
      });
    } else if (fileEntityType === 'OTHER' && referenceOtherImage) {
      setUploadedImage({
        id: referenceOtherImage.entityId,
        name: 'other',
        type: 'image',
        url: referenceOtherImage.url,
      });
    } else {
      setUploadedImage(null);
    }
  }, [
    referenceImage,
    referencePattern,
    referenceSketch,
    referenceGraphic,
    referenceOtherImage,
  ]);

  useEffect(() => {
    setUploadedImage(null);

    if (fileEntityType === 'APPAREL') {
      setReferenceImage(null);
    } else if (fileEntityType === 'PATTERN') {
      setReferencePattern(null);
    } else if (fileEntityType === 'SKETCH') {
      setReferenceSketch(null);
    } else if (fileEntityType === 'OTHER') {
      setReferenceOtherImage(null);
    } else if (fileEntityType === 'GRAPHIC') {
      setReferenceGraphic(null);
    }
  }, [createItem.type]);

  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);

    const file = e.dataTransfer.files[0];
    if (file) {
      handleFileUpload({ target: { files: [file] } });
    } else if (draggingItem) {
      const imageUrl = draggingItem.url;
      const entityId = draggingItem.entityId;
      const generationType = draggingItem.generationType;
      const entityType =
        draggingItem.entityType === 'Post'
          ? 'SOCIAL_MEDIA_ITEM'
          : draggingItem.entityType === 'ExploreFashionWeekItem'
            ? 'FASHION_WEEK_ITEM'
            : draggingItem.entityType;

      if (
        [
          'GENERATE_APPAREL',
          'REALIZE_SKETCH',
          'CREATE_VARIANTS',
          'UPSCALE_IMAGE',
          'ENHANCE_IMAGE',
          'ZOOM_IN_IMAGE',
          'INPAINTING',
          'GENERATE_FLAT_SKETCH',
          'COLOR_TRANSFER',
        ].includes(generationType) &&
        fileEntityType === 'APPAREL'
      ) {
        setReferenceImage({
          entityId,
          entityType,
          url: imageUrl,
        });
      } else if (
        [
          'GENERATE_PATTERN',
          'PATTERN_TILE',
          'CREATE_PATTERN_VARIANTS',
        ].includes(generationType) &&
        fileEntityType === 'PATTERN'
      ) {
        setReferencePattern({
          entityId,
          entityType,
          url: imageUrl,
        });
      } else if (fileEntityType === 'OTHER') {
        setReferenceOtherImage({
          entityId,
          entityType,
          url: imageUrl,
        });
      } else if (
        ['GENERATE_GRAPHIC', 'CREATE_VARIANTS'].includes(generationType) &&
        fileEntityType === 'GRAPHIC'
      ) {
        setReferenceGraphic({
          entityId,
          entityType,
          url: imageUrl,
        });
      } else if (
        ['REALIZE_SKETCH', 'GENERATE_FLAT_SKETCH'].includes(generationType) &&
        fileEntityType === 'SKETCH'
      ) {
        setReferenceSketch({
          entityId,
          entityType,
          url: imageUrl,
        });
      }
      setDraggingItem(null);
    }
  };

  useEffect(() => {
    if (referenceError) {
      setTimeout(() => {
        setReferenceError(null);
      }, 4000);
    }
  }, [referenceError]);

  const handleFileUpload = async (event) => {
    const file = event.target.files?.[0];

    if (!file) {
      setReferenceError('No file selected');
      return;
    }

    try {
      setUploading(true);
      setReferenceError(null);

      const { data, error: queryError } = await generateFileUploadUrl({
        variables: {
          fileVisibility,
          fileName: file.name,
          mimeType: file.type,
          fileEntityType,
        },
      });

      if (queryError) {
        throw new Error(queryError.message);
      }

      if (!data?.generateFileUploadUrl?.signedUrl) {
        throw new Error('Failed to get signed URL');
      }

      const response = await fetch(data.generateFileUploadUrl.signedUrl, {
        method: 'PUT',
        body: file,
      });

      if (!response.ok) {
        throw new Error(`Failed to upload image: ${response.statusText}`);
      }

      await updateFileStatus({
        variables: {
          fileId: data.generateFileUploadUrl.entityId,
          status: 'DONE',
        },
      });

      const imageUrl = URL.createObjectURL(file);

      setUploadedImage({
        id: data.generateFileUploadUrl.entityId,
        name: file.name,
        type,
        url: imageUrl,
      });

      if (fileEntityType === 'APPAREL') {
        setReferenceImage({
          entityId: data.generateFileUploadUrl.entityId,
          entityType: 'FILE_UPLOAD',
          url: imageUrl,
        });
      } else if (fileEntityType === 'PATTERN') {
        setReferencePattern({
          entityId: data.generateFileUploadUrl.entityId,
          entityType: 'FILE_UPLOAD',
          url: imageUrl,
        });
      } else if (fileEntityType === 'OTHER') {
        setReferenceOtherImage({
          entityId: data.generateFileUploadUrl.entityId,
          entityType: 'FILE_UPLOAD',
          url: imageUrl,
        });
      } else if (fileEntityType === 'GRAPHIC') {
        setReferenceGraphic({
          entityId: data.generateFileUploadUrl.entityId,
          entityType: 'FILE_UPLOAD',
          url: imageUrl,
        });
      } else if (fileEntityType === 'SKETCH') {
        setReferenceSketch({
          entityId: data.generateFileUploadUrl.entityId,
          entityType: 'FILE_UPLOAD',
          url: imageUrl,
        });
      }
    } catch (error) {
      if (error.message === 'FILE_UPLOAD_IN_PROGRESS') {
        setReferenceError('This file is already being uploaded');
      } else {
        setReferenceError(`Failed to upload image: ${error.message}`);
      }
    } finally {
      setUploading(false);
    }
  };

  const handleDeleteImage = () => {
    if (fileEntityType === 'APPAREL') {
      setReferenceImage(null);
    } else if (fileEntityType === 'PATTERN') {
      setReferencePattern(null);
    } else if (fileEntityType === 'SKETCH') {
      setReferenceSketch(null);
    } else if (fileEntityType === 'OTHER') {
      setReferenceOtherImage(null);
    } else if (fileEntityType === 'GRAPHIC') {
      setReferenceGraphic(null);
    }
    setUploadedImage(null);
    setReferenceError(false);
  };

  if (variant === 'button') {
    return (
      <div
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
      >
        <button
          className={cn(
            'flex w-full items-center justify-between rounded-b border border-night/5 bg-primary/10 p-2',
            className,
          )}
          onClick={() =>
            document.getElementById(`file-input-${fileEntityType}`).click()
          }
        >
          <span className="text-sm font-bold text-primary">{title}</span>
          <div className="flex items-center justify-center rounded bg-primary p-1">
            <Icon size={16} stroke="#F6EADF" />
          </div>
        </button>
        <input
          id={`file-input-${fileEntityType}`}
          type="file"
          className="hidden"
          onChange={handleFileUpload}
          accept="image/*"
          disabled={uploading}
        />
        {referenceError && (
          <p className="mt-2 text-sm text-red-500">{referenceError}</p>
        )}
      </div>
    );
  }

  if (variant === 'cardButton') {
    return (
      <div
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
      >
        <button
          className={cn(
            'group flex w-full items-center justify-center rounded-b border border-secondary/50 bg-primaryLight p-3 transition-colors duration-300 ease-in-out hover:border-primary hover:bg-primaryLight hover:bg-secondary/5 rounded',
            className,
          )}
          onClick={() =>
            document.getElementById(`file-input-${fileEntityType}`).click()
          }
        >
          <span className="text-sm font-bold text-night group-hover:text-primary">
            {title}
          </span>
        </button>
        <input
          id={`file-input-${fileEntityType}`}
          type="file"
          className="hidden"
          onChange={handleFileUpload}
          accept="image/*"
          disabled={uploading}
        />
        {referenceError && (
          <p className="mt-2 text-sm text-red-500">{referenceError}</p>
        )}
      </div>
    );
  }

  if (disabled) {
    return (
      <div className="flex flex-col gap-2">
        <SidebarTitle text={title} />
        <label className="flex cursor-not-allowed flex-col items-center justify-center gap-y-2 rounded border border-night/10 bg-secondary/20 p-5 text-center text-sm text-night">
          <Icon size={16} />
          {text}
        </label>
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-2">
      <SidebarTitle text={title} />
      {uploadedImage ? (
        <div className="group relative max-h-[12rem] w-full">
          <img
            src={uploadedImage.url}
            alt={uploadedImage.name}
            className="h-[12rem] w-full rounded object-cover"
          />
          <div className="absolute inset-0 bg-night/40 opacity-0 transition-opacity duration-300 group-hover:opacity-100"></div>
          <div className="absolute right-2 top-2 flex gap-2 opacity-0 transition-opacity duration-300 group-hover:opacity-100">
            <label className="cursor-pointer rounded border border-secondary/10 bg-white p-1 text-black hover:border-primary hover:bg-white hover:text-primary">
              <RefreshCw size={20} />
              <input
                type="file"
                className="hidden"
                onChange={handleFileUpload}
                accept="image/*"
              />
            </label>
            <button
              className="rounded border border-secondary/10 bg-white p-1 text-black hover:border-red-600 hover:bg-white hover:text-red-600"
              onClick={handleDeleteImage}
            >
              <Trash size={20} />
            </button>
          </div>
          {/* <div className="absolute bottom-2 right-2 opacity-0 transition-opacity duration-300 group-hover:opacity-100">
            <button
              className="rounded border border-secondary/10 bg-white p-1 text-primary hover:border-primary/90 hover:bg-primary hover:text-white"
              onClick={handlePromptMagic}
            >
              <WandSparkles size={20} />
            </button>
          </div> */}
        </div>
      ) : (
        <label
          className={`focus-visible:none flex min-h-[12rem] min-w-[8rem] cursor-pointer flex-col items-center justify-center gap-y-2 rounded border ${
            isDragging
              ? 'border-primary bg-[#FFFDFD]'
              : 'border-night/5 bg-secondary/5'
          } p-5 text-center text-sm text-night hover:border-primary hover:bg-[#FFFDFD] hover:text-primary focus:border-primary focus:bg-[#FFFDFD] focus:ring-0 focus-visible:outline-none`}
          onDragEnter={handleDragEnter}
          onDragLeave={handleDragLeave}
          onDragOver={handleDragOver}
          onDrop={handleDrop}
        >
          <input
            type="file"
            className="hidden"
            onChange={handleFileUpload}
            accept="image/*"
            disabled={uploading}
          />
          <Icon size={16} />
          <p>{uploading ? 'Uploading...' : text}</p>
        </label>
      )}
    </div>
  );
};

export function ToneSelect({ onChange, currentValue }) {
  const [selectedTone, setSelectedTone] = useState({
    r: 0,
    g: 0,
    b: 0,
    a: 1,
  });
  const [showTextSearch, setShowTextSearch] = useState(false);
  const [textSearch, setTextSearch] = useState('');

  const { data, loading } = useQuery(GET_SIMILAR_TONES, {
    variables: {
      tone: [selectedTone?.r, selectedTone?.g, selectedTone?.b],
      textSearch,
    },
    skip: !selectedTone && !textSearch,
  });

  const triggerRef = useRef(null);
  return (
    <div className="flex w-full flex-col items-center space-y-2">
      <div
        className={cn(
          'tone-picker w-full gap-2',
          showTextSearch ? 'flex-col' : 'flex',
        )}
      >
        {showTextSearch ? (
          <div className="mb-2 flex w-full items-center gap-2 rounded border border-charcoal p-2">
            <input
              className="w-full text-[.875rem] leading-5 outline-none"
              value={textSearch}
              onChange={(e) => setTextSearch(e.target.value)}
              placeholder="Color, Pantone Code or Pantone Name"
            />
          </div>
        ) : (
          <div
            style={{
              width: '8rem',
              height: '8rem',
              minWidth: '7rem',
              minHeight: '9rem',
            }}
          >
            <RgbaColorPicker
              onChange={debounce((color) => {
                setSelectedTone(color);
                onChange(null);
                triggerRef?.current?.click();
              }, 250)}
              value={selectedTone}
            />
          </div>
        )}

        <div className="flex w-full flex-col gap-2">
          <h1 className="text-[.875rem] font-bold leading-5">
            Select a Pantone Color
          </h1>

          {loading && (
            <div className="flex flex-col gap-2">
              {Array.from({ length: 3 }).map((_, i) => (
                <div
                  key={i}
                  className="ghost flex items-center gap-1.5 rounded px-0.5 py-1.5 shadow-sm"
                >
                  <div className="flex h-3 w-3 items-center justify-center rounded-full p-1" />
                  <span
                    className={cn('text-[0.925rem] leading-5 text-transparent')}
                  >
                    TCX 11-1100
                  </span>
                </div>
              ))}
            </div>
          )}

          <div className="flex flex-col gap-0.5">
            {data?.exploreSimilarTones.map((tone) => (
              <div
                key={tone.code}
                className={cn(
                  'flex cursor-pointer items-center gap-1.5 py-1.5 px-0.5 border rounded hover:border-charcoal group transition-colors',
                  tone.code === currentValue?.code
                    ? 'border-charcoal'
                    : 'border-transparent',
                )}
                onClick={() => {
                  onChange({
                    ...tone,
                    label: `${tone.name.replace(/-/g, ' ')} - ${tone.code}`,
                  });
                  triggerRef?.current?.click();
                }}
              >
                <div
                  className="flex h-3 w-3 items-center justify-center rounded-full p-1"
                  style={{
                    background: tone.hex,
                  }}
                />
                <span
                  className={cn(
                    'text-[0.925rem] leading-6 group-hover:text-charcoal transition-colors',
                    tone.code === currentValue?.code
                      ? 'text-charcoal'
                      : 'text-night',
                  )}
                >
                  TCX {tone.code}
                </span>
              </div>
            ))}
          </div>
        </div>
      </div>

      <span
        className="cursor-pointer text-xs text-primary hover:underline"
        onClick={() => setShowTextSearch(!showTextSearch)}
      >
        {showTextSearch ? '... or pick a color' : '... or search by color name'}
      </span>
    </div>
  );
}
