import { LazyVideo } from 'react-lazy-media';

import { ImageSlider } from '@/components/ImageSlider/index';
import { STUDIO_HOME_AI_TOOLS, STUDIO_HOME_DISCOVER } from '@/constants/studio';
import { useStudioContext } from '@/contexts/StudioContext';

import { SidebarButton } from './components/SidebarMenu';
import { ImageView } from './components/StudioContents';

export const StudioHome = ({ handleChange }) => {
  const { setPositivePrompt, updateCreateItemDetails } = useStudioContext();

  return (
    <div className="flex max-w-[77rem] flex-col px-6">
      <div className="flex w-full flex-col gap-y-8">
        {/* Hero */}
        <div className="flex flex-col gap-y-4">
          <h1 className="text-[2rem] font-bold leading-[2.7rem] text-primary">
            Home
          </h1>
          {/* Banner Video */}
          <div className="relative flex flex-col items-center justify-center">
            <LazyVideo
              src={'/assets/studio/studio-banner.mp4'}
              controls={false}
              loop={true}
              muted={true}
              autoplay={true}
              poster={'/assets/studio/hero-poster.png'}
              playsInline={true}
              classes={['aspect-[3.4/1] w-full h-full object-cover rounded']}
            />
            <div className="absolute left-0 top-0 flex flex-col p-6">
              <h1 className="text-xl font-medium text-white sm:text-5xl sm:leading-[5rem]">
                Design Without Limits
              </h1>
              <h2 className="text-base font-normal text-white sm:text-2xl sm:leading-[2rem]">
                AI-Powered Creativity at Your Fingertips
              </h2>
            </div>
          </div>
        </div>
        {/* Design Content */}
        <div className="flex flex-col gap-y-4">
          <div className="flex flex-col gap-y-2">
            <h2 className="text-[2rem] font-bold leading-[2.7rem] text-primary">
              Design with T-Fashion
            </h2>
            <p className="text-[1rem] leading-[1.5rem] text-night">
              Experience limitless creativity with T-Fashion&apos;s AI studio.
              Whether you&apos;re designing apparel, patterns, or working from a
              sketch, our platform empowers you to bring your ideas to life
              effortlessly. Think it, design it, and watch it come to life.
            </p>
          </div>
          <div className="grid grid-cols-1 gap-6 md:grid-cols-4 lg:grid-cols-4 lg:gap-6">
            <div className="flex flex-col gap-y-4 py-4">
              <div>
                <h3 className="pb-2 text-2xl font-bold leading-[2rem] text-night/90">
                  Create an Apparel
                </h3>
                <p className="text-[1rem] leading-[1.5rem] text-night/90">
                  Describe your design using AI prompt helper, Let T-Fashion
                  Studio turn your idea into a unique design.
                  <span className="text-transparent" inert="">
                    char optimizer cha
                  </span>
                </p>
              </div>
              <div
                style={{
                  background:
                    'url(/assets/studio/studio-apparel.png) no-repeat center center / cover',
                }}
                className="aspect-[3/4] h-full w-full rounded"
              ></div>
              <SidebarButton
                className="flex w-full items-center justify-center rounded border border-secondary/20 bg-secondary/5 py-2 font-bold text-black hover:bg-primary hover:text-white"
                text="Get Started"
                onClick={() => handleChange('GENERATE_APPAREL')}
              />
            </div>
            <div className="flex flex-col gap-y-4 py-4">
              <div>
                <h3 className="pb-2 text-2xl font-bold leading-[2rem] text-night/90">
                  Create a Pattern
                </h3>
                <p className="text-[1rem] leading-[1.5rem] text-night/90">
                  Think of your ideal pattern and describe it using our AI
                  prompt helper. You can add a reference pattern for
                  inspiration.
                  <span className="text-transparent" inert="">
                    char optimizer
                  </span>
                </p>
              </div>
              <div
                style={{
                  background:
                    'url(/assets/studio/studio-pattern.png) no-repeat center center / cover',
                }}
                className="aspect-[3/4] h-full w-full rounded"
              ></div>
              <SidebarButton
                className="flex w-full items-center justify-center rounded border border-secondary/20 bg-secondary/5 py-2 font-bold text-black hover:bg-primary hover:text-white"
                text="Get Started"
                onClick={() => handleChange('GENERATE_PATTERN')}
              />
            </div>
            <div className="flex flex-col gap-y-4 py-4">
              <div>
                <h3 className="pb-2 text-2xl font-bold leading-[2rem] text-night/90">
                  Create from Sketch
                </h3>
                <p className="text-[1rem] leading-[1.5rem] text-night/90">
                  Upload your sketch and describe your vision, Let our AI tool
                  transform your sketch into a fashionable design in seconds.
                </p>
              </div>
              <ImageSlider
                firstImage="/assets/studio/studio-sketch2.png"
                secondImage="/assets/studio/studio-sketch1.png"
              />
              <SidebarButton
                className="flex w-full items-center justify-center rounded border border-secondary/20 bg-secondary/5 py-2 font-bold text-black hover:bg-primary hover:text-white"
                text="Get Started"
                onClick={() => handleChange('REALIZE_SKETCH')}
              />
            </div>
            <div className="flex flex-col gap-y-4 py-4">
              <div>
                <h3 className="pb-2 text-2xl font-bold leading-[2rem] text-night/90">
                  Create a Graphic
                </h3>
                <p className="text-[1rem] leading-[1.5rem] text-night/90">
                  Upload an image or write a prompt to generate a graphic and
                  apply it to mockups or photos.
                  <span className="text-transparent" inert="">
                    char optimizer char char opti
                  </span>
                </p>
              </div>
              <div
                style={{
                  background:
                    'url(/assets/studio/studio-graphic.png) no-repeat center center / cover',
                }}
                className="aspect-[3/4] h-full w-full rounded"
              ></div>
              <SidebarButton
                className="flex w-full items-center justify-center rounded border border-secondary/20 bg-secondary/5 py-2 font-bold text-black hover:bg-primary hover:text-white"
                text="Get Started"
                onClick={() => handleChange('GENERATE_GRAPHIC')}
              />
            </div>
          </div>
        </div>
        {/* AI Tools Content */}
        <div className="flex flex-col gap-y-4">
          <div className="flex flex-col gap-y-2">
            <h2 className="text-[2rem] font-bold leading-[2.7rem] text-primary">
              AI Tools
            </h2>
            <p className="text-[1rem] leading-[1.5rem] text-night">
              Our AI tools empower you to take full control of your creative
              process. Make real-time adjustments, from colors to intricate
              details. Save time while maintaining complete creative control,
              delivering unique, high-quality designs faster than ever.
            </p>
          </div>
          <div className="grid grid-cols-1 gap-12 md:grid-cols-3">
            {STUDIO_HOME_AI_TOOLS.map((tool, index) => {
              return (
                <ImageView
                  imageUrl={tool.image}
                  hoverImageUrl={tool.hoverImage}
                  title={tool.title}
                  description={tool.description}
                  alt={tool.alt}
                  key={index}
                />
              );
            })}
          </div>
        </div>
        {/* Tutorials */}
        {import.meta.env.VITE_APP_ENV === 'development' && (
          <div className="flex flex-col gap-y-4">
            <div className="flex flex-col gap-y-2">
              <h2 className="text-[2rem] font-bold leading-[2.7rem] text-primary">
                Tutorials
              </h2>
              <p className="text-[1rem] leading-[1.5rem] text-night">
                Unleash your creativity with T-Fashion Studio! Our AI-powered
                platform is here to support your artistic vision every step of
                the way. Whether you&rsquo;re just starting or looking to refine
                your skills, these tutorials will show you how to use the studio
                to its full potential.
              </p>
            </div>
            <div className="grid grid-cols-1 gap-12 md:grid-cols-3">
              {STUDIO_HOME_AI_TOOLS.slice(6, 9).map((tool, index) => {
                return (
                  <ImageView
                    imageUrl={tool.image}
                    aspect="[4/3]"
                    hoverImageUrl={tool.hoverImage}
                    description={tool.description}
                    alt={tool.alt}
                    key={index}
                  />
                );
              })}
            </div>
          </div>
        )}
        {/* Inspiration */}
        <div className="flex flex-col gap-y-4">
          <div className="flex flex-col gap-y-2">
            <h2 className="text-[2rem] font-bold leading-[2.7rem] text-primary">
              Discover & Inspiration
            </h2>
            <p className="text-[1rem] leading-[1.5rem] text-night">
              Unlock a world of inspiration with T-Fashion Studio. Browse
              through a curated collection of AI-generated designs created by
              other users, and gain insight into their creative process.
              Discover the prompts behind each design and learn how to craft
              your own AI-powered creations.
            </p>
          </div>
          <div className="grid grid-cols-1 gap-12 md:grid-cols-3">
            {STUDIO_HOME_DISCOVER.map((tool, index) => {
              return (
                <div
                  key={index}
                  onClick={() => {
                    updateCreateItemDetails('GENERATE_APPAREL');
                    setPositivePrompt(tool.description);
                  }}
                >
                  <ImageView
                    imageUrl={tool.image}
                    aspect="[3/4]"
                    hoverImageUrl={tool.hoverImage}
                    description={tool.description}
                    alt={tool.alt}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};
