import { Outlet } from 'react-router-dom';

import { FEATURE_FLAGS, useFeatureFlag } from '@/hooks/useFeatureFlag';
import { TrendsFilters } from '@/pages/general/trends/components/TrendsFilters';

import { TrendsTabs } from './components';

export const Trends = () => {
  const { isEnabled: isTrendsPageEnabled } = useFeatureFlag(
    FEATURE_FLAGS.TRENDS,
  );

  return (
    <div className={'flex h-full w-full'}>
      {isTrendsPageEnabled !== undefined && isTrendsPageEnabled === false ? (
        <div className="flex h-full w-full items-start justify-center pt-40">
          <div className="h-max w-2/5 rounded-lg border border-secondary/30 bg-secondary/5 p-2 px-6 text-center shadow-sm">
            <h1 className="text-xl font-bold leading-8 text-black">
              Trends feature is not active for your organization.
            </h1>
            <p className="text-lg">
              If you believe this is an error, please contact us at{' '}
              <a
                href="mailto:hello@tfashion.ai"
                className="font-semibold text-primary hover:underline hover:underline-offset-4"
              >
                hello@tfashion.ai
              </a>
            </p>
          </div>
        </div>
      ) : (
        <>
          <Outlet />
          <TrendsFilters />
          <TrendsTabs />
        </>
      )}
    </div>
  );
};
