import { useMutation, useQuery } from '@apollo/client';

import {
  ADD_APPAREL_TO_COLLECTION,
  ADD_TONE_TO_COLLECTION,
  CHANGE_COLLECTION_ACCESS,
  CREATE_COLLECTION,
  DELETE_COLLECTION,
  REMOVE_ITEM_FROM_COLLECTION,
  REMOVE_ITEM_FROM_COLLECTION_BY_ID,
} from './mutations';
import {
  COLLECTION,
  COLLECTIONS,
  GET_COLLECTION_ITEMS_GROUPED,
  GET_COLLECTION_ITEMS_MERGED,
} from './queries';

const queryMap = {
  collections: COLLECTIONS,
  collectionItemsMerged: GET_COLLECTION_ITEMS_MERGED,
  collectionItemsGrouped: GET_COLLECTION_ITEMS_GROUPED,
  collection: COLLECTION,
};

const mutationMap = {
  create: CREATE_COLLECTION,
  addApparel: ADD_APPAREL_TO_COLLECTION,
  addTone: ADD_TONE_TO_COLLECTION,
  removeItem: REMOVE_ITEM_FROM_COLLECTION,
  removeItemById: REMOVE_ITEM_FROM_COLLECTION_BY_ID,
  deleteCollection: DELETE_COLLECTION,
  changeAccess: CHANGE_COLLECTION_ACCESS,
};

const handleUserNotFoundError = (data) => {
  if (!data) return data;

  const transformOwner = (item) => {
    if (item?.owner === null) {
      return {
        ...item,
        owner: { name: 'Unknown User', id: null },
      };
    }
    return item;
  };

  // Handle single collection
  if (data.collection) {
    return {
      ...data,
      collection: transformOwner(data.collection),
    };
  }

  // Handle collections array
  if (data.collections?.data) {
    return {
      ...data,
      collections: {
        ...data.collections,
        data: data.collections.data.map(transformOwner),
      },
    };
  }

  return data;
};

export const useCollectionsQuery = (queryName, options) => {
  const query = queryMap[queryName];
  if (!query) {
    throw new Error(`Query ${queryName} not found`);
  }

  options.errorPolicy = 'all';

  const result = useQuery(query, options);

  const errorCode = result.error?.cause?.extensions?.code;

  // TODO: Remove this once we have a proper error handling
  return {
    ...result,
    error:
      errorCode === 'COLLECTION_RESOLVER_GET_COLLECTION_OWNER'
        ? null
        : result.error,
    data: handleUserNotFoundError(result.data),
  };
};

export const useCollectionsMutations = (mutationName, options) => {
  const mutation = mutationMap[mutationName];
  if (!mutation) {
    throw new Error(`Mutation ${mutationName} not found`);
  }

  return useMutation(mutation, options);
};
