import { Outlet } from 'react-router-dom';

import { Container } from '@/components';
import { StudioDesignArea } from '@/pages/general/studio/components/StudioDesignArea';
import { StudioImagePopup } from '@/pages/general/studio/components/StudioImagePopup';
import { StudioTabs } from '@/pages/general/studio/components/StudioTabs';

export const StudioV1 = () => {
  return (
    <div className="h-full w-full">
      <Container className={'mt-0 flex h-full max-h-full p-0'} mainPage>
        <StudioDesignArea />
        <StudioTabs className={'overflow-scroll px-[3.75rem] py-[2rem]'} />

        <StudioImagePopup />
      </Container>

      <Outlet />
    </div>
  );
};
