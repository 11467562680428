import { forwardRef, useCallback, useMemo, useState } from 'react';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';
import * as SliderPrimitive from '@radix-ui/react-slider';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import { useTrendsQuery } from '@/api/trends/hook';
import { useAppFilters } from '@/contexts/AppFiltersContext';
import { useInsightContext } from '@/contexts/InsightContext';
import { useTrendsContext } from '@/contexts/TrendsContext';
import { cn } from '@/helpers/utils.js';

import {
  ForecastLineChart,
  SnapshotLineChart,
} from './components/ForecastLineChart';
import { Background, Header } from './components';

dayjs.extend(utc);

export const monthToSeason = {
  Dec: {
    color: '#E6EFFF',
    season: 'Winter',
  },
  Jan: {
    color: '#E6EFFF',
    season: 'Winter',
  },
  Feb: {
    color: '#E6EFFF',
    season: 'Winter',
  },
  Mar: {
    color: '#DFF1EB',
    season: 'Spring',
  },
  Apr: {
    color: '#DFF1EB',
    season: 'Spring',
  },
  May: {
    color: '#DFF1EB',
    season: 'Spring',
  },
  Jun: {
    color: '#FFECC2',
    season: 'Summer',
  },
  Jul: {
    color: '#FFECC2',
    season: 'Summer',
  },
  Aug: {
    color: '#FFECC2',
    season: 'Summer',
  },
  Sep: {
    color: '#FFDDBE',
    season: 'Fall',
  },
  Oct: {
    color: '#FFDDBE',
    season: 'Fall',
  },
  Nov: {
    color: '#FFDDBE',
    season: 'Fall',
  },
};

const ChartSlider = forwardRef(({ className, ...props }, ref) => (
  <SliderPrimitive.Root
    ref={ref}
    className={cn(
      'relative flex w-full touch-none select-none items-center',
      className,
    )}
    {...props}
  >
    <SliderPrimitive.Track className="relative h-1 w-full grow overflow-hidden rounded bg-secondary">
      <SliderPrimitive.Range className="absolute h-full bg-primary" />
    </SliderPrimitive.Track>
    <SliderPrimitive.Thumb className="focus-visible:ring-ring block h-2.5 w-8 rounded-full border-2 border-primary bg-background ring-offset-background transition-all hover:h-3 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50" />
  </SliderPrimitive.Root>
));

ChartSlider.displayName = SliderPrimitive.Root.displayName;

function ChartForecast() {
  const { seasonId, marketId } = useParams();
  const [searchParams] = useSearchParams();
  const [slider, setSlider] = useState(null);

  const itemId = searchParams.get('id');

  const { data, error, loading } = useTrendsQuery('trendItem', {
    variables: {
      trendItemId: itemId,
      season: seasonId,
      market: marketId,
    },
  });

  const processData = useCallback((data, slider) => {
    if (!data) return { additions: [], forecast: [] };

    const originalForecast = data?.trendItem?.forecast?.data
      ?.map((item) =>
        item.points.map((point) => ({ ...point, year: item.year })),
      )
      ?.flat(2);

    if (!originalForecast) return { additions: [], forecast: [] };

    if (!slider) {
      setSlider({
        min: 0,
        max: originalForecast.length - 12,
        step: 1,
        value: [originalForecast.length - 12],
      });
    }

    const forecast = slider
      ? originalForecast.slice(slider.value[0], slider.value[0] + 12)
      : originalForecast.slice(originalForecast.length - 12);

    const additions = JSON.parse(JSON.stringify(forecast))
      ?.sort((a, b) => dayjs(a.date) - dayjs(b.date))
      .reduce((acc, item) => {
        item.date = item.date.endsWith('Z') ? item.date : Number(item.date);

        const month = dayjs.utc(item.date).format('MMM');
        let year = dayjs.utc(item.date).format('YYYY');

        if (month.toLowerCase() === 'dec') {
          year = dayjs.utc(item.date).add(1, 'year').format('YYYY');
        }

        const season = monthToSeason[month]?.season;

        const seasonExists = acc.find(
          (item) => item.seasonCursor === `${year}-${season}`,
        );

        if (!seasonExists) {
          acc.push({
            seasonCursor: `${year}-${season}`,
            season,
            color: monthToSeason[month].color,
            months: [
              {
                month,
                date: item.date,
              },
            ],
          });

          return acc;
        }

        seasonExists.months.push({
          month,
          date: item.date,
        });

        return acc;
      }, []);

    return {
      additions,
      forecast,
    };
  }, []);

  const { additions, forecast } = useMemo(
    () => processData(data, slider),
    [data, processData, slider],
  );

  if (loading) {
    return (
      <div className="flex h-full items-center justify-center">
        <span>Calculating...</span>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex h-full items-center justify-center">
        <span>Unexpected error {error.message}</span>
      </div>
    );
  }

  if (!forecast.length) {
    return <div></div>;
  }

  return (
    <div className="relative mt-6 flex w-full flex-col">
      <Background.Root>
        {additions?.map((item, index) => (
          <Background.Tile
            key={index}
            {...item}
            className={
              index === 0
                ? 'rounded-bl-lg'
                : index === additions.length - 1
                  ? 'rounded-br-lg'
                  : ''
            }
          />
        ))}
      </Background.Root>

      <ForecastLineChart data={forecast} />

      {slider && (
        <ChartSlider
          {...slider}
          onValueChange={(value) => {
            setSlider({ ...slider, value });
          }}
        />
      )}
    </div>
  );
}
function ChartSnapshots() {
  const { activeChart } = useTrendsContext();
  const { selectedTimeframe } = useAppFilters();

  const [searchParams] = useSearchParams();

  const itemId = searchParams.get('id');

  const { data, error, loading } = useTrendsQuery('snapshotItem', {
    variables: {
      snapshotItemId: itemId,
      timeframe: selectedTimeframe,
    },
  });

  const processData = useCallback((payload) => {
    const data = payload?.snapshotItem?.node;
    if (!data || !data.weeklyData) return { additions: [], forecast: [] };

    const forecast = data.weeklyData.map((item, i) => {
      const dateValue =
        typeof item.date === 'number' || /^\d+$/.test(item.date)
          ? Number(item.date)
          : item.date;

      return {
        date: new Date(dateValue),
        value:
          activeChart === 'visibility'
            ? item.visibility
            : item.qualityEngagement,
        yoyGrowth:
          activeChart === 'visibility'
            ? item.visibility
            : item.qualityEngagement,
        trendType: item.trendType === null ? 'FLAT' : item.trendType,
      };
    });

    const additions = forecast.reduce((acc, item) => {
      const date = dayjs.utc(item.date);
      const month = date.format('MMM');
      const year = date.format('YYYY');
      const season = monthToSeason[month].season;

      const seasonExists = acc.find(
        (accItem) => accItem.seasonCursor === `${year}-${season}`,
      );

      if (!seasonExists) {
        acc.push({
          seasonCursor: `${year}-${season}`,
          season,
          color: monthToSeason[month].color,
          months: [{ month, date: Number(item.date) }],
        });
      } else {
        seasonExists.months.push({ month, date: Number(item.date) });
      }

      return acc;
    }, []);

    return { additions, forecast };
  }, []);

  const { additions, forecast } = useMemo(
    () => processData(data),
    [data, processData],
  );

  if (loading) {
    return (
      <div className="flex h-full items-center justify-center">
        <span>Calculating...</span>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex h-full items-center justify-center">
        <span>Unexpected error {error.message}</span>
      </div>
    );
  }

  if (!forecast.length) {
    return <div></div>;
  }

  return (
    <div className="relative mt-6 flex w-full flex-col">
      <Background.Root>
        {additions?.map((item, index) => (
          <Background.Chart
            key={index}
            {...item}
            className={
              index === 0
                ? 'rounded-bl-lg'
                : index === additions.length - 1
                  ? 'rounded-br-lg'
                  : ''
            }
          />
        ))}
      </Background.Root>

      <SnapshotLineChart data={forecast} />
    </div>
  );
}

function ChartInsight() {
  const { activeChart } = useInsightContext();

  const { insightData, insightLoading, insightError } = useInsightContext();

  const processData = useCallback((payload) => {
    const data = insightData?.insight;
    if (!data || !data.monthlyForecast) return { additions: [], forecast: [] };

    const forecast = data.monthlyForecast.map((item, i) => ({
      date: new Date(item.timeframe),
      value:
        activeChart === 'YOY_GROWTH' ? item.yoyGrowth : item.qualityEngagement,
      yoyGrowth:
        activeChart === 'YOY_GROWTH' ? item.yoyGrowth : item.qualityEngagement,
      trendType: item.trendType === null ? 'FLAT' : item.trendType,
    }));

    console.log({ forecast });

    const additions = forecast.reduce((acc, item) => {
      const date = dayjs.utc(item.date);
      const month = date.format('MMM');
      const year = date.format('YYYY');
      const season = monthToSeason[month].season;

      const seasonExists = acc.find(
        (accItem) => accItem.seasonCursor === `${year}-${season}`,
      );

      if (!seasonExists) {
        acc.push({
          seasonCursor: `${year}-${season}`,
          season,
          color: monthToSeason[month].color,
          months: [{ month, date: Number(item.date) }],
        });
      } else {
        seasonExists.months.push({ month, date: Number(item.date) });
      }

      return acc;
    }, []);

    return { additions, forecast };
  }, []);

  const { additions, forecast } = useMemo(
    () => processData(insightData),
    [insightData, processData],
  );

  if (insightLoading) {
    return (
      <div className="flex h-full items-center justify-center">
        <span>Calculating...</span>
      </div>
    );
  }

  if (insightError) {
    return (
      <div className="flex h-full items-center justify-center">
        <span>Unexpected error {insightError.message}</span>
      </div>
    );
  }

  if (!forecast.length) {
    return <div></div>;
  }

  return (
    <div className="relative mt-6 flex w-full flex-col">
      <Background.Root>
        {additions?.map((item, index) => (
          <Background.Chart
            key={index}
            {...item}
            className={
              index === 0
                ? 'rounded-bl-lg'
                : index === additions.length - 1
                  ? 'rounded-br-lg'
                  : ''
            }
          />
        ))}
      </Background.Root>

      <SnapshotLineChart data={forecast} />
    </div>
  );
}

function Chart() {
  const { pathname } = useLocation();
  const isSnapshots = pathname.includes('snapshots');
  const isInsight = pathname.includes('insight');

  return isSnapshots ? (
    <ChartSnapshots />
  ) : isInsight ? (
    <ChartInsight />
  ) : (
    <ChartForecast />
  );
}

function Root({ children }) {
  return <div className="flex w-full flex-col">{children}</div>;
}

export default {
  Root,
  Chart,
  Header,
};
