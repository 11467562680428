import { useState } from 'react';
import { ImSpinner8 } from 'react-icons/im';
import { useNavigate, useSearchParams } from 'react-router-dom';

import {
  useOrganizationMutation,
  useOrganizationQuery,
} from '@/api/organization/hook';
import { ORGANIZATION } from '@/api/organization/queries';
import { cn } from '@/helpers/utils';
import { useToast } from '@/hooks/useToast';

export function AssignMembers() {
  const [searchParams] = useSearchParams();

  const checkoutUUID = searchParams.get('checkoutUUID');

  const { data } = useOrganizationQuery('organization');
  const { data: licenceData } = useOrganizationQuery('licence', {
    variables: {
      checkoutUUID,
    },
  });

  const navigate = useNavigate();

  const toast = useToast();

  const [assignMembers, { loading }] = useOrganizationMutation(
    'assignStudioLicences',
    {
      refetchQueries: [ORGANIZATION],
      onCompleted: () => {
        toast.createToast({
          message: 'Licence assigned to selected members successfully!',
          type: 'success',
          position: 'top-right',
        });

        navigate('/profile/plans');
      },
      onError: () => {
        toast.createToast({
          message:
            'An error occurred when assigning licence to members. Please try again.',
          type: 'error',
          showCloseButton: true,
          position: 'top-right',
        });
      },
    },
  );

  const [selectedMembers, setSelectedMembers] = useState([]);

  function handleMemberSelect(member) {
    if (selectedMembers.includes(member.id)) {
      return setSelectedMembers((prev) => prev.filter((m) => m !== member.id));
    }

    if (licenceData?.licence.quantity === selectedMembers.length) {
      return toast.createToast({
        message: `You can only select ${licenceData?.licence.quantity} members to assign.`,
        type: 'error',
        showCloseButton: true,
        position: 'top-right',
      });
    }

    return setSelectedMembers((prev) => [...prev, member.id]);
  }

  return (
    <div className="flex flex-col items-center gap-4">
      <h5 className="mt-[52px] text-center text-[48px] font-semibold leading-[55px]">
        Assign Members To New Licence
      </h5>
      <p className="font-regular mx-auto mb-[32px] mt-[16px] max-w-[546px] text-center text-[14px] text-[#626775]">
        Assign member description, you can select{' '}
        {licenceData?.licence.quantity} members.
      </p>

      <div className="flex items-center justify-center gap-2">
        <span>Selected Member Count</span>
        <span className="text-xl font-semibold">
          {selectedMembers.length}/{licenceData?.licence.quantity || 0}
        </span>
      </div>

      <div className="flex w-full flex-col items-center gap-4 px-8">
        {data?.organization.members.map((member) => (
          <div
            key={member.id}
            className={cn(
              'flex items-center justify-between w-full py-4 px-6 border-2',
              selectedMembers.includes(member.id)
                ? 'border-primary'
                : 'hover:border-primary',
            )}
          >
            <div className="flex flex-col gap-1 font-medium">
              <span>{member.fullName}</span>
              <span className="font-normal text-gray-400">{member.email}</span>
            </div>

            {member.currentStudioPlan && (
              <span>{member.currentStudioPlan.label}</span>
            )}
            <button
              className={cn(
                'border-2 border-primary px-4 py-2 font-medium transition-colors hover:bg-primary hover:text-white disabled:opacity-70',
                selectedMembers.includes(member.id) && 'bg-primary text-white',
              )}
              disabled={member.currentStudioPlan.slug !== 'FREE_STUDIO_PLAN'}
              onClick={() => handleMemberSelect(member)}
            >
              {selectedMembers.includes(member.id)
                ? 'Assigned'
                : 'Assign Licence'}
            </button>
          </div>
        ))}
      </div>

      <button
        className="flex items-center gap-2 border-2 border-primary px-8 py-2 text-xl transition-colors hover:bg-primary hover:text-white disabled:opacity-70"
        disabled={loading}
        onClick={() => {
          assignMembers({
            variables: {
              members: selectedMembers,
              licence: licenceData.licence.id,
            },
          });
        }}
      >
        {loading && <ImSpinner8 className="animate-spin" />}
        <span>Complete</span>
      </button>
    </div>
  );
}
