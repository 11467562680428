import { useEffect, useMemo, useRef, useState } from 'react';
import dayjs from 'dayjs';
import { X } from 'lucide-react';
import { findBestMatch } from 'string-similarity';

import { Button } from '@/components/Button';
import { FormLabel } from '@/components/FormInput';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/components/ui/accordion';
import { Checkbox } from '@/components/ui/checkbox';
import { cn } from '@/helpers/utils';

export function InsightTimeframeDropdownSelectItem({
  filter,
  filterKey,
  onChange,
  closeOnChange = true,
  isClearable = false,
}) {
  const triggerRef = useRef(null);
  const [filterPlaceholder, setFilterPlaceholder] = useState(
    filter.placeholder,
  );

  useEffect(() => {
    if (filter.isMulti && filter.value?.length > 0) {
      setFilterPlaceholder(`${filter.value?.length} ${filter.label} selected`);
    } else {
      setFilterPlaceholder(filter.placeholder);
    }
  }, [filter.value]);

  const [alertOption, setAlertOption] = useState(null);

  useEffect(() => {
    function handleFocus(e) {
      if (e.keyCode === 32) {
        e.preventDefault();
        setSearch((prev) => prev + ' ');
      }
    }

    triggerRef.current.addEventListener('keydown', handleFocus);

    return () => {
      if (triggerRef.current) {
        triggerRef.current.removeEventListener('keydown', handleFocus);
      }
    };
  }, [triggerRef]);

  const [search, setSearch] = useState('');

  const searchData = useMemo(() => {
    if (!search || search === '') {
      return filter?.options;
    }

    const ratings = findBestMatch(
      search,
      filter?.options?.map((option) => option.label),
    )
      .ratings.sort((a, b) => b.rating - a.rating)
      .filter((rating) => rating.rating > 0.2)
      .map((rating) =>
        filter?.options?.find((option) => option.label === rating.target),
      );

    const filteredOptions = filter?.options?.filter((option) => {
      const labelIncludesSearch = option.label
        .toLowerCase()
        .includes(search.toLowerCase().trim());

      if (option.options && option.options.length > 0) {
        return (
          labelIncludesSearch ||
          option.options.some((subOption) =>
            subOption.label.toLowerCase().includes(search.toLowerCase().trim()),
          )
        );
      }
      return labelIncludesSearch;
    });

    const finalOptions = [
      ...new Set([...filteredOptions, ...ratings].map((opt) => opt.value)),
    ].map((value) =>
      [...filteredOptions, ...ratings].find((opt) => opt.value === value),
    );

    return finalOptions;
  }, [filter.options, search]);

  const handleClear = (e) => {
    e.stopPropagation();
    onChange(null, filterKey);
    setAlertOption(null);
    setSearch('');
  };

  const handleConfirm = (e) => {
    e.stopPropagation();
    filter.value.forEach((v) => {
      onChange(v, filterKey);
    });
    onChange(alertOption, filterKey);
    setAlertOption(null);
  };

  const handleCancel = (e) => {
    e.stopPropagation();
    setAlertOption(null);
  };

  const isCanSelect = (option) => {
    if (filter.value.find((v) => v.value === option.value)) {
      return true;
    }

    if (filter.value.length >= 3) {
      return false;
    }

    if (filter.value.length === 0) {
      return true;
    }

    const sortedValue = filter.value.sort(
      (a, b) => dayjs(a.value).valueOf() - dayjs(b.value).valueOf(),
    );

    const newDate = dayjs(option.value);

    const minDate = dayjs(sortedValue[0].value);
    const maxDate = dayjs(sortedValue[sortedValue.length - 1].value);

    return !(
      newDate.isBefore(minDate.subtract(1, 'month')) ||
      newDate.isAfter(maxDate.add(1, 'month'))
    );
  };

  return (
    <AccordionItem value={filterKey}>
      <FormLabel>{filter?.label}</FormLabel>
      <AccordionTrigger
        ref={triggerRef}
        className={cn(
          'flex items-center gap-6 mt-2 border rounded-md border-[#1A1A1A] bg-white px-4 py-2 text-[.875rem] leading-5 outline-none font-normal [&_div]:data-[state=open]:hidden [&_div]:data-[state=closed]:flex [&_input]:data-[state=open]:block [&_input]:data-[state=closed]:hidden',
          filter.value ? 'text-charcoal' : 'text-[#A1A1A1]',
          Array.isArray(filter.value) && !filterPlaceholder.includes('selected')
            ? 'py-1.5'
            : 'py-2',
          isClearable &&
            (Array.isArray(filter.value)
              ? filter.value.length > 0
              : Boolean(filter.value))
            ? '[&>svg]:hidden'
            : '',
        )}
      >
        <div className="flex grow items-center gap-2 overflow-hidden">
          {filter.value?.length > 0 && (
            <div className="flex items-center gap-2 overflow-hidden">
              <div className="flex items-center gap-2 overflow-hidden">
                {(() => {
                  const maxVisibleItems = 3;
                  const visibleItems =
                    filter.value.length > maxVisibleItems
                      ? filter.value.slice(0, 2)
                      : filter.value.slice(0, maxVisibleItems);
                  const remainingItemCount =
                    filter.value.length > maxVisibleItems
                      ? filter.value.length - 2
                      : 0;

                  return (
                    <>
                      {visibleItems.map((v, idx) => (
                        <div
                          key={idx}
                          className="flex w-max shrink-0 items-center gap-1 rounded border border-backgroundBorder bg-background p-1"
                          onClick={(e) => {
                            e.stopPropagation();
                            onChange(v, filterKey);
                          }}
                        >
                          <span className="text-[0.725rem] font-semibold leading-4 text-charcoal">
                            {v?.label}
                          </span>
                          <X
                            size={12}
                            strokeWidth={2.5}
                            fill="black"
                            className="text-charcoal"
                          />
                        </div>
                      ))}
                      {remainingItemCount > 0 && (
                        <span className="ml-2 text-sm font-semibold text-primary">
                          +{remainingItemCount} more
                        </span>
                      )}
                    </>
                  );
                })()}
              </div>
            </div>
          )}

          {!filter.value?.length && (
            <span className="line-clamp-1 max-w-fit text-left text-[0.875rem] capitalize leading-5">
              {filter.value?.label || filterPlaceholder}
            </span>
          )}
        </div>
        <input
          className="w-full text-black outline-none"
          type="text"
          value={search}
          placeholder={filterPlaceholder}
          onClick={(e) => e.preventDefault()}
          onChange={(e) => {
            e.stopPropagation();
            e.preventDefault();
            setSearch(e.target.value);
          }}
        />
        {isClearable &&
          (Array.isArray(filter.value)
            ? filter.value.length > 0
            : Boolean(filter.value)) && (
            <button
              onClick={handleClear}
              className="pr-1 font-bold text-charcoal hover:text-[#000]"
            >
              ✕
            </button>
          )}
      </AccordionTrigger>
      <AccordionContent
        className={cn(
          'rounded-md right-0 !z-[999] mt-2 w-full min-w-[230px] bg-[#F7F7F7]  outline-none p-2 max-h-96 overflow-y-auto',
        )}
      >
        {!searchData?.length ? (
          <div className="p-2 text-center text-sm text-black">
            No results for the given filter or search term.
          </div>
        ) : (
          searchData?.map((option, index) => (
            <div key={index}>
              <div
                className={cn(
                  'group flex items-center justify-between rounded border border-transparent px-2 text-[0.875rem] leading-5 transition-all',
                  isCanSelect(option)
                    ? 'cursor-pointer hover:border-[#1A1A1A/5] hover:bg-[#FFFDFD]'
                    : ' text-gray-400',
                )}
              >
                <div
                  onClick={(e) => {
                    e.stopPropagation();

                    if (isCanSelect(option) && closeOnChange) {
                      setAlertOption(null);

                      onChange(option, filterKey);

                      if (filter.value?.length === 3) {
                        const sortedValue = filter.value.sort(
                          (a, b) =>
                            dayjs(a.value).valueOf() - dayjs(b.value).valueOf(),
                        );
                        const isConsecutiveMonths = sortedValue.every(
                          (item, index) => {
                            if (index === 0) return true;
                            const currentMonth = dayjs(item.value);
                            const prevMonth = dayjs(
                              sortedValue[index - 1].value,
                            );
                            return currentMonth.diff(prevMonth, 'month') === 1;
                          },
                        );

                        if (!isConsecutiveMonths) {
                          const filteredWithNewValue = sortedValue.filter(
                            (v) => v.value !== option.value,
                          );

                          const [selectedOne, selectedTwo] =
                            filteredWithNewValue;

                          if (
                            Math.abs(
                              dayjs(option.value).diff(
                                dayjs(selectedTwo.value),
                                'month',
                              ),
                            ) === 1
                          ) {
                            onChange(selectedOne, filterKey);
                          } else if (
                            Math.abs(
                              dayjs(option.value).diff(
                                dayjs(selectedOne.value),
                                'month',
                              ),
                            ) === 1
                          ) {
                            onChange(selectedTwo, filterKey);
                          }
                        }
                      }
                    } else {
                      setAlertOption(option);
                    }
                  }}
                  className="flex h-full w-full grow items-center gap-2 py-[.625rem]"
                >
                  <div
                    className={cn(
                      'flex h-4 w-4 items-center justify-center rounded border',
                      filter?.value?.find((v) => v.value === option.value)
                        ? 'border-primary bg-primary'
                        : 'border-gray-300',
                    )}
                  >
                    {filter?.value?.find((v) => v.value === option.value) && (
                      <Checkbox id="filters" checked={true} />
                    )}
                  </div>

                  {option.label}
                </div>
              </div>
              {alertOption === option && (
                <div className="items-center gap-2 ">
                  <div className="text-sm text-gray-500">
                    Selecting a new filter will clear your previous choices.
                  </div>
                  <div className="flex items-center gap-2">
                    <Button
                      variant={'primary'}
                      size={'sm'}
                      onClick={handleConfirm}
                    >
                      Confirm
                    </Button>
                    <Button
                      variant={'primary'}
                      size={'sm'}
                      onClick={handleCancel}
                    >
                      Cancel
                    </Button>
                  </div>
                </div>
              )}
            </div>
          ))
        )}
      </AccordionContent>
    </AccordionItem>
  );
}

export function InsightTimeframeDropdownSelect({ children, ...props }) {
  return <Accordion {...props}>{children}</Accordion>;
}
