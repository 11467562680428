import React from 'react';
import { useForm } from 'react-hook-form';

import { inviteCoWorkersFormOptions } from './inviteCoWorkersFormOptions';

const CopyIcon = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_523_5136)">
      <path
        d="M15 6.75H8.25C7.42157 6.75 6.75 7.42157 6.75 8.25V15C6.75 15.8284 7.42157 16.5 8.25 16.5H15C15.8284 16.5 16.5 15.8284 16.5 15V8.25C16.5 7.42157 15.8284 6.75 15 6.75Z"
        stroke="#282928"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.75 11.25H3C2.60218 11.25 2.22064 11.092 1.93934 10.8107C1.65804 10.5294 1.5 10.1478 1.5 9.75V3C1.5 2.60218 1.65804 2.22064 1.93934 1.93934C2.22064 1.65804 2.60218 1.5 3 1.5H9.75C10.1478 1.5 10.5294 1.65804 10.8107 1.93934C11.092 2.22064 11.25 2.60218 11.25 3V3.75"
        stroke="#282928"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_523_5136">
        <rect width="18" height="18" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const SendIcon = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_523_5139)">
      <path
        d="M16.5 1.5L8.25 9.75"
        stroke="#C29B77"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.5 1.5L11.25 16.5L8.25 9.75L1.5 6.75L16.5 1.5Z"
        stroke="#C29B77"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_523_5139">
        <rect width="18" height="18" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const InviteCoWorkers = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm(inviteCoWorkersFormOptions);

  const onSubmit = (data) => {};

  return (
    <div className="mx-auto flex w-full max-w-[404px] flex-col items-center justify-center">
      <h5 className="mt-[114px] text-[48px] font-semibold  leading-[55px] ">
        Invite Co-workers
      </h5>
      <p className="font-regular mt-[24px] text-[14px] leading-[24px] text-[#626775]">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do.
      </p>
      <p className="mb-[4px] mt-[48px] flex w-full justify-start pl-[18px]  text-[16px] font-semibold leading-[28px]">
        Write Co-worker E-mail
      </p>
      <form className="flex w-full flex-col" onSubmit={handleSubmit(onSubmit)}>
        <input
          type="text"
          name="email"
          className="font-regular flex h-[55px] w-full border border-charcoal px-[18px] text-[14px] leading-[16px] outline-none placeholder:text-[#D9D9D9]"
          placeholder="Write Co-worker E-mail"
          {...register('email', { required: true })}
        />
        {errors.email?.message && (
          <p className="mt-[4px] text-[16px] font-semibold leading-[28px] text-red-500 ">
            {errors.email?.message}
          </p>
        )}
        <div className="mb-[40px] mt-[28px] flex w-full justify-between">
          <span className="flex items-center gap-x-[10px]   text-[16px] font-semibold underline">
            <CopyIcon /> Copy Invite Link
          </span>
          <span className="flex items-center gap-x-[10px]  text-[16px] font-semibold text-primary underline">
            <SendIcon /> Send Invitation
          </span>
        </div>
        <button className="btn-primary-without-hover h-[46px]">Next</button>
      </form>
      <span className="mt-[22px] cursor-pointer  text-[16px] font-semibold leading-[28px] text-primary underline">
        Skip
      </span>
    </div>
  );
};
