import { useEffect, useMemo, useRef, useState } from 'react';
import Highlighter from 'react-highlight-words';
import { RxCross1 } from 'react-icons/rx';
import * as Tabs from '@radix-ui/react-tabs';
import { debounce } from 'lodash';
import { ChevronDownIcon, Search } from 'lucide-react';

import { FormInput } from '@/components/FormInput';
import { Heading } from '@/components/Heading';
import { Input } from '@/components/Input/index';
import { SelectDropdown } from '@/components/SelectDropdown';
import { Sidebar } from '@/components/Sidebar';
import { Spacing } from '@/components/Spacing';
import { TabHeading } from '@/components/TabHeading';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { ScrollArea, ScrollBar } from '@/components/ui/scroll-area';
import { useExploreContext } from '@/contexts/ExploreContext';
import { cn } from '@/helpers/utils';
import { FEATURE_FLAGS, useFeatureFlag } from '@/hooks/useFeatureFlag';
import { SocialMediaImages } from '@/pages/general';
import { FashionWeekItems } from '@/pages/general/explore/FashionItems';
const tabs = [
  // {
  //   label: 'All',
  //   value: 'all',
  //   Component: SocialMediaImages
  // },
  {
    label: 'Fashion Weeks',
    value: 'fashion-weeks',
    Component: FashionWeekItems,
  },
  {
    label: 'Social Media',
    value: 'social-media',
    Component: SocialMediaImages,
  },
  // {
  //   label: 'AI Designs',
  //   value: 'ai-designs',
  //   Component: SocialMediaImages
  // }
];

const unwantedFilterKeys = ['audience', 'search'];

function returnPillLabel(key, filter) {
  if (key === 'market') {
    if (filter?.value && Array.isArray(filter.value)) {
      const firstValue = filter.value[0];
      return (
        firstValue?.label?.toLowerCase() ||
        firstValue?.location?.toLowerCase() ||
        filter?.location?.toLowerCase()
      );
    }
    return `${filter?.value?.label?.toLowerCase() || filter?.label?.toLowerCase() || filter?.location?.toLowerCase()}`;
  }

  if (key === 'personPosition') {
    return `Person Position: ${filter?.value?.label?.toLowerCase()}`;
  }

  if (key === 'personCount') {
    return `Person Count: ${filter?.value?.label?.toLowerCase()}`;
  }

  if (key === 'gender' || key === 'platform') {
    return (
      (key === 'gender' ? 'Gender: ' : 'Platform: ') +
      (filter?.value?.label || filter?.value?.name).toLowerCase()
    );
  }

  if (key === 'category') {
    return filter?.type
      ? filter?.type + ': ' + filter?.label
      : filter?.value?.label || filter?.label;
  }

  if (key === 'color') {
    return filter?.value?.label;
  }

  if (key === 'subcolor') {
    return filter?.value?.label;
  }

  if (key === 'designs') {
    return filter?.label?.toLowerCase();
  }

  return (
    filter?.value?.label ||
    filter?.value?.name ||
    filter?.value[0]?.label
  )?.toLowerCase();
}

export function ExploreSort({ variant = 'default' }) {
  const { isEnabled: exploreAdvanced } = useFeatureFlag(
    FEATURE_FLAGS.EXPLORE_ADVANCED,
  );

  const { socialMediaSort, handleSocialMediaSortOnChange, activeTab } =
    useExploreContext();

  if (!exploreAdvanced || activeTab === 'fashion-weeks') {
    return null;
  }

  if (variant === 'mobile') {
    return (
      <div className="flex w-max items-center justify-end gap-1 pr-2">
        <DropdownMenu>
          <DropdownMenuTrigger>
            <div className="flex w-16 items-center justify-end gap-0.5 text-[0.7rem] font-medium xs:w-20 xs:text-xs">
              {socialMediaSort?.sort?.value?.label ||
                socialMediaSort?.sort?.placeholder}
              {!socialMediaSort?.sort?.value?.label && (
                <ChevronDownIcon size={14} strokeWidth={2.5} />
              )}
            </div>
          </DropdownMenuTrigger>
          <DropdownMenuContent>
            {socialMediaSort?.sort?.options.map((option, i) => (
              <DropdownMenuItem
                onClick={() => handleSocialMediaSortOnChange(option, 'sort')}
                key={i}
              >
                {option.label}
              </DropdownMenuItem>
            ))}
          </DropdownMenuContent>
        </DropdownMenu>
        {socialMediaSort?.sort?.value?.label && (
          <button
            className="flex items-center justify-center pt-0.5"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              handleSocialMediaSortOnChange(null, 'sort');
            }}
          >
            <RxCross1 size={10} strokeWidth={1.5} />
          </button>
        )}
      </div>
    );
  }

  return Object.entries(socialMediaSort).map(([sortKey, sort], i) => (
    <FormInput className="relative right-0 max-w-[8rem]" key={i}>
      <SelectDropdown
        variant="sort"
        placeholder={sort?.placeholder}
        isSearchable={false}
        options={sort?.options}
        multiselect={false}
        isClearable={true}
        value={sort?.value}
        key={sort?.key}
        className="font-normal"
        onChange={(e) => handleSocialMediaSortOnChange(e, sortKey)}
      />
    </FormInput>
  ));
}

export function ExploreFilterPills() {
  const {
    includes,
    setIncludes,
    excludes,
    setExcludes,
    activeTab,
    fashionWeekFilters,
    socialMediaFilters,
    handleFashionWeekFiltersOnChange,
    handleSocialMediaFiltersOnChange,
    shouldRefetch,
    triggerRefetch,
    fashionWeekClearAll,
    socialMediaClearAll,
    isExploreMobile,
    activeSearchTab,
    setActiveSearchTab,
  } = useExploreContext();

  useEffect(() => {
    if (activeTab === 'fashion-weeks') {
      socialMediaClearAll();
    } else if (activeTab === 'social-media') {
      fashionWeekClearAll();
    }
    if (shouldRefetch) {
      triggerRefetch();
    }
  }, [activeTab]);

  useEffect(() => {
    if (shouldRefetch === true) {
      // For deep copy, cause left side filter when changed triggered here;
      const newPills = JSON.parse(
        JSON.stringify(
          Object.entries(
            activeTab === 'fashion-weeks'
              ? fashionWeekFilters
              : socialMediaFilters,
          ).filter(
            ([key, pill]) => pill.value && !unwantedFilterKeys.includes(key),
          ),
        ),
      );

      setIncludes(newPills);

      const allExclude = JSON.parse(
        JSON.stringify(
          Object.entries(
            activeTab === 'fashion-weeks'
              ? fashionWeekFilters
              : socialMediaFilters,
          ).filter(
            ([key, pill]) =>
              pill.excludeValue && !unwantedFilterKeys.includes(key),
          ),
        ),
      );

      setExcludes(allExclude);
    }
  }, [shouldRefetch, activeTab, fashionWeekFilters, socialMediaFilters]);

  function handleRemovePill(key, filter) {
    const updatePills = (updatedFilters = null) => {
      setIncludes((prevPills) => {
        if (!updatedFilters) {
          return prevPills.filter(([pillKey]) => pillKey !== key);
        } else {
          return prevPills.map(([pillKey, pill]) =>
            pillKey === key
              ? [pillKey, { ...pill, value: updatedFilters }]
              : [pillKey, pill],
          );
        }
      });
      triggerRefetch();
    };

    if (activeTab === 'fashion-weeks') {
      if (key === 'category') {
        handleFashionWeekFiltersOnChange(filter, key);
        updatePills();
      } else if (key === 'designs') {
        handleFashionWeekFiltersOnChange(filter, key);
        updatePills();
      } else if (key === 'color') {
        handleFashionWeekFiltersOnChange(filter, key);
        updatePills();
      } else {
        handleFashionWeekFiltersOnChange(null, key);
        updatePills();
      }
    } else {
      if (key === 'category') {
        handleSocialMediaFiltersOnChange(filter, key);
        updatePills();
      } else if (key === 'attribute.custom') {
        handleSocialMediaFiltersOnChange(filter, key);
        updatePills();
      } else if (key === 'timeframe') {
        handleSocialMediaFiltersOnChange(null, key);
        updatePills();
      } else if (key === 'tone') {
        handleSocialMediaFiltersOnChange(null, key);
        updatePills();
      } else {
        if (key === 'market') {
          const removeMarket = {
            audVal: filter.value,
            marketId: filter.marketId,
          };
          handleSocialMediaFiltersOnChange(removeMarket, key);
        } else {
          handleSocialMediaFiltersOnChange(filter, key);
        }
        updatePills();
      }
    }
  }

  function handleRemoveExcludePill(key, filter) {
    const updateExcludes = (updatedFilters = null) => {
      setExcludes((prevExcludes) => {
        if (!updatedFilters) {
          return prevExcludes.filter(([pillKey]) => pillKey !== key);
        } else {
          return prevExcludes.map(([pillKey, pill]) =>
            pillKey === key
              ? [pillKey, { ...pill, excludeValue: updatedFilters }]
              : [pillKey, pill],
          );
        }
      });
      triggerRefetch();
    };

    if (activeTab === 'fashion-weeks') {
      if (key === 'category') {
        handleFashionWeekFiltersOnChange(filter, key, true);
        updateExcludes();
      } else if (key === 'designs') {
        handleFashionWeekFiltersOnChange(filter, key, true);
        updateExcludes();
      } else {
        handleFashionWeekFiltersOnChange(null, key, true);
        updateExcludes();
      }
    } else {
      if (key === 'category') {
        handleSocialMediaFiltersOnChange(filter, key, false, true);
        updateExcludes();
      } else if (key === 'attribute.custom') {
        handleSocialMediaFiltersOnChange(filter, key, false, true);
        updateExcludes();
      } else if (key === 'color' || key === 'subcolor') {
        handleSocialMediaFiltersOnChange(filter, key, false, true);
        updateExcludes();
      } else {
        handleSocialMediaFiltersOnChange(null, key, false, true);
        updateExcludes();
      }
    }
  }

  const includePills = includes.filter(([key, filter]) => {
    if (key === 'gender' || key === 'platform') return false;

    return filter.value && Array.isArray(filter.value)
      ? !filter.value.includes(null)
      : true;
  });

  const excludePills = excludes.filter(([key, filter]) => {
    if (key === 'gender' || key === 'platform') return false;
    return filter.excludeValue && Array.isArray(filter.excludeValue)
      ? !filter.excludeValue.includes(null)
      : true;
  });

  return (
    <div className="flex w-full flex-col gap-4">
      <div className="flex w-full flex-row items-center justify-between gap-4 rounded border border-backgroundBorder bg-background pr-2">
        <ScrollArea className="w-full whitespace-nowrap">
          <div
            className={cn(
              'flex w-full overflow-y-auto items-center gap-x-4 gap-y-2 pr-4 px-4 py-1 sm:py-3',
            )}
          >
            <span className="text-sm font-semibold capitalize text-[#565758]">
              Includes
            </span>
            {includePills.length === 0 && (
              <div className="flex shrink-0 items-center gap-2 rounded border border-backgroundBorder bg-white p-1 px-2 text-night sm:p-2">
                <span
                  className={cn('text-night text-sm font-semibold capitalize')}
                >
                  {activeTab === 'fashion-weeks'
                    ? 'All Fashion Weeks'
                    : 'All Markets'}
                </span>
              </div>
            )}
            {includePills.map(([key, filter]) =>
              key === 'attribute.custom' ? (
                filter?.value?.map((attribute) => (
                  <div
                    className="flex shrink-0 items-center gap-2 rounded border border-backgroundBorder bg-white p-2 text-night"
                    key={attribute.name}
                  >
                    <span
                      className={cn(
                        'text-night text-sm font-semibold capitalize',
                      )}
                    >
                      {attribute.name}: {attribute.value}
                    </span>
                    <button onClick={() => handleRemovePill(key, attribute)}>
                      <RxCross1 size={12} />
                    </button>
                  </div>
                ))
              ) : filter.isMulti ? (
                filter.value.map((valueItem, index) => (
                  <div
                    className="flex shrink-0 items-center gap-2 rounded border border-backgroundBorder bg-white p-2 text-night"
                    key={`${key}-${index}`}
                  >
                    {(key === 'color' ||
                      key === 'subcolor' ||
                      key === 'tone') &&
                      valueItem.hex && (
                        <div
                          className="h-4 w-4 gap-4 rounded-full"
                          style={{
                            background: valueItem.hex,
                          }}
                        />
                      )}
                    <span
                      className={cn(
                        'text-night text-sm font-semibold capitalize',
                      )}
                    >
                      {returnPillLabel(key, valueItem) || valueItem.label}
                    </span>
                    {key !== 'platform' && key !== 'gender' && (
                      <button onClick={() => handleRemovePill(key, valueItem)}>
                        <RxCross1 size={12} />
                      </button>
                    )}
                  </div>
                ))
              ) : (
                <div
                  className="flex shrink-0 items-center gap-2 rounded border border-backgroundBorder bg-white p-2 text-night"
                  key={key}
                >
                  {(key === 'color' || key === 'tone') &&
                    filter?.value?.hex && (
                      <div
                        className="h-4 w-4 gap-4 rounded-full"
                        style={{
                          background: filter.value.hex,
                        }}
                      />
                    )}
                  <span
                    className={cn(
                      'text-night text-sm font-semibold capitalize',
                    )}
                  >
                    {returnPillLabel(key, filter)}
                  </span>
                  {key !== 'platform' && key !== 'gender' && (
                    <button onClick={() => handleRemovePill(key, filter)}>
                      <RxCross1 size={12} />
                    </button>
                  )}
                </div>
              ),
            )}
          </div>
          <ScrollBar orientation="horizontal" />
        </ScrollArea>
        {!isExploreMobile && <ExploreSort />}
      </div>
      {excludePills.length > 0 && (
        <ScrollArea className="w-full whitespace-nowrap">
          <div className="flex w-full items-center gap-x-4 gap-y-2 overflow-y-auto rounded border border-backgroundBorder bg-background px-4 py-3">
            <span className="text-sm font-semibold capitalize text-[#565758]">
              Excludes
            </span>
            {excludePills.map(([key, filter]) => (
              <div className="flex flex-row gap-2" key={key}>
                {filter.excludeValue.map((value) => (
                  <div
                    className="flex items-center gap-2 rounded border border-backgroundBorder bg-white p-2 text-night"
                    key={value}
                  >
                    <span
                      className={cn(
                        'text-night text-sm font-semibold capitalize',
                      )}
                    >
                      {value.label}
                    </span>
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        handleRemoveExcludePill(key, value);
                      }}
                    >
                      <RxCross1 size={12} />
                    </button>
                  </div>
                ))}
              </div>
            ))}
          </div>
        </ScrollArea>
      )}

      {socialMediaFilters.search.value && (
        <div className="sticky top-0 z-50 bg-white">
          <Tabs.Root defaultValue="tab1">
            <Tabs.List className="flex gap-2 [&>*]:w-20">
              <Tabs.Trigger
                key="tab2"
                value="tab2"
                className={cn(
                  'text-sm font-medium p-2 border border-secondary/20 bg-secondary/5 rounded capitalize',
                  activeSearchTab === 'THEMATIC' &&
                    'text-primary border-primary',
                )}
                onClick={() => setActiveSearchTab('THEMATIC')}
              >
                Thematic
              </Tabs.Trigger>
              <Tabs.Trigger
                key="tab1"
                value="tab1"
                className={cn(
                  'text-sm font-medium p-2 border border-secondary/20 bg-secondary/5 rounded capitalize ',
                  activeSearchTab === 'CONTAINS' &&
                    'text-primary border-primary',
                )}
                onClick={() => setActiveSearchTab('CONTAINS')}
              >
                Text
              </Tabs.Trigger>
            </Tabs.List>
          </Tabs.Root>
        </div>
      )}
    </div>
  );
}

export function ExploreSocialMediaSearch() {
  const {
    socialMediaFilters,
    handleSocialMediaFiltersOnChange,
    isSearchOpen,
    isExploreMobile,
    setIsSearchOpen,
  } = useExploreContext();

  const search = socialMediaFilters?.search || {};
  const [inputValue, setInputValue] = useState(search.value || '');
  const [isTyping, setIsTyping] = useState(false);

  const debouncedSearch = useMemo(
    () =>
      debounce((value) => {
        setIsTyping(false);
        if (value.trim()) {
          handleSocialMediaFiltersOnChange(value, 'search');
        }
      }, 1400),
    [handleSocialMediaFiltersOnChange, setIsSearchOpen],
  );

  const handleInputChange = (e) => {
    const value = e.target.value;
    setInputValue(value);

    if (!isExploreMobile) {
      setIsTyping(true);
      debouncedSearch(value);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      setIsTyping(false);
      if (inputValue.trim()) {
        handleSocialMediaFiltersOnChange(inputValue, 'search');
      }
      if (isExploreMobile && isSearchOpen) {
        setIsSearchOpen(false);
      }
    }
  };

  const handleClearSearch = () => {
    if (isTyping) setIsTyping(false);
    setInputValue('');
    handleSocialMediaFiltersOnChange('', 'search');
    if (isExploreMobile && isSearchOpen) setIsSearchOpen(false);
  };

  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, [debouncedSearch]);

  if (isExploreMobile && !isSearchOpen) return null;

  if (isExploreMobile && isSearchOpen) {
    return (
      <>
        <Sidebar
          className="flex h-full w-full flex-col gap-y-6 pt-2"
          variant="mobile"
          style={{
            display: 'block',
            transition: 'display 0.3s ease-in-out',
          }}
        >
          <div className="flex items-center justify-between">
            <Heading size={'xl'}>Search</Heading>
            <button onClick={() => setIsSearchOpen(false)}>
              <RxCross1 size={28} />
            </button>
          </div>
          <Spacing size={1.125} />
          <div className="flex w-full items-center gap-2 rounded-md border-5 border-[#1A1A1A] bg-white px-4 py-2 outline-none">
            <Search size={24} strokeWidth={1.5} stroke="#a1a1a1" />
            <Input
              type="text"
              placeholder={search.placeholder}
              value={inputValue}
              onChange={handleInputChange}
              onKeyDown={handleKeyPress}
              className="w-full text-[0.875rem] font-medium leading-6 outline-none"
            />
            {inputValue && (
              <RxCross1
                size={18}
                className="mt-0.5 cursor-pointer text-black"
                onClick={handleClearSearch}
              />
            )}
          </div>
        </Sidebar>
      </>
    );
  }

  return (
    <div className="flex min-w-[20rem] items-center gap-2 rounded-md border-5 border-[#1A1A1A] bg-white px-4 py-2 outline-none">
      <Search size={24} strokeWidth={1.5} stroke="#a1a1a1" />
      <Input
        type="text"
        placeholder={search.placeholder}
        value={inputValue}
        onChange={handleInputChange}
        onKeyDown={handleKeyPress}
        className="w-full text-[0.800rem] font-medium leading-6 outline-none"
      />
      {inputValue && (
        <RxCross1
          size={18}
          className="mt-0.5 cursor-pointer text-black"
          onClick={handleClearSearch}
        />
      )}
    </div>
  );
}

export function ExploreFashionWeekSearch() {
  const {
    fashionWeekFilters,
    handleFashionWeekFiltersOnChange,
    loadFashionWeekSearchOptions,
    isExploreMobile,
    isSearchOpen,
    setIsSearchOpen,
  } = useExploreContext();

  const search = fashionWeekFilters?.search;
  const [isTyping, setIsTyping] = useState(false);
  const [isOptionsLoading, setIsOptionsLoading] = useState(false);

  const debouncedLoadOptions = debounce((inputValue, callback) => {
    setIsOptionsLoading(true);
    loadFashionWeekSearchOptions(inputValue, (options) => {
      callback(options);
      setIsOptionsLoading(false);
    });
  }, 500);

  const handleInputChange = (selectedOption) => {
    setIsTyping(true);
    handleFashionWeekFiltersOnChange(selectedOption, 'search');
    if (isExploreMobile && isSearchOpen) setIsSearchOpen(false);
  };

  useEffect(() => {
    return () => {
      debouncedLoadOptions.cancel();
      setIsTyping(false);
    };
  }, [debouncedLoadOptions]);

  if (isExploreMobile && !isSearchOpen) return null;

  if (isExploreMobile && isSearchOpen) {
    return (
      <>
        <Sidebar
          className="flex h-full w-full flex-col gap-y-6 pt-2"
          variant="mobile"
          style={{
            display: 'block',
            transition: 'display 0.3s ease-in-out',
          }}
        >
          <div className="mt-2 flex items-center justify-between">
            <Heading size={'xl'}>Search</Heading>
            <button onClick={() => setIsSearchOpen(false)}>
              <RxCross1 size={28} />
            </button>
          </div>
          <Spacing size={1.125} />
          <FormInput>
            <SelectDropdown
              placeholder={search?.placeholder}
              isSearchable={search?.isSearchable}
              options={search?.options}
              multiselect={false}
              isClearable={search?.isClearable}
              value={search?.value}
              key={'search'}
              onChange={handleInputChange}
              isAsync={true}
              isCreatable={true}
              formatCreateLabel={(inputValue) => `${inputValue}`}
              cacheOptions={false}
              loadOptions={!isTyping ? debouncedLoadOptions : undefined}
              defaultOptions
              classNames={{
                control: 'w-full',
                valueContainer: 'min-w-[12rem]',
              }}
              allowCreateWhileLoading={true}
              createOptionPosition={'first'}
              variant="search"
              noOptionsMessage={() => ''}
              loading={isOptionsLoading}
              icon={<Search size={20} strokeWidth={1.5} stroke="#a1a1a1" />}
              components={{
                Option: ({
                  selectProps,
                  innerProps,
                  innerRef,
                  data,
                  ...props
                }) => {
                  return (
                    <div
                      ref={innerRef}
                      {...innerProps}
                      className={props.getClassNames(props.type, props)}
                    >
                      <Highlighter
                        highlightClassName="bg-transparent"
                        unhighlightClassName="font-semibold"
                        searchWords={[selectProps.inputValue]}
                        autoEscape={true}
                        textToHighlight={data.label}
                      />
                    </div>
                  );
                },
              }}
            />
          </FormInput>
        </Sidebar>
      </>
    );
  }

  return (
    <div className="min-w-[20rem]">
      <FormInput>
        <SelectDropdown
          placeholder={search?.placeholder}
          isSearchable={search?.isSearchable}
          options={search?.options}
          multiselect={false}
          isClearable={search?.isClearable}
          value={search?.value}
          key={'search'}
          onChange={handleInputChange}
          isAsync={true}
          isCreatable={true}
          formatCreateLabel={(inputValue) => `${inputValue}`}
          cacheOptions={false}
          loadOptions={!isTyping ? debouncedLoadOptions : undefined}
          defaultOptions
          classNames={{
            control: 'min-w-[20rem]',
            valueContainer: 'min-w-[12rem]',
          }}
          allowCreateWhileLoading={true}
          createOptionPosition={'first'}
          variant="search"
          noOptionsMessage={() => ''}
          loading={isOptionsLoading}
          icon={<Search size={20} strokeWidth={1.5} stroke="#a1a1a1" />}
          components={{
            Option: ({ selectProps, innerProps, innerRef, data, ...props }) => {
              return (
                <div
                  ref={innerRef}
                  {...innerProps}
                  className={props.getClassNames(props.type, props)}
                >
                  <Highlighter
                    highlightClassName="bg-transparent"
                    unhighlightClassName="font-semibold"
                    searchWords={[selectProps.inputValue]}
                    autoEscape={true}
                    textToHighlight={data.label}
                  />
                </div>
              );
            },
          }}
        />
      </FormInput>
    </div>
  );
}

export function ExploreTabs({ className }) {
  const {
    activeTab,
    setActiveTab,
    shouldRefetch,
    isExploreMobile,
    isSearchOpen,
    isFiltersOpen,
  } = useExploreContext();
  const contentRef = useRef(null);
  const [isVisible, setIsVisible] = useState(true);
  const [showScrollTopButton, setShowScrollTopButton] = useState(false);
  const lastScrollTop = useRef(0);

  useEffect(() => {
    if (!isExploreMobile) return;

    const handleScroll = () => {
      if (!contentRef.current) return;
      const scrollableContent =
        contentRef.current.querySelector('.overflow-y-auto');
      if (scrollableContent) {
        const scrollTop = scrollableContent.scrollTop;
        setShowScrollTopButton(scrollTop > 500);
        if (scrollTop > lastScrollTop.current) {
          setIsVisible(scrollTop <= 50);
        } else {
          setIsVisible(true);
        }
        lastScrollTop.current = scrollTop <= 0 ? 0 : scrollTop;
      }
    };

    const scrollableContent =
      contentRef.current?.querySelector('.overflow-y-auto');
    if (scrollableContent) {
      scrollableContent.addEventListener('scroll', handleScroll);
      return () =>
        scrollableContent.removeEventListener('scroll', handleScroll);
    }
  }, [isExploreMobile]);

  useEffect(() => {
    if (shouldRefetch && contentRef.current) {
      const scrollableContent =
        contentRef.current.querySelector('.overflow-y-auto');
      if (scrollableContent) {
        scrollableContent.scrollTo(0, 0);
      }
    }
  }, [shouldRefetch]);

  const handleScrollToTop = () => {
    const scrollableContent =
      contentRef.current?.querySelector('.overflow-y-auto');
    if (scrollableContent) {
      scrollableContent.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };

  return (
    <div
      className={cn('h-full w-full overflow-y-auto relative', className)}
      ref={contentRef}
    >
      <Tabs.Root
        value={activeTab}
        onValueChange={(tab) => {
          setActiveTab(tab);
        }}
        className={'relative flex h-full flex-col overflow-y-auto'}
      >
        <div
          className={cn(
            'sticky -top-1 z-[400] bg-white pt-2 transition-transform duration-300 sm:pt-7',
            !isVisible && isExploreMobile && '-translate-y-full',
          )}
        >
          <div
            className={cn(
              'flex items-center justify-between',
              isExploreMobile ? 'px-4 pb-4' : 'px-[3.75rem] pb-7',
            )}
          >
            <Tabs.List
              aria-label="tabs"
              className={'flex w-full items-center justify-between'}
            >
              <div className={cn('flex gap-12', isExploreMobile && 'gap-1')}>
                {tabs.map((tab, i) => (
                  <TabHeading
                    label={tab.label}
                    value={tab.value}
                    size={isExploreMobile ? 'md' : 'xl'}
                    key={i}
                    variant={isExploreMobile ? 'mobile' : ''}
                  />
                ))}
              </div>
            </Tabs.List>
            {isExploreMobile && <ExploreSort variant="mobile" />}

            {!isExploreMobile && (
              <>
                {activeTab === 'social-media' ? (
                  <ExploreSocialMediaSearch />
                ) : (
                  <ExploreFashionWeekSearch />
                )}
              </>
            )}
          </div>

          <div
            className={cn(
              'flex w-full gap-x-6 pb-4',
              isExploreMobile ? 'px-4' : 'px-[3.75rem]',
            )}
          >
            <ExploreFilterPills />
          </div>
        </div>

        {tabs.map((tab, i) => (
          <Tabs.Content
            key={i}
            value={tab.value}
            className={cn(
              'h-full w-full',
              isExploreMobile ? 'px-4' : 'px-[3.75rem]',
            )}
          >
            <tab.Component />
          </Tabs.Content>
        ))}

        {showScrollTopButton && !isSearchOpen && !isFiltersOpen && (
          <button
            onClick={handleScrollToTop}
            className="group fixed bottom-6 right-6 z-[100] cursor-pointer rounded-full border border-primaryLight/60 bg-primaryLight/40 px-4 py-2 font-medium text-white/80 shadow-lg hover:border-primaryHover hover:bg-primary hover:text-white hover:shadow-xl"
          >
            <span className="block transition-all duration-200 ease-in-out group-hover:hidden">
              Top ↑
            </span>
            <span className="hidden group-hover:block group-hover:transition-all group-hover:duration-200 group-hover:ease-in-out">
              Scroll to Top ↑
            </span>
          </button>
        )}
      </Tabs.Root>
    </div>
  );
}
