import React from 'react';

import { useReports } from '@/hooks/report';

import { CheckIcon, CrossIcon } from '../../icons';

export const SelectPlanInfo = ({ item }) => {
  const { plan } = useReports();

  const planSelected = plan && plan.slug === item.slug;

  return (
    <div
      className="flex flex-col border-2 border-charcoal p-[24px] group-hover:border-primary"
      style={{
        borderColor: planSelected && '#3CA780',
      }}
    >
      <h5 className="text-[48px] font-semibold leading-[55px] ">
        {item.price} $
      </h5>
      <p className="font-regular mt-[12px] border-b border-[#A1A1A1] pb-[22px] text-[14px] leading-[16px] text-[#626775]">
        {item.description}
      </p>
      <div className="mb-[33px] mt-[24px] flex w-full flex-col gap-y-[14px]">
        {item.rights.map((right, index) => {
          if (right.type === 'boolean') {
            return (
              <p key={index} className="flex items-center gap-x-[13px]">
                {right.value >= 1 ? <CheckIcon /> : <CrossIcon />}
                <span>{right.description}</span>
              </p>
            );
          }

          return (
            <p key={index} className="flex items-center gap-x-[13px]">
              <span className="capitalize">{right.name}</span>
              <span className="font-semibold">{right.value}</span>
            </p>
          );
        })}

        <p className="flex items-center gap-x-[13px]">
          <span className="capitalize">Renewal</span>
          <span className="font-semibold capitalize">{item.renewal}</span>
        </p>
      </div>
    </div>
  );
};
