export function ImageSkeleton() {
  return <div className="h-full w-full animate-pulse bg-slate-400"></div>;
}

export function AttributesSkeleton() {
  return (
    <div className="flex h-full w-full flex-col gap-8 py-8 pl-16 pr-8">
      <div className="h-[50px] animate-pulse rounded-xl bg-slate-300"></div>
      <div className="h-[76px] animate-pulse rounded-xl bg-slate-300"></div>
      <div className="h-full animate-pulse rounded-xl bg-slate-300"></div>
      <div className="h-[200px] animate-pulse rounded-xl bg-slate-300"></div>
    </div>
  );
}
