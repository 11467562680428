'use client';

import * as React from 'react';
import * as HoverCardPrimitive from '@radix-ui/react-hover-card';

import { cn } from '@/helpers/utils.js';

const HoverCard = HoverCardPrimitive.Root;

// Controlled HoverCard component with close functionality
const ControlledHoverCard = React.forwardRef(
  ({ defaultOpen, onOpenChange, ...props }, ref) => (
    <HoverCardPrimitive.Root
      ref={ref}
      defaultOpen={defaultOpen}
      onOpenChange={onOpenChange}
      {...props}
    />
  ),
);
ControlledHoverCard.displayName = 'ControlledHoverCard';

const HoverCardTrigger = HoverCardPrimitive.Trigger;

const HoverCardPortal = HoverCardPrimitive.Portal;

const HoverCardContent = React.forwardRef(
  ({ className, align = 'center', sideOffset = 4, ...props }, ref) => (
    <HoverCardPrimitive.Content
      ref={ref}
      align={align}
      sideOffset={sideOffset}
      className={cn(
        'z-[1000] w-64 outline-none data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2',
        className,
      )}
      {...props}
    />
  ),
);
HoverCardContent.displayName = HoverCardPrimitive.Content.displayName;

export {
  ControlledHoverCard,
  HoverCard,
  HoverCardContent,
  HoverCardPortal,
  HoverCardTrigger,
};
