import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Blurhash } from 'react-blurhash';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import { useNavigate } from 'react-router-dom';
import { useLazyQuery } from '@apollo/client';
import dayjs from 'dayjs';
import { ChevronRight, Copy, Minus, Plus } from 'lucide-react';

import { GET_STUDIO_TASK_HISTORY } from '@/api/studio/queries';
import {
  Dialog,
  DialogContent,
  useDialogContext,
} from '@/components/ui/dialog';
import { Separator } from '@/components/ui/separator';
import { Slider } from '@/components/ui/slider';
import { useStudioContext } from '@/contexts/StudioContext';
import { useToast } from '@/hooks/useToast';
import { SaveToCollection } from '@/layouts/common/index';

import { LazyLoadTrigger } from '../../explore/components/utils';

import { SidebarHeader } from './SidebarMenu';

export const StudioGeneratedImage = ({
  image,
  payload,
  variant = 'default',
  entityType,
  date,
  studioItemId,
  generationType,
  createdBy,
}) => {
  const { positivePrompt: prompt } = payload || {};
  const [loading, setLoading] = useState(true);

  const [imageWidth, setImageWidth] = useState(image?.width || 1200);
  const [imageHeight, setImageHeight] = useState(image?.height || 1080);
  const [aspectRatio, setAspectRatio] = useState('portrait');
  const [openModal, setOpenModal] = useState(false);
  const [isFullImageLoaded, setIsFullImageLoaded] = useState(false);

  const { setDraggingItem } = useStudioContext();

  const { setIsDialogOpen } = useDialogContext();

  const navigate = useNavigate();
  const toast = useToast();

  // const typeNameToExploreType = {
  //   Post: 'socialMediaItem',
  //   StudioItem: 'studioItem',
  //   ExploreFashionWeekItem: 'fashionWeekItem',
  // };

  useEffect(() => {
    const imageToLoad = new window.Image();
    imageToLoad.src = image?.url || '';
    imageToLoad.onload = () => {
      setLoading(false);
      setImageWidth(imageToLoad.naturalWidth);
      setImageHeight(imageToLoad.naturalHeight);
      setAspectRatio(
        imageToLoad.naturalWidth / imageToLoad.naturalHeight === 1
          ? 'square'
          : imageToLoad.naturalWidth / imageToLoad.naturalHeight > 1
            ? 'landscape'
            : 'portrait',
      );
    };
  }, [image]);

  const aspectRatioClass = {
    square: 'aspect-square',
    landscape: 'aspect-[4/3]',
    portrait: 'aspect-[3/4]',
  };

  const entityTypeToExploreType = {
    FILE_UPLOAD: 'fileUploadItem',
    STUDIO_ITEM: 'studioItem',
    Post: 'socialMediaItem',
    TrendItem: 'fashionWeekItem',
  };

  const entityTypeToDisplayName = {
    FILE_UPLOAD: 'Uploaded Image',
    STUDIO_ITEM: 'Generated in Studio',
    Post: 'Social Media Image',
    TrendItem: 'Fashion Week Image',
  };

  const blur = image?.blurhash || 'L0LETl.8j[?b~qoffQazj[j[a|ay';

  const getImageSizeToDisplayName = () => {
    if (imageWidth > imageHeight) {
      return 'Landscape';
    } else if (imageWidth < imageHeight) {
      return 'Portrait';
    } else if (imageWidth === imageHeight) {
      return 'Square';
    }
  };

  const handleClick = () => {
    setOpenModal(true);
    setIsDialogOpen(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setIsDialogOpen(false);
  };

  const thumbnailUrl = useMemo(() => {
    return image.url.replace('/original/', '/thumbnail/');
  }, [image.url]);

  if (loading) {
    return (
      <div className="relative">
        {blur && (
          <div
            className={`relative h-full w-full overflow-hidden rounded-md bg-white ${aspectRatioClass[aspectRatio]}`}
          >
            <Blurhash
              height="100%"
              width="100%"
              hash={blur}
              resolutionX={32}
              resolutionY={32}
              punch={1}
              className={`absolute inset-0 ${aspectRatioClass[aspectRatio]}`}
            />
          </div>
        )}
        <div className="absolute inset-0 flex items-center justify-center">
          <div className="animate-pulse rounded-md bg-gray-200" />
        </div>
        <div className="absolute inset-0">
          <img
            src={image.url}
            width={imageWidth}
            height={imageHeight}
            alt=""
            className="h-full w-full object-cover"
            style={{ visibility: 'hidden' }}
          />
        </div>
      </div>
    );
  }

  return (
    <>
      {openModal && (
        <Dialog open={openModal} onOpenChange={handleCloseModal}>
          <DialogContent preview>
            <div className="flex h-full max-h-[90vh] w-full gap-x-4 overflow-hidden">
              <div className="flex basis-3/5 items-center">
                <div className="relative h-full w-full">
                  <img
                    className="h-full w-full object-contain"
                    src={image.url}
                    alt=""
                  />
                </div>
              </div>
              <Separator
                orientation="vertical"
                className="mx-2 h-full bg-gray-300 text-black"
              />
              <div className="flex basis-2/5 flex-col items-start gap-y-8 overflow-y-auto pb-40 pr-6">
                <div className="flex flex-row justify-start gap-x-4">
                  <SaveToCollection
                    id={studioItemId || image.studioItemId}
                    type={entityTypeToExploreType[entityType]}
                  >
                    {(loading) =>
                      loading ? (
                        <button className="rounded-lg bg-secondary px-4 py-2 font-bold text-white outline-none focus:outline-none focus:ring-0 focus-visible:ring-0">
                          Save
                        </button>
                      ) : (
                        <button className="self-end rounded border-3 border-primary bg-primary px-4 py-2 text-sm font-bold text-white outline-none hover:border-primaryHover hover:bg-primaryHover hover:text-white focus:outline-none focus:ring-0 focus-visible:ring-0">
                          Save
                        </button>
                      )
                    }
                  </SaveToCollection>
                  <button
                    onClick={() => {
                      const studioParams = new URLSearchParams();
                      studioParams.append(
                        'itemId',
                        studioItemId || image.studioItemId,
                      );
                      studioParams.append(
                        'itemType',
                        entityTypeToExploreType[entityType],
                      );
                      studioParams.append('generationType', 'edit');
                      navigate(`/studio?${studioParams.toString()}`);
                      handleCloseModal();
                    }}
                    className="self-end rounded border-3 border-primary bg-white px-4 py-2 text-sm font-bold text-primary outline-none hover:border-primaryHover hover:bg-primary hover:text-white focus:outline-none focus:ring-0 focus-visible:ring-0"
                  >
                    {Object.keys(payload).length !== 0
                      ? 'Edit with AI Tools'
                      : 'Design with AI Tools'}
                  </button>
                </div>
                {Object.keys(payload).length !== 0 && (
                  <div className="flex flex-col gap-y-4">
                    <div className="flex flex-col gap-y-4">
                      <h2 className="text-4xl font-semibold">
                        Generated In Studio
                      </h2>
                    </div>
                    <div className="flex flex-col gap-y-2">
                      <h3 className="text-lg font-bold leading-[1.125rem] text-night">
                        Prompt
                      </h3>
                      <div className="group relative">
                        <button
                          className="absolute -right-2 -top-4 hidden rounded-md p-1 hover:bg-gray-100 group-hover:block"
                          onClick={() => {
                            navigator.clipboard.writeText(prompt);
                            toast.createToast({
                              message: 'Prompt copied to clipboard',
                              type: 'success',
                              showCloseButton: true,
                              position: 'top-right',
                            });
                          }}
                          title="Copy prompt"
                        >
                          <Copy size={16} />
                        </button>
                        <p
                          className="max-w-[40rem] cursor-pointer text-sm font-normal text-night"
                          onClick={() => {
                            navigator.clipboard.writeText(prompt);
                            toast.createToast({
                              message: 'Prompt copied to clipboard',
                              type: 'success',
                              showCloseButton: true,
                              position: 'top-right',
                            });
                          }}
                        >
                          {prompt}
                        </p>
                      </div>
                    </div>
                    <div className="flex flex-col gap-y-2">
                      <h3 className="text-lg font-bold leading-[1.125rem] text-night">
                        Size
                      </h3>
                      <p className="text-sm font-normal capitalize text-night">
                        {getImageSizeToDisplayName()}
                      </p>
                    </div>
                    {payload?.visibility === 'PRIVATE' ? (
                      <div className="flex flex-col gap-y-2">
                        <h3 className="text-lg font-bold leading-[1.125rem] text-night">
                          Generated by
                        </h3>
                        <p className="text-sm font-normal text-night">
                          {createdBy?.fullName}
                        </p>
                      </div>
                    ) : (
                      <div className="flex flex-col gap-y-2">
                        <p className="text-sm font-bold text-night">
                          Publicly Generated
                        </p>
                      </div>
                    )}
                  </div>
                )}
                {Object.keys(payload).length === 0 && (
                  <div className="flex flex-col gap-y-4">
                    <h2 className="text-4xl font-semibold">
                      {entityType
                        ? entityTypeToDisplayName[entityType]
                        : 'Uploaded Image'}
                    </h2>
                    <p className="text-sm font-bold text-secondary">
                      {dayjs(date).format('MMM DD, YYYY')}
                    </p>
                    <h3 className="mt-4 text-lg font-bold leading-[1.125rem] text-night">
                      Size
                    </h3>
                    <p className="text-sm font-normal text-night">
                      {getImageSizeToDisplayName()}
                    </p>
                  </div>
                )}
              </div>
            </div>
          </DialogContent>
        </Dialog>
      )}
      <div
        className={`group w-full ${variant === 'collection' ? '' : 'cursor-pointer'} overflow-hidden rounded-md transition-transform hover:shadow`}
        onClick={handleClick}
      >
        <img
          src={thumbnailUrl}
          width={imageWidth}
          height={imageHeight}
          alt=""
          className={`w-full object-cover transition-transform hover:rounded-md group-hover:scale-105 ${
            isFullImageLoaded ? 'hidden' : ''
          }`}
          loading="lazy"
        />
        <img
          src={image.url}
          width={imageWidth}
          height={imageHeight}
          alt=""
          className={`w-full object-cover transition-transform hover:rounded-md group-hover:scale-105 ${
            isFullImageLoaded ? '' : 'hidden'
          }`}
          loading="lazy"
          onMouseDown={() => {
            setDraggingItem({
              entityType,
              entityId: studioItemId || image.studioItemId,
              url: image.url,
              generationType,
            });
          }}
          onLoad={() => setIsFullImageLoaded(true)}
        />
      </div>
    </>
  );
};

const StudioHistory = ({ columnCount, setHistoryDate, activeModal }) => {
  const [edges, setEdges] = useState([]);
  const [noEdges, setNoEdges] = useState(false);
  const [, { data, error, loading, fetchMore, networkStatus, refetch }] =
    useLazyQuery(GET_STUDIO_TASK_HISTORY, {
      notifyOnNetworkStatusChange: true,
      onCompleted: (data) => {
        if (data.studioTaskHistory.edges.length !== 0) {
          setEdges(data.studioTaskHistory.edges);
          setHistoryDate(
            dayjs(data.studioTaskHistory.edges[0].node.createdAt).format(
              'MMM YYYY',
            ),
          );
        } else {
          setNoEdges(true);
        }
      },
    });

  useEffect(() => {
    if (activeModal === 'history') {
      refetch();
    }
  }, [activeModal]);

  const { pageInfo } = data?.studioTaskHistory || {};
  const { endCursor, hasNextPage } = pageInfo || {};

  const loadingMore = networkStatus === 3 || networkStatus === 4;

  if (loading && !loadingMore) {
    return <StudioTabHistoryLoading />;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <div className="h-full px-2 lg:px-6">
      <ResponsiveMasonry columnsCountBreakPoints={{ 350: columnCount }}>
        <Masonry gutter="0.5rem">
          {edges &&
            edges.length > 0 &&
            edges.map((item, i) =>
              item.node.images.map((image, j) => (
                <StudioGeneratedImage
                  key={`${i}_${j}`}
                  image={image}
                  payload={item.node.payload}
                  variant="default"
                  entityType="STUDIO_ITEM"
                  date={item.node.createdAt}
                  generationType={item.node.generateTaskType}
                  createdBy={item.node.createdBy}
                />
              )),
            )}
        </Masonry>
      </ResponsiveMasonry>
      {noEdges && (
        <div className="flex h-full items-center justify-center">
          <p className="text-center text-sm text-secondary">No items found</p>
        </div>
      )}
      {hasNextPage &&
        !loadingMore &&
        Array(10)
          .fill()
          .map((_, index) => (
            <div key={index} className="flex h-12 w-full bg-transparent">
              <LazyLoadTrigger
                fetchMore={() => {
                  fetchMore({
                    variables: {
                      cursor: endCursor,
                    },
                  });
                }}
                cursorNext={endCursor}
                loading={loading}
              />
            </div>
          ))}
    </div>
  );
};

export const StudioHistorySidebar = ({ activeModal, onClose }) => {
  const [columnCount, setColumnCount] = useState(3);
  const [historyDate, setHistoryDate] = useState(dayjs().format('MMM YYYY'));
  const historySidebarRef = useRef(null);
  const { isDialogOpen } = useDialogContext();

  useEffect(() => {
    const handleClickOutside = (event) => {
      const asideBar = document.getElementById('aside-bar');
      if (
        historySidebarRef.current &&
        !historySidebarRef.current.contains(event.target) &&
        !asideBar.contains(event.target) &&
        !isDialogOpen
      ) {
        onClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onClose, isDialogOpen]);

  const handleSliderChange = (value) => {
    setColumnCount(Math.max(1, Math.min(5, 6 - value[0])));
  };

  const sliderValue = useMemo(() => [6 - columnCount], [columnCount]);

  return (
    <div
      ref={historySidebarRef}
      style={{
        boxShadow: '-4px 0 8px rgba(0, 0, 0, 0.05)',
        transition: 'right 2s ease',
      }}
      className="absolute right-[7rem] top-0 flex h-full w-[22.5rem] items-start justify-start border-x border-secondary/50 bg-white"
    >
      <div className="flex h-full w-full flex-col items-start justify-between py-6">
        <div className="flex h-full w-full flex-col gap-4 overflow-hidden">
          <div className="flex flex-col px-2 lg:px-6">
            <SidebarHeader
              text="History"
              variant="back"
              icon={ChevronRight}
              onClick={onClose}
            />
          </div>
          <div className="flex w-full flex-row items-center justify-between px-2 lg:px-6">
            <h3 className="text-xs font-bold text-secondary">{historyDate}</h3>
            <div className="flex w-1/2 items-center justify-center gap-x-2">
              <div className="flex cursor-pointer items-center justify-center">
                <Minus
                  size={18}
                  strokeWidth={1.5}
                  onClick={() => handleSliderChange([sliderValue[0] - 1])}
                />
              </div>
              <Slider
                value={sliderValue}
                min={1}
                max={5}
                step={1}
                onValueChange={handleSliderChange}
              />
              <div className="flex cursor-pointer items-center justify-center">
                <Plus
                  size={18}
                  strokeWidth={1.5}
                  onClick={() => handleSliderChange([sliderValue[0] + 1])}
                />
              </div>
            </div>
          </div>
          <div className="flex h-full flex-col overflow-auto">
            <StudioHistory
              columnCount={columnCount}
              setHistoryDate={setHistoryDate}
              activeModal={activeModal}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const StudioTabHistoryLoading = () => {
  return (
    <div className="grid grid-cols-3 gap-2 px-2 lg:px-6">
      {Array.from({ length: 16 }).map((_, i) => (
        <div
          key={i}
          className="aspect-[3/4] animate-pulse rounded-md bg-gray-200"
        />
      ))}
    </div>
  );
};
