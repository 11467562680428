import React, { useEffect, useState } from 'react';
import { ChevronLeft, ChevronRight } from 'lucide-react';

import { useStudioContext } from '@/contexts/StudioContext';
import { cn } from '@/helpers/utils';

export function ImageSlider({
  firstImage,
  secondImage,
  variant = 'default',
  className,
  ...props
}) {
  const [sliderPosition, setSliderPosition] = useState(50);
  const [isDragging, setIsDragging] = useState(false);
  const [imageLoading, setImageLoading] = useState(true);
  const [initialLoad, setInitialLoad] = useState(false);

  const { studioTask } = useStudioContext();

  const handleMove = (event) => {
    if (!isDragging) return;

    const rect = event.currentTarget.getBoundingClientRect();
    const x = Math.max(0, Math.min(event.clientX - rect.left, rect.width));
    const percent = Math.max(0, Math.min((x / rect.width) * 100, 100));

    setSliderPosition(percent);
  };

  const handleMouseDown = () => {
    setIsDragging(true);
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  useEffect(() => {
    if (firstImage) {
      const imageToLoad = new window.Image();
      imageToLoad.src = firstImage;
      imageToLoad.onload = () => {
        setImageLoading(false);
      };

      if (!initialLoad) {
        setInitialLoad(true);
      }
    }
  }, [firstImage, initialLoad]);

  if (imageLoading && variant === 'studio') {
    const aspectRatio =
      studioTask?.payload?.orientation === 'PORTRAIT'
        ? '[3/4]'
        : studioTask?.payload?.orientation === 'SQUARE'
          ? '[1/1]'
          : '[4/3]';
    return <div className={`ghost aspect-${aspectRatio} w-full`} />;
  }

  return (
    <div
      className={cn(
        'relative h-full w-full max-w-[48rem] rounded',
        variant !== 'studio' && 'aspect-[3/4]',
      )}
      onMouseUp={handleMouseUp}
    >
      <div
        className="relative m-auto h-full w-full select-none overflow-hidden rounded"
        onMouseMove={handleMove}
        onMouseDown={handleMouseDown}
      >
        <img
          alt=""
          draggable={false}
          src={firstImage}
          width={variant === 'studio' ? firstImage.width : '80%'}
          height={variant === 'studio' ? firstImage.height : '80%'}
          className={cn(
            variant === 'default' && 'absolute inset-0 h-full w-full',
            'h-full',
          )}
        />
        {variant === 'studio' && (
          <span className="absolute bottom-2 right-4 text-sm font-medium text-white">
            New version
          </span>
        )}
        <div
          className="absolute inset-0 h-full w-full select-none overflow-hidden"
          style={{ clipPath: `inset(0 ${100 - sliderPosition}% 0 0)` }}
        >
          <img
            alt=""
            draggable={false}
            src={secondImage}
            width={variant === 'studio' ? secondImage.width : '80%'}
            height={variant === 'studio' ? secondImage.height : '80%'}
            className={cn(
              variant === 'default' && 'absolute inset-0 h-full w-full',
              'h-full',
            )}
          />
        </div>
        <div
          className={cn(
            'absolute inset-y-0 w-1 cursor-ew-resize bg-primary',
            variant === 'studio' && 'bg-white',
          )}
          style={{
            left: `calc(${sliderPosition}% - 1px)`,
          }}
        >
          {variant === 'studio' ? (
            <>
              <div className="absolute left-1 top-[calc(50%-12px)]">
                <ChevronRight className="h-8 w-8 text-white" />
              </div>
              <div className="absolute right-1 top-[calc(50%-12px)]">
                <ChevronLeft className="h-8 w-8 text-white" />
              </div>
            </>
          ) : (
            <div
              className={cn(
                'absolute -left-1.5 top-[calc(50%-5px)] h-4 w-4 rounded-full bg-primary',
                variant === 'studio' && 'bg-white',
              )}
            />
          )}
        </div>
      </div>
    </div>
  );
}
