import { useState } from 'react';
import { TfiShare } from 'react-icons/tfi';
import { useSearchParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import dayjs from 'dayjs';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import numeral from 'numeral';

import { POST } from '@/api/explore/queries';
import SeeMore from '@/components/SeeMore';
import { useExploreContext } from '@/contexts/ExploreContext';
import { cn } from '@/helpers/utils';
import { Image } from '@/pages/general/imagePreview/components/Image';
import { Attributes, Wrapper } from '@/pages/general/imagePreview/index';

import { ReportButton } from '../explore/components/ReportButton';

import { AttributesSkeleton, ImageSkeleton } from './components/Skeletons';

export function SocialMediaItemHeader({ item }) {
  const { influencer } = item;
  const { isExploreMobile } = useExploreContext();
  return (
    <div className="flex flex-col items-start gap-2">
      <div className="flex w-full items-center gap-[0.625rem]">
        <div className="flex h-[3.375rem] w-[3.375rem] shrink-0 items-center justify-center rounded-full">
          <img
            className="w-full"
            src={
              influencer.platform === 'TIKTOK'
                ? '/assets/images/tiktok.png'
                : '/assets/images/instagram.png'
            }
          />
        </div>

        <div className="flex w-full items-center justify-between">
          <div className="flex flex-col gap-2 py-2">
            <h1 className="text-lg font-semibold leading-none">
              {influencer.fullName}{' '}
              <a
                target="_blank"
                href={
                  influencer.platform === 'TIKTOK'
                    ? `https://tiktok.com/@${influencer.username || influencer.tiktokUsername}`
                    : `https://instagram.com/${influencer.username || influencer.instagramUsername}`
                }
                className="text-primary"
                rel="noreferrer"
              >
                @
                {influencer.username ||
                  influencer.instagramUsername ||
                  influencer.tiktokUsername}
              </a>
            </h1>
            <h1 className="text-sm font-semibold leading-none text-[#A1A1A1]">
              {item?.likes > 0
                ? '❤️ ' + numeral(item.likes).format('0a') + ' — '
                : ''}
              💬 {numeral(item.comments || 1).format('0a')} — 📅{' '}
              {dayjs(item.publishDate).format('DD MMM YYYY')}
            </h1>
          </div>
          <div className="flex items-center justify-end gap-x-3">
            <ReportButton id={item.id} preview reportColor="#000" />
            <a
              href={
                item.ttPostId
                  ? `https://tiktok.com/@${
                      influencer.username || influencer.tiktokUsername
                    }/video/${item.ttPostId.split('_')[0]}`
                  : `https://instagram.com/p/${item.shortcode}`
              }
              target="_blank"
              className="flex items-center justify-center rounded-full border border-[#A1A1A1]/20 bg-[#A1A1A1]/5 p-2"
              rel="noreferrer"
            >
              <TfiShare size={18} />
            </a>
          </div>
        </div>
      </div>
      {!isExploreMobile && (
        <div className="mt-4 flex flex-col items-center justify-start gap-4">
          <SeeMore text={item.description} maxLength={25} />
        </div>
      )}
    </div>
  );
}

function PaginationButton({ onClick, disabled, IconComponent, className }) {
  return (
    <div
      className={`pointer flex items-center justify-center rounded-full p-1 transition group-hover:bg-black/25 ${className}`}
    >
      <button onClick={onClick} disabled={disabled}>
        <IconComponent size={24} className="text-white drop-shadow" />
      </button>
    </div>
  );
}

function IndicatorDots({ items, activeIndex, setActiveIndex }) {
  return (
    <div className="flex items-center justify-center gap-2.5 rounded-full p-3 transition group-hover:bg-black/25">
      {items.map((_, index) => (
        <button
          key={index}
          onClick={() => setActiveIndex(index)}
          className={cn(
            `h-3 w-3 rounded-full bg-white/50 drop-shadow-lg`,
            index === activeIndex && 'bg-white',
          )}
        />
      ))}
    </div>
  );
}

function SocialMediaItemCarouselPagination({
  activeIndex,
  setActiveIndex,
  items,
}) {
  if (items.length <= 1) return null;

  return (
    <div className="group flex items-center justify-center gap-1">
      <PaginationButton
        onClick={() => setActiveIndex(activeIndex - 1)}
        disabled={activeIndex === 0}
        IconComponent={ChevronLeft}
      />
      <IndicatorDots
        items={items}
        activeIndex={activeIndex}
        setActiveIndex={setActiveIndex}
      />
      <PaginationButton
        onClick={() => setActiveIndex(activeIndex + 1)}
        disabled={activeIndex === items.length - 1}
        IconComponent={ChevronRight}
      />
    </div>
  );
}

function SocialMediaItemCarouselPaginationWrapper({ children }) {
  return (
    <div className="absolute bottom-0 flex h-[10%] w-full items-center justify-center">
      {children}
    </div>
  );
}

export function SocialMediaItem({ postId }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const urlActiveIndex = parseInt(searchParams.get('item'), 0);
  const { isExploreMobile } = useExploreContext();
  const [isParent, setIsParent] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);

  const { data, loading, error } = useQuery(POST, {
    variables: { id: postId },
    skip: !postId,
    onCompleted: (data) => {
      setIsParent(data.post.children.length === 0);

      const defaultActiveIndex =
        data.post.children.length === 0
          ? data.post.children.findIndex((child) => child.id === postId)
          : -1;

      const initialActiveIndex = Number.isInteger(urlActiveIndex)
        ? urlActiveIndex
        : defaultActiveIndex !== -1
          ? defaultActiveIndex
          : 0;
      setActiveIndex(initialActiveIndex);
    },
  });

  if (loading) {
    return (
      <Wrapper>
        <ImageSkeleton />
        <AttributesSkeleton />
      </Wrapper>
    );
  }

  if (error) {
    return (
      <Wrapper>
        <div className="flex h-full w-full items-center justify-center">
          <p>{error.message}</p>
        </div>
      </Wrapper>
    );
  }

  const { post } = data;

  const updateActiveIndex = (newIndex) => {
    setActiveIndex(newIndex);
    setSearchParams({ item: newIndex });
  };

  const item = !isParent ? post.children[activeIndex] : post;

  return (
    <Wrapper>
      {isExploreMobile ? (
        <>
          <Attributes
            item={item}
            apparels={item?.apparels}
            variant="mobile-header"
          />
          <div className="flex w-full flex-col rounded-lg bg-white px-8">
            <img
              src={item?.image?.url}
              alt={item?.image?.alt}
              className="rounded-lg"
            />
          </div>
        </>
      ) : (
        <Image image={item?.image} apparels={item?.apparels} key={item?.id}>
          {!isParent && (
            <SocialMediaItemCarouselPaginationWrapper>
              <SocialMediaItemCarouselPagination
                items={post.children}
                activeIndex={activeIndex}
                setActiveIndex={updateActiveIndex}
              />
            </SocialMediaItemCarouselPaginationWrapper>
          )}
        </Image>
      )}
      <Attributes item={item} apparels={item?.apparels} />
    </Wrapper>
  );
}
