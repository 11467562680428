import { useContext } from 'react';

import { FiltersContext } from '@/contexts/FiltersContext';

export const useFilters = (filterType = 'explore') => {
  const context = useContext(FiltersContext);

  if (!context) {
    throw new Error('useFilters must be used within a FiltersProvider');
  }

  if (!['explore', 'trends'].includes(filterType)) {
    throw new Error('useFilters must be used with a valid filterType');
  }

  if (
    !window.location.pathname.includes('trends') &&
    !window.location.pathname.includes('overview')
  ) {
    delete context.common.selectedAudienceId;
    delete context.common.selectedMarketId;
  }

  return {
    ...context.common,
    ...context[filterType],
  };
};
