import { useEffect, useState } from 'react';
import { Blurhash } from 'react-blurhash';
import { useImageSize } from 'react-image-size';
import dayjs from 'dayjs';
import { Users } from 'lucide-react';
import numeral from 'numeral';

import { Text } from '@/components';
import { cn } from '@/helpers/utils';

function SnapshotItemCardImage({ i, imageInfo: image }) {
  const [loader, setLoader] = useState(true);

  const item = image ? { ...image } : {};
  const [dimensions, { loading, error }] = useImageSize(item.url);

  useEffect(() => {
    const imageToLoad = new window.Image();
    imageToLoad.src = item.url;

    imageToLoad.onload = () => {
      setLoader(false);
    };
    return () => setLoader(false);
  }, [item.url]);

  if (error) return <div></div>;
  if (loading) return <div></div>;

  if (!item || !dimensions) return <></>;

  if (!item?.width) item.width = dimensions.width;
  if (!item?.height) item.height = dimensions.height;

  return (
    <>
      {loader ? (
        <div className="h-full w-full rounded-xl">
          {item.blurhash && (
            <Blurhash
              hash={item.blurhash}
              width="100%"
              height="160px"
              punch={1}
            />
          )}
        </div>
      ) : (
        <div className="h-full w-full">
          <div
            style={{
              background: `url(${image.url})` || '#111',
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              aspectRatio: '18 / 25',
              height: '100%',
              width: '100%',
            }}
            className={`h-full ${i === 0 ? 'rounded-l-xl' : ''} ${
              i === 2 ? 'rounded-r-xl' : ''
            }`}
          />
        </div>
      )}
    </>
  );
}

export const SnapshotItemCard = ({ snapshotItem, layout }) => {
  const { title, featuredImages, metadata, weeklyData } = snapshotItem;

  const lastWeek = weeklyData.at(-1);
  const previousWeek = weeklyData.at(-2);

  const lastWeekQualityEngagement = lastWeek?.qualityEngagement;
  const lastWeekQualityEngagementFormatted = numeral(lastWeekQualityEngagement)
    .format('0.00a')
    .toUpperCase();

  const previousWeekQualityEngagement = previousWeek?.qualityEngagement;
  const previousWeekQualityEngagementFormatted = numeral(
    previousWeekQualityEngagement,
  )
    .format('0.00a')
    .toUpperCase();

  const lastWeekVisibility = lastWeek?.visibility;
  const lastWeekVisibilityFormatted = numeral(lastWeekVisibility)
    .format('0.00a')
    .toUpperCase();

  const previousWeekVisibility = previousWeek?.visibility;
  const previousWeekVisibilityFormatted = numeral(previousWeekVisibility)
    .format('0.00a')
    .toUpperCase();

  const isVisibilityIncrease = lastWeekVisibility >= previousWeekVisibility;
  const isQEIncrease =
    lastWeekQualityEngagement >= previousWeekQualityEngagement;
  const statusColor =
    isVisibilityIncrease || isQEIncrease ? '#1C9A6D' : '#EA3323';
  const bgColor = statusColor + '20';

  return (
    <div
      className={cn(
        'w-full p-4 rounded-xl bg-secondary/5 border border-secondary/20 col-span-3 2xl:col-span-2 shadow-sm',
        layout === 0 && 'col-span-6 2xl:col-span-6',
      )}
    >
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-2 text-sm text-primary">
          <Users className="h-4 w-4" />
          <span>{metadata?.audienceName}</span>
        </div>
        <Text
          className="rounded-lg px-4 py-2 text-sm font-semibold"
          style={{
            backgroundColor: bgColor,
            color: statusColor,
          }}
        >
          Rising Star
        </Text>
      </div>

      <Text className="mt-2 text-2xl font-semibold capitalize text-gray-900">
        {title}
      </Text>

      <div className="mt-4 grid grid-cols-5 gap-4">
        <div className="col-span-2 grid grid-rows-2 gap-4">
          <div
            className={cn('rounded-lg p-3')}
            style={{
              backgroundColor: bgColor,
            }}
          >
            <div className="flex items-center justify-between">
              <Text className="text-sm font-semibold text-gray-600">
                Visibility
              </Text>
              <span
                style={{
                  color: statusColor,
                }}
              >
                {isVisibilityIncrease ? '▲' : '▼'}
              </span>
            </div>
            <div className="mt-5 flex items-baseline justify-between">
              <Text
                className={cn('font-satoshi text-2xl font-semibold')}
                style={{
                  color: statusColor,
                }}
              >
                {lastWeekVisibilityFormatted}%
              </Text>
              <Text className="text-right text-xs font-medium text-gray-700">
                <span className="text-sm">
                  {previousWeekVisibilityFormatted}%
                </span>{' '}
                <div className="text-xs">Last Week</div>
              </Text>
            </div>
          </div>

          <div
            className="rounded-lg p-3"
            style={{
              backgroundColor: bgColor,
            }}
          >
            <div className="flex items-center justify-between">
              <Text className="text-sm font-semibold text-gray-600">
                Quality Engagement
              </Text>
              <span
                style={{
                  color: statusColor,
                }}
              >
                {isQEIncrease ? '▲' : '▼'}
              </span>
            </div>
            <div className="mt-5 flex items-baseline justify-between">
              <Text
                className={cn('font-satoshi text-2xl font-semibold')}
                style={{
                  color: statusColor,
                }}
              >
                {lastWeekQualityEngagementFormatted}
              </Text>
              <Text className="text-right text-xs font-medium text-gray-700">
                <span className="text-sm">
                  {previousWeekQualityEngagementFormatted}
                </span>
                <div className="text-xs">Last Week</div>
              </Text>
            </div>
          </div>
        </div>
        <div className="col-span-3 grid grid-cols-3 gap-[1px] overflow-hidden rounded-xl">
          {featuredImages.slice(0, 3).map((image, index) => (
            <div key={index} className="h-full">
              <SnapshotItemCardImage i={index} imageInfo={image} />
            </div>
          ))}
        </div>
      </div>

      <Text className="mt-4 text-sm text-gray-500">
        Saved on {dayjs(snapshotItem.metadata.addedDate).format('DD MMM YYYY')}.
        Update to see current metrics.
      </Text>
    </div>
  );
};
