import { createContext, useContext, useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import dayjs from 'dayjs';
import { capitalize } from 'lodash';

import { useFiltersQuery } from '@/api/filters/hook';
import { INSIGHT, INSIGHTS } from '@/api/trends/queries';
import { genderMapping } from '@/constants';

export const InsightContext = createContext();
export const getMarketLabel = (market) => market?.location?.name;

export const getAudienceLabel = (audience) => {
  return (
    audience?.name ||
    `${genderMapping[audience.gender].label}, ${audience.ageMin}-${audience.ageMax}`
  );
};

export const InsightProvider = ({ children }) => {
  const {
    data: marketsData,
    loading: marketsLoading, // error: marketsError
  } = useFiltersQuery('markets');

  const {
    data: trendFiltersData, // loading: trendFiltersLoading,
    // error: trendFiltersError
  } = useFiltersQuery('trendFilters', {
    audience: null,
  });

  const [section, setSection] = useState('TOP_TRENDS');
  const [activeChart, setActiveChart] = useState('YOY_GROWTH');

  const [filters, setFilters] = useState({
    source: {
      label: 'Markets',
      placeholder: 'Loading...',
      options: [],
      value: null,
      loading: true,
      isMulti: false,
    },
    timeframe: {
      label: 'Timeframe',
      placeholder: 'Select Timeframe Range',
      options: [],
      value: [],
      loading: false,
      isMulti: true,
    },
    category: {
      label: 'Category',
      placeholder: 'Select a Category',
      options: [],
      value: [],
      loading: true,
      isMulti: true,
    },
  });

  const initializeInsightFilters = () => {
    let baseDate = dayjs().set('date', 1).toDate();
    const timeframeRangeOptions = [
      {
        label: dayjs(baseDate).format('MMM YYYY'),
        value: dayjs(baseDate).format('YYYY-MM-DD'),
      },
      ...Array.from({ length: 11 }, (_, index) => {
        baseDate = dayjs(baseDate).add(1, 'month').toDate();

        return {
          label: dayjs(baseDate).format('MMM YYYY'),
          value: dayjs(baseDate).format('YYYY-MM-DD'),
        };
      }),
    ];

    const markets = marketsData?.markets;
    if (!markets) return;

    // Load market options for select dropdown
    const marketOptions = markets
      .map((market) => ({
        label: getMarketLabel(market),
        options: market?.audiences
          ?.map((audience) => ({
            label: `${getAudienceLabel(audience)}`,
            value: audience?.groups?.[0]?.audienceId,
            field: 'audience',
            source: 'trends',
            marketId: market.id,
            groupId: audience?.groupId,
            audVal: audience.id,
            location: market.location.name,
            socialWatch: audience?.groups?.[0]?.features?.socialwatch,
          }))
          .sort((a, b) => {
            if (a.socialWatch === b.socialWatch) {
              return a.label.localeCompare(b.label);
            }
            return b.socialWatch - a.socialWatch;
          }),
      }))
      .sort((a, b) => {
        const aOptions = a.options;
        const bOptions = b.options;

        const aSocialWatch = aOptions.find(
          (option) => option.socialWatch === true,
        )?.value;
        const bSocialWatch = bOptions.find(
          (option) => option.socialWatch === true,
        )?.value;

        if (a.label === filters.source.value?.location) return -1;
        if (b.label === filters.source.value?.location) return 1;

        return bSocialWatch - aSocialWatch;
      });

    const categoryOptions = trendFiltersData?.trendFilters?.apparels?.map(
      (item) => ({
        ...item,
        originalLabel: item.label,
        value: item?.value ? item?.value : item?.label,
        label: capitalize(item.label.replaceAll('-', ' ')),
      }),
    );

    setFilters({
      ...filters,
      source: {
        ...filters.source,
        options: marketOptions,
        loading: marketsLoading,
        value: marketOptions?.[0]?.options?.[0],
      },
      category: {
        ...filters.category,
        options: categoryOptions,
        loading: false,
      },
      timeframe: {
        ...filters.timeframe,
        options: timeframeRangeOptions,
        loading: false,
        value:
          timeframeRangeOptions?.length > 0
            ? timeframeRangeOptions.slice(0, 1)
            : [],
      },
    });

    const filtersPayload = {
      section,
      groupId: filters.source.value?.groupId,
      timeframeRange: filters.timeframe.value.map((item) => ({
        date: new Date(item.value),
      })),
    };

    getInsights({ variables: { filters: filtersPayload } });
    return true;
  };

  const handleFiltersClearAll = () => {
    setFilters({
      ...filters,
      source: { ...filters.source, value: null },
      category: { ...filters.category, value: null },
      timeframe: { ...filters.timeframe, value: [] },
    });
  };

  const handleChangeFilters = (key, value) => {
    const filter = filters[key];

    if (filter.isMulti) {
      const alreadyExists = filter.value.find(
        (item) => item.value === value.value,
      );
      if (alreadyExists) {
        filter.value = filter.value.filter(
          (item) => item.value !== value.value,
        );
      } else {
        filter.value = [...filter.value, value];
      }
    } else {
      filter.value = value;
    }

    setFilters({
      ...filters,
      [key]: filter,
    });
  };

  const [
    getInsights,
    { data: insightsData, loading: insightsLoading, refetch: refetchInsights },
  ] = useLazyQuery(INSIGHTS);

  const [
    // eslint-disable-next-line no-unused-vars
    getInsight,
    // eslint-disable-next-line no-unused-vars
    {
      data: insightData,
      error: insightError,
      loading: insightLoading,
      refetch: refetchInsight,
    },
  ] = useLazyQuery(INSIGHT);

  useEffect(() => {
    initializeInsightFilters();
  }, [marketsData, trendFiltersData]);

  const handleApplyFilters = () => {
    const filtersPayload = {
      section,
      groupId: filters.source.value?.groupId,
      timeframeRange: filters.timeframe.value.map((item) => ({
        date: new Date(item.value),
      })),
    };
    getInsights({
      variables: {
        filters: filtersPayload,
      },
    });
    refetchInsights();
  };

  useEffect(() => {
    const filtersPayload = {
      section,
      groupId: filters.source.value?.groupId,
      timeframeRange: filters.timeframe.value.map((item) => ({
        date: new Date(item.value),
      })),
    };

    getInsights({ variables: { filters: filtersPayload } });
    refetchInsights();
  }, [section]);

  return (
    <InsightContext.Provider
      value={{
        filters,
        handleFiltersClearAll,
        handleChangeFilters,
        handleApplyFilters,
        insightsData,
        insightsLoading,
        refetchInsight,
        section,
        setSection,
        getInsight,
        insightError,
        insightData,
        insightLoading,
        activeChart,
        setActiveChart,
      }}
    >
      {children}
    </InsightContext.Provider>
  );
};

export const useInsightContext = () => useContext(InsightContext);
