import { FormLabel } from '@/components/FormInput';
import { useExploreContext } from '@/contexts/ExploreContext';
import { cn } from '@/helpers/utils';
export function ButtonSelect({ filter, filterKey, onChange, currentValue }) {
  const { isExploreMobile } = useExploreContext();

  return (
    <div className="flex flex-col">
      <FormLabel isMobile={isExploreMobile}>{filter?.label}</FormLabel>
      <div className="flex flex-row gap-2">
        {filter?.options?.map((option, index) => (
          <button
            key={index}
            onClick={() => onChange(option, filterKey)}
            className={cn(
              'rounded-md border transition-all duration-300 border-charcoal text-center py-2 text-sm leading-5 w-full capitalize',
              isExploreMobile && 'text-base leading-7',
              option.value === currentValue
                ? 'border-primary text-primary bg-primary/10 font-semibold'
                : 'border-charcoal/50 text-charcoal/50',
            )}
          >
            {option.label.toLowerCase()}
          </button>
        ))}
      </div>
    </div>
  );
}
