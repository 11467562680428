import { useMutation, useQuery } from '@apollo/client';

import {
  ACCEPT_INVITATION,
  ASSIGN_STUDIO_LICENCES,
  CREATE_USER_GROUP,
  RESEND_INVITATION,
  SEND_INVITATIONS,
  UPDATE_ORGANIZATION_NAME,
  UPDATE_USER_GROUP,
} from './mutations';
import {
  LATEST_LICENCE,
  LICENCE,
  ORGANIZATION,
  ORGANIZATIONS,
  VERIFY_INVITATION,
} from './queries';

const queryMap = {
  organization: ORGANIZATION,
  organizations: ORGANIZATIONS,
  verifyInvitation: VERIFY_INVITATION,
  latestLicence: LATEST_LICENCE,
  licence: LICENCE,
};

const mutationMap = {
  updateName: UPDATE_ORGANIZATION_NAME,
  acceptInvitation: ACCEPT_INVITATION,
  resendInvitation: RESEND_INVITATION,
  sendInvitations: SEND_INVITATIONS,
  assignStudioLicences: ASSIGN_STUDIO_LICENCES,
  createUserGroup: CREATE_USER_GROUP,
  updateUserGroup: UPDATE_USER_GROUP,
};

export function useOrganizationQuery(queryName, options = {}) {
  const query = queryMap[queryName];
  if (!query) throw new Error('Invalid query name');

  return useQuery(query, options);
}

export function useOrganizationMutation(mutationName, options = {}) {
  const mutation = mutationMap[mutationName];
  if (!mutation) throw new Error('Invalid mutation name');

  return useMutation(mutation, options);
}
