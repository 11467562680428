import { gql } from '@apollo/client';

export const EXPLORE = gql`
  query Explore(
    $filters: ExploreFilterInput
    $filterOutFields: FilterOutFields
    $audiences: [String]
    $cursor: String
    $market: String
    $platform: String
    $sorting: SortingInput
    $searchQuery: String
    $searchType: SEARCH_TYPE
  ) {
    explore(
      filters: $filters
      filterOutFields: $filterOutFields
      audiences: $audiences
      cursor: $cursor
      market: $market
      platform: $platform
      sorting: $sorting
      searchQuery: $searchQuery
      searchType: $searchType
    ) {
      data {
        id
        title
        apparel
        post {
          apparels {
            tones {
              hex
              main
              code
              name
              weight
            }
            attributes {
              name
              value
            }
            title
            originalBox
            box
          }
          id
          image {
            url
            width
            height
            blurhash
            urlKey
          }
          shortcode
          ttPostId
          likes
          comments
          description
          isVideo
          publishDate
          influencer {
            fullName
            instagramUsername
            tiktokUsername
            username
            platform
          }
          children {
            apparels {
              tones {
                hex
                main
                code
                name
                weight
              }
              title
              attributes {
                name
                value
              }
              id
              disabled
              apparel
              originalBox
              box
            }
            id
            image {
              url
              width
              height
              blurhash
              urlKey
            }
            shortcode
            ttPostId
            likes
            comments
            description
            isVideo
            publishDate
            influencer {
              fullName
              instagramUsername
              tiktokUsername
              username
              platform
            }
          }
        }
        image {
          blurhash
          width
          height
          url
          urlKey
          exploreItemId
        }
        tones {
          hex
          main
          code
          name
          weight
        }
        attributes {
          name
          value
        }
        disabled
        filter {
          label
          value
        }
        collections
        isLiked
      }
      cursor
      hasNextPage
    }
  }
`;

export const EXPLORE_FASHION_ITEMS = gql`
  query ExploreFashionWeekItems(
    $cursor: String
    $limit: Int
    $filters: ExploreFashionWeekItemFiltersInput
    $filterOutFields: FilterOutFieldsForFashionWeek
    $search: String
  ) {
    exploreFashionWeekItems(
      cursor: $cursor
      limit: $limit
      filters: $filters
      filterOutFields: $filterOutFields
      search: $search
    ) {
      edges {
        node {
          id
          image {
            url
            width
            height
            urlKey
            blurhash
          }
          disabled
          score
          designer
          season
          category
          city
          fashionWeekSlug
          photoItem
          fashionWeek {
            season
            fwType
            city
            name
            designers
          }
        }
      }
      pageInfo {
        hasNextPage
        endCursor
        total
      }
    }
  }
`;

export const EXPLORE_FASHION_ITEMS_WITH_TEXT_SEARCH = gql`
  query ExploreFashionWeekItems(
    $cursor: String
    $limit: Int
    $filters: ExploreFashionWeekItemFiltersInput
    $search: String
  ) {
    exploreFashionWeekItems(
      cursor: $cursor
      limit: $limit
      filters: $filters
      search: $search
    ) {
      edges {
        node {
          id
          image {
            url
            width
            height
            urlKey
            blurhash
          }
          disabled
          score
          designer
          season
          category
          city
          fashionWeekSlug
          photoItem
          fashionWeek {
            season
            fwType
            city
            name
            designers
          }
        }
      }
      groupBySeasonEdges {
        season
        edges {
          node {
            id
            image {
              url
              width
              height
              urlKey
              blurhash
            }
            disabled
            score
            designer
            season
            category
            city
            fashionWeekSlug
            photoItem
            fashionWeek {
              season
              fwType
              city
              name
              designers
            }
          }
        }
      }
      pageInfo {
        hasNextPage
        endCursor
        total
      }
    }
  }
`;

export const EXPLORE_FASHION_WEEK_FILTERS = gql`
  query ExploreFashionWeekItemFilters(
    $filters: ExploreFashionWeekItemFiltersInput
  ) {
    exploreFashionWeekItemFilters(filters: $filters) {
      label
      name
      values {
        label
        value
        values {
          label
          value
        }
      }
    }
  }
`;

export const EXPLORE_FASHION_WEEKS = gql`
  query ExploreFashionWeeks {
    exploreFashionWeeks {
      season
      fwType
      city
      name
      designers
    }
  }
`;

export const EXPLORE_FASHION_WEEK_CITIES = gql`
  query ExploreFashionWeekCities {
    exploreFashionWeekCities
  }
`;

export const EXPLORE_SOCIALWATCH_FILTERS = gql`
  query ExploreSocialWatchFilters(
    $audiences: [String]
    $apparels: [String]
    $colors: [String]
  ) {
    socialWatchFilters(
      audiences: $audiences
      apparels: $apparels
      colors: $colors
    ) {
      filters
    }
  }
`;

export const GET_TYPEAHEAD_SUGGESTIONS = gql`
  query TypeaheadSuggestions($query: String) {
    typeaheadSuggestions(query: $query) {
      options {
        label
        value
      }
    }
  }
`;

export const FASHION_WEEK_ITEM = gql`
  query FashionWeekItem($itemId: String) {
    fashionWeekItem(itemId: $itemId) {
      photo {
        image {
          url
          width
          height
          urlKey
          blurhash
        }
        designer
        season
        city
        id
      }
      apparels {
        originalBox
        box
        tones {
          hex
          main
          code
          name
          weight
        }
        attributes {
          name
          value
        }
        apparel
        id
      }
    }
  }
`;

export const POST = gql`
  query Post($id: String) {
    post(id: $id) {
      isLiked
      collections
      apparels {
        isLiked
        title
        tones {
          hex
          main
          code
          name
          weight
        }
        attributes {
          name
          value
        }
        apparel
        originalBox
        box
        id
        disabled
      }
      id
      image {
        url
        width
        height
        blurhash
        urlKey
      }
      shortcode
      ttPostId
      likes
      comments
      description
      isVideo
      publishDate
      influencer {
        fullName
        instagramUsername
        tiktokUsername
        username
        platform
      }
      children {
        apparels {
          tones {
            hex
            main
            code
            name
            weight
          }
          attributes {
            name
            value
          }
          apparel
          title
          originalBox
          box
          id
          disabled
        }
        id
        image {
          url
          width
          height
          blurhash
          urlKey
        }
        shortcode
        ttPostId
        likes
        comments
        description
        isVideo
        publishDate
        influencer {
          fullName
          instagramUsername
          tiktokUsername
          username
          platform
        }
      }
    }
  }
`;

export const SOCIAL_MEDIA_SIMILAR_IMAGES = gql`
  query ExploreSimilarImage(
    $apparel: SimilarImageSearchApparel
    $image: SimilarImageSearchImage
  ) {
    exploreSimilarImage(apparel: $apparel, image: $image) {
      data {
        image {
          url
          blurhash
        }
        influencer {
          instagramUsername
          fullName
          username
          platform
        }
        likes
        publishDate
        shortcode
        id
        description
        comments
        apparels {
          apparel
          apparelId
        }
      }
    }
  }
`;
export const FASHION_WEEK_SIMILAR_IMAGES = gql`
  query FashionWeekSimilarImages(
    $apparel: SimilarImageSearchApparel
    $image: SimilarImageSearchImage
  ) {
    fashionWeekSimilarImages(apparel: $apparel, image: $image) {
      data {
        id
        image {
          width
          height
          url
          blurhash
        }
        season
        designer
        city
        apparel
        tones {
          main
          name
          code
          weight
        }
      }
    }
  }
`;

export const GET_SIMILAR_TONES = gql`
  query GetSimilarTones($tone: [Int], $textSearch: String) {
    exploreSimilarTones(tone: $tone, textSearch: $textSearch) {
      main
      name
      code
      hex
    }
  }
`;

export const UPLOAD_ITEM_IMAGE = gql`
  query UploadItemImage($uploadItemImageId: ID!) {
    uploadItemImage(id: $uploadItemImageId) {
      node {
        id
        status
        fileEntityType
        visibility
        image {
          url
        }
        createdAt
        updatedAt
      }
    }
  }
`;
