import React from 'react';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import { useUser } from '@/api/authentication/hook';
import { PopoverContent, PopoverRoot, PopoverTrigger } from '@/components';
import { reachMapping, trendTypeMapping } from '@/constants';
import { useAppFilters } from '@/contexts/AppFiltersContext';
import { getInitials } from '@/helpers/utils';
import { Icon as ReachIcon } from '@/pages/general/trends/components/Reach/Icon';

import { SaveToCollection } from './saveToCollection';
import YearOverYearGrowthChart from './YearOverYearGrowthChart';

dayjs.extend(utc);

const CardStats = ({ item }) => {
  const isDown = item?.yoyGrowth?.percent < 0;

  return (
    <div className="flex flex-1 pb-[0.3125rem] pt-[1.125rem] 2xl:pt-[2rem]">
      <div className="flex w-[50%] flex-col items-center border-r border-[#A1A1A1] 2xl:justify-start">
        <div className="flex flex-col items-center px-4 2xl:space-y-[1rem]">
          <PopoverRoot>
            <PopoverTrigger asChild>
              <p className="pb-2 text-center text-[1rem] font-medium leading-[1.15] 2xl:text-[1.25rem] 2xl:leading-[2.125rem]">
                Year over Year Growth
              </p>
            </PopoverTrigger>

            <PopoverContent sideOffset={5}>
              <div className="flex w-[29.875rem] flex-col px-[2rem] pt-[1.625rem]">
                <div className="mb-[1.3125rem] flex flex-col">
                  <h5 className="mb-[1.5625rem] cursor-pointer text-[1.5rem]  font-semibold leading-[1.733125rem] text-white">
                    Year over Year Growth
                  </h5>
                  <p className="font-semi-light text-[1rem] leading-[1.155rem]  text-white">
                    A metric that compares the engagement rate generated by the
                    item in the current year to the engagement rate generated in
                    the same period in the previous year on online channels.
                    This metric is used to evaluate the overall performance of
                    the item over time and to track its growth trajectory in the
                    market.
                  </p>
                </div>
              </div>
            </PopoverContent>
          </PopoverRoot>
          <span
            className={[
              'flex flex-col text-4xl font-semibold 2xl:text-[3rem]',
              isDown ? 'text-red-500' : 'text-[#1c9a6d]',
            ].join(' ')}
          >
            {item?.yoyGrowth?.percent.toFixed(0)}%
          </span>
          <YearOverYearGrowthChart
            data={item?.yoyGrowth?.data}
            isDown={isDown}
          />
        </div>
      </div>
      <div className="mb-4 flex w-[50%] flex-col items-center 2xl:justify-start">
        <div className="flex flex-col items-center 2xl:space-y-[1rem]">
          <PopoverRoot>
            <PopoverTrigger asChild>
              <p className="pb-2 text-center text-[1rem] font-medium leading-[1.15] 2xl:text-[1.25rem] 2xl:leading-[2.125rem]">
                Reach
              </p>
            </PopoverTrigger>

            <PopoverContent sideOffset={5}>
              <div className="flex w-[29.875rem] flex-col px-[2rem] pt-[1.625rem]">
                <div className="mb-[1.3125rem] flex flex-col">
                  <h5 className="mb-[1.5625rem] cursor-pointer text-[1.5rem]  font-semibold leading-[1.733125rem] text-white">
                    Reach
                  </h5>
                  <p className="font-semi-light text-[1rem] leading-[1.155rem]  text-white">
                    A metric that refers to the strength or intensity of a
                    fashion trend and shows how big of an audience is in the
                    ‘reach’ for this item. Trend magnitude is important for your
                    brand to consider when developing collections, as it can
                    impact consumer demand and ultimately sales.
                  </p>
                </div>
              </div>
            </PopoverContent>
          </PopoverRoot>
          <span
            className="text-4xl font-semibold 2xl:text-[3rem]"
            style={{
              color: reachMapping[item?.reach]?.color,
            }}
          >
            {reachMapping[item?.reach]?.text}
          </span>
          <ReachIcon level={item?.reach} />
        </div>
      </div>
    </div>
  );
};

const CardImage = ({ url, onChangeCover, tiType, tone }) => {
  const { moderationEnabled } = useUser();

  return (
    <div className="group relative flex w-[55%] pb-9 lg:w-[50%] 2xl:w-[45%]">
      {tiType === 3 ? (
        <div
          className="-ml-10 -mt-10 aspect-auto h-auto w-full object-cover"
          style={{
            backgroundColor: tone.hex,
          }}
        />
      ) : (
        <img
          src={`${url}`}
          className="-ml-10 -mt-10 aspect-auto h-auto w-full bg-[#eae0d7] object-cover"
          alt=""
        />
      )}

      {moderationEnabled && (
        <div className="absolute inset-0 -ml-10 -mt-10 flex h-full w-full items-center justify-center bg-black/30 text-white opacity-0 transition-all duration-150 ease-in-out group-hover:opacity-100">
          <button
            onClick={onChangeCover}
            className="border-2 border-gray-100 px-4 py-2 transition-all  duration-200 ease-in-out hover:bg-gray-100/30 active:bg-gray-100/60"
          >
            Change Cover
          </button>
        </div>
      )}
    </div>
  );
};

const SaveToCollectionIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22 19C22 19.5304 21.7893 20.0391 21.4142 20.4142C21.0391 20.7893 20.5304 21 20 21H4C3.46957 21 2.96086 20.7893 2.58579 20.4142C2.21071 20.0391 2 19.5304 2 19V5C2 4.46957 2.21071 3.96086 2.58579 3.58579C2.96086 3.21071 3.46957 3 4 3H9L11 6H20C20.5304 6 21.0391 6.21071 21.4142 6.58579C21.7893 6.96086 22 7.46957 22 8V19Z"
      stroke="#2A916B"
      strokeWidth="2"
      strokeLinecap="square"
    />
    <path
      d="M12 11V17"
      stroke="#2A916B"
      strokeWidth="2"
      strokeLinecap="square"
    />
    <path
      d="M9 14H15"
      stroke="#2A916B"
      strokeWidth="2"
      strokeLinecap="square"
    />
  </svg>
);

function SaveToCollectionButton({ item }) {
  return (
    <SaveToCollection
      id={item?.tiId}
      type="trendItem"
      collections={item.collections || []}
    >
      <div className="group relative flex h-[2.625rem] cursor-pointer items-center justify-center gap-2 bg-[#e5f1ed] px-[0.875rem] text-[#2A916B] transition hover:bg-[#d7eae4]">
        <SaveToCollectionIcon />
        <span className={'translate-y-0.5'}>Save</span>
      </div>
    </SaveToCollection>
  );
}

const CardBatch = ({ item }) => {
  return (
    <div className="flex justify-end gap-x-[1.125rem] font-semibold">
      {item.addedBy ? (
        <PopoverRoot>
          <PopoverTrigger asChild>
            <div className="flex h-[2.625rem] cursor-pointer items-center justify-center bg-primaryLight px-[0.875rem] pt-[0.2125rem] font-bold text-primary">
              {getInitials(item.addedBy?.fullName)}
            </div>
          </PopoverTrigger>
          <PopoverContent sideOffset={5}>
            <div className="flex w-[17.3125rem] flex-col px-[2rem] py-[1.625rem]">
              <h5 className="text-[1rem] font-semibold leading-[1.155rem]  text-white">
                Added by {item.addedBy?.fullName}
              </h5>
            </div>
          </PopoverContent>
        </PopoverRoot>
      ) : (
        <SaveToCollectionButton item={item} />
      )}
      <PopoverRoot>
        <PopoverTrigger asChild>
          <div
            className="flex h-[2.625rem] cursor-pointer items-center justify-center px-[0.875rem] pt-[0.2125rem]"
            style={{
              backgroundColor: trendTypeMapping[item.trendType]?.color || 'red',
            }}
          >
            {trendTypeMapping[item.trendType]?.name || 'Unknown'}
          </div>
        </PopoverTrigger>
        <PopoverContent sideOffset={5}>
          <div className="flex w-[17.3125rem] flex-col px-[2rem] pt-[1.625rem]">
            <div className="flex flex-col">
              <div className="mb-[1.3125rem] flex flex-col">
                <h5 className="text-[1rem] font-semibold leading-[1.155rem]  text-white">
                  {trendTypeMapping[item.trendType]?.name || 'Unknown'}
                </h5>
                <p className="font-semi-light text-[1rem] leading-[1.155rem]  text-white">
                  {trendTypeMapping[item.trendType]?.definition || 'Unknown'}
                </p>
              </div>
            </div>
          </div>
        </PopoverContent>
      </PopoverRoot>
    </div>
  );
};

const CardTitle = ({ title, id, trendItemId, marketId }) => {
  const { moderationEnabled } = useUser();

  const { selectedAudience, selectedSeason } = useAppFilters();

  const itemUrl = `/trends/detail/${
    selectedAudience?.marketId || marketId
  }/${encodeURIComponent(title.toLowerCase().replaceAll(' ', '-'))}/${
    trendItemId || id
  }/${selectedSeason?.value || '0'}`;

  return (
    <Link to={itemUrl} target={moderationEnabled ? '_blank' : undefined}>
      <div className="flex w-full cursor-pointer items-center justify-between px-[1.03125rem] 2xl:px-[2.0625rem]">
        <p className="flex h-[5.3125rem] items-center pt-[0.25rem] text-[1.75rem] font-semibold capitalize leading-[2.3125rem] 2xl:text-[2rem]">
          {title}
        </p>
        <div className="flex">
          <svg
            width="27"
            height="24"
            viewBox="0 0 27 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13.0671 22.336L21.7205 13.8686L22.831 13.6112V13.271H0V10.9588H22.831V10.6187L21.7534 10.3934L12.8695 1.68244L14.5917 0L27 12.1218L14.8081 24L13.0718 22.336H13.0671Z"
              fill="#282928"
            />
          </svg>
        </div>
      </div>
    </Link>
  );
};

const CardDate = ({ optimalLaunch }) => {
  return (
    <div className="flex h-[4rem] items-center border-t border-[#A1A1A1] bg-[#FAF7F4] px-[1.03125rem] 2xl:h-[5.25rem] 2xl:px-[2.0625rem]">
      <PopoverRoot>
        <PopoverTrigger asChild>
          <p className="text-[1.125rem] font-medium 2xl:text-[1.625rem] 2xl:leading-[1.8125rem]">
            Optimal Launch
          </p>
        </PopoverTrigger>

        <PopoverContent sideOffset={5}>
          <div className="flex w-[29.875rem] flex-col px-[2rem] pt-[1.625rem]">
            <div className="mb-[1.3125rem] flex flex-col">
              <h5 className="mb-[1.5625rem] cursor-pointer text-[1.5rem]  font-semibold leading-[1.733125rem] text-white">
                Optimal Launch
              </h5>
              <p className="font-semi-light text-[1rem] leading-[1.155rem]  text-white">
                The optimal launch time is the sweet spot where the brand can
                maximize consumer interest and demand, and effectively compete
                with other brands in the market. This timing is crucial to the
                success of the collection, as launching too early or too late
                can result in missed opportunities and lost revenue.
              </p>
            </div>
          </div>
        </PopoverContent>
      </PopoverRoot>

      <div
        className="mx-[1.25rem] h-[1px] grow"
        style={{ backgroundColor: '#BC722D' }}
      ></div>
      <p className="text-[1.5rem] font-semibold leading-[1.75rem] text-[#BC722D]">
        {dayjs.utc(optimalLaunch)?.format('MMMM')}
      </p>
    </div>
  );
};

export const Card = ({ item, onChangeCover }) => {
  return (
    <div className="flex w-full flex-col pl-10">
      <CardBatch item={item} />
      <div className="flex w-full border border-[#A1A1A1]">
        <CardImage
          url={item?.image?.url || item?.featuredImages?.[0]?.url}
          onChangeCover={onChangeCover}
          tone={item?.tone}
          tiType={item?.tiType}
        />
        <div className="-ml-10 flex w-full flex-col">
          <CardTitle
            title={item.title}
            id={item.id}
            marketId={item?.market?.id}
          />
          <CardDate optimalLaunch={item?.optimalLaunch} />
          <CardStats item={item} />
        </div>
      </div>
    </div>
  );
};
