import {
  PopoverContent,
  PopoverRoot,
  PopoverTrigger,
  Text,
} from '@/components';
import { hexToHsl } from '@/helpers/hexToHsl';
import { getInitials } from '@/helpers/utils';
import { useToast } from '@/hooks/useToast';
import { Icon } from '@/icons';

import { RemoveFromCollection } from './ItemCard/Overlay';

export const PantoneCard = ({ item, handleDelete, id }) => {
  const { hex, code, addedBy, name } = item;
  const toast = useToast();

  return (
    <div
      className={''}
      style={{
        breakInside: 'avoid',
      }}
    >
      <div
        className="group relative flex aspect-[16/9] min-h-44 w-full flex-col items-center justify-center"
        style={{ background: hex }}
      >
        <div className="absolute bottom-3 left-5 hidden group-hover:flex">
          {addedBy && (
            <PopoverRoot>
              <PopoverTrigger asChild>
                <Text
                  weight="bold"
                  className={hexToHsl(hex) >= 75 ? 'text-black' : 'text-white'}
                >
                  {getInitials(addedBy.fullName)}
                </Text>
              </PopoverTrigger>
              <PopoverContent sideOffset={5} className="z-[999]">
                <div className="flex w-[17.3125rem] flex-col px-[2rem] py-[1.625rem]">
                  <h5 className="text-[1rem] font-semibold leading-[1.155rem]  text-white">
                    Added by {addedBy.fullName}
                  </h5>
                </div>
              </PopoverContent>
            </PopoverRoot>
          )}
        </div>
        <div
          className="absolute left-0 top-0 hidden scale-50 cursor-pointer items-center justify-center rounded-full p-2 hover:bg-primary group-hover:flex"
          onClick={(e) => {
            e.stopPropagation();
            navigator.clipboard.writeText(hex);
            toast.createToast({
              message: 'Copied to clipboard',
              type: 'success',
              showCloseButton: true,
              position: 'top-right',
            });
          }}
        >
          <Icon name="copy" color={hexToHsl(hex) >= 75 ? 'black' : 'white'} />
        </div>
        <div
          className="absolute right-0 top-0 hidden scale-90 cursor-pointer items-center justify-center group-hover:flex"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <RemoveFromCollection onClick={handleDelete} show={true} id={id}>
            <Icon
              name="trash"
              color={hexToHsl(hex) >= 75 ? 'black' : 'white'}
            />
          </RemoveFromCollection>
        </div>
        <div
          className="w-full p-4 text-lg font-semibold"
          style={{
            color: hexToHsl(hex) >= 75 ? 'black' : 'white',
          }}
        >
          <p>Pantone {code} TCX</p>
          {/* <p></p> */}
          <p className="capitalize">{name.replaceAll('-', ' ')}</p>
        </div>
      </div>
    </div>
  );
};
