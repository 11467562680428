import { useRef, useState } from 'react';
import { ImSpinner8 } from 'react-icons/im';
import * as AlertDialog from '@radix-ui/react-alert-dialog';

import {
  useMarketsLazyQuery,
  useMarketsMutation,
  useMarketsQuery,
} from '@/api/market/hook';

function ShowProratedPayment({ planSlug, price }) {
  const [quantity, setQuantity] = useState(1);
  const [proratedChecked, setProratedChecked] = useState(false);

  const cancelButtonRef = useRef(null);

  const [createCheckoutSession, { loading: checkoutLoading }] =
    useMarketsMutation('createStudioCheckoutSession', {
      onCompleted: (data) => {
        window.location.href = data?.createStudioPlanCheckoutSession.url;
      },
    });

  function handleCreateCheckoutSession() {
    return createCheckoutSession({
      variables: {
        successUrl: `${window.location.origin}/studio-licence/assign-members`,
        cancelUrl: `${window.location.origin}/studio-licence/select-plan`,
        planSlug,
        quantity,
      },
    });
  }

  const [checkProrated, { data, loading }] = useMarketsLazyQuery(
    'checkProratedPayment',
    {
      onCompleted: (completedData) => {
        if (completedData?.checkProratedPayment.price === 0) {
          return handleCreateCheckoutSession();
        }

        setProratedChecked(true);
      },
    },
  );

  return (
    <AlertDialog.Root>
      <AlertDialog.Trigger asChild>
        <button className="h-[54px] cursor-pointer border-x-2 border-b-2 border-charcoal bg-primary text-[18px] font-semibold leading-[21px] text-white group-hover:border-primary">
          {/* {planSelected ? 'Selected' : 'Select a Plan'} */}
          Select Plan
        </button>
      </AlertDialog.Trigger>
      <AlertDialog.Portal>
        <AlertDialog.Overlay
          className="fixed inset-0 z-[990] bg-black/90"
          onClick={() => {
            cancelButtonRef?.current.click();
          }}
        />
        <AlertDialog.Content className="rounded-6 w-90vw max-h-screen-85vh animate-contentShow fixed left-1/2 top-1/2 z-[999] max-w-screen-sm -translate-x-1/2 -translate-y-1/2 bg-white p-8 shadow-xl focus:outline-none">
          <AlertDialog.Title className="text-lg font-semibold">
            Licence Quantity
          </AlertDialog.Title>
          <AlertDialog.Description className="text-gray-500">
            Please enter the number of licenses you would like to purchase.
          </AlertDialog.Description>
          {data ? (
            <div className="my-4 flex flex-col gap-4">
              <h5 className="text-[48px] font-semibold leading-[55px] ">
                {data.checkProratedPayment.price}$ / Mo
              </h5>

              <p className="font-regular mt-[12px] border-b border-[#A1A1A1] pb-[22px] text-[14px] leading-[16px] text-[#626775]">
                We will charge you ${data.checkProratedPayment.price} for{' '}
                {data.checkProratedPayment.days} days of usage. Subsequent
                billing periods will reflect on your monthly invoice as ${price}
                .
              </p>
            </div>
          ) : (
            <div className="my-4 flex items-center gap-4">
              <h1 className="text-lg font-medium">Quantity</h1>
              <input
                type="number"
                value={quantity}
                onChange={(e) => {
                  if (e !== 0) setQuantity(Number(e.target.value));
                }}
                className="w-14 max-w-full border border-primary p-2 text-left [appearance:textfield] focus:outline-none focus-visible:ring-0 focus-visible:ring-transparent [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none"
                disabled={loading}
                data-input-counter-min="1"
              />
            </div>
          )}

          <div className="flex items-center justify-end gap-4">
            <AlertDialog.Cancel asChild>
              <button
                className="bg-red-500 px-4 py-2 font-medium text-white disabled:opacity-70"
                ref={cancelButtonRef}
                disabled={loading}
              >
                Cancel
              </button>
            </AlertDialog.Cancel>

            <AlertDialog.Action asChild>
              <button
                className="flex items-center gap-4 bg-green px-4 py-2 font-medium text-white disabled:opacity-70"
                disabled={loading || checkoutLoading}
                onClick={(e) => {
                  e.preventDefault();

                  if (!proratedChecked) {
                    return checkProrated({
                      variables: {
                        quantity,
                        planSlug,
                      },
                    });
                  }

                  return handleCreateCheckoutSession();
                }}
              >
                {(loading || checkoutLoading) && (
                  <ImSpinner8 className="animate-spin" />
                )}
                Proceed
              </button>
            </AlertDialog.Action>
          </div>
        </AlertDialog.Content>
      </AlertDialog.Portal>
    </AlertDialog.Root>
  );
}

function numberFormatter(number) {
  return new Intl.NumberFormat('en-US').format(number);
}

function StudioPlanCard(plan) {
  return (
    <div className="group flex flex-col">
      <div
        className="flex h-12 items-center justify-center border-x-2 border-t-2 border-charcoal px-4 text-[24px] font-medium leading-[28px] text-white group-hover:border-primary"
        style={{
          background: plan.color,
        }}
      >
        {plan.label}
      </div>
      <div className="flex flex-col border-2 border-charcoal p-[24px] group-hover:border-primary">
        <div className="flex flex-row items-end">
          <h5 className="flex items-start justify-start text-5xl font-semibold leading-[55px]">
            <span className="pr-2 text-lg font-semibold">$</span>
            {plan.price}
          </h5>
          <span className="items-end pl-2 text-lg font-semibold">/ Mo</span>
        </div>
        <p className="font-regular mt-[12px] border-b border-[#A1A1A1] pb-[22px] text-[14px] leading-[16px] text-[#626775]">
          {plan.description}
        </p>

        <div className="mb-[33px] flex min-h-[9rem] w-full flex-col items-start pt-4">
          <p className="flex items-center gap-x-2">
            <span className="font-semibold">
              {numberFormatter(plan.credit)}
            </span>
            <span className="capitalize">Credits</span>
            <span> per month</span>
          </p>
          <p className="flex items-center gap-x-2">
            {!plan.featureFlags.isPrivateImageEnabled && (
              <span className="font-semibold">No</span>
            )}
            <span className="capitalize">Private image</span>
            <span className="font-semibold">
              {plan.featureFlags.isPrivateImageEnabled && (
                <span>mode available</span>
              )}
            </span>
          </p>
          <p className="flex items-center">
            <span className="font-semibold">
              {!plan.featureFlags.isPrivateImageEnabled &&
                plan.label !== 'Basic Studio Plan' && (
                  <span className="pr-2">No</span>
                )}
            </span>
            <span className="capitalize">Expert mode</span>
          </p>
          <p className="flex items-center gap-x-2">
            <span className="capitalize">Inpainting</span>
            <span className="font-semibold">
              <span className="font-normal">(coming soon)</span>
              {/* {plan.featureFlags.isPrivateImageEnabled ? (
                <Icon name="check-2" />
              ) : (
                <span>No</span>
              )} */}
            </span>
          </p>
          {plan.label === 'Professional Studio Plan' && (
            <p className="flex items-center gap-x-2">
              <span className="font-semibold capitalize">
                Early access to beta features
              </span>
            </p>
          )}
        </div>
      </div>
      <ShowProratedPayment planSlug={plan.slug} price={plan.price} />
    </div>
  );
}

export function SelectStudioPlan() {
  const { data, loading } = useMarketsQuery('studioPlans');
  // const [_, setSelectedPlan]z = useState(null)

  // const [createCheckout, { loading: checkoutLoading }] = useMarketsMutation(
  //   'createStudioCheckoutSession',
  //   {
  //     onCompleted: (data) => {
  //       window.location.replace(data.createStudioCheckoutSession.url);
  //     },
  //   }
  // );

  console.log(data);

  if (loading) {
    return <div>Loading...</div>;
  }

  function handlePlanSelect(planSlug) {
    // setSelectedPlan(planSlug)
    // checkPayment({
    //   variables: {
    //     planSlug,
    //     quantity: 1,
    //   },
    // });
  }

  return (
    <div className="flex flex-col items-center gap-4">
      <h5 className="mt-[52px] text-center text-[48px] font-semibold leading-[55px]">
        Select a Plan
      </h5>
      <p className="font-regular mx-auto mb-[32px] mt-[16px] max-w-[546px] text-center text-[14px] text-[#626775]">
        Choose a subscription that best aligns with your fashion analysis needs,
        providing tailored insights and robust trend forecasting across pivotal
        global fashion capitals.
      </p>

      <div className="grid grid-cols-4 gap-4">
        {data?.studioPlans.map((plan) => (
          <StudioPlanCard
            key={plan.slug}
            {...plan}
            onPlanSelected={handlePlanSelect}
          />
        ))}
      </div>
    </div>
  );
}
