import { useState } from 'react';
import { X } from 'lucide-react';

import {
  ControlledHoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from '@/components/ui/hover-card';
import { useStudioContext } from '@/contexts/StudioContext';

import { UploadBox } from './StudioUtils';

const ReferenceImageUpload = ({
  title,
  icon,
  text,
  fileEntityType,
  guideText,
  images,
}) => {
  const {
    referenceImage,
    referenceSketch,
    referenceGraphic,
    referencePattern,
    referenceOtherImage,
  } = useStudioContext();
  const [isOpen, setIsOpen] = useState(false);
  return (
    <ControlledHoverCard open={isOpen} onOpenChange={setIsOpen}>
      <HoverCardTrigger>
        <UploadBox
          title={title}
          icon={icon}
          text={text}
          type="image"
          fileEntityType={fileEntityType}
        />
      </HoverCardTrigger>

      {((fileEntityType === 'APPAREL' && !referenceImage) ||
        (fileEntityType === 'PATTERN' && !referencePattern) ||
        (fileEntityType === 'GRAPHIC' && !referenceGraphic) ||
        (fileEntityType === 'SKETCH' && !referenceSketch) ||
        (fileEntityType === 'OTHER' && !referenceOtherImage)) && (
        <HoverCardContent side="right">
          <div className="flex w-[22.625rem] flex-col gap-6 rounded border border-secondary/50 bg-primaryLight p-6 shadow-md">
            <div className="flex flex-col gap-y-2">
              <div className="flex flex-row items-center justify-between gap-y-2">
                <h2 className="text-lg font-bold leading-6 text-primary">
                  Reference Image Guide
                </h2>
                <div
                  className="cursor-pointer"
                  onClick={() => setIsOpen(false)}
                >
                  <X
                    size={20}
                    className="cursor-pointer text-night hover:text-secondary"
                  />
                </div>
              </div>
              <p className="text-sm text-gray-700">{guideText}</p>
            </div>
            <div className="grid grid-cols-3 gap-2.5">
              {images.map((src, i) => (
                <img
                  key={i}
                  className="aspect-[3/4] w-full rounded border border-secondary/50 bg-secondary/5"
                  src={src}
                />
              ))}
            </div>

            <UploadBox
              title="Click to Upload"
              variant="cardButton"
              type="image"
              fileEntityType={fileEntityType}
            />
          </div>
        </HoverCardContent>
      )}
    </ControlledHoverCard>
  );
};

export default ReferenceImageUpload;
