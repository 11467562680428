import React, { useContext } from 'react';
import { Info, Triangle } from 'lucide-react';
import numeral from 'numeral';
import {
  CartesianGrid,
  Dot,
  ResponsiveContainer,
  Scatter,
  ScatterChart,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import { InsightContext } from '../../../../contexts/InsightContext';

const RenderDot = ({ cx, cy }) => {
  return <Dot cx={cx} cy={cy} fill="#58B7FF" r={7.5} />;
};

const SectionTabs = ({ tabs, selectedTab, onTabChange }) => {
  return (
    <div className="flex gap-4 pt-3">
      {tabs.map((tab) => (
        <button
          key={tab.value}
          onClick={() => onTabChange(tab.value)}
          className={` border-b-3  px-4 py-2 text-sm font-semibold ${
            selectedTab === tab.value
              ? ' border-primary text-primary'
              : 'border-gray-400 text-gray-400'
          }`}
        >
          {tab.label}
        </button>
      ))}
    </div>
  );
};

export const InsightChart = ({ insights, sectionTabs }) => {
  const { section, setSection } = useContext(InsightContext);

  const data = insights?.map((insight, index) => ({
    x: insight.node.qualityEngagement,
    y: insight.node.yoyGrowth,
    index,
  }));

  return (
    <div className="h-[400px] w-full pt-10">
      <div className="flex justify-between pb-10">
        <h2 className="text-2xl font-bold text-primary">Market Detail</h2>
        <div className="flex items-center gap-2">
          <SectionTabs
            tabs={sectionTabs}
            selectedTab={section}
            onTabChange={setSection}
          />
        </div>
      </div>
      <ResponsiveContainer width="100%" height="100%">
        <ScatterChart
          margin={{
            top: 50,
            right: 150,
            bottom: 50,
            left: 50,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            type="number"
            dataKey="x"
            name="Quality Engagement"
            unit=""
            tickFormatter={(value) =>
              numeral(value).format('0,0.00a').toUpperCase()
            }
            label={{
              value: 'Quality Eng.',
              position: 'right',
              offset: 40,
            }}
          />
          <YAxis
            type="number"
            dataKey="y"
            name="Year over Year Growth"
            unit="%"
            label={{
              value: 'Year over Year Growth (%)',
              position: 'insideTopLeft',
              offset: -45,
            }}
          />
          <Tooltip
            content={({ active, payload }) => {
              if (active && payload && payload.length) {
                const insight = insights[payload[0].payload.index];
                const featuredImages = [
                  ...insight.node.featuredImages.fashionWeekImages,
                  ...insight.node.featuredImages.socialMediaImages,
                  ...insight.node.featuredImages.studioImages,
                ];
                return (
                  <div className="rounded-lg border border-gray-200 bg-white p-4 shadow-sm">
                    <h3 className="text-lg font-bold capitalize">
                      {insight.node.title}
                    </h3>
                    <div className="flex flex-wrap gap-2">
                      {featuredImages.slice(0, 3).map((image) => (
                        <img
                          key={image.id}
                          src={image.url}
                          alt="Insight"
                          className="mb-2 h-32 w-32 rounded-md object-cover"
                        />
                      ))}
                    </div>
                    <div className="text-sm">
                      <div>
                        Quality Engagement:{' '}
                        <div className="float-right flex items-center gap-1 font-bold text-green">
                          {numeral(insight.node.qualityEngagement)
                            .format('0,0.00a')
                            .toUpperCase()}
                          <Triangle className="h-4 w-4 fill-green" />
                        </div>
                      </div>
                      <div>
                        Year over Year Growth:{' '}
                        <div className="float-right flex items-center gap-1 font-bold text-red-700">
                          %
                          {numeral(insight.node.yoyGrowth)
                            .format('0,0.00a')
                            .toUpperCase()}
                          <Triangle className="h-4 w-4 rotate-180 fill-red-700" />
                        </div>
                      </div>
                    </div>
                  </div>
                );
              }

              return null;
            }}
          />
          <Scatter
            data={data}
            strokeWidth={1}
            onMouseEnter={(e, index, obj) => {
              const target = obj.target;
              target.style.cursor = 'pointer';
              target.style.fill = '#DFF0FF';
            }}
            onMouseLeave={(e, index, obj) => {
              const target = obj.target;
              target.style.cursor = 'default';
              target.style.fill = '#58B7FF';
              target.style.stroke = '#58B7FF';
            }}
            shape={<RenderDot />}
          />
        </ScatterChart>
      </ResponsiveContainer>
      <div className="flex justify-between">
        <div className="flex items-center gap-1">
          <span>Timeframe:</span>
          <span className="font-bold">March24, April24, May24</span>
        </div>
      </div>
      <div className="flex justify-between pt-10">
        <div className="flex items-center gap-2">
          <Info className="h-4 w-4" />
          <span>Last Update: February 2025</span>
        </div>
      </div>
    </div>
  );
};
