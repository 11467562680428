import { useContext, useEffect, useRef, useState } from 'react';
import { RxCross1 } from 'react-icons/rx';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import * as Tabs from '@radix-ui/react-tabs';
import {
  ChevronLeft,
  ChevronRight,
  Circle,
  LayoutGrid,
  List,
} from 'lucide-react';
import numeral from 'numeral';

import { MetricPill } from '@/components/Pills';
import { TabHeading } from '@/components/TabHeading';
import { InsightContext } from '@/contexts/InsightContext';
import { cn } from '@/helpers/utils';

import { LazyLoadButton } from '../../../explore/components/utils';
import { InsightCard } from '../../components/Card';
import { InsightChart } from '../../components/InsightChart';
import { InsightListView } from '../../components/InsightListView';
import { Forecast } from '../detail/components';
import { DetailBreakDown } from '../detail/components/DetailBreakdown';
import { FeaturedImages } from '../detail/InsightDetail';

const GhostItem = () => {
  return (
    <div className="flex flex-col">
      <div className={'ghost aspect-[54/25] rounded-xl'}></div>
      <h3 className="ghost mt-4 w-max text-2xl text-ghost">Black Shoes</h3>
      <p className="ghost mt-1 h-6 w-max text-md text-ghost">
        Year over Year Growth %40
      </p>
    </div>
  );
};

const sectionTabs = [
  {
    label: 'Top Trends',
    value: 'TOP_TRENDS',
  },
  {
    label: 'Top Colors',
    value: 'TOP_COLORS',
  },
  {
    label: 'Top Fabrics',
    value: 'TOP_FABRICS',
  },
  {
    label: 'Top Patterns',
    value: 'TOP_PATTERNS',
  },
];

const modalTabs = [
  {
    label: 'Gallery',
    value: 'GALLERY',
  },
  {
    label: 'Analysis',
    value: 'ANALYSIS',
  },
];
const TrendsCardGhost = ({ count, variant }) => {
  const variantMap = {
    sm: 'w-64',
    md: 'w-full',
  };

  return (
    <>
      <h2 className="mt-6 text-3xl font-bold">Top Trends</h2>
      <div
        className={`grid animate-pulse grid-cols-3 gap-4 rounded-xl pt-8 ${variantMap[variant]}`}
      >
        {[...Array(6)].map((_, index) => (
          <GhostItem key={index} />
        ))}
      </div>
      <div className="mt-4 flex items-center justify-center">
        <LazyLoadButton fetchMore={() => {}} cursorNext={null} disabled />
      </div>
      <h2 className="mt-6 text-3xl font-bold">Top Tones</h2>
      <div
        className={`grid animate-pulse grid-cols-3 gap-4 rounded-xl pt-8 ${variantMap[variant]}`}
      >
        {[...Array(6)].map((_, index) => (
          <GhostItem key={index} />
        ))}
      </div>
      <div className="mt-4 flex items-center justify-center">
        <LazyLoadButton fetchMore={() => {}} cursorNext={null} disabled />
      </div>
    </>
  );
};

const SectionTabs = ({ tabs, selectedTab, onTabChange }) => {
  return (
    <div className="flex gap-4 pt-3">
      {tabs.map((tab) => (
        <button
          key={tab.value}
          onClick={() => onTabChange(tab.value)}
          className={`font-planck rounded border px-4 py-2 text-lg font-semibold ${
            selectedTab === tab.value
              ? ' border-primary bg-primary/20 text-primary'
              : 'bg-secondary/10 text-black hover:text-gray-700'
          }`}
        >
          {tab.label}
        </button>
      ))}
    </div>
  );
};

/* 
TODO:
- Loading state, ghost items for insights design
- Chart, hover  selected time period, Tasarımdaki gibi seçili aylar chart üzerinde alt taraftan background eklenicek
- timeframe and category add url query params
- get url query params and set filters
*/

export function TrendsInsights() {
  const {
    insightsData,
    insightsLoading,
    section,
    setSection,
    insightData,
    insightLoading,
    getInsight,
    activeChart,
    setActiveChart,
    refetchInsight,
  } = useContext(InsightContext);

  const [searchParams] = useSearchParams();

  const [view, setView] = useState('grid');
  const [insightIndex, setInsightIndex] = useState(0);
  const [modalActive, setModalActive] = useState(false);
  const modalRef = useRef(null);
  const { marketId, audienceId } = useParams();
  const selectedTimeframe = searchParams.get('timeframe');

  const [modalTab, setModalTab] = useState('ANALYSIS'); // GALLERY || ANALYSIS

  const charts = [
    {
      label: 'Visibility',
      value: 'YOY_GROWTH',
      component: (
        <Forecast.Root>
          <Forecast.Chart />
        </Forecast.Root>
      ),
    },
    {
      label: 'Engagement',
      value: 'QUALITY_ENGAGEMENT',
      component: (
        <Forecast.Root>
          <Forecast.Chart />
        </Forecast.Root>
      ),
    },
  ];

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setModalActive(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const setNextInsight = () => {
    if (insightIndex + 6 >= insightsData?.insights?.edges?.length) return;
    setInsightIndex((prev) => prev + 6);
  };

  const setPrevInsight = () => {
    if (insightIndex === 0) return;
    setInsightIndex((prev) => prev - 6);
  };

  return (
    <div>
      <div className="flex items-center justify-between">
        <SectionTabs
          tabs={sectionTabs}
          selectedTab={section}
          onTabChange={setSection}
        />
        <div className="flex items-center justify-between gap-4">
          <LayoutGrid
            className={`h-8 w-8 cursor-pointer rounded-md p-1 text-sm font-bold ${
              view === 'grid' ? 'bg-primary text-white' : 'text-black'
            }`}
            onClick={() => {
              setInsightIndex(0);
              setView('grid');
            }}
          />

          <List
            className={`h-8 w-8 cursor-pointer rounded-md p-1 text-sm font-bold ${
              view === 'list' ? 'bg-primary text-white' : 'text-black'
            }`}
            onClick={() => {
              setInsightIndex(0);
              setView('list');
            }}
          />
        </div>
      </div>

      {/* <!-- Grid View --> */}
      {view === 'grid' && (
        <div className="flex flex-col pt-6">
          {insightsLoading ? (
            <TrendsCardGhost variant="md" />
          ) : (
            <>
              <div className="flex flex-col">
                <div className="flex items-center justify-end gap-2">
                  <ChevronLeft
                    className={cn(
                      'h-5 w-5 cursor-pointer text-gray-500',
                      insightIndex === 0 && 'opacity-50',
                    )}
                    onClick={setPrevInsight}
                    disabled={insightIndex === 0}
                  />
                  <ChevronRight
                    className={cn(
                      'h-5 w-5 cursor-pointer text-gray-500',
                      insightIndex + 6 >=
                        insightsData?.insights?.edges?.length && 'opacity-50',
                    )}
                    onClick={setNextInsight}
                  />
                </div>
                <div className="my-4 grid grid-cols-3 gap-8">
                  {insightsData?.insights?.edges
                    ?.slice(insightIndex, insightIndex + 6)
                    .map((item, index) => (
                      <InsightCard
                        key={index}
                        variant="md"
                        insightItem={item?.node}
                      />
                    ))}
                  {insightsLoading &&
                    [...Array(6)].map((_, index) => <GhostItem key={index} />)}
                </div>
                <div className="flex items-center justify-center gap-1.5">
                  {insightsData?.insights?.edges?.length > 0 &&
                    Array(Math.ceil(insightsData?.insights?.edges?.length / 6))
                      .fill(0)
                      .map((_, i) => (
                        <Circle
                          key={i}
                          className={cn(
                            'h-3 w-3 cursor-pointer text-gray-500 opacity-50 m-0 fill-gray-500',
                            insightIndex === i * 6 && 'opacity-100',
                          )}
                          onClick={() => setInsightIndex(i * 6)}
                        />
                      ))}
                </div>
              </div>
            </>
          )}
        </div>
      )}
      {!insightsLoading && view === 'grid' && (
        <InsightChart
          insights={insightsData?.insights?.edges}
          sectionTabs={sectionTabs}
        />
      )}

      {/* <!-- List View --> */}
      {view === 'list' && (
        <div className="flex flex-col pt-6">
          {insightsLoading ? (
            <TrendsCardGhost variant="sm" />
          ) : (
            <InsightListView
              insights={insightsData?.insights?.edges}
              setModalActive={setModalActive}
              modalActive={modalActive}
              getInsight={getInsight}
              refetchInsight={refetchInsight}
              insightData={insightData}
            />
          )}
        </div>
      )}

      {/* Modal */}
      <div
        key={'detailModal'}
        className={` fixed inset-y-0 right-0 z-50 w-1/3 bg-white shadow-lg transition-transform duration-300 ease-in-out${
          modalActive ? ' translate-x-0' : ' translate-x-full'
        }`}
        ref={modalRef}
      >
        {insightLoading ? (
          <>
            <div className="min-h-[11.875rem] w-full"></div>

            {/* <!-- TODO: Remove this and implement the loading design from figma if exists --> */}
            <div className="flex items-center justify-center gap-3.5 pt-14">
              <Circle className="m-0 h-6 w-6 animate-[ping_1s_ease-in-out_infinite] cursor-pointer fill-gray-500 text-gray-500 opacity-50" />
              <Circle className="m-0 h-6 w-6 animate-[ping_1s_ease-in-out_infinite] cursor-pointer fill-gray-500 text-gray-500 opacity-50 [animation-delay:333ms]" />
              <Circle className="m-0 h-6 w-6 animate-[ping_1s_ease-in-out_infinite] cursor-pointer fill-gray-500 text-gray-500 opacity-50 [animation-delay:666ms]" />
            </div>
          </>
        ) : (
          <div className="flex h-full flex-col overflow-y-auto">
            <div className="min-h-[5.875rem] w-full"></div>
            <div className="flex items-center justify-between px-8 pt-14">
              <h2 className="text-3xl font-bold capitalize text-primary">
                {insightLoading}
                {insightData?.insight?.title}
              </h2>
              <div className="flex items-center gap-4">
                <Link
                  to={`/trends/insights/${marketId}/${audienceId}/insight-detail?title=${encodeURIComponent(insightData?.insight?.title.toLowerCase().replaceAll(' ', '-'))}&id=${insightData?.insight?.id}&timeframe=${selectedTimeframe}`}
                  onClick={() => {
                    setModalActive(false);
                    setView('list');
                  }}
                  className="text cursor-pointer rounded-md border border-black px-4 py-2"
                >
                  View Details
                </Link>
                <RxCross1
                  size={24}
                  className="cursor-pointer"
                  onClick={() => setModalActive(false)}
                />
              </div>
            </div>
            <div className="flex items-center justify-between px-8 pt-8">
              <div className="flex items-center gap-4">
                <SectionTabs
                  tabs={modalTabs}
                  selectedTab={modalTab}
                  onTabChange={setModalTab}
                />
              </div>
            </div>
            {modalTab === 'ANALYSIS' &&
              insightData?.insight?.insightBreakdowns && (
                <div className="grid grid-cols-3 gap-6 px-8 pt-8">
                  <MetricPill
                    isIncrease={true}
                    metric="Trend Type"
                    metricValue={'Rising Star'}
                    timePeriod={{ value: null, label: null }}
                  />
                  <MetricPill
                    isIncrease={true}
                    metric="Year over Year Growth"
                    metricValue={`+${insightData?.insight?.yoyGrowth}%`}
                    timePeriod={{ value: null, label: null }}
                  />
                  <MetricPill
                    isIncrease={true}
                    metric="Quality Engagement"
                    metricValue={numeral(
                      insightData?.insight?.qualityEngagement,
                    )
                      .format('0.0a')
                      .toUpperCase()}
                    timePeriod={{ value: null, label: null }}
                  />
                </div>
              )}
            {modalTab === 'ANALYSIS' &&
              insightData?.insight?.insightBreakdowns?.filter(
                (tab) => tab?.data?.length > 0,
              ).length > 0 && (
                <div
                  className={cn(
                    'grid gap-x-6 px-8 pt-8',
                    `grid-cols-${insightData?.insight?.insightBreakdowns?.filter((tab) => tab?.data?.length > 0).length === 1 ? 2 : insightData?.insight?.insightBreakdowns?.filter((tab) => tab?.data?.length > 0).length}`,
                  )}
                >
                  {insightData?.insight?.insightBreakdowns.map(
                    (tab, index) =>
                      tab?.data?.length > 0 && (
                        <DetailBreakDown key={index} tab={tab} />
                      ),
                  )}
                </div>
              )}
            {modalTab === 'ANALYSIS' && (
              <div className="w-full px-8 pt-12">
                <Tabs.Root
                  value={activeChart}
                  onValueChange={(chart) => {
                    setActiveChart(chart);
                  }}
                  className={'flex flex-col gap-y-4'}
                >
                  <div className="flex flex-row items-start justify-between">
                    <h4 className="text-lg font-bold leading-[1.125rem]">
                      Chart
                    </h4>
                    <Tabs.List
                      aria-label="trends"
                      className={'flex items-start justify-start'}
                    >
                      <div className={'flex gap-x-2.5'}>
                        {charts.map((chart, i) => (
                          <TabHeading
                            size={'md'}
                            label={chart.label}
                            value={chart.value}
                            variant="snapshots"
                            className="pb-2 text-sm leading-4"
                            key={i}
                            onClick={() => setActiveChart(chart.value)}
                          />
                        ))}
                      </div>
                    </Tabs.List>
                  </div>
                  <p className="text-xs font-normal leading-[0.875rem]">
                    This chart about how users interact with content. For
                    example, shares, comments, saves, long-term, etc.
                  </p>
                  <div>
                    {charts.map((chart, i) => (
                      <Tabs.Content key={i} value={chart.value}>
                        {chart.component}
                      </Tabs.Content>
                    ))}
                  </div>
                </Tabs.Root>
              </div>
            )}
            {modalTab === 'GALLERY' && insightData?.insight?.featuredImages && (
              <div className="px-8 pt-8">
                <FeaturedImages
                  images={insightData?.insight?.featuredImages}
                  isLiked={insightData?.insight?.isLiked}
                  skeleton={false}
                  filter={() => {}}
                />
              </div>
            )}
            <div className="min-h-[5.875rem] w-full"></div>
          </div>
        )}
      </div>
    </div>
  );
}
