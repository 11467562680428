import React from 'react';

import { genderMapping } from '@/constants';
import { useReports } from '@/hooks/report';

export const AudienceGender = ({ audience }) => {
  const { updateAudience } = useReports();

  return (
    <div className="flex w-full flex-col">
      <span className="mb-[25px] mt-[21px] text-[18px] font-semibold leading-[21px]">
        Gender
      </span>
      <div className="flex w-full gap-x-[24px]">
        {Object.values(genderMapping).map((gender, index) => (
          <div className="inline-flex items-center" key={index}>
            <input
              className="form-check-input float-left h-[14px] w-[14px] cursor-pointer appearance-none rounded-none border border-charcoal bg-white bg-contain bg-center bg-no-repeat align-top transition duration-200 checked:border-charcoal checked:bg-primary focus:outline-none"
              type="checkbox"
              id={gender.value}
              checked={audience?.gender === gender.value}
              onChange={() =>
                updateAudience({
                  id: audience?.id,
                  gender: gender.value,
                })
              }
            />
            <label
              htmlFor={gender.value}
              className="font-regular  ml-[8px] flex cursor-pointer justify-center pt-[4px] text-[16px] leading-[18px]"
            >
              {gender.label}
            </label>
          </div>
        ))}
      </div>
    </div>
  );
};
