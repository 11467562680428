import { cva } from 'class-variance-authority';

import { cn } from '@/helpers/utils';

const sidebarVariants = cva('h-full w-full flex flex-col', {
  variants: {
    variant: {
      default: 'max-w-md min-w-[20rem]',
      primary:
        'max-w-md min-w-[20rem] overflow-scroll border-r-2 py-8 px-[3.25rem] justify-start',
      mobile: 'p-8',
    },
  },
  defaultVariants: {
    variant: 'default',
  },
});

export function Sidebar({ className, variant, children, ...props }) {
  const classNames = cn(
    sidebarVariants({
      variant,
    }),
    className,
  );

  return (
    <div className={classNames} {...props}>
      {children}
    </div>
  );
}
