import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import dayjs from 'dayjs';

import { STUDIO_TOOLS_CONFIG } from '@/constants/studio';
import { useStudioContext } from '@/contexts/StudioContext';
import { cn } from '@/helpers/utils';

import { GenerateButton } from './components/GenerateButton';
import { SidebarButton } from './components/SidebarMenu';

const StudioToolsGrid = ({ isPattern, generatedTaskType, entityType }) => {
  const {
    selectedTool,
    setSelectedTool,
    updateCreateItemDetails,
    setGenerateSize,
    isHidingTool,
    createItem,
    studioTask,
  } = useStudioContext();

  const [hasGenerationType, setHasGenerationType] = useState(false);
  const [canFlowsToTask, setCanFlowsToTask] = useState({});

  const location = useLocation();

  useEffect(() => {
    setHasGenerationType(location.search.includes('generationType'));
  }, [location.search]);

  const [apparelTools, setApparelTools] = useState([
    'CREATE_VARIANTS',
    'UPSCALE_IMAGE',
    'ENHANCE_IMAGE',
    'ZOOM_IN_IMAGE',
    'IMAGE_BACKGROUND_REMOVER',
    'IMAGE_CHANGE_BACKGROUND',
    'COLOR_TRANSFER',
    'GENERATE_FLAT_SKETCH',
  ]);

  const [patternTools, setPatternTools] = useState([
    'CREATE_PATTERN_VARIANTS',
    'PATTERN_TILE',
    'UPSCALE_IMAGE',
    'ENHANCE_IMAGE',
    'ZOOM_IN_IMAGE',
  ]);

  useEffect(() => {
    if (isPattern) {
      setGenerateSize('SQUARE');
    }
  }, [isPattern]);

  useEffect(() => {
    if (isHidingTool.length > 0) {
      if (isPattern) {
        setPatternTools(
          patternTools.filter((tool) => tool !== isHidingTool[0]),
        );
      } else {
        setApparelTools(
          apparelTools.filter((tool) => tool !== isHidingTool[0]),
        );
      }
    } else {
      if (isPattern) {
        setPatternTools(patternTools);
      } else {
        setApparelTools(apparelTools);
      }
    }
  }, [createItem.type]);

  useEffect(() => {
    setCanFlowsToTask(STUDIO_TOOLS_CONFIG[generatedTaskType]?.canFlowsToTask);
  }, [generatedTaskType]);

  useEffect(() => {
    if (studioTask.generateTaskType === 'GENERATE_GRAPHIC') {
      setPatternTools(
        patternTools.filter(
          (tool) => tool !== 'GENERATE_FLAT_SKETCH' && tool !== 'INPAINTING',
        ),
      );
      setApparelTools(
        apparelTools.filter(
          (tool) => tool !== 'GENERATE_FLAT_SKETCH' && tool !== 'INPAINTING',
        ),
      );
    }
  }, [studioTask]);

  console.log({ canFlowsToTask });

  const toolsToRender = (isPattern ? patternTools : apparelTools)
    .filter((tool) =>
      import.meta.env.VITE_APP_ENV === 'development'
        ? true
        : tool !== 'INPAINTING',
    )
    .map(
      (tool, index) =>
        STUDIO_TOOLS_CONFIG[tool].canGenerate && (
          <div
            className="h-full"
            key={index}
            onClick={() => {
              setSelectedTool(tool);
              updateCreateItemDetails(tool);
            }}
          >
            <GenerateButton
              variant="tool"
              text={STUDIO_TOOLS_CONFIG[tool].buttonText}
              icon={STUDIO_TOOLS_CONFIG[tool].icon}
              tool={tool}
              disabled={canFlowsToTask ? !canFlowsToTask[tool] : false}
              className={cn(
                hasGenerationType &&
                  (tool === 'CREATE_VARIANTS' ||
                    tool === 'CREATE_PATTERN_VARIANTS') &&
                  'hidden',
              )}
            />
          </div>
        ),
    );

  const sidebarToolsToRender = (isPattern ? patternTools : apparelTools).map(
    (tool, index) =>
      !STUDIO_TOOLS_CONFIG[tool].canGenerate && (
        <SidebarButton
          key={index}
          text={STUDIO_TOOLS_CONFIG[tool].buttonText}
          icon={STUDIO_TOOLS_CONFIG[tool].icon}
          disabled={
            studioTask?.status !== 'COMPLETED' ||
            (canFlowsToTask ? !canFlowsToTask[tool] : true)
          }
          variant="tool"
          active={selectedTool === tool}
          className={cn('shadow-sm')}
          onClick={() => {
            setSelectedTool(tool);
            updateCreateItemDetails(tool);
          }}
        />
      ),
  );

  return (
    <div className="grid min-w-full grid-cols-1 gap-x-4 gap-y-6 lg:grid-cols-2">
      {toolsToRender}
      {sidebarToolsToRender}
    </div>
  );
};

export const StudioTools = () => {
  const {
    openTools,
    setOpenTools,
    studioTaskData,
    studioTaskLoading,
    refSoloItemType,
    createItem,
    processStudioTaskLoading,
    positivePrompt,
  } = useStudioContext();
  const [isProcessing, setIsProcessing] = useState(false);
  const [currentPrompt, setCurrentPrompt] = useState(
    studioTaskData?.studioTask?.payload?.positivePrompt || '',
  );
  const [isPattern, setIsPattern] = useState(false);

  const location = useLocation();

  useEffect(() => {
    if (location.pathname.includes('home')) {
      setOpenTools(false);
    }
  }, [location.pathname]);

  useEffect(() => {
    setIsProcessing(
      studioTaskData?.studioTask?.status === 'PENDING_GENERATION' ||
        studioTaskLoading ||
        processStudioTaskLoading,
    );
  }, [
    studioTaskData?.studioTask?.status,
    studioTaskLoading,
    processStudioTaskLoading,
  ]);

  useEffect(() => {
    if (studioTaskData?.studioTask.status !== 'COMPLETED') {
      setCurrentPrompt(positivePrompt);
    }
  }, [studioTaskData?.studioTask?.status]);

  useEffect(() => {
    const patternTypes = [
      'GENERATE_PATTERN',
      'PATTERN_TILE',
      'CREATE_PATTERN_VARIANTS',
    ];
    const nonPatternTypes = [
      'GENERATE_APPAREL',
      'REALIZE_SKETCH',
      'GENERATE_GRAPHIC',
    ];

    const isPatternType =
      patternTypes.includes(studioTaskData?.studioTask?.generateTaskType) ||
      refSoloItemType === 'PATTERN' ||
      createItem.type === 'GENERATE_PATTERN';

    const isNonPatternType = nonPatternTypes.includes(createItem.type);

    setIsPattern((isPattern) => {
      if (isPatternType) return true;
      if (isNonPatternType) return false;
      return isPattern;
    });
  }, [
    studioTaskData?.studioTask?.generateTaskType,
    refSoloItemType,
    createItem,
  ]);

  useEffect(() => {
    const newPrompt = studioTaskData?.studioTask?.payload?.positivePrompt || '';
    if (newPrompt !== currentPrompt) {
      setCurrentPrompt(newPrompt);
    }
  }, [studioTaskData?.studioTask?.payload?.positivePrompt, currentPrompt]);

  return (
    <div
      className={cn(
        'flex h-full w-full items-start justify-center md:justify-start',
        !openTools && 'hidden',
      )}
    >
      <div className="flex h-full w-full max-w-[36rem] flex-col items-start gap-6 p-6">
        <div className="flex w-full flex-col gap-4 pt-2">
          <h2 className="text-2xl font-bold leading-6 text-night" inert="">
            {[
              'GENERATE_PATTERN',
              'PATTERN_TILE',
              'CREATE_PATTERN_VARIANTS',
            ].includes(studioTaskData?.studioTask?.generateTaskType) ||
            refSoloItemType === 'PATTERN' ||
            createItem.type === 'GENERATE_PATTERN'
              ? 'Pattern Tools'
              : 'Design Tools'}
          </h2>
        </div>
        <StudioToolsGrid
          isPattern={isPattern}
          generatedTaskType={studioTaskData?.studioTask?.generateTaskType}
          entityType={studioTaskData?.studioTask?.images?.[0]?.entityType}
        />
        <div className="flex min-h-0 w-full flex-col gap-4">
          {(studioTaskData?.studioTask?.payload?.positivePrompt ||
            !isProcessing) && (
            <div className="flex w-full flex-col gap-1">
              <h3 className="text-lg font-bold leading-8 text-night">Prompt</h3>
              <p
                className={cn(
                  'text-sm text-night min-h-6 rounded',
                  // isProcessing && 'ghost w-full min-w-32 h-12',
                )}
              >
                {currentPrompt}
              </p>
            </div>
          )}
          {!isProcessing && (
            <div className="flex w-full flex-col gap-2">
              <p className="text-xs font-bold text-secondary">
                {dayjs(studioTaskData?.studioTask?.createdAt).format(
                  'D MMMM YYYY',
                )}
              </p>
            </div>
          )}
          {studioTaskData?.studioTask?.payload?.referenceImages?.length > 0 && (
            <div className="flex w-full flex-col gap-1">
              <h3 className="text-lg font-bold leading-8 text-night">
                Reference Image
              </h3>
              <img
                src={studioTaskData.studioTask.payload.referenceImages[0].url}
                alt="Reference Image"
                className={cn(
                  'rounded max-h-60 w-max',
                  studioTaskData?.studioTask?.payload?.orientation ===
                    'LANDSCAPE'
                    ? 'aspect-[4/3]'
                    : studioTaskData?.studioTask?.payload?.orientation ===
                        'PORTRAIT'
                      ? 'aspect-[3/4]'
                      : 'aspect-square',
                )}
              />
            </div>
          )}
          {studioTaskData?.studioTask?.payload?.image && (
            <div className="flex w-full flex-col gap-1">
              <h3 className="text-lg font-bold leading-8 text-night">
                Reference Image
              </h3>
              <img
                src={studioTaskData.studioTask.payload.image.url}
                alt="Reference Image"
                className={cn(
                  'rounded max-h-60 w-max',
                  studioTaskData?.studioTask?.payload?.orientation ===
                    'LANDSCAPE'
                    ? 'aspect-[4/3]'
                    : studioTaskData?.studioTask?.payload?.orientation ===
                        'PORTRAIT'
                      ? 'aspect-[3/4]'
                      : 'aspect-square',
                )}
              />
            </div>
          )}
          {(studioTaskData?.studioTask?.payload?.orientation ||
            !isProcessing) && (
            <div className="flex w-full flex-col gap-1">
              <h3 className="text-lg font-bold leading-8 text-night">Size</h3>
              <p
                className={cn(
                  'text-sm font-bold text-secondary h-6 rounded',
                  // isProcessing && 'ghost w-1/2',
                )}
              >
                {studioTaskData?.studioTask?.payload.orientation}
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
