import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import dayjs from 'dayjs';
import { Copy } from 'lucide-react';

import {
  FASHION_WEEK_ITEM,
  GET_STUDIO_ITEM,
  SOCIAL_MEDIA_ITEM,
  UPLOAD_ITEM_IMAGE,
} from '@/api/studio/queries';
import { Dialog, DialogContent } from '@/components/ui/dialog';
import { Separator } from '@/components/ui/separator';
import { STUDIO_DISPLAY } from '@/constants/studio';
import { useStudioContext } from '@/contexts/StudioContext';
import { cn, downloadFile } from '@/helpers/utils';
import { useMediaQuery } from '@/hooks/useMediaQuery';
import { useToast } from '@/hooks/useToast';
import { SaveToCollection } from '@/layouts/common/index';

import { AsideBar, Collections } from './components/AsidebarMenu';
import {
  StudioExploreSideBar,
  StudioHelpSideBar,
} from './components/StudioExploreSideBar';
import { StudioHistorySidebar } from './components/StudioHistorySidebar';
import { ImageDisplay } from './StudioGeneration';
import { StudioHome } from './StudioHome';
import { StudioLeftSidebar } from './StudioLeftSidebar';
import { StudioTools } from './StudioTools';

export const StudioLayout = () => {
  const navigate = useNavigate();

  const location = useLocation();

  const {
    activeModal,
    setActiveModal,
    updateCreateItemDetails,
    setGeneratedImages,
    studioTaskData,
    orientation,
    openTools,
    setSelectedGeneratedImage,
    setReferencePattern,
    setReferenceImage,
    setDisplayMode,
    setReferenceSketch,
    setRefSoloItemType,
    studioTask,
    setStudioTask,
    similarImagesData,
    similarImagesLoading,
    similarImagesError,
    setReferenceGraphic,
    setReferenceOtherImage,
  } = useStudioContext();

  const [displayImages, setDisplayImages] = useState([]);

  const isHome = location.pathname.includes('home');
  const searchParams = new URLSearchParams(location.search);
  const isEditing = searchParams.has('generationType');

  const [similarImagesLocalError, setSimilarImagesLocalError] = useState(null);

  useEffect(() => {
    setGeneratedImages([]);
    setDisplayMode(STUDIO_DISPLAY.PENDING);
    setSelectedGeneratedImage(-1);
    setReferencePattern(null);
    setReferenceImage(null);
    setReferenceGraphic(null);
    setReferenceSketch(null);
    setReferenceOtherImage(null);
  }, [isEditing]);

  useEffect(() => {
    if (searchParams.has('isGenerating')) {
      setGeneratedImages([]);
    }
  }, [searchParams.has('isGenerating')]);

  useEffect(() => {
    setSimilarImagesLocalError(similarImagesError);
  }, [similarImagesData]);

  const validParams = [
    'isEditing',
    'taskId',
    'itemId',
    'itemType',
    'isGenerating',
  ];

  const areRequiredParamsMissing = validParams.some((param) =>
    searchParams.has(param),
  );

  useEffect(() => {
    if (
      !areRequiredParamsMissing &&
      !location.pathname.includes('studio/image-preview')
    ) {
      navigate('/studio/home');
    }
  }, [areRequiredParamsMissing]);

  useEffect(() => {
    if (studioTask?.images?.length > 0) {
      setDisplayImages(studioTask.images);
    }
  }, [studioTask?.images]);

  const { loading: fashionWeekItemLoading } = useQuery(FASHION_WEEK_ITEM, {
    variables: {
      itemId: searchParams.get('itemId'),
    },
    skip:
      searchParams.get('itemType') !== 'fashionWeekItem' ||
      !searchParams.has('itemId'),
    onCompleted: (data) => {
      const imageToLoad = new window.Image();
      imageToLoad.src = data.fashionWeekItem.photo.image.url;
      imageToLoad.onload = () => {
        setReferenceImage({
          ...data.fashionWeekItem.photo.image,
          width: imageToLoad.naturalWidth,
          height: imageToLoad.naturalHeight,
          studioItemId: data.fashionWeekItem.photo.id,
          entityType: 'FASHION_WEEK_ITEM',
          entityId: data.fashionWeekItem.photo.id,
        });

        setStudioTask({
          ...studioTask,
          status: 'COMPLETED',
          images: [
            {
              ...data.fashionWeekItem.photo.image,
              studioItemId: data.fashionWeekItem.photo.id,
              entityType: 'FASHION_WEEK_ITEM',
              entityId: data.fashionWeekItem.photo.id,
              width: imageToLoad.naturalWidth,
              height: imageToLoad.naturalHeight,
            },
          ],
        });
      };

      setSelectedGeneratedImage(-1);
      setRefSoloItemType('APPAREL');
      if (searchParams.get('generationType') === 'pattern') {
        setRefSoloItemType('PATTERN');
      }
      setDisplayMode(STUDIO_DISPLAY.PREVIEW);
    },
  });

  const { loading: socialMediaItemLoading } = useQuery(SOCIAL_MEDIA_ITEM, {
    variables: {
      postId: searchParams.get('itemId'),
    },
    skip:
      searchParams.get('itemType') !== 'socialMediaItem' ||
      !searchParams.has('itemId'),
    onCompleted: (data) => {
      setStudioTask({
        ...studioTask,
        status: 'COMPLETED',
        images: [
          {
            ...data.post.image,
            studioItemId: data.post.id,
            entityType: 'SOCIAL_MEDIA_ITEM',
            entityId: data.post.id,
          },
        ],
      });

      setReferenceImage({
        entityType: 'SOCIAL_MEDIA_ITEM',
        entityId: data.post.id,
        url: data.post.image.url,
      });

      setSelectedGeneratedImage(-1);
      setRefSoloItemType('APPAREL');
      if (searchParams.get('generationType') === 'pattern') {
        setRefSoloItemType('PATTERN');
      }
      setDisplayMode(STUDIO_DISPLAY.PREVIEW);
    },
  });

  const { loading: uploadItemImageLoading } = useQuery(UPLOAD_ITEM_IMAGE, {
    variables: {
      uploadItemImageId: searchParams.get('itemId'),
    },
    skip:
      searchParams.get('itemType') !== 'fileUploadItem' ||
      !searchParams.has('itemId'),
    onCompleted: (data) => {
      const imageToLoad = new window.Image();
      imageToLoad.src = data.uploadItemImage.node.image.url;
      imageToLoad.onload = () => {
        setStudioTask({
          ...studioTask,
          status: 'COMPLETED',
          images: [
            {
              ...data.uploadItemImage.node.image,
              studioItemId: data.uploadItemImage.node.id,
              entityType: 'FILE_UPLOAD',
              entityId: data.uploadItemImage.node.id,
              width: imageToLoad.naturalWidth,
              height: imageToLoad.naturalHeight,
            },
          ],
        });

        if (data.uploadItemImage.node.fileEntityType === 'PATTERN') {
          setReferencePattern({
            ...data.uploadItemImage.node.image,
            studioItemId: data.uploadItemImage.node.id,
            entityType: 'FILE_UPLOAD',
            entityId: data.uploadItemImage.node.id,
            width: imageToLoad.naturalWidth,
            height: imageToLoad.naturalHeight,
          });
          setRefSoloItemType('PATTERN');
        } else if (data.uploadItemImage.node.fileEntityType === 'APPAREL') {
          setReferenceImage({
            ...data.uploadItemImage.node.image,
            studioItemId: data.uploadItemImage.node.id,
            entityType: 'FILE_UPLOAD',
            entityId: data.uploadItemImage.node.id,
            width: imageToLoad.naturalWidth,
            height: imageToLoad.naturalHeight,
          });
          setRefSoloItemType('APPAREL');
        } else if (data.uploadItemImage.node.fileEntityType === 'SKETCH') {
          setReferenceSketch({
            ...data.uploadItemImage.node.image,
            studioItemId: data.uploadItemImage.node.id,
            entityType: 'FILE_UPLOAD',
            entityId: data.uploadItemImage.node.id,
            width: imageToLoad.naturalWidth,
            height: imageToLoad.naturalHeight,
          });
          setRefSoloItemType('SKETCH');
        } else if (data.uploadItemImage.node.fileEntityType === 'GRAPHIC') {
          setReferenceGraphic({
            ...data.uploadItemImage.node.image,
            studioItemId: data.uploadItemImage.node.id,
            entityType: 'FILE_UPLOAD',
            entityId: data.uploadItemImage.node.id,
            width: imageToLoad.naturalWidth,
            height: imageToLoad.naturalHeight,
          });
          setRefSoloItemType('GRAPHIC');
        }
      };

      setSelectedGeneratedImage(-1);
      setDisplayMode(STUDIO_DISPLAY.PREVIEW);
    },
  });

  const { loading: studioItemLoading } = useQuery(GET_STUDIO_ITEM, {
    variables: {
      id: searchParams.get('itemId'),
    },
    skip:
      searchParams.get('itemType') !== 'studioItem' ||
      !searchParams.has('itemId'),
    onCompleted: (data) => {
      setStudioTask({
        ...studioTask,
        status: 'COMPLETED',
        images: [
          {
            ...data.studioItem.node.image,
            width: data.studioItem.node.image.width,
            height: data.studioItem.node.image.height,
            studioItemId: data.studioItem.node.id,
            entityType: 'STUDIO_ITEM',
            entityId: data.studioItem.node.id,
            generateTaskType: data.studioItem.node.generateTaskType,
          },
        ],
      });

      setSelectedGeneratedImage(-1);
      setDisplayMode(STUDIO_DISPLAY.PREVIEW);

      if (
        [
          'GENERATE_APPAREL',
          'IMAGE_BACKGROUND_REMOVER',
          'ZOOM_IN_IMAGE',
          'CREATE_VARIANTS',
        ].includes(data.studioItem.node.generateTaskType)
      ) {
        setReferenceImage({
          ...data.studioItem.node.image,
          studioItemId: data.studioItem.node.id,
          entityType: 'STUDIO_ITEM',
          entityId: data.studioItem.node.id,
        });
        setRefSoloItemType('APPAREL');
        if (searchParams.get('generationType') === 'pattern') {
          setRefSoloItemType('PATTERN');
        }
      } else if (
        ['GENERATE_PATTERN', 'PATTERN_TILE'].includes(
          data.studioItem.node.generateTaskType,
        )
      ) {
        setReferencePattern({
          ...data.studioItem.node.image,
          studioItemId: data.studioItem.node.id,
          entityType: 'STUDIO_ITEM',
          entityId: data.studioItem.node.id,
        });
        setRefSoloItemType('PATTERN');
      } else if (data.studioItem.node.generateTaskType === 'REALIZE_SKETCH') {
        setReferenceSketch({
          ...data.studioItem.node.image,
          studioItemId: data.studioItem.node.id,
          entityType: 'STUDIO_ITEM',
          entityId: data.studioItem.node.id,
        });
        setReferenceImage({
          ...data.studioItem.node.image,
          studioItemId: data.studioItem.node.id,
          entityType: 'STUDIO_ITEM',
          entityId: data.studioItem.node.id,
        });
        setRefSoloItemType('SKETCH');
      }
    },
  });

  const isMobile = useMediaQuery({ size: 'md' });

  const shouldShowSimilarImages = !(
    studioTaskData?.studioTask?.generateTaskType ===
      'IMAGE_BACKGROUND_REMOVER' || searchParams.has('generationType')
  );

  const isLoading =
    similarImagesLoading ||
    socialMediaItemLoading ||
    uploadItemImageLoading ||
    fashionWeekItemLoading ||
    studioItemLoading;

  return (
    <div className="flex h-full w-full flex-row items-start justify-start">
      {/* Sidebar */}
      <StudioLeftSidebar />
      {/* Complement of Sidebar */}
      <div className="relative grid h-full w-full grid-cols-[1fr,7rem] overflow-hidden">
        {/* Main Section */}
        <div className="flex flex-col overflow-auto border-r border-secondary/50 bg-[#FAF8F8] py-6">
          {/* Home Page */}
          {isHome && <StudioHome handleChange={updateCreateItemDetails} />}
          {/* Create an Apparel Page */}
          {!isHome && (
            <div
              className={cn(
                'grid h-max grid-cols-[1fr,0.8fr] px-20 sm:px-4 md:px-8 lg:px-20',
                openTools && 'grid-cols-1',
                isMobile && 'grid-cols-1',
              )}
            >
              <div
                className={cn(
                  'grid h-full grid-cols-1 md:grid-cols-[1fr,0.7fr]',
                  !openTools && 'grid-cols-1 md:grid-cols-1',
                )}
              >
                <div className="relative top-0 h-max w-full md:sticky">
                  <ImageDisplay
                    generatedImages={displayImages}
                    orientation={orientation}
                    downloadFile={downloadFile}
                  />
                </div>
                {activeModal === null && openTools && <StudioTools />}
              </div>

              {shouldShowSimilarImages && (
                <div className="container mx-auto h-full w-full p-6">
                  <div
                    className={cn('flex w-full flex-col gap-y-6')}
                    id="more-ideas"
                  >
                    <h2 className="text-2xl font-bold leading-6 text-primary">
                      More ideas to explore
                    </h2>

                    <SimilarImagesGrid
                      loading={isLoading || studioTask?.status === 'PENDING'}
                      error={similarImagesLocalError}
                      images={similarImagesData?.studioSimilarImages}
                      openTools={openTools}
                      navigate={navigate}
                    />
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
        {/* AsideBar */}
        <AsideBar
          activeModal={activeModal}
          setActiveModal={(modal) =>
            setActiveModal(modal === activeModal ? null : modal)
          }
        />
        {/* Collection Modal */}
        {activeModal === 'collections' && (
          <Collections onClose={() => setActiveModal(null)} />
        )}
        {activeModal === 'history' && (
          <StudioHistorySidebar
            activeModal={activeModal}
            onClose={() => setActiveModal(null)}
          />
        )}
        {activeModal === 'designs' && (
          <StudioExploreSideBar
            activeModal={activeModal}
            onClose={() => setActiveModal(null)}
          />
        )}
        {activeModal === 'patterns' && (
          <StudioExploreSideBar
            activeModal={activeModal}
            onClose={() => setActiveModal(null)}
          />
        )}
        {activeModal === 'sketches' && (
          <StudioExploreSideBar
            activeModal={activeModal}
            onClose={() => setActiveModal(null)}
          />
        )}
        {activeModal === 'graphics' && (
          <StudioExploreSideBar
            activeModal={activeModal}
            onClose={() => setActiveModal(null)}
          />
        )}

        {activeModal === 'help' && (
          <StudioHelpSideBar
            activeModal={activeModal}
            onClose={() => setActiveModal(null)}
          />
        )}
      </div>
    </div>
  );
};

const SimilarImagesGrid = ({ loading, error, images, openTools, navigate }) => {
  const { studioTask } = useStudioContext();
  const [openModal, setOpenModal] = useState(null);
  const toast = useToast();

  if (error) {
    return (
      <div className="flex w-full items-center justify-center rounded-lg bg-gray-50">
        <div className="text-center">
          <p className="text-base font-semibold text-gray-900">
            Failed to load more ideas
          </p>
          <p className="mt-1 text-sm text-gray-500">
            Please try refreshing the page
          </p>
        </div>
      </div>
    );
  }

  if (loading || !images) {
    return (
      <div
        className={cn(
          `mb-20 grid w-full max-w-[60rem] grid-cols-3 gap-2 sm:grid-cols-4 md:grid-cols-3 md:gap-4 lg:gap-6`,
          openTools && 'grid-cols-4',
        )}
      >
        {Array.from({ length: 18 }).map((_, index) => (
          <div
            key={index}
            className="aspect-[3/4] animate-pulse rounded bg-gray-200"
          />
        ))}
      </div>
    );
  }

  if (
    studioTask?.status === 'COMPLETED' &&
    (!images || !images.length || images.length === 0)
  ) {
    return (
      <div className="flex w-full items-start justify-start rounded-lg bg-gray-50">
        <p className="text-base font-bold text-black">
          No similar images found
        </p>
      </div>
    );
  }

  return (
    <div
      className={cn(
        `mb-20 grid w-full max-w-[60rem] grid-cols-3 gap-2 sm:grid-cols-4 md:grid-cols-3 md:gap-4 lg:gap-6`,
        location.pathname.includes('image-preview') && 'mb-0',
        openTools && 'grid-cols-4',
      )}
    >
      {images.map((data, index) => {
        const startIndex = data.node.image.url.indexOf('/v3');
        const endIndex = data.node.image.url.indexOf('.jpg') + 4;
        const extractedPath = data.node.image.url.slice(startIndex, endIndex);
        const newUrl = `https://assets.tfashion.ai${extractedPath}?format=auto`;

        return (
          <div key={index}>
            {openModal === data.node.id && (
              <Dialog open={true} onOpenChange={() => setOpenModal(null)}>
                <DialogContent preview>
                  <div className="flex h-full max-h-[90vh] w-full gap-x-4 overflow-hidden">
                    <div className="flex basis-3/5 items-center">
                      <div className="relative h-full w-full">
                        <img
                          className="h-full w-full object-contain"
                          src={
                            import.meta.env.VITE_APP_ENV === 'development'
                              ? newUrl
                              : data?.node?.image?.url
                          }
                          alt={data?.node?.image?.studioItemId}
                        />
                      </div>
                    </div>
                    <Separator
                      orientation="vertical"
                      className="mx-2 h-full bg-gray-300 text-black"
                    />
                    <div className="flex basis-2/5 flex-col items-start gap-y-8 overflow-y-auto pb-40 pr-6">
                      <div className="flex flex-row justify-start gap-x-4">
                        <SaveToCollection id={data.node.id} type={'studioItem'}>
                          {(loading) =>
                            loading ? (
                              <button className="rounded-lg bg-secondary px-4 py-2 font-bold text-white outline-none focus:outline-none focus:ring-0 focus-visible:ring-0">
                                Save
                              </button>
                            ) : (
                              <button className="self-end rounded border-3 border-primary bg-primary px-4 py-2 text-sm font-bold text-white outline-none hover:border-primaryHover hover:bg-primaryHover hover:text-white focus:outline-none focus:ring-0 focus-visible:ring-0">
                                Save
                              </button>
                            )
                          }
                        </SaveToCollection>
                        <button
                          onClick={() => {
                            const studioParams = new URLSearchParams();
                            studioParams.append('itemId', data.node.id);
                            studioParams.append('itemType', 'studioItem');
                            studioParams.append('generationType', 'edit');
                            navigate(`/studio?${studioParams.toString()}`);
                            setOpenModal(null);
                          }}
                          className="self-end rounded border-3 border-primary bg-white px-4 py-2 text-sm font-bold text-primary outline-none hover:border-primaryHover hover:bg-primary hover:text-white focus:outline-none focus:ring-0 focus-visible:ring-0"
                        >
                          Edit with AI Tools
                        </button>
                      </div>
                      {data?.node?.payload && (
                        <div>
                          <div className="flex flex-col gap-y-4">
                            <h2 className="text-4xl font-semibold">
                              Generated In Studio
                            </h2>
                          </div>
                          <div className="flex flex-col gap-y-2">
                            <h3 className="mt-4 text-lg font-bold leading-[1.125rem] text-night">
                              Prompt
                            </h3>
                            <div className="group relative">
                              <button
                                className="absolute right-2 top-2 hidden rounded-md p-1 hover:bg-gray-100 group-hover:block"
                                onClick={() => {
                                  navigator.clipboard.writeText(
                                    data?.node?.payload?.positivePrompt,
                                  );
                                  toast.createToast({
                                    message: 'Prompt copied to clipboard',
                                    type: 'success',
                                    showCloseButton: true,
                                    position: 'top-right',
                                  });
                                }}
                                title="Copy prompt"
                              >
                                <Copy size={16} />
                              </button>
                              <p
                                className="max-w-[40rem] cursor-pointer text-sm font-normal text-night"
                                onClick={() => {
                                  navigator.clipboard.writeText(
                                    data?.node?.payload?.positivePrompt,
                                  );
                                  toast.createToast({
                                    message: 'Prompt copied to clipboard',
                                    type: 'success',
                                    showCloseButton: true,
                                    position: 'top-right',
                                  });
                                }}
                              >
                                {data?.node?.payload?.positivePrompt}
                              </p>
                            </div>
                            <p className="text-sm font-bold text-secondary">
                              {dayjs(data?.node?.createdAt).format(
                                'MMM DD, YYYY',
                              )}
                            </p>
                          </div>
                          <div className="flex flex-col gap-y-2">
                            <h3 className="mt-4 text-lg font-bold leading-[1.125rem] text-night">
                              Size
                            </h3>
                            <p className="text-sm font-normal capitalize text-night">
                              {data?.node?.payload?.orientation?.value?.toLowerCase() ||
                                'Portrait'}
                            </p>
                          </div>
                          {data?.node?.payload?.visibility === 'PRIVATE' ? (
                            <div className="mt-4 flex flex-col gap-y-2">
                              <h3 className="text-base font-bold leading-[1.125rem] text-night">
                                Generated by
                              </h3>
                              <p className="text-sm font-normal text-night">
                                <span className="font-normal text-night">
                                  {data?.node?.createdBy?.fullName}
                                </span>
                              </p>
                            </div>
                          ) : (
                            <div className="mt-4 flex flex-col gap-y-2">
                              <p className="text-sm font-bold text-night">
                                Publicly Generated
                              </p>
                            </div>
                          )}
                        </div>
                      )}
                      {!data?.node?.payload && (
                        <div className="flex flex-col gap-y-4">
                          <h2 className="text-4xl font-semibold">
                            This is an Uploaded Image
                          </h2>
                        </div>
                      )}
                    </div>
                  </div>
                </DialogContent>
              </Dialog>
            )}
            <div
              key={index}
              className="aspect-[3/4] cursor-pointer rounded transition-transform hover:scale-105 hover:shadow"
              onClick={() => setOpenModal(data.node.id)}
            >
              <img
                src={
                  import.meta.env.VITE_APP_ENV === 'development'
                    ? newUrl
                    : data.node.image.url
                }
                onError={(e) => {
                  if (e.target.src !== data.node.image.url) {
                    e.target.src = data.node.image.url;
                  }
                  e.target.onerror = null;
                }}
                alt={data.node.image.studioItemId}
                className="h-full w-full rounded object-cover"
              />
            </div>
          </div>
        );
      })}
    </div>
  );
};
