import React, { useRef, useState } from 'react';
import { RgbaColorPicker } from 'react-colorful';
import Highlighter from 'react-highlight-words';
import { RxCross1 } from 'react-icons/rx';
import { useQuery } from '@apollo/client';
import { debounce } from 'lodash';

import { GET_SIMILAR_TONES } from '@/api/explore/queries';
import { Button } from '@/components/Button';
import { FormInput, FormLabel } from '@/components/FormInput';
import { Heading } from '@/components/Heading';
import {
  ButtonSelect,
  DropdownSelect,
  DropdownSelectItem,
} from '@/components/Select/index';
import { SelectDropdown } from '@/components/SelectDropdown';
import { Sidebar } from '@/components/Sidebar';
import { Spacing } from '@/components/Spacing';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/components/ui/accordion';
import { useExploreContext } from '@/contexts/ExploreContext';
import { cn } from '@/helpers/utils';
import {
  FEATURE_FLAGS,
  POSTHOG_FEATURE_FLAGS,
  useFeatureFlag,
} from '@/hooks/useFeatureFlag';

function FiltersHeading() {
  const { clearAll, isExploreMobile, setIsFiltersOpen } = useExploreContext();

  return (
    <div
      className={cn(
        'flex justify-between',
        isExploreMobile
          ? 'sticky top-0 bg-white py-2 items-start'
          : 'mb-4 items-end',
      )}
    >
      <Heading
        size={isExploreMobile ? 'base' : 'lg'}
        className={cn(!isExploreMobile && 'font-bold')}
      >
        Filter
      </Heading>

      <div className="flex h-full flex-col items-center gap-4">
        {isExploreMobile && (
          <Button
            variant={'ghost'}
            size={'xs'}
            className={'mb-2 p-0'}
            onClick={() => setIsFiltersOpen(false)}
          >
            <RxCross1 size={28} />
          </Button>
        )}

        <Button
          variant={'link'}
          size={'xs'}
          className={'mb-2 p-0'}
          onClick={() => clearAll()}
        >
          Clear All
        </Button>
      </div>
    </div>
  );
}

export function ExploreFashionWeekSearch() {
  const {
    fashionWeekFilters,
    handleFashionWeekFiltersOnChange,
    loadFashionWeekSearchOptions,
  } = useExploreContext();

  const searchFilter = fashionWeekFilters?.search;

  const debouncedLoadOptions = debounce(loadFashionWeekSearchOptions, 1200);

  return (
    <>
      <Heading size={'xl'}>Search</Heading>
      <Spacing size={1.125} />
      <FormInput>
        <SelectDropdown
          placeholder={searchFilter?.placeholder}
          isSearchable={searchFilter?.isSearchable}
          options={searchFilter?.options}
          multiselect={false}
          isClearable={searchFilter?.isClearable}
          value={searchFilter?.value}
          key={'search'}
          onChange={(e) => handleFashionWeekFiltersOnChange(e, 'search')}
          isAsync={true}
          isCreatable={true}
          formatCreateLabel={(inputValue) => `${inputValue}`}
          cacheOptions={false}
          loadOptions={debouncedLoadOptions}
          defaultOptions
          allowCreateWhileLoading={true}
          createOptionPosition={'first'}
          noOptionsMessage={() => 'No results found'}
          components={{
            DropdownIndicator: () => null,
            IndicatorSeparator: () => null,
            Option: ({ selectProps, innerProps, innerRef, data, ...props }) => {
              return (
                <div
                  ref={innerRef}
                  {...innerProps}
                  className={props.getClassNames(props.type, props)}
                >
                  <Highlighter
                    highlightClassName="bg-transparent"
                    unhighlightClassName="font-semibold"
                    searchWords={[selectProps.inputValue]}
                    autoEscape={true}
                    textToHighlight={data.label}
                  />
                </div>
              );
            },
          }}
        />
      </FormInput>
    </>
  );
}
/* eslint-disable-next-line no-unused-vars */
function ToneSelect({
  filter,
  filterKey,
  onChange,
  currentValue,
  isClearable = false,
}) {
  const [selectedTone, setSelectedTone] = useState({
    r: 0,
    g: 0,
    b: 0,
    a: 1,
  });
  const [showTextSearch, setShowTextSearch] = useState(false);
  const [textSearch, setTextSearch] = useState('');

  const { isExploreMobile } = useExploreContext();

  const { data, loading } = useQuery(GET_SIMILAR_TONES, {
    variables: {
      tone: [selectedTone?.r, selectedTone?.g, selectedTone?.b],
      textSearch,
    },
    skip: !selectedTone && !textSearch,
  });

  const triggerRef = useRef(null);

  return (
    <AccordionItem value={'tone'}>
      <FormLabel isMobile={isExploreMobile}>{filter?.label}</FormLabel>
      <AccordionTrigger
        ref={triggerRef}
        className={cn(
          'flex items-center mt-2 rounded-md border-[#1A1A1A] bg-white px-4 py-2 text-[.875rem] leading-5 outline-none font-normal border',
          filter.value ? 'text-charcoal capitalize' : 'text-[#A1A1A1]',
          isClearable && filter.value ? '[&>svg]:hidden' : '',
        )}
      >
        <div className="flex w-full items-center justify-between">
          <div className="flex items-center gap-2">
            {filter?.value?.hex && (
              <div
                className="h-4 w-4 rounded-full"
                style={{
                  background: filter?.value?.hex,
                }}
              />
            )}
            <span
              className={cn(
                'line-clamp-1 max-w-fit text-left capitalize leading-5',
                isExploreMobile && 'text-base leading-7',
              )}
            >
              {filter?.value?.label || filter?.placeholder}
            </span>
          </div>
          {filter.value && isClearable && (
            <button
              onClick={(e) => {
                e.stopPropagation();
                onChange(null, filterKey);
              }}
              className="ml-2 text-black"
            >
              <RxCross1 size={12} />
            </button>
          )}
        </div>
      </AccordionTrigger>
      <AccordionContent className="mt-2 flex flex-col items-center space-y-2">
        <div
          className={cn(
            'tone-picker w-full gap-4 px-2',
            showTextSearch ? 'flex-col' : 'flex',
          )}
        >
          {showTextSearch ? (
            <div className="mb-2 flex w-full items-center gap-2 rounded border border-charcoal p-2">
              <input
                className="w-full text-[.875rem] leading-5 outline-none"
                value={textSearch}
                onChange={(e) => setTextSearch(e.target.value)}
                placeholder="Color, Pantone Code or Pantone Name"
              />
            </div>
          ) : (
            <RgbaColorPicker
              onChange={(color) => setSelectedTone(color)}
              value={selectedTone}
            />
          )}

          <div className="flex shrink-0 flex-col">
            {data && (
              <h1 className="ml-2 text-sm font-bold leading-5">
                Select A Pantone Color
              </h1>
            )}

            {loading && (
              <div className="space-y-2">
                {Array.from({ length: 3 }).map((_, i) => (
                  <div
                    key={i}
                    className="flex animate-pulse items-center gap-2 rounded border bg-gray-100 p-2"
                  >
                    <div className="h-4 w-4 rounded-full " />
                    <span className="text-sm leading-5 text-transparent">
                      Pantone TCX 00-0000
                    </span>
                  </div>
                ))}
              </div>
            )}

            {data?.exploreSimilarTones.map((tone) => (
              <div
                key={tone.code}
                className={cn(
                  'flex cursor-pointer items-center gap-2 p-2 border rounded hover:border-charcoal group transition-colors',
                  tone.code === currentValue?.code
                    ? 'border-charcoal'
                    : 'border-transparent',
                )}
                onClick={() => {
                  onChange(
                    {
                      ...tone,
                      label: `${tone.name.replace(/-/g, ' ')} - ${tone.code}`,
                    },
                    filterKey,
                  );
                  triggerRef?.current?.click();
                }}
              >
                <div
                  className="h-4 w-4 rounded-full"
                  style={{
                    background: tone.hex,
                  }}
                />
                <span
                  className={cn(
                    'text-sm leading-5 group-hover:text-charcoal transition-colors',
                    tone.code === currentValue?.code
                      ? 'text-charcoal'
                      : 'text-[#A1A1A1]',
                  )}
                >
                  Pantone TCX {tone.code}
                </span>
              </div>
            ))}
          </div>
        </div>

        <span
          className="cursor-pointer text-xs text-primary hover:underline"
          onClick={() => setShowTextSearch(!showTextSearch)}
        >
          {showTextSearch
            ? '... or pick a color'
            : '... or search by color name'}
        </span>
      </AccordionContent>
    </AccordionItem>
  );
}

export function ExploreSocialMediaFilters() {
  const { isEnabled: exploreAdvanced } = useFeatureFlag(
    FEATURE_FLAGS.EXPLORE_ADVANCED,
  );

  const {
    socialMediaFilters,
    handleSocialMediaFiltersOnChange,
    isExploreMobile,
    setIsGenderTouched,
  } = useExploreContext();

  if (!exploreAdvanced) {
    delete socialMediaFilters.personCount;
    delete socialMediaFilters.personPosition;
  }
  // const selectedTones = socialMediaFilters.color?.value || [];
  return (
    <div
      className={cn(
        'flex flex-col',
        isExploreMobile
          ? 'h-[calc(100vh-8rem)] overflow-auto no-scrollbar'
          : 'h-max',
      )}
    >
      {!isExploreMobile && <Spacing size={1.25} />}
      <FiltersHeading />

      <div className={cn('flex flex-col', isExploreMobile && 'px-1')}>
        <DropdownSelect collapsible className="space-y-4">
          {Object.entries(socialMediaFilters).map(([filterKey, filter], i) => {
            // Calculate total length including nested arrays
            const optionsCount =
              filter.options?.reduce((total, option) => {
                if (Array.isArray(option.options)) {
                  return total + option.options.length;
                }
                return total + 1;
              }, 0) || 0;

            const labelWithCount =
              import.meta.env.VITE_APP_ENV === 'development'
                ? `${filter.label} (${optionsCount})`
                : filter.label;
            const filterWithCount = { ...filter, label: labelWithCount };

            if (filterKey === 'attribute.custom') {
              return (
                <DropdownSelectItem
                  key={i}
                  filter={filterWithCount}
                  filterKey={filterKey}
                  onChange={handleSocialMediaFiltersOnChange}
                  isClearable={true}
                  variant="collapsible"
                  isExcludable={true}
                />
              );
            }
            if (
              filterKey !== 'search' &&
              filterKey !== 'audience' &&
              filterKey !== 'tone' &&
              filterKey !== 'personCount' &&
              filterKey !== 'personPosition' &&
              filterKey !== 'color'
            ) {
              const additionalProps =
                filterKey === 'market'
                  ? { isLoading: !filter.options?.length }
                  : {};

              return filter.button ? (
                <ButtonSelect
                  key={i}
                  filter={filterWithCount}
                  filterKey={filterKey}
                  onChange={(value, key) => {
                    handleSocialMediaFiltersOnChange(value, key);
                    if (key === 'gender') {
                      setIsGenderTouched(true);
                    }
                  }}
                  currentValue={filter?.value?.value}
                />
              ) : (
                <DropdownSelectItem
                  key={i}
                  filter={filterWithCount}
                  filterKey={filterKey}
                  onChange={(value, key) => {
                    handleSocialMediaFiltersOnChange(value, key);
                    if (key === 'gender') {
                      setIsGenderTouched(true);
                    }
                  }}
                  isClearable={true}
                  variant="collapsible"
                  isExcludable={filter.isExcludable}
                  {...additionalProps}
                />
              );
            }

            return null;
          })}

          {useFeatureFlag(
            POSTHOG_FEATURE_FLAGS.EXPLORE_SOCIAL_MEDIA_COLOR_TONE_FILTER,
            'posthog',
          ) && (
            <ToneSelect
              filter={socialMediaFilters.tone}
              filterKey={'tone'}
              onChange={handleSocialMediaFiltersOnChange}
              currentValue={socialMediaFilters.tone?.value}
              isClearable={true}
            />
          )}
          {exploreAdvanced && (
            <AccordionItem value="explore-advanced">
              <AccordionTrigger className="pb-0">
                <FormLabel isMobile={isExploreMobile}>
                  Advanced Filters
                </FormLabel>
              </AccordionTrigger>
              <AccordionContent>
                <Accordion className="space-y-4" collapsible>
                  <DropdownSelect
                    filter={socialMediaFilters.personCount}
                    filterKey={'personCount'}
                    onChange={handleSocialMediaFiltersOnChange}
                    closeOnChange={false}
                  />
                  <DropdownSelect
                    filter={socialMediaFilters.personPosition}
                    filterKey={'personPosition'}
                    onChange={handleSocialMediaFiltersOnChange}
                    closeOnChange={false}
                  />
                </Accordion>
              </AccordionContent>
            </AccordionItem>
          )}
        </DropdownSelect>
      </div>
    </div>
  );
}

export function ExploreFashionWeekFilters() {
  const {
    fashionWeekFilters,
    handleFashionWeekFiltersOnChange,
    isExploreMobile,
  } = useExploreContext();

  return (
    <div
      className={cn(
        'flex h-max flex-col overflow-y-auto',
        isExploreMobile && 'h-full',
      )}
    >
      {!isExploreMobile && <Spacing size={1.25} />}
      <FiltersHeading />
      <div className="space-y-4">
        <DropdownSelect collapsible className="space-y-4">
          {Object.entries(fashionWeekFilters).map(
            ([filterKey, filter], i) =>
              filterKey !== 'search' &&
              filterKey !== 'type' && (
                <DropdownSelectItem
                  key={i}
                  filter={filter}
                  filterKey={filterKey}
                  onChange={handleFashionWeekFiltersOnChange}
                  isClearable={true}
                  variant="collapsible"
                  isExcludable={filter.isExcludable}
                />
              ),
          )}
        </DropdownSelect>
      </div>
    </div>
  );
}

export function ExploreFilters({ className }) {
  const {
    activeTab,
    // fashionWeekFilters,
    socialMediaFilters,
    triggerRefetch,
    isFiltersOpen,
    isExploreMobile,
    setIsFiltersOpen,
    isGenderTouched,
  } = useExploreContext();

  const filters = {
    'fashion-weeks': <ExploreFashionWeekFilters />,
    'social-media': <ExploreSocialMediaFilters />,
  };

  const fashionWeekFormSubmitConditions = () => {
    // TODO: Implement required conditions for fashion week form submit
    return true;
  };

  const socialMediaFormSubmitConditions = () => {
    const hasNonGenderPlatformFilter = Object.entries(socialMediaFilters).some(
      ([key, filter]) =>
        key !== 'gender' &&
        key !== 'platform' &&
        filter?.value &&
        (Array.isArray(filter.value) ? filter.value.length > 0 : true),
    );

    const hasGenderFilter =
      Boolean(socialMediaFilters.gender?.value) && isGenderTouched;

    return hasNonGenderPlatformFilter || hasGenderFilter;
  };

  const isSubmitButtonActive =
    activeTab === 'fashion-weeks'
      ? fashionWeekFormSubmitConditions()
      : socialMediaFormSubmitConditions();

  return (
    <>
      <Sidebar
        className={cn(
          'flex flex-col gap-y-6 pt-2 no-scrollbar',
          // isExploreMobile && 'h-full',
        )}
        variant={isExploreMobile ? 'mobile' : 'primary'}
        style={{
          display: !isExploreMobile
            ? 'block'
            : isFiltersOpen
              ? 'block'
              : 'none',
          transition: 'display 0.3s ease-in-out',
        }}
      >
        {filters[activeTab]}
        {!isExploreMobile && (
          <Button
            className="mt-6 w-full p-2 font-bold text-primaryLight disabled:bg-black/60"
            disabled={!isSubmitButtonActive}
            onClick={() => triggerRefetch()}
          >
            Apply
          </Button>
        )}
        {isExploreMobile && (
          <div className="fixed inset-x-0 bottom-0 z-50 mx-4 bg-white p-4 pb-8">
            <Button
              className={cn(
                'w-full p-1 font-bold text-primaryLight disabled:bg-black/60',
                isExploreMobile && 'text-lg leading-9',
              )}
              disabled={!isSubmitButtonActive}
              onClick={() => {
                if (isFiltersOpen) {
                  setIsFiltersOpen(false);
                }
                triggerRefetch();
              }}
            >
              Apply
            </Button>
          </div>
        )}
      </Sidebar>
    </>
  );
}
