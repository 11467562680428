import { useState } from 'react';
import { Mention, MentionsInput } from 'react-mentions';
import { useParams } from 'react-router';
import { useMutation, useQuery } from '@apollo/client';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import utc from 'dayjs/plugin/utc';

import { useUser } from '@/api/authentication/hook';
import {
  CREATE_COMMENT,
  DISLIKE_COMMENT,
  LIKE_COMMENT,
  REPLY_COMMENT,
} from '@/api/comment/mutations';
import { GET_COMMENTS_FOR_ENTITY } from '@/api/comment/queries';
import { POST } from '@/api/explore/queries';
import { useOrganizationQuery } from '@/api/organization/hook';
import { LikeButton } from '@/components/LikeButton';
import { cn } from '@/helpers/utils';
import { useToast } from '@/hooks/useToast';

import commentStyles from './commentStyles';
import mentionStyles from './mentionStyles';

dayjs.extend(utc);
dayjs.extend(relativeTime);

export function Comment(comment) {
  const { user } = useUser();

  const [isCommentLiked, setIsCommentLiked] = useState(
    comment.likes.includes(user?.id),
  );
  const [showReplies, setShowReplies] = useState(false);
  const [replyCommentText, setReplyCommentText] = useState('');
  const [likeCount, setLikeCount] = useState(comment.likes.length || 0);
  const [users, setUsers] = useState([]);

  const { data: organization } = useOrganizationQuery('organization', {
    onCompleted: (data) => {
      setUsers(
        data.organization.members.map((user) => ({
          id: user.id,
          display: user.fullName,
        })),
      );
    },
  });

  const commentDate = dayjs(comment.createdAt).fromNow();

  const toast = useToast();

  const { type, id } = useParams();

  const [likeComment, { loading: likeCommentLoading }] = useMutation(
    LIKE_COMMENT,
    {
      refetchQueries: [GET_COMMENTS_FOR_ENTITY],
      onCompleted: () => {
        setIsCommentLiked(true);
        setLikeCount((prev) => prev + 1);
      },
      onError: () => {
        toast.createToast({
          message: 'Something went wrong',
          type: 'error',
          position: 'top-right',
        });
      },
    },
  );

  const [dislikeComment, { loading: dislikeCommentLoading }] = useMutation(
    DISLIKE_COMMENT,
    {
      refetchQueries: [GET_COMMENTS_FOR_ENTITY],
      onCompleted: () => {
        setIsCommentLiked(false);
        setLikeCount((prev) => prev - 1);
      },
      onError: () => {
        toast.createToast({
          message: 'Something went wrong',
          type: 'error',
          position: 'top-right',
        });
      },
    },
  );

  const [replyComment, { loading: replyCommentLoading }] = useMutation(
    REPLY_COMMENT,
    {
      refetchQueries: [GET_COMMENTS_FOR_ENTITY],
      onCompleted: () => {
        setReplyCommentText('');
      },
      onError: () => {
        toast.createToast({
          message: 'Something went wrong',
          type: 'error',
          position: 'top-right',
        });
      },
    },
  );

  function handleLikeButton() {
    if (likeCommentLoading || dislikeCommentLoading) {
      return;
    }

    if (isCommentLiked) {
      return dislikeComment({
        variables: {
          commentId: comment.id,
          parentComment: comment.parentComment,
        },
      });
    }

    return likeComment({
      variables: {
        commentId: comment.id,
        parentComment: comment.parentComment,
      },
    });
  }

  return (
    <div className="flex items-start gap-2">
      {comment.author.profileImage ? (
        <img
          className="h-10 w-10 shrink-0 rounded-full"
          src={comment.author.profileImage}
        />
      ) : (
        <div className="flex h-10 w-10 shrink-0 items-center justify-center rounded-full border border-[#a1a1a140] bg-[#a1a1a110] text-xl text-[#1a1a1a80]">
          {comment.author.fullName[0]}
        </div>
      )}
      <div className="flex flex-col gap-y-1">
        <span className="text-sm leading-[1.4]">
          <strong className="font-bold">{comment.author.fullName}</strong>{' '}
          {comment.comment
            .split(
              /(@[A-ZÇĞİÖŞÜ][a-zçğıöşü]+(?:\s+[A-ZÇĞİÖŞÜ][a-zçğıöşü]+){0,2})/g,
            )
            .map((part, index) =>
              part.startsWith('@') ? (
                <a className="font-medium" key={index}>
                  {part}
                </a>
              ) : (
                part
              ),
            )}
        </span>

        <div className="flex items-center gap-1 text-xs font-medium leading-none text-gray-500">
          <span>
            {commentDate} – {likeCount} Likes –{' '}
          </span>
          <div
            className={cn(
              'cursor-pointer transition-colors hover:text-red-600 group',
            )}
            onClick={handleLikeButton}
          >
            {isCommentLiked ? (
              <>
                <span className="group-hover:hidden">Liked</span>
                <span className="hidden group-hover:block">Dislike</span>
              </>
            ) : (
              <span>Like</span>
            )}
          </div>{' '}
          {!comment.parentComment && (
            <span
              className="cursor-pointer"
              onClick={() => setShowReplies((prev) => !prev)}
            >
              {comment.replies.length > 0
                ? ` - ${comment.replies.length} Replies`
                : ' - Reply'}
            </span>
          )}
        </div>

        {showReplies && !comment.parentComment && (
          <div className="mt-4 flex flex-col gap-4">
            {comment.replies.map((reply) => (
              <Comment key={reply.id} {...reply} />
            ))}

            <MentionsInput
              value={replyCommentText}
              onChange={(e, newValue) => setReplyCommentText(newValue)}
              className="w-full rounded-xl"
              placeholder="Reply"
              disabled={replyCommentLoading}
              style={commentStyles}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  replyComment({
                    variables: {
                      input: {
                        comment: replyCommentText,
                        targetEntity: {
                          entityId: id,
                          entityType: type,
                        },
                      },
                      parentComment: comment.id,
                    },
                  });
                }
              }}
            >
              <Mention
                trigger="@"
                data={organization ? users : null}
                displayTransform={(id, display) => `@${display} `}
                style={mentionStyles}
              />
            </MentionsInput>
          </div>
        )}
      </div>
    </div>
  );
}

export function Comments({ itemId, isTrendItem, isSnapshotItem }) {
  const [comment, setComment] = useState('');
  const [users, setUsers] = useState([]);
  const [mentions, setMentions] = useState([]);

  const toast = useToast();

  const { id, type, seasonId } = useParams();

  const { data: organization } = useOrganizationQuery('organization', {
    onCompleted: (data) => {
      setUsers(
        data.organization.members.map((user) => ({
          id: user.id,
          display: user.fullName,
        })),
      );
    },
  });

  const { data } = useQuery(GET_COMMENTS_FOR_ENTITY, {
    variables: {
      entityId: itemId || id,
    },
    notifyOnNetworkStatusChange: true,
  });

  const { data: postData } = useQuery(POST, {
    variables: {
      id: itemId || id,
    },
  });

  const [createComment, { loading: createCommentLoading }] = useMutation(
    CREATE_COMMENT,
    {
      refetchQueries: [GET_COMMENTS_FOR_ENTITY],
      onCompleted: () => {
        setComment('');
      },
      onError: () => {
        toast.createToast({
          message: 'Something went wrong',
          type: 'error',
          position: 'top-right',
        });
      },
    },
  );

  const handleCommentChange = (e, newValue, newPlainTextValue, mentions) => {
    const formattedComment = newValue.replace(/@\[(.*?)\]\((.*?)\)/g, '@$1');
    setComment(formattedComment);

    const newMentionedUsers = mentions.map((mention) => mention?.id);
    setMentions(newMentionedUsers);
  };

  return (
    <div className="my-auto flex flex-col gap-4">
      <h1 className="text-lg font-bold leading-5">Comments</h1>

      {data?.comments.length > 0 && (
        <div className="flex max-h-60 flex-col gap-y-3 overflow-auto">
          {data?.comments.map((comment) => (
            <Comment key={comment.id} {...comment} />
          ))}
        </div>
      )}
      <div className="flex items-center gap-2">
        <MentionsInput
          value={comment}
          onChange={handleCommentChange}
          className="w-full rounded-xl [&>*]:rounded-xl"
          placeholder="Leave a comment and tag teammates with '@'"
          disabled={createCommentLoading}
          style={commentStyles}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              createComment({
                variables: {
                  input: {
                    comment,
                    mentions,
                    targetEntity: {
                      entityId: itemId || id,
                      entityType: isTrendItem
                        ? 'trendItem'
                        : isSnapshotItem
                          ? 'snapshotItem'
                          : type,
                    },
                  },
                },
              });
            }
          }}
        >
          <Mention
            trigger="@"
            data={organization ? users : null}
            displayTransform={(id, display) => `@${display} `}
            style={mentionStyles}
            onAdd={(id) =>
              setMentions((prev) => {
                if (prev && !prev.includes(id)) {
                  return [...prev, id];
                } else {
                  return prev;
                }
              })
            }
          />
        </MentionsInput>

        <LikeButton
          item={itemId || id}
          isLiked={postData?.post?.isLiked}
          type={
            isTrendItem ? 'trendItem' : isSnapshotItem ? 'snapshotItem' : 'post'
          }
          season={seasonId}
          variant="outlined"
        />
      </div>
    </div>
  );
}
