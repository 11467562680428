export function Modal({ isOpen, onClose, children, fluid = true }) {
  if (isOpen) {
    return (
      <div className="fixed left-0 top-0 z-[450] flex h-full w-[100vw] items-center justify-center">
        <div
          className="fixed inset-0 z-[451] h-screen w-screen bg-black/50 backdrop-blur-sm"
          onClick={onClose}
        />
        <div
          className={`z-[452] max-w-[75vw] rounded-md bg-white ${
            fluid ? 'w-full' : ''
          }`}
        >
          {children}
        </div>
      </div>
    );
  }

  return null;
}
