import { useEffect, useRef, useState } from 'react';
import { RxCross1 } from 'react-icons/rx';
// import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import * as Tabs from '@radix-ui/react-tabs';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { ChevronLeft, ChevronRight, Pencil } from 'lucide-react';
import numeral from 'numeral';

import { useUser } from '@/api/authentication/hook';
import { Button } from '@/components';
import { FormInput } from '@/components/FormInput';
import { MetricPill } from '@/components/Pills';
import { SelectDropdown } from '@/components/SelectDropdown';
// import SeeMore from '@/components/SeeMore';
import { TabHeading } from '@/components/TabHeading';
import { colorMap } from '@/constants/index';
import { useInsightContext } from '@/contexts/InsightContext';
import { useTrendsContext } from '@/contexts/TrendsContext';
import { cn, stringFormatter } from '@/helpers/utils';
import { SaveToCollection } from '@/layouts/common';
import { Card } from '@/pages/general/explore/components';
import { Comments } from '@/pages/general/imagePreview/components/Comments';

import { DetailBreakDown } from './components/DetailBreakdown';
import { Forecast } from './components';

dayjs.extend(relativeTime);

const itemTypeToFields = {
  snapshotItem: {
    color: 'ssiColor',
    colorTone: 'ssiColor',
    fabric: 'ssiFabric',
    pattern: 'ssiPattern',
    attributeKeyA: 'ssiAttributeKeyA',
    attributeKeyB: 'ssiAttributeKeyB',
    attributeValueA: 'ssiAttributeValueA',
    attributeValueB: 'ssiAttributeValueB',
  },
  trendItem: {
    color: 'tiColor',
    colorTone: 'tiColorTone',
    fabric: 'tiFabric',
    pattern: 'tiPattern',
    attributeKeyA: 'tiAttributeKeyA',
    attributeKeyB: 'tiAttributeKeyB',
    attributeValueA: 'tiAttributeValueA',
    attributeValueB: 'tiAttributeValueB',
  },
};

function ImageSelector({ item, itemType }) {
  const query = {
    attributes: [],
  };

  const color = item[itemTypeToFields[itemType].color];
  const colorTone = item[itemTypeToFields[itemType].colorTone];
  const fabric = item[itemTypeToFields[itemType].fabric];
  const pattern = item[itemTypeToFields[itemType].pattern];

  const attributeKeyA = item[itemTypeToFields[itemType].attributeKeyA];
  const attributeValueA = item[itemTypeToFields[itemType].attributeValueA];
  const attributeKeyB = item[itemTypeToFields[itemType].attributeKeyB];
  const attributeValueB = item[itemTypeToFields[itemType].attributeValueB];

  if (color) {
    query.colors = [color];
  }

  if (colorTone) {
    query.tone = {
      tone: colorTone,
      similarity: 3, // TODO: make this dynamic
    };
  }

  if (fabric) {
    query.attributes.push({
      name: 'fabric',
      value: fabric,
    });
  }

  if (pattern) {
    query.patterns = [pattern];
  }

  if (attributeKeyA && attributeValueA) {
    query.attributes.push({
      name: attributeKeyA,
      value: attributeValueA,
    });
  }

  if (attributeKeyB && attributeValueB) {
    query.attributes.push({
      name: attributeKeyB,
      value: attributeValueB,
    });
  }

  if (item.apparel) {
    query.apparels = [
      {
        value: item.apparel.toString(),
        items: [],
      },
    ];
  }

  if (query.attributes.length === 0) {
    delete query.attributes;
  }

  const [selectedItems, setSelectedItems] = useState(
    item.featuredImages.map((item) => ({
      ...item,
      image: item.entity.image,
      post: item.entity,
      entity: {
        entityType: 'post',
        entityId: item.entity.id,
      },
    })),
  );

  function onItemCoverChange(item) {
    const isItemSelected = checkIsSelected(item.post.id);

    if (isItemSelected) {
      setSelectedItems((prev) => [
        {
          ...item,
          entity: {
            entityType: 'post',
            entityId: item.post.id,
          },
        },
        ...prev.filter(
          (selectedItem) => selectedItem.entity.entityId !== item.post.id,
        ),
      ]);
    } else {
      setSelectedItems([
        {
          ...item,
          entity: {
            entityType: 'post',
            entityId: item.post.id,
          },
        },
        ...selectedItems,
      ]);
    }
  }

  function checkIsSelected(itemId) {
    return selectedItems.some((item) => item.entity.entityId === itemId);
  }

  function onItemSelect(item) {
    const isSelected = checkIsSelected(item.post.id);

    if (isSelected) {
      setSelectedItems(
        selectedItems.filter(
          (selectedItem) => selectedItem.entity.entityId !== item.post.id,
        ),
      );
    } else {
      setSelectedItems([
        ...selectedItems,
        {
          ...item.image,
          entity: {
            entityType: 'post',
            entityId: item.post.id,
          },
        },
      ]);
    }
  }

  const [filters, setFilters] = useState({});

  function isCoverSelected(itemId) {
    const idx = selectedItems.findIndex(
      (item) => item.entity.entityId === itemId,
    );

    return idx !== -1 && idx <= 2;
  }

  if (!Object.keys(itemTypeToFields).includes(itemType)) {
    // eslint-disable-next-line no-console
    console.warn(`Unsupported item type: ${itemType}`);

    return null;
  }

  const triggerClassName =
    'border-b-2 border-primary px-4 py-2 font-semibold data-[state=active]:bg-primary data-[state=active]:text-white data-[state=active]:rounded-t-lg';

  return (
    <div className="flex flex-col gap-2">
      <Tabs.Root defaultValue="current">
        <div className="flex items-center justify-between">
          <Tabs.List aria-label="Image Selector" className="mb-4">
            <Tabs.Trigger value="current" className={triggerClassName}>
              Current Images
            </Tabs.Trigger>
            <Tabs.Trigger className={triggerClassName} value="search">
              Search Images
            </Tabs.Trigger>
            <Tabs.Trigger className={triggerClassName} value="final">
              Final Look
            </Tabs.Trigger>
          </Tabs.List>
        </div>
        <Tabs.Content value="current">
          <FeaturedImages
            editMode
            images={selectedItems}
            onItemSelect={onItemSelect}
            isSelected={checkIsSelected}
            isCoverSelected={isCoverSelected}
            onItemCoverChange={onItemCoverChange}
          />
        </Tabs.Content>
        <Tabs.Content value="search">
          <div className="grid grid-cols-5 gap-2">
            {Object.entries(filters).map(([filterKey, filter], i) => (
              <div key={i} className="z-[300]  mb-4">
                <FormInput key={i}>
                  <SelectDropdown
                    placeholder={filter?.placeholder}
                    isSearchable={filter?.isSearchable}
                    options={filter?.options}
                    multiselect={false}
                    isClearable={filter?.isClearable}
                    isMulti={filter?.isMulti}
                    value={filter?.value}
                    key={filterKey}
                    onChange={(e) =>
                      setFilters((prev) => ({
                        ...prev,
                        [filterKey]: {
                          ...prev[filterKey],
                          value: e,
                        },
                      }))
                    }
                    {...(filterKey === 'color'
                      ? {
                          formatOptionLabel: (option) => {
                            const labelLowerCase = option.label.toLowerCase();
                            const tone = item.color?.value.find(
                              (tone) => tone.value === option.value,
                            );
                            const backgroundColor = Object.keys(
                              colorMap,
                            ).includes(labelLowerCase)
                              ? colorMap[labelLowerCase]
                              : tone
                                ? tone.hex
                                : option.value;

                            return (
                              <div
                                className={'flex flex-row items-center gap-3'}
                                style={{
                                  margin: '1px 0',
                                }}
                              >
                                <div
                                  className={cn(
                                    'h-4 w-4 border-charcoal rounded-full',
                                    option.value === 'white' && 'border-2',
                                  )}
                                  style={{
                                    background: backgroundColor,
                                  }}
                                ></div>
                                <span>{option.label}</span>
                              </div>
                            );
                          },
                        }
                      : {})}
                    {...(filterKey === 'tones'
                      ? {
                          formatOptionLabel: (option) => {
                            return (
                              <div
                                className={
                                  '-mx-4 -my-2 flex h-full flex-row items-center'
                                }
                              >
                                <div className="flex flex-row items-center gap-2 px-4 py-2">
                                  <div
                                    className={cn(
                                      'h-4 w-4 border-charcoal rounded-full',
                                    )}
                                    style={{
                                      background: option.hex,
                                      border: '1px solid black',
                                    }}
                                  ></div>
                                  <span className="p-1">
                                    {stringFormatter(option.label)} -{' '}
                                    {option.code || option.value}
                                  </span>
                                </div>
                              </div>
                            );
                          },
                        }
                      : {})}
                  />
                </FormInput>
              </div>
            ))}
          </div>
          <FeaturedImages
            editMode
            images={[]}
            onItemSelect={onItemSelect}
            isSelected={checkIsSelected}
            isCoverSelected={isCoverSelected}
            onItemCoverChange={onItemCoverChange}
            onLoadMore={() => {
              console.log('load more');
            }}
          />
        </Tabs.Content>
        <Tabs.Content value="final">
          <FeaturedImages
            editMode
            images={selectedItems}
            onItemSelect={onItemSelect}
            isSelected={checkIsSelected}
            isCoverSelected={isCoverSelected}
            onItemCoverChange={onItemCoverChange}
          />
        </Tabs.Content>
      </Tabs.Root>
    </div>
  );
}

export function FeaturedImageSkeleton() {
  return (
    <div className="ghost flex h-[480px] w-[200px] flex-col justify-items-start gap-y-[1rem] " />
  );
}

export function FeaturedImages({
  images,
  skeleton,
  filter,
  editMode,
  isSelected,
  onItemSelect,
  isCoverSelected,
  onItemCoverChange,
  isLiked,
  itemId,
}) {
  const [fashionWeekImageIndex, setFashionWeekImageIndex] = useState(0);
  const [socialMediaImageIndex, setSocialMediaImageIndex] = useState(0);
  const [aiStudioImageIndex, setAiStudioImageIndex] = useState(0);

  const fashionWeekImages = images.fashionWeekImages.map((image) => ({
    image: {
      ...image,
    },
    __type: 'ExploreFashionWeekItem',
  }));

  const renderImages = ({ images, skeleton, index, setIndex }) => {
    return skeleton ? (
      Array.from({ length: 8 }).map((_, index) => (
        <FeaturedImageSkeleton key={index} />
      ))
    ) : (
      <>
        {images.slice(index, index + 5).map((item, idx) => (
          <div key={'explore_' + idx} className="group">
            {idx === 0 && index > 0 && (
              <ChevronLeft
                size={'2rem'}
                className="absolute -left-4 top-1/2 z-[500] my-auto mt-2 cursor-pointer rounded-full bg-gray-500 text-white  opacity-90 group-hover:opacity-100"
                onClick={() => setIndex((prev) => prev - 5)}
              />
            )}
            <div className="group">
              <Card.Root
                data={item}
                key={'explore_' + idx}
                moderationEnabled={false}
              >
                <Card.Image />
              </Card.Root>
            </div>
            {idx === 4 && index + 5 < images.length && (
              <ChevronRight
                size={'2rem'}
                className="absolute -right-4 top-1/2 z-[500] my-auto mt-2 cursor-pointer rounded-full bg-gray-500 text-white opacity-90 group-hover:opacity-100 "
                onClick={() => setIndex((prev) => prev + 5)}
              />
            )}
          </div>
        ))}
      </>
    );
  };

  return (
    <div className="relative w-full">
      {/* Blurred overlay div */}
      <div className="absolute inset-0 bg-white/30 backdrop-blur-md"></div>

      {/* Image grid */}
      <div className="relative">
        <div className="mb-4 text-2xl font-semibold text-primary">
          Fashion Week
        </div>
        <div className="grid grid-cols-2 gap-6 first-letter:grid sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-5">
          {renderImages({
            images: fashionWeekImages,
            skeleton,
            index: fashionWeekImageIndex,
            setIndex: setFashionWeekImageIndex,
          })}
        </div>
      </div>
      <div className="relative">
        <div className="mb-4 text-2xl font-semibold text-primary">
          Social Media
        </div>
        <div className="grid grid-cols-2 gap-6 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-5">
          {renderImages({
            images: fashionWeekImages,
            skeleton,
            index: socialMediaImageIndex,
            setIndex: setSocialMediaImageIndex,
          })}
        </div>
      </div>
      <div className="relative">
        <div className="mb-4 text-2xl font-semibold text-primary">
          AI Studio
        </div>
        <div className="grid grid-cols-2 gap-6 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-5">
          {renderImages({
            images: fashionWeekImages,
            skeleton,
            index: aiStudioImageIndex,
            setIndex: setAiStudioImageIndex,
          })}
        </div>
      </div>
    </div>
  );
}

function Wrapper({ children, imagePreview = true }) {
  const [wrapperHeight, setWrapperHeight] = useState(0);

  useEffect(() => {
    function handleSizeChange() {
      const navbarEl = document.getElementById('main-nav');
      const bodyEl = document.querySelector('body');

      setWrapperHeight(bodyEl.offsetHeight - navbarEl.offsetHeight);
    }

    handleSizeChange();

    window.addEventListener('resize', handleSizeChange);

    return () => window.removeEventListener('resize', handleSizeChange);
  }, []);

  return (
    <div
      className={cn(
        'absolute right-0 left-0 z-[500] grid animate-fade-in overflow-y-auto overflow-x-hidden bg-white',
        imagePreview ? 'grid-cols-[67%_33%]' : 'grid-cols-[33%_67%]',
      )}
      style={{
        height: `${wrapperHeight}px`,
      }}
    >
      {children}
    </div>
  );
}

function Header() {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <div className="flex flex-row items-center justify-between">
      <div className="flex flex-row items-center gap-x-2 text-lg font-semibold leading-[1.125rem] text-black">
        Analysis
      </div>
      <RxCross1
        size={'2rem'}
        onClick={() => {
          if (location.key === 'default') {
            navigate('/overview');
          } else {
            navigate(-1);
          }
        }}
        className="cursor-pointer"
      />
    </div>
  );
}

export function InsightDetail() {
  const [editCoverState, setEditCoverState] = useState(false);

  const [editTitleState, setEditTitleState] = useState(false);
  const [newTitle, setNewTitle] = useState('Black Shoe');

  const editTitleRef = useRef(null);

  const { moderationEnabled } = useUser();

  // const moderationEnabled = false;
  const { getInsight, insightData, insightError, insightLoading } =
    useInsightContext();

  const navigate = useNavigate();
  const location = useLocation();

  const [searchParams] = useSearchParams();

  const itemId = searchParams.get('id');

  const { activeChart, setActiveChart } = useTrendsContext();

  useEffect(() => {
    function closePage(e) {
      if (e.key === 'Escape') {
        if (location.key === 'default') {
          return navigate('/overview');
        } else {
          return navigate(-1);
        }
      }
      return null;
    }

    window.addEventListener('keydown', closePage);

    return () => window.removeEventListener('keydown', closePage);
  }, []);

  useEffect(() => {
    getInsight({
      variables: {
        insightId: itemId,
        filters: {
          timeframeRange: [{ date: new Date('2025-01-01').toISOString() }],
        },
      },
    });
  }, [itemId]);

  if (insightError) {
    return (
      <div className="flex h-full items-center justify-center">
        <span>Unexpected error {insightError?.message}</span>
      </div>
    );
  }

  if (insightLoading || !insightData) {
    return (
      <div className="absolute inset-x-0 z-[500] grid  h-full w-full animate-fade-in grid-cols-[67%_33%] overflow-hidden bg-white">
        <div className="flex h-full w-full flex-col overflow-hidden px-[3.75rem] py-8">
          <div className="grid grid-cols-3 gap-8 lg:grid-cols-5">
            {Array.from({ length: 20 }).map((_, index) => (
              <div
                key={index}
                className="ghost flex aspect-[7/11] flex-col justify-items-start gap-y-[1rem] rounded-md"
              />
            ))}
          </div>
        </div>
        <div className="flex h-[90vH] w-full flex-col justify-between overflow-hidden border-l border-secondary px-[3.75rem] py-9">
          <div className="flex flex-col gap-y-8">
            <div className="ghost flex h-12 flex-wrap items-center justify-start gap-4"></div>
            <div className="ghost flex h-16 flex-wrap items-center justify-start gap-4"></div>
            <div className="ghost flex h-20 flex-wrap items-center justify-start gap-4"></div>
            <div className="ghost flex h-28 flex-wrap items-center justify-start gap-4"></div>
          </div>
          <div className="flex flex-col gap-y-8">
            <div className="ghost flex h-12 flex-col gap-y-4"></div>
            <div className="ghost flex h-12 flex-col gap-y-4"></div>
          </div>
        </div>
      </div>
    );
  }

  const insightItem = insightData?.insight;

  const charts = [
    {
      label: 'Visibility',
      value: 'visibility',
      component: (
        <Forecast.Root>
          <Forecast.Chart />
        </Forecast.Root>
      ),
    },
    {
      label: 'Engagement',
      value: 'qualityEngagement',
      component: (
        <Forecast.Root>
          <Forecast.Chart />
        </Forecast.Root>
      ),
    },
  ];

  const visibility = 100;

  const qualityEngagement = numeral(1291212).format('0.0a').toUpperCase();

  const totalEngagement = numeral(12093021).format('0.0a').toUpperCase();

  const trendType = '2';

  const locationName = 'locationName';

  const selectedAudienceName = 'selectedAudienceName';

  const gender = '2';

  const ageMin = '20';

  const ageMax = '35';

  return (
    <Wrapper>
      <div className="flex w-full flex-col gap-y-6 overflow-auto px-[3.75rem] py-8">
        <div className="flex flex-col">
          <div className="flex flex-row items-start justify-between gap-4">
            <div className="flex flex-row items-end gap-4">
              <div className="flex flex-col gap-2.5">
                <h3 className="text-lg font-semibold leading-5 text-primary">
                  Snapshots: {locationName || 'London'},{' '}
                  {selectedAudienceName ||
                    `${gender || 'Women'}, ${ageMin || '20'}-${ageMax || '35'}`}
                </h3>
                <div className="flex items-center gap-4">
                  {moderationEnabled && (
                    <button
                      onClick={() => {
                        editTitleRef.current?.focus();
                        setEditTitleState(true);
                      }}
                    >
                      <Pencil className="text-primary" />
                    </button>
                  )}
                  {moderationEnabled ? (
                    <input
                      ref={editTitleRef}
                      disabled={!moderationEnabled || !editTitleState}
                      className="text-4xl font-bold capitalize text-black"
                      value={newTitle}
                      onChange={(e) => setNewTitle(e.target.value)}
                    />
                  ) : (
                    <div className="flex flex-row items-center gap-4">
                      <h2 className="text-4xl font-bold capitalize text-black">
                        {insightItem?.title}
                      </h2>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="flex flex-row items-end gap-2">
              <SaveToCollection
                id={itemId}
                type="snapshotItem"
                metadata={{
                  audienceName: selectedAudienceName,
                }}
              >
                {(loading) => (
                  <div className="flex gap-x-2">
                    <Button
                      className={`${loading ? 'ghost' : ''} rounded-lg py-2 font-bold text-white`}
                    >
                      Save
                    </Button>
                  </div>
                )}
              </SaveToCollection>
              {moderationEnabled && (
                <Button
                  className={`${insightLoading ? 'ghost' : ''} rounded-lg py-2 font-bold text-white`}
                  onClick={() => setEditCoverState((prev) => !prev)}
                >
                  Edit Gallery
                </Button>
              )}
            </div>
          </div>
        </div>
        {insightItem?.insightBreakdowns?.filter((tab) => tab?.data?.length > 0)
          .length > 0 && (
          <div
            className={cn(
              'grid gap-x-6',
              `grid-cols-${insightItem.insightBreakdowns.filter((tab) => tab?.data?.length > 0).length === 1 ? 2 : insightItem.insightBreakdowns.filter((tab) => tab?.data?.length > 0).length}`,
            )}
          >
            {insightItem.insightBreakdowns.map(
              (tab, index) =>
                tab?.data?.length > 0 && (
                  <DetailBreakDown key={index} tab={tab} />
                ),
            )}
          </div>
        )}
        {moderationEnabled && editCoverState && (
          <ImageSelector item={insightItem} itemType="snapshotItem" />
        )}
        {insightItem?.featuredImages && !editCoverState && (
          <FeaturedImages
            images={insightItem.featuredImages}
            isLiked={insightItem.isLiked}
            skeleton={false}
            filter={() => {}}
          />
        )}
      </div>
      <div className="flex flex-col justify-between border-l border-secondary/50 px-[3.75rem] pb-4 pt-9">
        <div className="flex flex-col gap-y-8">
          <Header />
          <div className={cn('grid gap-1', 'grid-cols-3')}>
            <MetricPill
              color={trendType === 'FLAT' ? '#C29B77' : null}
              isIncrease={true}
              metric="Trend Type"
              metricValue={'Rising Star'}
              timePeriod={{ value: null, label: null }}
            />
            <MetricPill
              isIncrease={true}
              metric="Year over Year Growth"
              metricValue={'+56%'}
              timePeriod={{ value: null, label: null }}
            />
            <MetricPill
              isIncrease={true}
              metric="Quality Engagement"
              metricValue={qualityEngagement.toString()}
              timePeriod={{ value: null, label: null }}
            />

            <MetricPill
              isIncrease={null}
              metric="Reach Type"
              metricValue={'Big'}
            />

            <MetricPill
              isIncrease={true}
              metric="Visibility"
              metricValue={visibility.toString()}
              timePeriod={{ value: null, label: null }}
            />

            <MetricPill
              isIncrease={true}
              metric="Total Engagement"
              metricValue={totalEngagement.toString()}
              timePeriod={{ value: null, label: null }}
            />

            <MetricPill
              isIncrease={null}
              metric="Market Segmentation"
              metricValue={'Trendy'}
            />

            <MetricPill
              isIncrease={null}
              metric="Optimal Launch"
              metricValue={'December'}
            />
          </div>
          <div className="w-full">
            <Tabs.Root
              value={activeChart}
              onValueChange={(chart) => {
                setActiveChart(chart);
              }}
              className={'flex flex-col gap-y-4'}
            >
              <div className="flex flex-row items-start justify-between">
                <h4 className="text-lg font-bold leading-[1.125rem]">Chart</h4>
                <Tabs.List
                  aria-label="trends"
                  className={'flex items-start justify-start'}
                >
                  <div className={'flex gap-x-2.5'}>
                    {charts.map((chart, i) => (
                      <TabHeading
                        size={'md'}
                        label={chart.label}
                        value={chart.value}
                        variant="snapshots"
                        className="pb-2 text-sm leading-4"
                        key={i}
                      />
                    ))}
                  </div>
                </Tabs.List>
              </div>
              <p className="text-xs font-normal leading-[0.875rem]">
                This chart about how users interact with content. For example,
                shares, comments, saves, long-term, etc.
              </p>
              <div>
                {charts.map((chart, i) => (
                  <Tabs.Content key={i} value={chart.value}>
                    {chart.component}
                  </Tabs.Content>
                ))}
              </div>
            </Tabs.Root>
          </div>
        </div>
        <div className="flex flex-col gap-y-8">
          <Comments itemId={itemId} isSnapshotItem />
        </div>
      </div>
    </Wrapper>
  );
}
