import { createContext, useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useLazyQuery } from '@apollo/client';
import { startCase } from 'lodash';

import { EXPLORE_FASHION_WEEK_FILTERS } from '@/api/explore';
import { GET_TYPEAHEAD_SUGGESTIONS } from '@/api/explore/queries';
import { GET_MARKETS, SOCIAL_MEDIA_FILTERS } from '@/api/filters/queries';
import { isExploreMobilePage } from '@/components/constants';
import { genders, platforms, seasons } from '@/constants';
import { apparelMapping } from '@/constants/index';
import { getAudienceLabel, getMarketLabel } from '@/contexts/AppFiltersContext';
import { FEATURE_FLAGS, useFeatureFlag } from '@/hooks/useFeatureFlag';
import { colorOptions } from '@/pages/general/explore/components/options';
import { subColorOptions } from '@/pages/general/explore/components/subColorOptions';
import tones from '@/tones.json';

export const ExploreContext = createContext();

const unisexCategories = [3, 4, 5, 6, 15, 17, 18, 19, 20, 29, 38, 39];

const categoryByGenderMap = {
  0: [...unisexCategories, 2, 7, 8, 16], // All (Unisex + Only Male + Only Female)
  1: [...unisexCategories], // Male (Unisex + Only Male) (Current Status, Male haven't special categories)
  2: [...unisexCategories, 2, 7, 8, 16], // Female (Unisex + Only Female)
};

export function returnFashionItemFilters(filters) {
  let city = filters?.fashionWeek?.value?.value;
  if (city === 'all') city = null;

  const seasons = [];

  const season = filters?.season?.value?.value;
  const designer = filters?.designer?.value?.value;
  const itemType = filters?.itemType?.value?.value;

  const category = Array.isArray(filters?.category?.value)
    ? filters?.category?.value.map((i) => ({
        value: i?.value,
      }))
    : filters?.category?.value?.value
      ? [
          {
            value: filters?.category?.value?.value,
          },
        ]
      : undefined;

  const attributes = filters?.designs?.value?.map((i) => {
    return {
      name: i?.value.name,
      value: i?.value.value,
    };
  });

  if (filters?.search?.value?.value) {
    const seasonOptions = filters?.season?.options?.slice(0, 3);
    if (filters?.season?.value?.value) {
      seasons.push(filters?.season?.value?.value);
    }
    seasons.push(
      ...seasonOptions
        .map((i) => i.value)
        .filter((value) => !seasons.includes(value)),
    );
  }

  return {
    city,
    seasons,
    season,
    designer,
    itemType,
    apparels: category,
    attributes,
  };
}

export function returnFashionItemFilterOutFields(filters) {
  const apparels = filters?.category?.excludeValue?.map((i) => ({
    value: i?.value,
  }));
  const attributes = filters?.designs?.excludeValue?.map((i) => ({
    name: i?.value.name,
    value: i?.value.value,
  }));
  const designers = filters?.designer?.excludeValue?.value
    ? [filters?.designer?.excludeValue?.value]
    : null;

  const returnObj = {};

  if (apparels) {
    returnObj.apparels = apparels;
  }

  if (attributes) {
    returnObj.attributes = attributes;
  }

  if (designers) {
    returnObj.designers = designers;
  }

  return returnObj;
}

export const ExploreProvider = ({ children }) => {
  const { section } = useParams();
  const [activeTab, setActiveTab] = useState(() => {
    const validSections = ['fashion-weeks', 'social-media'];
    return validSections.includes(section) ? section : 'fashion-weeks';
  });

  const handleSetActiveTab = (newTab) => {
    const validTabs = ['fashion-weeks', 'social-media'];
    if (validTabs.includes(newTab)) {
      setActiveTab(newTab);
    } else {
      console.warn(
        `Invalid tab value: ${newTab}. Using default: fashion-weeks`,
      );
      setActiveTab('social-media');
    }
  };

  const [hasBannedApparel, setHasBannedApparel] = useState(false);
  const [shouldRefetch, setShouldRefetch] = useState(false);
  const [includes, setIncludes] = useState([]);
  const [excludes, setExcludes] = useState([]);
  // explore mobile experience
  const [isExploreMobile, setIsExploreMobile] = useState(false);
  const [isFiltersOpen, setIsFiltersOpen] = useState(false);
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  // const [, ] = useSearchParams()
  const [hoveredCardId, setHoveredCardId] = useState(null);
  const [isGenderTouched, setIsGenderTouched] = useState(false);

  const [activeSearchTab, setActiveSearchTab] = useState('THEMATIC');

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (
      activeTab !== section &&
      location.pathname.startsWith('/explore/') &&
      !location.pathname.includes('image-preview')
    ) {
      navigate(`/explore/${activeTab}`, { replace: true });
    }
  }, [activeTab, section, navigate, location.pathname]);

  useEffect(() => {
    if (location.pathname === '/explore' && activeTab) {
      const currentParams = new URLSearchParams(location.search);

      // Remove tone and design parameters if they exist
      currentParams.delete('tone');
      currentParams.delete('design');

      // Create the new URL with remaining parameters
      const searchString = currentParams.toString();
      const newPath = `/explore/${activeTab}${searchString ? `?${searchString}` : ''}`;

      navigate(newPath, { replace: true });
    }
  }, [activeTab, location.pathname, navigate, location.search]);

  useEffect(() => {
    setIsExploreMobile(isExploreMobilePage(location.pathname));
  }, [location.pathname, window.innerWidth]);

  const [fashionWeekFilters, setFashionWeekFilters] = useState(() => {
    return {
      search: {
        placeholder: 'Search by a theme or brand inspired looks',
        value: '',
        loading: false,
        isClearable: true,
        isSearchable: true,
        options: [],
      },

      itemType: {
        label: 'Type',
        placeholder: 'Select Type',
        options: [],
        value: null,
        loading: false,
        isClearable: true,
        isMulti: false,
      },

      fashionWeek: {
        label: 'City',
        placeholder: 'Search For A City',
        options: [],
        value: null,
        loading: false,
        isClearable: true,
      },

      season: {
        label: 'Season',
        placeholder: 'Select A Season',
        options: [],
        value: null,
        loading: false,
        isClearable: true,
      },

      designer: {
        label: 'Designer',
        placeholder: 'Search A Designer',
        options: [],
        value: null,
        loading: false,
        isClearable: true,
      },

      category: {
        label: 'Category',
        placeholder: 'Select Category',
        options: [],
        value: null,
        excludeValue: null,
        loading: false,
        isClearable: true,
        isMulti: true,
        isExcludable: true,
      },

      designs: {
        label: 'Design Detail',
        placeholder: 'Select Design Detail',
        options: [],
        value: null,
        excludeValue: null,
        loading: false,
        isClearable: true,
        isMulti: true,
        isExcludable: true,
        isSearchable: true,
      },
    };
  });

  const [socialMediaFilters, setSocialMediaFilters] = useState(() => {
    // const storedFilters = localStorage.getItem('socialMediaFilters');
    // if (storedFilters) {
    //   const parsedFilters = JSON.parse(storedFilters);
    //   if (parsedFilters && typeof parsedFilters === 'object') {
    //     return parsedFilters;
    //   }
    // }
    return {
      search: {
        placeholder: 'Search by a theme or brand inspired looks',
        value: '',
        loading: false,
      },
      gender: {
        label: 'Gender',
        placeholder: 'Select Gender',
        options: genders,
        value: genders[0],
        loading: false,
        isClearable: true,
        button: true,
      },
      market: {
        label: 'Market',
        placeholder: 'Select Market',
        options: [],
        value: null,
        loading: false,
        isClearable: true,
        isMulti: true,
      },
      platform: {
        label: 'Platform',
        placeholder: 'Select a social media platform',
        options: platforms,
        value: null,
        loading: false,
        isClearable: true,
        isMulti: false,
        button: true,
      },
      timeframe: {
        label: 'Timeframe',
        placeholder: 'Select A Timeframe',
        options: seasons,
        value: null,
        loading: false,
        isClearable: true,
      },
      category: {
        label: 'Category',
        placeholder: 'Select Category',
        options: [],
        value: null,
        excludeValue: null,
        loading: false,
        isClearable: true,
        isMulti: true,
        isExcludable: true,
      },
      'attribute.custom': {
        label: 'Design',
        placeholder: 'Select Design',
        options: [],
        value: null,
        excludeValue: null,
        loading: false,
        isClearable: true,
        isMulti: true,
        isSearchable: true,
      },
      color: {
        label: 'Color',
        placeholder: 'Select Color',
        options: colorOptions,
        value: null,
        excludeValue: null,
        loading: false,
        isClearable: true,
        isMulti: true,
        isExcludable: true,
      },
      subcolor: {
        label: 'Color',
        placeholder: 'Select Color',
        options: subColorOptions,
        value: null,
        excludeValue: null,
        loading: false,
        isClearable: true,
        isMulti: true,
        isExcludable: true,
      },
      tone: {
        label: 'Color Tone',
        placeholder: 'Select A Tone',
        options: tones.map((tone) => ({
          hex: tone.hex,
          label: tone.label,
          value: tone.value,
        })),
        value: null,
        excludeValue: null,
        loading: false,
        isClearable: true,
        isMulti: false,
        isExcludable: true,
      },
      personCount: {
        label: 'Person Count',
        placeholder: 'Default',
        options: [
          { label: 'Default', value: null },
          { label: 'All', value: 'all' },
        ],
        value: null,
        loading: false,
        isClearable: true,
      },
      personPosition: {
        label: 'Person Position',
        placeholder: 'Default',
        options: [
          { label: 'Default', value: null },
          { label: 'All', value: 'all' },
        ],
        value: null,
        loading: false,
        isClearable: true,
      },
      audience: {
        label: 'Audience',
        placeholder: 'Select an audience',
        options: [],
        value: [],
        loading: false,
        isClearable: true,
        isMulti: true,
      },
    };
  });

  const [posts, setPosts] = useState(null);

  const [socialMediaSort, setSocialMediaSort] = useState(() => {
    // TODO: causing issues with new sort options

    // const storedSort = localStorage.getItem('socialMediaSort');
    // if (storedSort) {
    //   const parsedSort = JSON.parse(storedSort);
    //   if (parsedSort && typeof parsedSort === 'object' && parsedSort.sort) {
    //     return parsedSort;
    //   }
    // }
    return {
      sort: {
        label: 'Sort',
        placeholder: 'Sort By',
        options: [
          { label: 'Freshness', value: 'takenAt.desc' },
          { label: 'Likes', value: 'likes.desc' },
          // { label: 'Default', value: null },
        ],
        value: { label: 'Freshness', value: 'takenAt.desc' },
        loading: false,
        isClearable: true,
      },
    };
  });

  const triggerRefetch = () => {
    setShouldRefetch(true);
  };

  const { isEnabled: exploreAdvanced } = useFeatureFlag(
    FEATURE_FLAGS.EXPLORE_ADVANCED,
  );

  const [socialMediaImageVersion, setSocialMediaImageVersion] =
    useState('full');

  const [
    fetchFashionWeekFilters,
    {
      data: fashionItemFiltersData,
      // loading: fashionItemFiltersLoading,
      // error: fashionItemsFilterError,
      refetch: refetchFashionWeekFilters,
    },
  ] = useLazyQuery(EXPLORE_FASHION_WEEK_FILTERS);

  // Social Media Queries
  const [
    fetchMarkets,
    {
      data: marketsData,
      // loading: marketsLoading
      // error: marketsError
    },
  ] = useLazyQuery(GET_MARKETS);

  // const [fetchSocialMediaNewFilters] = useLazyQuery(GET_TREND_FILTERS);

  const [
    fetchSocialMediaNewFilters,
    { data: socialMediaNewFiltersData, refetch: refetchSocialMediaNewFilters },
  ] = useLazyQuery(SOCIAL_MEDIA_FILTERS);

  // Fashion Weeks Filter Logic
  const initializeFashionItemFilters = () => {
    if (activeTab === 'fashion-weeks') {
      const fashionWeekItemFilters = {
        fashionWeek: {},
        designer: {},
        category: {},
        season: {},
        designs: {},
        itemType: {},
      };

      const filterData =
        fashionItemFiltersData?.exploreFashionWeekItemFilters || [];

      for (const filter of filterData) {
        // Check if this is the itemType filter
        if (filter.name === 'itemType') {
          fashionWeekItemFilters.itemType = {
            ...fashionWeekFilters.itemType,
            label: 'Type',
            placeholder: 'Select Type',
            options: filter.values.map((item) => ({
              label: item.label,
              value: item.value,
            })),
          };
          continue;
        }

        const filterKey = filter.label;
        if (!fashionWeekItemFilters[filterKey]) continue;
        if (fashionWeekFilters[filterKey]?.value) continue;

        let options;

        if (filterKey === 'designs') {
          options = filter.values
            .map((collection) => {
              const pattern = startCase(collection.label);
              const collectionOptions = collection.values
                .filter((item) => item.value !== 'N/A')
                .map((item) => ({
                  label:
                    pattern + ': ' + startCase(item.value.replaceAll('-', ' ')),
                  name: collection.label,
                  value: {
                    name: pattern.toLowerCase(),
                    value: item.value,
                  },
                  group: pattern,
                }));

              return {
                label: pattern,
                options: collectionOptions,
              };
            })
            .filter((group) => group.options.length > 0)
            .sort((a, b) => a.label.localeCompare(b.label));
        } else if (filterKey === 'category') {
          // Handle flat category structure
          options = filter.values
            .map((item) => ({
              label: startCase(item.label.replaceAll(/[-_]/g, ' ')),
              value: item.value,
            }))
            .sort((a, b) => a.label.localeCompare(b.label));
        } else {
          options = filter.values.map((item) => ({
            ...item,
            label: startCase(item.label.replaceAll(/[-_]/g, ' ')),
          }));
        }

        if (filterKey === 'season') {
          options = options
            .sort((a, b) => b.label.localeCompare(a.label))
            .sort((a, b) =>
              b.label
                .split(' ')
                .at(-1)
                .localeCompare(a.label.split(' ').at(-1)),
            );
        } else {
          options = options.sort((a, b) => a.label.localeCompare(b.label));
        }

        fashionWeekItemFilters[filterKey] = {
          ...fashionWeekFilters[filterKey],
          label: startCase(filter.label),
          placeholder: 'Select a ' + filter.label,
          options,
        };

        if (filterKey === 'season') {
          fashionWeekItemFilters[filterKey].value = null;
        }
      }

      return fashionWeekItemFilters;
    }

    return {};
  };

  useEffect(() => {
    const updatedFilters = {};

    if (fashionItemFiltersData) {
      const { designer, category, season, designs, itemType } =
        initializeFashionItemFilters();

      const cities = fashionItemFiltersData.exploreFashionWeekItemFilters.find(
        (filter) => filter.label === 'city',
      ).values;
      if (cities)
        updatedFilters.fashionWeek = {
          ...fashionWeekFilters.fashionWeek,
          options: cities.map((city) => ({
            label: startCase(city.label.replaceAll('-', ' ')),
            value: city.value,
          })),
        };
      if (designer?.label) updatedFilters.designer = designer;
      if (category?.label) {
        updatedFilters.category = category;
      }
      if (season?.label) updatedFilters.season = season;
      if (designs?.label) updatedFilters.designs = designs;
      if (itemType) updatedFilters.itemType = itemType;
    }

    setFashionWeekFilters({
      ...fashionWeekFilters,
      ...updatedFilters,
    });
  }, [fashionItemFiltersData]);

  // Social Media Filters Logic
  useEffect(() => {
    if (marketsData?.markets) {
      const marketOptions = marketsData.markets
        .map((market) => ({
          label: getMarketLabel(market),
          options: market.audiences
            .map((audience) => ({
              label: getAudienceLabel(audience),
              value: `${audience.ageMax}-${audience.ageMin}-${audience.gender}`,
              marketId: market.id,
              audVal: audience.id,
              location: getMarketLabel(market),
              socialWatch: audience?.groups?.[0]?.features?.socialwatch,
            }))
            .sort((a, b) => {
              if (a.socialWatch === b.socialWatch) {
                return a.label.localeCompare(b.label);
              }
              return b.socialWatch - a.socialWatch;
            }),
        }))
        .sort((a, b) => {
          const aOptions = a.options;
          const bOptions = b.options;

          const aSocialWatch = aOptions.find(
            (option) => option.socialWatch === true,
          )?.value;
          const bSocialWatch = bOptions.find(
            (option) => option.socialWatch === true,
          )?.value;

          if (a.label === socialMediaFilters.market.value?.location) return -1;
          if (b.label === socialMediaFilters.market.value?.location) return 1;

          return bSocialWatch - aSocialWatch;
        })
        .filter((market) => market.options.length > 0);

      const apparels = marketsData.markets
        .flatMap((m) => m.apparels)
        .filter(
          (item, index, self) =>
            index === self.findIndex((t) => t.value === item.value),
        )
        .filter((apparel) => categoryByGenderMap[0]?.includes(apparel.value)) // Inital categories for unisex
        .map((apparel) => ({
          label: startCase(apparel.label.replaceAll('-', ' ')),
          value: String(apparel.value),
        }));

      setSocialMediaFilters((prev) => {
        prev.market.options = marketOptions;
        if (prev.category.options.length === 0)
          prev.category.options = apparels;

        return prev;
      });
    }
  }, [marketsData]);

  const handleFashionWeekFiltersOnChange = (
    item,
    filter,
    isExclude = false,
  ) => {
    if (item === null) {
      if (filter === 'category') {
        setFashionWeekFilters((prevFilters) => {
          const newFilters = { ...prevFilters };
          delete newFilters.category.value;
          newFilters.category.value = null;
          return newFilters;
        });
      } else if (filter === 'designs') {
        setFashionWeekFilters((prevFilters) => {
          const newFilters = { ...prevFilters };
          newFilters.designs.value = null;
          return newFilters;
        });
      } else {
        setFashionWeekFilters((prevFilters) => {
          const newFilters = { ...prevFilters };
          newFilters[filter].value = null;
          newFilters[filter].excludeValue = null;
          return newFilters;
        });
      }
    }

    setFashionWeekFilters((prevFilters) => {
      const newFilters = { ...prevFilters };
      const currentFilter = newFilters[filter];

      if (currentFilter.isMulti) {
        if (isExclude) {
          // Handle exclude array
          const currentExcludes = currentFilter.excludeValue || [];
          const excludeIndex = currentExcludes.findIndex((v) =>
            filter === 'category'
              ? v.value === item.value && v.type === item.type
              : filter === 'designs'
                ? v.label === item.label
                : v.value === item.value,
          );

          if (excludeIndex > -1) {
            // Remove if exists
            currentExcludes.splice(excludeIndex, 1);
          } else {
            // Add if doesn't exist
            // Check if item exists in value array
            const valueArray = currentFilter.value || [];
            const existsInValue = valueArray.some((v) =>
              filter === 'category'
                ? v.value === item.value && v.type === item.type
                : filter === 'designs'
                  ? v.label === item.label
                  : v.value === item.value,
            );

            if (!existsInValue) {
              currentExcludes.push(item);
            }
          }

          currentFilter.excludeValue = currentExcludes.length
            ? currentExcludes
            : null;
        } else {
          // Handle value array
          const currentValues = currentFilter.value || [];

          // Skip processing if item is null for category or designs
          if (
            item === null &&
            (filter === 'category' || filter === 'designs')
          ) {
            currentFilter.value = null;
            return newFilters;
          }

          const valueIndex = currentValues.findIndex((v) =>
            filter === 'category'
              ? v.value === item.value && v.type === item.type
              : filter === 'designs'
                ? v.label === item.label
                : v.value === item.value,
          );

          if (valueIndex > -1) {
            // Remove if exists
            currentValues.splice(valueIndex, 1);
          } else {
            // Add if doesn't exist
            // Check if item exists in exclude array
            const excludeArray = currentFilter.excludeValue || [];
            const existsInExclude = excludeArray.some((v) =>
              filter === 'category'
                ? v.value === item.value && v.type === item.type
                : filter === 'designs'
                  ? v.label === item.label
                  : v.value === item.value,
            );

            if (!existsInExclude) {
              currentValues.push(item);
            }
          }

          currentFilter.value = currentValues.length ? currentValues : null;
        }
      } else {
        currentFilter.value = item;
      }

      if (activeTab === 'fashion-weeks') {
        refetchFashionWeekFilters({
          notifyOnNetworkStatusChange: true,
          fetchPolicy: 'network-only',
          filters: returnFashionItemFilters(newFilters),
        });
      }

      return newFilters;
    });
  };

  const handleSocialMediaSortOnChange = (item, sortKey) => {
    if (item === null && sortKey === 'sort') {
      localStorage.removeItem('socialMediaSort');

      setSocialMediaSort((prevSort) => ({
        ...prevSort,
        [sortKey]: {
          ...prevSort[sortKey],
          value: null,
        },
      }));

      return;
    }

    setSocialMediaSort((prevSort) => ({
      ...prevSort,
      [sortKey]: {
        ...prevSort[sortKey],
        value: item?.value ? item : null,
      },
    }));

    localStorage.setItem(
      'socialMediaSort',
      JSON.stringify({
        ...socialMediaSort,
        [sortKey]: {
          ...socialMediaSort[sortKey],
          value: item,
        },
      }),
    );
  };

  const handleSocialMediaFiltersOnChange = (
    item,
    filter,
    hardSet = false,
    isExclude = false,
  ) => {
    if (hardSet) {
      socialMediaFilters[filter] = {
        ...socialMediaFilters[filter],
        [isExclude ? 'excludeValue' : 'value']: item,
      };
      localStorage.setItem(
        'socialMediaFilters',
        JSON.stringify(socialMediaFilters),
      );
      setShouldRefetch(true);
      return;
    }

    setSocialMediaFilters((prevFilters) => {
      const currentValue = isExclude
        ? prevFilters[filter]?.excludeValue
        : prevFilters[filter]?.value;

      const otherListValue = isExclude
        ? prevFilters[filter]?.value
        : prevFilters[filter]?.excludeValue;

      // Check if item exists in current list
      const isItemInCurrentList =
        prevFilters[filter]?.isMulti &&
        Array.isArray(currentValue) &&
        currentValue?.some(
          (existingItem) =>
            JSON.stringify(existingItem) === JSON.stringify(item),
        );

      // Check if item exists in other list
      const isItemInOtherList =
        prevFilters[filter]?.isMulti &&
        Array.isArray(otherListValue) &&
        otherListValue?.some(
          (existingItem) =>
            JSON.stringify(existingItem) === JSON.stringify(item),
        );

      // If item exists in current list, remove it
      if (isItemInCurrentList) {
        const newValue = currentValue.filter(
          (existingItem) =>
            JSON.stringify(existingItem) !== JSON.stringify(item),
        );

        return {
          ...prevFilters,
          [filter]: {
            ...prevFilters[filter],
            [isExclude ? 'excludeValue' : 'value']:
              newValue.length === 0 ? null : newValue,
            [isExclude ? 'value' : 'excludeValue']: otherListValue,
          },
        };
      }

      // If item exists in other list, remove it from there
      let updatedOtherList = otherListValue;
      if (isItemInOtherList) {
        updatedOtherList = otherListValue.filter(
          (existingItem) =>
            JSON.stringify(existingItem) !== JSON.stringify(item),
        );
        if (updatedOtherList.length === 0) {
          updatedOtherList = null;
        }
      }

      // Rest of the function remains same...
      const updatedFilters = {
        ...prevFilters,
        [filter]: {
          ...prevFilters[filter],
          [isExclude ? 'excludeValue' : 'value']:
            filter === 'gender' && item === null
              ? genders[0]
              : hardSet
                ? item
                : prevFilters[filter].isMulti
                  ? [
                      ...(Array.isArray(currentValue) ? currentValue : []),
                      ...(Array.isArray(item) && item[0] ? [item[0]] : [item]),
                    ]
                  : item,
          [isExclude ? 'value' : 'excludeValue']: updatedOtherList,
        },
      };

      if (filter === 'gender') {
        const selectedGender = item?.value;

        if (marketsData?.markets) {
          const currentMarkets = socialMediaFilters.market.value || [];

          const validMarkets = currentMarkets.filter((market) => {
            const marketData = marketsData.markets.find(
              (m) => m.id === market.marketId,
            );
            const audience = marketData?.audiences.find(
              (a) => a.id === market.value,
            );
            return (
              audience &&
              (selectedGender === '0' || audience.gender === selectedGender)
            );
          });

          const marketOptions = marketsData.markets
            .map((market) => ({
              label: getMarketLabel(market),
              options: market.audiences
                .filter((audience) => {
                  if (selectedGender === '0') return true;
                  return audience.gender === selectedGender;
                })
                .map((audience) => ({
                  label: getAudienceLabel(audience),
                  value: `${audience.ageMax}-${audience.ageMin}-${audience.gender}`,
                  marketId: market.id,
                  audVal: audience.id,
                  location: getMarketLabel(market),
                  socialWatch: audience?.groups?.[0]?.features?.socialwatch,
                }))
                .sort((a, b) => {
                  if (a.socialWatch === b.socialWatch) {
                    return a.label.localeCompare(b.label);
                  }
                  return b.socialWatch - a.socialWatch;
                }),
            }))
            .filter((market) => market.options.length > 0);

          updatedFilters.market = {
            ...updatedFilters.market,
            options: marketOptions,
            value: validMarkets.length > 0 ? validMarkets : null,
          };

          if (validMarkets.length > 0) {
            const validAudienceValues = prevFilters.audience.value?.filter(
              (audienceId) => {
                return validMarkets.some((market) =>
                  market.groups?.some((g) => g.audienceId === audienceId),
                );
              },
            );

            updatedFilters.audience = {
              ...updatedFilters.audience,
              value: validAudienceValues || [],
            };
          } else {
            updatedFilters.audience = {
              ...updatedFilters.audience,
              value: [],
            };
          }

          const allOfPlatforms = { ...platforms[0] };
          delete allOfPlatforms.id;

          const uniquePlatforms = [
            ...new Set(
              validMarkets
                .map((market) => market.groups)
                .flatMap((group) => group)
                .map((group) => group.platform),
            ),
          ];

          updatedFilters.platform = {
            ...prevFilters.platform,
            options:
              currentMarkets.length === 0
                ? [
                    { label: 'ALL', value: 'ALL' },
                    { label: 'Instagram', value: 'INSTAGRAM' },
                    { label: 'TikTok', value: 'TIKTOK' },
                  ]
                : uniquePlatforms.length > 1
                  ? [
                      allOfPlatforms,
                      ...uniquePlatforms.map((platform) => ({
                        label: platform,
                        value: platform,
                      })),
                    ]
                  : uniquePlatforms.map((platform) => ({
                      label: platform,
                      value: platform,
                    })),
            value:
              uniquePlatforms.length === 0
                ? null
                : {
                    label:
                      uniquePlatforms.length === 1 ? uniquePlatforms[0] : 'ALL',
                    value:
                      uniquePlatforms.length === 1 ? uniquePlatforms[0] : 'ALL',
                  },
          };

          if (validMarkets.length > 0) {
            const filteredApparels = marketsData.markets
              .flatMap((m) => m.apparels)
              .filter(
                (item, index, self) =>
                  index === self.findIndex((t) => t.value === item.value),
              )
              .filter((apparel) =>
                categoryByGenderMap[selectedGender]?.includes(apparel.value),
              )
              .map((apparel) => ({
                label: startCase(apparel.label.replaceAll('-', ' ')),
                value: String(apparel.value),
              }));

            updatedFilters.category.options = filteredApparels;
          }
        }
      }

      if (filter === 'market') {
        if (item === null) {
          updatedFilters.audience = {
            ...prevFilters.audience,
            value: [],
          };
          updatedFilters.platform = {
            ...prevFilters.platform,
            options: [
              { label: 'ALL', value: 'ALL' },
              { label: 'Instagram', value: 'INSTAGRAM' },
              { label: 'TikTok', value: 'TIKTOK' },
            ],
            value: null,
          };
          updatedFilters.market = {
            ...prevFilters.market,
            value: null,
          };

          const filteredApparels = marketsData.markets
            .flatMap((m) => m.apparels)
            .filter(
              (item, index, self) =>
                index === self.findIndex((t) => t.value === item.value),
            )
            .filter((apparel) =>
              categoryByGenderMap[
                prevFilters.gender.value?.value || '0'
              ]?.includes(apparel.value),
            )
            .map((apparel) => ({
              label: startCase(apparel.label.replaceAll('-', ' ')),
              value: String(apparel.value),
            }));

          updatedFilters.category.options = filteredApparels;
        } else {
          const market = marketsData?.markets.find(
            (market) => market.id === item?.marketId,
          );

          const audience = market?.audiences.find((audience) => {
            const key = audience.id;

            return key === item?.audVal;
          });

          const currentMarkets = prevFilters.market.value || [];

          const marketFoundIndex = currentMarkets.findIndex(
            (market) => market.value === item?.audVal,
          );

          if (marketFoundIndex === -1) {
            currentMarkets.push({
              label: item?.location + ', ' + item?.label,
              value: item?.audVal,
              location: item?.location,
              groups: audience?.groups,
              marketId: market?.id,
            });
          } else {
            currentMarkets.splice(marketFoundIndex, 1);
          }

          if (audience) {
            updatedFilters.market = {
              ...prevFilters.market,
              value: currentMarkets.length > 0 ? currentMarkets : null,
            };

            const currentAudiences = prevFilters.audience.value || [];

            audience.groups.forEach(({ audienceId }) => {
              const foundIndex = currentAudiences.findIndex(
                (id) => id === audienceId,
              );
              if (foundIndex === -1) {
                currentAudiences.push(audienceId);
              } else {
                currentAudiences.splice(foundIndex, 1);
              }
            });

            updatedFilters.audience = {
              ...prevFilters.audience,
              value: currentAudiences,
            };

            const allOfPlatforms = {
              ...platforms[0],
            };
            delete allOfPlatforms.id;

            const uniquePlatforms = [
              ...new Set(
                currentMarkets
                  .map((market) => market.groups)
                  .flatMap((group) => group)
                  .map((group) => group.platform),
              ),
            ];

            allOfPlatforms.audienceId = audience.groups.map(
              (group) => group.audienceId,
            );
            updatedFilters.platform = {
              ...prevFilters.platform,
              options:
                currentMarkets.length === 0
                  ? [
                      { label: 'ALL', value: 'ALL' },
                      { label: 'Instagram', value: 'INSTAGRAM' },
                      { label: 'TikTok', value: 'TIKTOK' },
                    ]
                  : uniquePlatforms.length > 1
                    ? [
                        allOfPlatforms,
                        ...uniquePlatforms.map((platform) => ({
                          label: platform,
                          value: platform,
                        })),
                      ]
                    : uniquePlatforms.map((platform) => ({
                        label: platform,
                        value: platform,
                      })),
              value:
                uniquePlatforms.length === 0
                  ? null
                  : {
                      label:
                        uniquePlatforms.length === 1
                          ? uniquePlatforms[0]
                          : 'ALL',
                      value:
                        uniquePlatforms.length === 1
                          ? uniquePlatforms[0]
                          : 'ALL',
                    },
            };
            if (audience?.groups?.[0]?.features?.socialwatch) {
              updatedFilters.timeframe = {
                ...prevFilters.timeframe,
                options: [
                  {
                    label: 'Last Week',
                    value: 'last-week',
                    socialWatch: true,
                  },
                  {
                    label: 'Last Two Weeks',
                    value: 'last-two-weeks',
                    socialWatch: true,
                  },
                  ...seasons,
                ],
              };
            }
          }

          if (audience) {
            const filteredApparels = marketsData.markets
              .flatMap((m) => m.apparels)
              .filter(
                (item, index, self) =>
                  index === self.findIndex((t) => t.value === item.value),
              )
              .filter((apparel) =>
                categoryByGenderMap[audience.gender || '0']?.includes(
                  apparel.value,
                ),
              )
              .map((apparel) => ({
                label: startCase(apparel.label.replaceAll('-', ' ')),
                value: String(apparel.value),
              }));

            updatedFilters.category.options = filteredApparels;
          }
        }
      }

      if (filter === 'platform') {
        if (item?.value === 'all') {
          const audienceIds = socialMediaFilters.market.value?.reduce(
            (acc, market) => {
              const selectedMarket = marketsData?.markets.find(
                (m) => getMarketLabel(m) === market.location,
              );

              const selectedAudience = selectedMarket?.audiences.find(
                (audience) => audience.id === market.value,
              );

              if (selectedAudience?.groups) {
                acc.push(
                  ...selectedAudience.groups.map((group) => group.audienceId),
                );
              }

              return acc;
            },
            [],
          );

          updatedFilters.audience.value = audienceIds;
        } else {
          const audienceIds = socialMediaFilters.market.value?.reduce(
            (acc, market) => {
              const selectedMarket = marketsData?.markets.find(
                (m) => getMarketLabel(m) === market.location,
              );

              const selectedAudience = selectedMarket?.audiences.find(
                (audience) => audience.id === market.value,
              );

              const filteredGroups = selectedAudience?.groups?.filter(
                (group) =>
                  group.platform.toLowerCase() === item?.value?.toLowerCase(),
              );

              if (filteredGroups?.length) {
                acc.push(...filteredGroups.map((group) => group.audienceId));
              }

              return acc;
            },
            [],
          );

          updatedFilters.audience.value = audienceIds || [];
        }

        if (item === null) {
          updatedFilters.market.value = null;
        }
      }

      if (filter === 'timeframe') {
        if (item?.socialWatch) {
          const filters = {
            audiences:
              typeof prevFilters.audience.value === 'string'
                ? [prevFilters.audience.value]
                : prevFilters.audience.value,
          };

          if (socialMediaFilters.category.value) {
            filters.apparels = Array.isArray(prevFilters.category.value)
              ? prevFilters.category.value.map((item) => item.value)
              : [prevFilters.category.value?.value];
          }

          fetchSocialMediaNewFilters({
            variables: filters,
            fetchPolicy: 'network-only',
          });
        }
      }

      if (filter === 'category') {
        if (prevFilters.timeframe.value?.socialWatch) {
          const filters = {
            audiences:
              typeof prevFilters.audience.value === 'string'
                ? [prevFilters.audience.value]
                : prevFilters.audience.value,
          };

          let existingApparels = [];
          if (socialMediaFilters.category.value) {
            existingApparels = Array.isArray(prevFilters.category.value)
              ? prevFilters.category.value.map((item) => item.value)
              : [prevFilters.category.value?.value];
          }

          if (item) {
            const newApparels = Array.isArray(item)
              ? item.map((item) => item.value)
              : [item?.value];

            filters.apparels = [...existingApparels, ...newApparels];
          } else {
            filters.apparels = existingApparels;
          }

          fetchSocialMediaNewFilters({
            variables: filters,
            fetchPolicy: 'network-only',
          });
        }
      }

      if (filter === 'category' && item === null) {
        updatedFilters[filter].value = null;
        updatedFilters[filter].excludeValue = null;
      }

      if (filter === 'attribute.custom' && item === null) {
        updatedFilters[filter].value = null;
        updatedFilters[filter].excludeValue = null;
      }

      if (filter === 'color' && item === null) {
        updatedFilters[filter].value = null;
        updatedFilters[filter].excludeValue = null;
      }

      if (filter === 'subcolor' && item === null) {
        updatedFilters[filter][isExclude ? 'excludeValue' : 'value'] = null;
      }

      // Return the updated filters for the state update
      // localStorage.setItem(
      //   'socialMediaFilters',
      //   JSON.stringify(updatedFilters),
      // );

      return updatedFilters;
    });
  };

  const previousPath = location.state?.previousPath?.includes('/trends');

  useEffect(() => {
    const params = location.search;
    if (params.includes('design') || params.includes('tone')) {
      triggerRefetch();
      navigate(location.pathname);
    }
  }, [location]);

  useEffect(() => {
    if (previousPath) {
      const storedFilters = JSON.parse(
        localStorage.getItem('socialMediaFilters'),
      );
      if (storedFilters) {
        setSocialMediaFilters(storedFilters);
        localStorage.removeItem('socialMediaFilters');
      }
    }
  }, [previousPath]);

  useEffect(() => {
    const filters = {};
    if (
      socialMediaFilters.audience.value &&
      socialMediaFilters.audience.value.length > 0
    ) {
      filters.audiences = socialMediaFilters.audience.value;
    }

    if (
      Array.isArray(socialMediaFilters.category.value) &&
      socialMediaFilters.category.value.length > 0
    ) {
      filters.apparels = socialMediaFilters.category.value.map((item) => ({
        value: item.value,
      }));
    }

    if (socialMediaFilters.subcolor.value) {
      filters.subColors = socialMediaFilters.subcolor.value.map(
        (item) => item.value,
      );
    }

    if (socialMediaFilters['attribute.custom']?.value) {
      filters.attributes = socialMediaFilters['attribute.custom'].value.map(
        (attr) => ({
          name: attr.name,
          value: attr.value,
        }),
      );
    }

    console.log('filters', filters);

    if (
      filters.audiences ||
      filters.apparels ||
      filters.subColors ||
      filters.attributes
    ) {
      refetchSocialMediaNewFilters({ filters });
    }
  }, [
    socialMediaFilters.category.value,
    socialMediaFilters.market.value,
    socialMediaFilters.subcolor.value,
    socialMediaFilters['attribute.custom']?.value,
  ]);

  console.log(socialMediaFilters['attribute.custom']);

  useEffect(() => {
    const socialMediaFilters = socialMediaNewFiltersData?.socialMediaFilters;

    if (socialMediaFilters) {
      const filters = socialMediaFilters.reduce((obj, filterGroup) => {
        // Check if the group contains valid values
        if (Array.isArray(filterGroup.values)) {
          if (filterGroup.label === 'designs') {
            // Handle designs specifically
            if (!obj['attribute.custom']) {
              obj['attribute.custom'] = {
                label: 'Design',
                placeholder: 'Select Design',
                isClearable: true,
                isMulti: true,
                isSearchable: true,
                options: [],
              };
            }

            // Group options by the labels in values array
            filterGroup.values.forEach((group) => {
              if (Array.isArray(group.values)) {
                const groupLabel = startCase(group.label.replaceAll('-', ' '));
                const groupOptions = group.values
                  .filter((item) => item.value !== 'N/A')
                  .map((item) => ({
                    label:
                      groupLabel +
                      ': ' +
                      startCase(item.label.replaceAll('-', ' ')),
                    name: group.label,
                    value: item.value,
                    group: groupLabel,
                  }));

                if (groupOptions.length > 0) {
                  obj['attribute.custom'].options.push({
                    label: groupLabel,
                    options: groupOptions,
                  });
                }
              }
            });
          } else if (filterGroup.label === 'apparels') {
            // Handle apparels for category options
            const filteredApparels = filterGroup.values
              .filter(
                (item, index, self) =>
                  index === self.findIndex((t) => t.value === item.value),
              )
              .map((apparel) => ({
                label: startCase(
                  apparelMapping[apparel.value] ||
                    apparel.label.replaceAll('-', ' '),
                ),
                value: String(apparel.value),
              }));

            obj.category = {
              ...obj.category,
              options: filteredApparels,
            };
          } else if (filterGroup.label === 'subcolors') {
            const subColorOptionsMapping = subColorOptions
              .map((subColorOption) => {
                const matchingColor = filterGroup.values.find(
                  (color) =>
                    color.label.toLowerCase() ===
                    subColorOption.value.toLowerCase(),
                );

                if (!matchingColor) return null;

                return {
                  label: startCase(subColorOption.label.replaceAll('-', ' ')),
                  value: matchingColor.value,
                  hex: subColorOption.hex,
                  hexList: subColorOption.hexList,
                };
              })
              .filter(Boolean);

            obj.subcolor = {
              ...obj.subcolor,
              options: subColorOptionsMapping,
            };
          }
        }

        return obj;
      }, {});

      setSocialMediaFilters((prevFilters) => ({
        ...prevFilters,
        ...filters,
        'attribute.custom': {
          ...prevFilters['attribute.custom'],
          ...(filters['attribute.custom'] || {}),
        },
        category: {
          ...prevFilters.category,
          ...(filters.category || {}),
        },
        subcolor: {
          ...prevFilters.subcolor,
          ...(filters.subcolor || {}),
        },
      }));
    }
  }, [socialMediaNewFiltersData]);

  const [getTypeaheadSuggestions] = useLazyQuery(GET_TYPEAHEAD_SUGGESTIONS, {
    fetchPolicy: 'cache-first',
  });

  const loadFashionWeekSearchOptions = (inputValue, callback) => {
    const query = inputValue.toLowerCase();
    if (!query || query.length < 3) {
      // eslint-disable-next-line n/no-callback-literal
      return callback([]);
    }

    getTypeaheadSuggestions({
      variables: {
        query,
      },
      onCompleted: (data) => {
        const options = data?.typeaheadSuggestions?.options?.map((item) => ({
          label: item.label,
          value: item.value,
        }));

        callback(options);
      },
    });
  };

  function fashionWeekClearAll() {
    const updatedFilters = {};

    for (const filter in fashionWeekFilters) {
      updatedFilters[filter] = {
        ...fashionWeekFilters[filter],
        value: null,
        excludeValue: null,
      };
    }

    // clear search filter
    updatedFilters.search.value = '';

    setFashionWeekFilters(updatedFilters);
  }

  function socialMediaClearAll() {
    const updatedFilters = {};

    for (const filter in socialMediaFilters) {
      const currentFilter = socialMediaFilters[filter];

      updatedFilters[filter] = {
        ...currentFilter,
        value:
          filter === 'gender' ? genders[0] : filter === 'audience' ? [] : null,
        excludeValue: null,
      };
    }

    updatedFilters.search.value = '';

    setSocialMediaFilters(updatedFilters);
    localStorage.removeItem('socialMediaSort');
  }

  useEffect(() => {
    if (activeTab === 'fashion-weeks') {
      fetchFashionWeekFilters({
        variables: {
          season: [],
        },
      });
      setIsGenderTouched(false);
      setIncludes([]);
      setExcludes([]);
    } else if (activeTab === 'social-media') {
      fetchMarkets();
      fetchSocialMediaNewFilters();
      setIncludes([]);
      setExcludes([]);
    }
  }, [activeTab]);

  function clearAll() {
    if (activeTab === 'fashion-weeks') {
      setIncludes([]);
      setExcludes([]);
      fashionWeekClearAll();
      fetchFashionWeekFilters({
        variables: {
          season: [],
        },
      });
      triggerRefetch();
    } else if (activeTab === 'social-media') {
      fetchMarkets();
      fetchSocialMediaNewFilters();
      setIncludes([]);
      setExcludes([]);
      socialMediaClearAll();
      triggerRefetch();
    }
  }

  useEffect(() => {
    const htmlElement = document.documentElement;

    if (isExploreMobile) {
      htmlElement.classList.remove('is-not-explore-mobile');
    } else {
      htmlElement.classList.add('is-not-explore-mobile');
    }
  }, [isExploreMobile]);

  return (
    <ExploreContext.Provider
      value={{
        activeTab,
        setActiveTab: handleSetActiveTab,
        fashionWeekFilters,
        handleFashionWeekFiltersOnChange,
        socialMediaFilters,
        handleSocialMediaSortOnChange,
        socialMediaSort,
        handleSocialMediaFiltersOnChange,
        clearAll,
        fashionWeekClearAll,
        socialMediaClearAll,

        selectedSocialMediaAudience: socialMediaFilters.audience?.value,
        selectedSocialMediaColor: socialMediaFilters.color?.value,
        selectedSocialMediaSubColor: socialMediaFilters.subcolor?.value,
        selectedSocialMediaCategory: socialMediaFilters.category.value,
        selectedSocialMediaTimeframe: socialMediaFilters?.timeframe?.value,
        selectedSocialMediaPersonCount: exploreAdvanced
          ? socialMediaFilters?.personCount?.value
          : null,
        selectedSocialMediaPersonPosition: exploreAdvanced
          ? socialMediaFilters?.personPosition?.value
          : null,
        selectedSocialMediaTone: socialMediaFilters.tone?.value,
        socialMediaSorting: socialMediaSort.sort.value,

        socialMediaImageVersion,
        setSocialMediaImageVersion,

        posts,
        setPosts,
        hasBannedApparel,
        setHasBannedApparel,

        loadFashionWeekSearchOptions,

        shouldRefetch,
        setShouldRefetch,
        triggerRefetch,
        setSocialMediaSort,
        includes,
        setIncludes,
        excludes,
        setExcludes,
        marketsData,

        // explore mobile experience
        isExploreMobile,
        isFiltersOpen,
        setIsFiltersOpen,
        isSearchOpen,
        setIsSearchOpen,

        hoveredCardId,
        setHoveredCardId,

        isGenderTouched,
        setIsGenderTouched,
        activeSearchTab,
        setActiveSearchTab,
      }}
    >
      {children}
    </ExploreContext.Provider>
  );
};

export const useExploreContext = () => useContext(ExploreContext);
