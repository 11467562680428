import React from 'react';

export const SelectPlanCaption = () => (
  <>
    <h5 className="mt-[52px] text-center text-[48px] font-semibold leading-[55px]">
      Select a Plan
    </h5>
    <p className="font-regular mx-auto mb-[32px] mt-[16px] max-w-[546px] text-center text-[14px] text-[#626775]">
      Choose a subscription that best aligns with your fashion analysis needs,
      providing tailored insights and robust trend forecasting across pivotal
      global fashion capitals.
    </p>
  </>
);
