import { clsx } from 'clsx';
import { capitalize } from 'lodash';
import numeral from 'numeral';
import { twMerge } from 'tailwind-merge';

export function cn(...inputs) {
  return twMerge(clsx(inputs));
}

export function getInitials(name) {
  return name
    .split(' ')
    .map((part) => part.charAt(0))
    .join('')
    .toUpperCase();
}

export function deepEqual(obj1, obj2) {
  return JSON.stringify(obj1) === JSON.stringify(obj2);
}

export function formatLabel(string) {
  return capitalize(string.replaceAll('-', ' '));
}

export const stringFormatter = (str) =>
  str
    .split('-')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');

export const downloadFile = (url, filename) => {
  return new Promise((resolve, reject) => {
    if (!url) {
      reject(new Error('Resource URL not provided! You need to provide one'));
    }

    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        const blobURL = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = blobURL;
        a.style = 'display: none';

        if (filename && filename.length) a.download = filename;
        document.body.appendChild(a);
        a.click();

        // Clean up
        window.URL.revokeObjectURL(blobURL);
        document.body.removeChild(a);

        resolve();
      })
      .catch((error) => reject(error));
  });
};

export const generateFakeId = (id) => {
  const hash = id.split('').reduce((acc, char) => {
    return ((acc << 5) - acc + char.charCodeAt(0)) | 0;
  }, 0);

  return Math.abs(hash)
    .toString(36)
    .replace(/[^a-z]+/g, '')
    .substring(0, 6);
};

export const timeframeMapping = {
  lastWeek: 'weeklyData',
  lastTwoWeeks: 'twoWeeklyData',
  lastFourWeeks: 'fourWeeklyData',
};

export const timeframeLabelMapping = {
  lastWeek: 'Previous Week',
  lastTwoWeeks: 'Previous 2 Weeks',
  lastFourWeeks: 'Previous 4 Weeks',
};

export const timeframeExploreFiltersMapping = {
  lastWeek: {
    label: 'Last Week',
    value: 'last-week',
    socialWatch: true,
  },
  lastTwoWeeks: {
    label: 'Last Two Weeks',
    value: 'last-two-weeks',
    socialWatch: true,
  },
  lastFourWeeks: {
    label: 'Last Two Weeks',
    value: 'last-two-weeks',
    socialWatch: true,
  },
};

export function getEngagementValue(value, filterSort = 'QualityEngagement') {
  if (filterSort === 'Magnitude') {
    const magnitudeMap = {
      1: 'Micro',
      2: 'Medium',
      3: 'Macro',
    };
    return (
      magnitudeMap[value] || value.charAt(0).toUpperCase() + value.slice(1)
    );
  }

  if (Number.isInteger(value)) {
    return numeral(value).format('0a').toUpperCase();
  }

  if (typeof value === 'string') {
    return value.charAt(0).toUpperCase() + value.slice(1);
  }

  return numeral(value).format('0.0a').toUpperCase();
}

export function nameFormat(input) {
  const words = input.split(/[-,]/).map((word) => word.trim());

  const formatted = words
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');

  return formatted;
}
